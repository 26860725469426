import { Chip, Typography } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import { CHART_COLORS } from 'app/styles/colors'
import React from 'react'
import styles from './styles.module.scss'

const ChartFilter = ({ groupId, groupTitle, variables, values, groupIndex, onSelectionChange, colors }) => {
  const [selectedState, setSelectedState] = React.useState(new Array(variables.length).fill(true))

  const handleClick = index => {
    let newVals = selectedState.map((item, i) => {
      return index === i ? !item : item
    })
    setSelectedState(newVals)
    onSelectionChange(groupId, newVals)
  }

  const getColor = (groupIndex, index, varId) => {
    if (colors) {
      return colors.get(varId)
    } else {
      return CHART_COLORS[groupIndex][index]
    }
  }

  return (
    <div className={styles.wrapper}>
      <Typography className={styles.groupTitle}>{groupTitle}</Typography>
      <div className={styles.container}>{variables?.map((item, index) => renderVariable(item, index))}</div>
    </div>
  )

  function renderVariable(variable, index) {
    return (
      <div className={styles.button}>
        <Chip
          className={`${styles.chip} ${selectedState[index] ? styles.active : styles.inactive}`}
          size="small"
          key={index}
          icon={
            selectedState[index] ? (
              <FiberManualRecordIcon style={{ color: getColor(groupIndex, index, variable.id) }} />
            ) : (
              <FiberManualRecordOutlinedIcon style={{ color: getColor(groupIndex, index, variable.id) }} />
            )
          }
          label={variable.name}
          clickable
          style={{ borderColor: getColor(groupIndex, index, variable.id), color: getColor(groupIndex, index, variable.id) }}
          variant="outlined"
          onClick={() => handleClick(index)}
        />
        {values && selectedState[index] ? (
          <Typography className={styles.valueLegend}>{values.find(val => val.id === variable.id).value}</Typography>
        ) : (
          <Typography className={styles.valueLegend}>&nbsp;</Typography>
        )}
      </div>
    )
  }
}

export default ChartFilter
