import { shape, string } from 'prop-types';
/* import { UTLabel } from '@widergy/energy-ui'; */
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import moment from 'moment-timezone';
import styles from './styles.module.scss';

const formatValue = (value, title, decimals) => {
  if (!value) return null

  if (title.toUpperCase() === 'TIMESTAMP') return `${moment(value).format('DD/MM/YYYY HH:mm:ss')}`

  const valueString = value.toString()
  const decimalPosition = valueString.indexOf('.')

  const valueFormat = `${decimalPosition === -1 ? valueString : valueString.substring(0, decimalPosition + decimals + 1)}`

  return valueFormat === `-0.${'0'.repeat(decimals)}` || valueFormat === `0.${'0'.repeat(decimals)}` ? '0' : valueFormat
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 450,
    overflowY: 'scroll',
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'monospace',
  },
}))(Tooltip);

const MeasureItem = ({ data, validationData }) => {
  const dataPrimaryValue = formatValue(data.value, data.title, data.decimalView || 2) || '0'
  const dataSecondaryValue =
    data.title.toUpperCase() !== 'TIMESTAMP'
      ? formatValue(data.scondaryData.value, data.scondaryData.title, data.scondaryData.decimalView || 2) || '0'
      : ''

  const dataUnit = data.unit || ''

  const validation = Array.isArray(validationData) ? validationData?.find(item => item.code === data.code) : undefined
  const validated = validation ? validation.validation_passed : true
  const validationJSON = validation?.validationData && JSON.stringify(validation.validationData)

  return (
    <div className={styles.listItem}>
      {dataPrimaryValue !== 'NA' && dataSecondaryValue !== 'NA' && (
        <div className={styles.item}>
          <Typography variant='body2'>{data.description}</Typography>
          {dataSecondaryValue ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 20 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minWidth: 150 }}>
                <Typography variant='caption' style={{ opacity: 0.5 }}>
                  Primario
                </Typography>
                <Typography variant='body2' color='primary'>{`${Number(data.value).toLocaleString('es-AR', {
                  minimumFractionDigits: data.decimalView,
                })} ${dataUnit}`}</Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minWidth: 100 }}>
                <Typography variant='caption' style={{ opacity: 0.5 }}>
                  Secundario
                </Typography>
                {
                  !validated ? (
                    <div className={styles.errorContainer}>
                      <Typography variant='body2' color='error'>{`${Number(data.scondaryData.value).toLocaleString('es-AR', {
                      minimumFractionDigits: data.scondaryData.decimalView,
                    })} ${dataUnit}`}</Typography>
                        <HtmlTooltip
                            placement="left-start"
                            title={JSON.stringify(data || {})}
                          >
                            <IconButton size="small">
                              <CodeRoundedIcon fontSize="small" />
                            </IconButton>
                          </HtmlTooltip>
                     
                      {/* <Tooltip title={validationJSON} placement="left">
                          <IconButton size="small" style={{marginLeft:10, marginTop:-10}}>
                            <ERROR_DATA_VALIDATION/>
                            </IconButton>
                      </Tooltip> */}
                    </div>)
                  : (<Typography variant='body2' color='primary'>{`${Number(data.scondaryData.value).toLocaleString('es-AR', {
                  minimumFractionDigits: data.scondaryData.decimalView,
                })} ${dataUnit}`}</Typography>)
                }
              </div>
            </div>
          ) : (
            <div style={{ minWidth: 200 }}>
              {data.title.toUpperCase() !== 'TIMESTAMP' ? (
                <Typography variant='body2' color='primary'>{`${Number(data.value).toLocaleString('es-AR', {
                  minimumFractionDigits: data.decimalView,
                })} ${dataUnit}`}</Typography>
              ) : (
                <Typography variant='body2' color='primary'>
                  {formatValue(data.value, data.title)}
                </Typography>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

MeasureItem.propTypes = {
  data: shape({
    detail: string.isRequired,
    value: string.isRequired,
  }).isRequired,
}

export default MeasureItem
