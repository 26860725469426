import apiIOT from 'config/api'
import { UTILITY } from 'constants/apiResources'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  healthCheck: async () => {
    apiIOT.setHeaders({
      ...apiIOT.headers,
    })
    const response = await apiIOT.get(UTILITY.HEALTHCHECK)
    return response?.data
  },
}
