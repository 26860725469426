import React from 'react'

/** Map and geo utils */
import MapGL, { _MapContext as MapContext, NavigationControl, ScaleControl, GeolocateControl } from 'react-map-gl'
import DeckGL from '@deck.gl/react'
import { FlyToInterpolator, WebMercatorViewport } from '@deck.gl/core'
import { IconLayer } from '@deck.gl/layers'
import { points, center } from '@turf/turf'
import IconClusterLayer from './LogicalUnitsLayer/clusterLayer'

/** View compoenents */
import Slide from '@material-ui/core/Slide'
import HierarchySelector from '../HierarchySelector'
import StatisticsOperationalPanel from '../StatisticsOperationalPanel/container'
import BubbleChartRoundedIcon from '@material-ui/icons/BubbleChartRounded'
import ToggleButton from '@material-ui/lab/ToggleButton'

/** Hierarchy Layer */
import HierarchyTooltip from './HierarchyLayer/components/HierarchyTooltip/view'
import { getHierarchyIconMarker, getHierarchyIconSize, getHierarchyLoadOptions } from './HierarchyLayer/markers'
import { getSessionStatisticsOperationalPanelFilters } from 'services/LocalStorageService'
import { OPERATION_STATUS, OPERATION_COMPONENT } from 'constants/iot'
import { UTILITY_DEFAULT_MAP_LOCATION } from 'constants/utility'
import { setSessionStatisticsOperationalPanelFilters } from 'services/LocalStorageService'

/** Redux */
import { useSelector, useDispatch } from 'react-redux'
import ioTHierarchyActions from 'redux/ioTHierarchy/actions'
import ioTLogicalUnitActions from 'redux/ioTLogicalUnit/actions'
import { briefHierarchiesLayer, unitsDeviceHierarchyStats } from 'redux/ioTHierarchy/selectors'
import { logicalUnitsLayer } from 'redux/ioTLogicalUnit/selectors'

import { mapboxConf } from 'config/mapboxConfig'
import styles from './styles.module.scss'
import { Button, Fade, Tooltip, Typography } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import LogicalUnitTooltip from './LogicalUnitsLayer/components/LogicalUnitTooltip/view'

import DetailPanel from '../DetailPanel'
import Search from '../Search/container'

import mapboxgl from 'mapbox-gl' // This is a dependency of react-map-gl even if you didn't explicitly install it
import { LocalDiningOutlined } from '@material-ui/icons'

import { getCoordinates as logicalUnitCoordinates } from 'utils/logicalUnitUtils';
import { getCoordinates as hierarchyCoordinates } from 'utils/hierarchyUtils';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const { mapboxAccessToken, mapboxMapStyle } = mapboxConf()

const initialViewState = {
  longitude: UTILITY_DEFAULT_MAP_LOCATION[0],
  latitude: UTILITY_DEFAULT_MAP_LOCATION[1],
  zoom: 14,
  bearing: 0,
  pitch: 0,
  minZoom: 5,
}

function Map() {
  const detailZoom = 19.6
  const [t] = useTranslation('selectorJerarquia')
  const deckRef = React.useRef(null)
  const [viewState, setViewState] = React.useState(initialViewState)
  const [screenInitialized, setScreenInitialized] = React.useState(false)
  const [mapControlEnabled] = React.useState({ scrollZoom: { speed: 0.003, smooth: true } })
  const [viewDetail, setViewDetail] = React.useState(false)
  const [idChildren, setIdChildren] = React.useState()

  /**Redux */
  const dispatch = useDispatch()
  const briefHierarchies = useSelector(briefHierarchiesLayer)
  const unitsDeviceHierarchyStatsSelector = useSelector(unitsDeviceHierarchyStats)
  const hierarchies = useSelector(state => state.ioTHierarchy.iothierarchies)
  const logicalUnits = useSelector(logicalUnitsLayer)
  const globalActiveHierarchy = useSelector(state => state.ioTHierarchy.iothierarchyActive)
  const logicalUnitSelected = useSelector(state => state.iotLogicalUnit.iotlogicalunitSelected)

  const usePrevious = value => {
    const ref = React.useRef()
    React.useEffect(() => {
      ref.current = value
    })
    return ref.current
  }


  const prevBriefHierarchies = usePrevious(briefHierarchies)
  const prevGlobalActiveHierarchy = usePrevious(globalActiveHierarchy)

  const getCenterGeo = React.useCallback(pointsArray => {
    let pol = points(pointsArray)
    return center(pol)
  }, [])

  const triggerLocationChange = React.useCallback(
    (destination, zoom) => {
      setViewState({
        ...viewState,
        longitude: destination[0],
        latitude: destination[1],
        zoom,
        transitionDuration: 1200,
        transitionInterpolator: new FlyToInterpolator(),
      })
    },
    [viewState, setViewState],
  )

  const handleChangeViewState = React.useCallback(({ viewState, interactionState }) => {
    if (interactionState?.isDragging || interactionState?.isZooming) {
      //TODO: reemplazar la llamada a estos dos metodos por una funcion que reposicione el tooltip activo en base a la proyeccion del punto.
      hideHierarchyTooltip()
      hideLogicalUnitTooltip()
    }
    setViewState(viewState)
  }, [])

  /** Inicializacion de ViewState del mapa en funcion al estado de datos global */
  React.useEffect(() => {
    if (prevGlobalActiveHierarchy !== globalActiveHierarchy) {
      hideLogicalUnitTooltip()
      if (globalActiveHierarchy) {
        setViewDetail(false)
        triggerLocationChange(
          logicalUnitCoordinates(globalActiveHierarchy),
          14,
        )
      } else {
        if (prevBriefHierarchies !== briefHierarchies) {
          if (briefHierarchies) {
            let points = briefHierarchies.map(d => 
              {
              return hierarchyCoordinates(d.hierarchy)
            })
            let centroid = getCenterGeo(points)
            triggerLocationChange(centroid.geometry.coordinates, 7)
          }
        }
      }
    } else {
      if (!screenInitialized) {
        if (briefHierarchies) {
          let points = briefHierarchies.map(d => {
            return hierarchyCoordinates(d.hierarchy)
          })
          let centroid = getCenterGeo(points)
          triggerLocationChange(centroid.geometry.coordinates, 7)
          setScreenInitialized(true)
        }
      }
    }
  }, [
    globalActiveHierarchy,
    prevGlobalActiveHierarchy,
    triggerLocationChange,
    getCenterGeo,
    briefHierarchies,
    prevBriefHierarchies,
    setScreenInitialized,
    screenInitialized,
  ])

  /** HIERARCHY LAYER */
  const [onMarkerHover, setOnMarkerHover] = React.useState(false)
  const [hierachyTooltipContent, setHierachyTooltipContent] = React.useState({ x: -9999, y: -9999, content: '', opacity: 0, zIndex: -999 })

  const getHierarchyTooltip = ({ object, x, y }) => {
    setOnMarkerHover(true)
    if (object) {
      // ojo con esta instanciacion (tendria que tenerla una vez)
      let viewport = new WebMercatorViewport(viewState)
      let pos = viewport.project(
        hierarchyCoordinates(object.hierarchy))
      setHierachyTooltipContent({
        x: pos[0] - 120 + 'px',
        y: pos[1] + 15 + 'px',
        hierarchy: `${object.hierarchy.description}`,
        set_total: `${
          object.logical_unit?.SET?.total_green || 0 + object.logical_unit?.SET?.total_red || 0 + object.logical_unit?.SET?.total_yellow || 0
        }`,
        mtr_total: `${
          object.logical_unit?.MTR_ELEC?.total_green ||
          0 + object.logical_unit?.MTR_ELEC?.total_red ||
          0 + object.logical_unit?.MTR_ELEC?.total_yellow ||
          0
        }`,
        edificio_total: `${
          object.logical_unit?.EDIFICIO?.total_green ||
          0 + object.logical_unit?.EDIFICIO?.total_red ||
          0 + object.logical_unit?.EDIFICIO?.total_yellow ||
          0
        }`,
        set_alerts: `${object.logical_unit?.SET?.total_red || 0}`,
        mtr_alerts: `${object.logical_unit?.MTR_ELEC?.total_red || 0}`,
        edificio_alerts: `${object.logical_unit?.EDIFICIO?.total_red || 0}`,
        content: object.logical_unit,
        opacity: 1,
        zIndex: 100,
      })
    } else {
      setOnMarkerHover(false)
      setHierachyTooltipContent({ x: -9999, y: -9999, opacity: 0, zIndex: -999 })
    }
  }
  const hideHierarchyTooltip = () => {
    setOnMarkerHover(false)
    setHierachyTooltipContent({ x: -9999, y: -9999, opacity: 0, zIndex: -999 })
  }
  const handleHierachyMakerClick = ({ object }) => {
    hideHierarchyTooltip()
    dispatch(ioTHierarchyActions.setActiveHierarchy(briefHierarchies.filter(el => el.hierarchy.id === object.hierarchy.id)[0].hierarchy.id))
    triggerLocationChange(
      hierarchyCoordinates(object.hierarchy),
      14,
    )
  }
  const hierarchiesMapLayer = new IconLayer({
    data: briefHierarchies,
    pickable: true,
    getPosition: d => hierarchyCoordinates(d.hierarchy),
    onHover: d => getHierarchyTooltip(d),
    id: 'hierarchy-view-layer',
    loadOptions: d => getHierarchyLoadOptions(d),
    sizeUnits: 'pixels',
    sizeScale: 90,
    sizeMinPixels: 6,
    getIcon: d => getHierarchyIconMarker(d),
    getSize: d => getHierarchyIconSize(d),
    getPixelOffset: [0, 0],
    onClick: d => handleHierachyMakerClick(d),
  })

  const [logicalUnitsToDisplay, setLogicalUnitsToDisplay] = React.useState(null)

  /** Funciones de screen */
  const handleResetHierarchy = () => {
    dispatch(ioTHierarchyActions.setActiveHierarchy(null))
  }
  const handleOnSelectHierarchy = id => {
    dispatch(ioTHierarchyActions.setActiveHierarchy(id))
  }

  /** LOGICAL UNIT LAYERS */
  const [showCluster, setShowCluster] = React.useState(true) // se setea la vista de cluster activa por defecto
  const [logicalUnitTooltipContent, setLogicalUnitTooltipContent] = React.useState({ x: -9999, y: -9999, content: '', opacity: 0, zIndex: -999 })

  const [currentFilter, setCurrentFilter] = React.useState(
    getSessionStatisticsOperationalPanelFilters() || {
      byStatus: {
        R: false,
        Y: false,
        G: false,
      },
      byComponent: {
        CT: false,
        MTR: false,
        BUILD: false,
      },
    },
  )

  React.useEffect(() => {
    setLogicalUnitsToDisplay(logicalUnits)
    filterDataOff()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logicalUnits, currentFilter])

  const handleFilterChange = event => {
    switch (event) {
      case OPERATION_STATUS.OK:
        setCurrentFilter({
          ...currentFilter,
          byStatus: {
            ...currentFilter.byStatus,
            [OPERATION_STATUS.OK]: !currentFilter.byStatus.G,
          },
        })
        setSessionStatisticsOperationalPanelFilters({
          ...currentFilter,
          byStatus: {
            ...currentFilter.byStatus,
            [OPERATION_STATUS.OK]: !currentFilter.byStatus.G,
          },
        })
        break
      case OPERATION_STATUS.WARNING:
        setCurrentFilter({
          ...currentFilter,
          byStatus: {
            ...currentFilter.byStatus,
            [OPERATION_STATUS.WARNING]: !currentFilter.byStatus.Y,
          },
        })
        setSessionStatisticsOperationalPanelFilters({
          ...currentFilter,
          byStatus: {
            ...currentFilter.byStatus,
            [OPERATION_STATUS.WARNING]: !currentFilter.byStatus.Y,
          },
        })
        break
      case OPERATION_STATUS.ALERT:
        setCurrentFilter({
          ...currentFilter,
          byStatus: {
            ...currentFilter.byStatus,
            [OPERATION_STATUS.ALERT]: !currentFilter.byStatus.R,
          },
        })
        setSessionStatisticsOperationalPanelFilters({
          ...currentFilter,
          byStatus: {
            ...currentFilter.byStatus,
            [OPERATION_STATUS.ALERT]: !currentFilter.byStatus.R,
          },
        })
        break
      case OPERATION_COMPONENT.CT:
        setCurrentFilter({
          ...currentFilter,
          byComponent: {
            ...currentFilter.byComponent,
            CT: !currentFilter.byComponent.CT,
          },
        })
        setSessionStatisticsOperationalPanelFilters({
          ...currentFilter,
          byComponent: {
            ...currentFilter.byComponent,
            CT: !currentFilter.byComponent.CT,
          },
        })
        break
      case OPERATION_COMPONENT.MTR:
        setCurrentFilter({
          ...currentFilter,
          byComponent: {
            ...currentFilter.byComponent,
            MTR: !currentFilter.byComponent.MTR,
          },
        })
        setSessionStatisticsOperationalPanelFilters({
          ...currentFilter,
          byComponent: {
            ...currentFilter.byComponent,
            MTR: !currentFilter.byComponent.MTR,
          },
        })
        break
      case OPERATION_COMPONENT.BUILD:
        setCurrentFilter({
          ...currentFilter,
          byComponent: {
            ...currentFilter.byComponent,
            BUILD: !currentFilter.byComponent.BUILD,
          },
        })
        setSessionStatisticsOperationalPanelFilters({
          ...currentFilter,
          byComponent: {
            ...currentFilter.byComponent,
            BUILD: !currentFilter.byComponent.BUILD,
          },
        })
        break
      default:
        break
    }
  }

  const filterDataOff = () => {
    hideLogicalUnitTooltip()
    let itemStatus = Object.keys(currentFilter.byStatus).filter(item => currentFilter.byStatus[item] === true)
    let itemComponent = Object.keys(currentFilter.byComponent).filter(item => currentFilter.byComponent[item] === true)
    if (itemComponent.length === 0 && itemStatus.length === 0) {
      setLogicalUnitsToDisplay(logicalUnits)
    } else {
      setLogicalUnitsToDisplay(
        logicalUnits?.filter(
          item => !itemComponent.includes(item.iot_logical_unit_type.internal_name) && !itemStatus.includes(item.status_operation),
        ),
      )
    }
  }

  const onSearchTransitionEnd = logicalUnit => {
    getLogicalUnitTooltip({ object: logicalUnit })
  }

  const handleSearchLogicalUnit = (idLogicalUnit, idChildren) => {
    if (idChildren) setIdChildren(idChildren)
    else {
      setIdChildren()
    }
    hideLogicalUnitTooltip()
    const logicalUnit = logicalUnits.find(el => {
      return el.id === idLogicalUnit
    })
    if (logicalUnit) {

      const [longitudeIn, latitudeIn] = logicalUnitCoordinates(logicalUnit);

      setViewState({
        ...viewState,
        longitude: longitudeIn,
        latitude: latitudeIn,
        zoom: detailZoom,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
        onTransitionEnd: el => {
          onSearchTransitionEnd(logicalUnit)
        },
      })
    }
  }

  const getLogicalUnitTooltip = ({ object }) => {
    if (object) {
      
      let coords = logicalUnitCoordinates(object);
      const viewport = iconLayer?.context?.viewport
      if (viewport) {
        let pos = viewport.project(coords)
        setLogicalUnitTooltipContent({
          x: pos[0] - 160 + 'px',
          y: pos[1] - 190 + 'px',
          content: object,
          opacity: 1,
          zIndex: 100,
          coordinates: coords,
        })
      }
    } else {
      setLogicalUnitTooltipContent({ x: -9999, y: -9999, opacity: 0, zIndex: -999 })
    }
  }
  const hideLogicalUnitTooltip = () => {
    setLogicalUnitTooltipContent({ x: -9999, y: -9999, opacity: 0, zIndex: -999 })
  }

  const getLogicalUnitDetail = (logicalUnit, coords) => {
    dispatch(ioTLogicalUnitActions.getLogicalUnitDetail(logicalUnit.id))
    setViewDetail(true)
    //Solo se actualiza el viewport si esta en un nivel de zoom diferente al del nivel de detalle
    if (viewState.zoom !== detailZoom) {
      hideLogicalUnitTooltip()
      setViewState({
        ...viewState,
        zoom: detailZoom,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
        onTransitionEnd: el => {
          onSearchTransitionEnd(logicalUnit)
        },
        longitude: coords[0],
        latitude: coords[1],
      })
    }
  }

  const layerPropsIcons = {
    visible: true,
    data: logicalUnitsToDisplay,
    pickable: true,
    getPosition: d => logicalUnitCoordinates(d),
    iconAtlas: '/mapSprites/logical-units-icon-atlas.png',
    iconMapping: '/mapSprites/logical-units-icon-mapping.json',
    onClick: d => getLogicalUnitTooltip(d),
    onHover: d => (d.object ? setOnMarkerHover(true) : setOnMarkerHover(false)),
    autoHighlight: true,
    loadOptions: {
      image: {
        type: 'image',
      },
      imagebitmap: {
        premultiplyAlpha: 'none',
      },
    },
    highlightColor: [255, 255, 255, 10],
  }
  const layerPropsCluster = {
    visible: true,
    data: logicalUnitsToDisplay,
    pickable: true,
    getPosition: d => logicalUnitCoordinates(d),
    onHover: d => (d.object ? setOnMarkerHover(true) : setOnMarkerHover(false)),
    onClick: d => {
      setViewState({
        ...viewState,
        zoom: viewState.zoom + 2,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
        longitude: d.coordinate[0],
        latitude: d.coordinate[1],
      })
    },
  }

  const getIconMarker = d => {
    return viewState.zoom < 18 ? `marker-${d.status_operation}` : `marker-${d.status_operation}-${d.iot_logical_unit_type.internal_name}`
  }

  // function svgToDataURL(svg) {
  //   return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`
  // }
  // const getIconSvg = d => {
  //   return {
  //     url: svgToDataURL(alertSvg),
  //     width: 300,
  //     height: 300,
  //   }
  // }

  const getIconSize = d => {
    let zoom = viewState.zoom
    if (d.status_operation === 'R') {
      if (zoom < 18) {
        if (zoom <= 12) {
          return 3
        } else if (zoom < 17) {
          return 7.5
        } else {
          return 15
        }
      } else {
        if (zoom < 18.5) {
          return 30
        } else if (zoom < 19) {
          return 40
        } else if (zoom < 19.5) {
          return 60
        } else {
          return 70
        }
      }
    } else {
      if (zoom < 18) {
        if (zoom <= 12) {
          return 2
        } else if (zoom < 17) {
          return 5
        } else {
          return 10
        }
      } else {
        if (zoom < 18.5) {
          return 16.5
        } else if (zoom < 19) {
          return 22
        } else if (zoom < 19.5) {
          return 33
        } else {
          return 38.5
        }
      }
    }
  }

  const clusterLayer = new IconClusterLayer({ ...layerPropsCluster, id: 'icon-cluster', sizeScale: 40 })
  const iconLayer = new IconLayer({
    ...layerPropsIcons,
    id: 'icon',
    sizeUnits: 'pixels',
    sizeScale: 1,
    updateTriggers: {
      getIcon: viewState.zoom,
      getSize: viewState.zoom,
    },
    getSize: d => getIconSize(d),
    getIcon: d => getIconMarker(d),

    // getIcon: (d, { index }) => ({
    //   url: svgToDataURL(createSVGIcon(index)),
    //   width: 64,
    //   height: 64,
    // }),
  })

  const logicalUnitsMapLayer = showCluster && viewState.zoom < 14 ? clusterLayer : iconLayer
  return (
    <div id='map' className={styles.root}>
      <div className={styles.hierachySelectorWrapper}>
        <HierarchySelector
          onHierarchySelect={handleOnSelectHierarchy}
          hierarchiesList={hierarchies}
          activeHierarchy={globalActiveHierarchy}
        ></HierarchySelector>
        {/* <div>{viewState.zoom}</div> */}
      </div>
      {globalActiveHierarchy && (
        <>
          <Fade in={globalActiveHierarchy && true}>
            <div className={styles.hierachyResetButtonWrapper}>
              <Tooltip title={'Regresar a la vista general'} placement='top'>
                <Button className={styles.resetHierarchyButton} color='primary' onClick={() => handleResetHierarchy()}>
                  {t('hierarchy-view')}
                </Button>
              </Tooltip>
            </div>
          </Fade>
          <Fade in={globalActiveHierarchy && true}>
            <div className={styles.clusterViewButtonWrapper}>
              <Tooltip title={showCluster ? 'Desactivar vista de clusters' : 'Activar vista de clusters'} placement='top'>
                <ToggleButton
                  value='cluster'
                  variant='outlined'
                  selected={showCluster}
                  className={styles.clusterViewButton}
                  onChange={() => {
                    setShowCluster(!showCluster)
                  }}
                >
                  <BubbleChartRoundedIcon style={{ marginRight: 5 }}></BubbleChartRoundedIcon>
                  <Typography variant='button'>Clusters</Typography>
                </ToggleButton>
              </Tooltip>
            </div>
          </Fade>
          <Slide direction='up' in={globalActiveHierarchy && true}>
            <div className={styles.statisticsOperationalPanelWrapper}>
              <StatisticsOperationalPanel
                data={unitsDeviceHierarchyStatsSelector}
                currentFilter={currentFilter}
                onFilterChange={handleFilterChange}
              />
            </div>
          </Slide>

          <Slide direction='left' in={globalActiveHierarchy && !viewDetail}>
            <div className={styles.searchWrapper}>
              <Search activeHierarchy={globalActiveHierarchy} onSelect={handleSearchLogicalUnit} />
            </div>
          </Slide>

          <Slide direction='left' in={viewDetail}>
            <div className={styles.detailPanelWrapper}>
              <DetailPanel logicalUnit={logicalUnitSelected} viewDetail={setViewDetail} idChildren={idChildren} />
            </div>
          </Slide>
        </>
      )}

      <DeckGL
        getCursor={() => (onMarkerHover ? 'pointer' : 'inherit')}
        layers={[!globalActiveHierarchy && hierarchiesMapLayer, globalActiveHierarchy && logicalUnitsMapLayer]}
        ref={deckRef}
        viewState={viewState}
        onViewStateChange={handleChangeViewState}
        controller={mapControlEnabled}
        ContextProvider={MapContext.Provider}
      >
        {briefHierarchies && briefHierarchies.length > 0 && (
          <>
            {/* Capa de Mapa Propietario*/}
            {/* <MapGL reuseMaps ref={deckRef} mapStyle={MAPBOX_MAP_STYLE} preventStyleDiffing={true} mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} /> */}
            <MapGL ref={deckRef} mapboxApiAccessToken={mapboxAccessToken} mapStyle={mapboxMapStyle}></MapGL>
            <GeolocateControl className={styles.controlGeolocateStyle} />
            <NavigationControl className={styles.controlNavStyle} />
            {/* <ScaleControl className={styles.controlScaleControlStyle} /> */}
          </>
        )}
      </DeckGL>

      {/* Tooltips */}
      <HierarchyTooltip tooltipContent={hierachyTooltipContent}></HierarchyTooltip>
      <LogicalUnitTooltip
        tooltipContent={logicalUnitTooltipContent}
        onClose={hideLogicalUnitTooltip}
        onDetail={getLogicalUnitDetail}
      ></LogicalUnitTooltip>
    </div>
  )
}

export default Map
