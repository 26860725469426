import moment from 'moment'

export const orderByDate = (dateA, dateB) => moment(dateB).format('YYYYMMDDHHmmss') - moment(dateA).format('YYYYMMDDHHmmss')

export const arrayIntersection = (booleanArray, valuesArray) => {
  const valuesArrayCopy = [...valuesArray]
  let array = []
  valuesArrayCopy.forEach(element => {
    array = [...array, element.values]
  })
  array = array.map((value, i) => value.map((item, j) => booleanArray[i][j] && item))
  let requestIds = []
  array.forEach(element => requestIds.push(...element))
  requestIds = requestIds.filter(Boolean).map(item => item.id)
  return requestIds
}

// returns true if the matrix contains at least one element that is true
export const containsTrue = matrix => {
  let containsTrue = false
  matrix.forEach(element => {
    if (element.some(item => item)) {
      containsTrue = true
    }
  })
  return containsTrue
}

// remove a value from an array by value and return the new array but not mutate the original array
export const removeValueFromArray = (array, value) => {
	const newArray = array.filter(item => item !== value)
	return newArray
}

// generate array of numbers from 0 to n
export const generateArray = n => {
	let array = []
	for (let i = 0; i < n; i++) {
		array.push(i)
	}
	return array
}
