import { Button, Card, Typography } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import BlurCircularRoundedIcon from '@material-ui/icons/BlurCircularRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { Skeleton } from '@material-ui/lab';
import { push } from 'connected-react-router';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { roles, validateAccess } from 'constants/userRoles';
import { FILLED_COLOR_SQUARE } from 'constants/icons';
import { ROUTES } from 'constants/routes';
import CreateGroupModal from './components/CreateGroupModal';

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}

const DeviceGroups = ({userRoles}) => {
	const [t] = useTranslation('devices')
	const dispatch = useDispatch()
	const loading = useSelector((state) => state.iot.iotGroupsOfDevicesLoading);
  const groups = useSelector((state) => state.iot.iotGroupsOfDevices);
	const newGroupId = useSelector((state) => state.iot.iotCreateGroupEntityId);
	const creating = useSelector((state) => state.iot.iotCreateGroupEntityLoading);

	const [createForm, setCreateForm] = useState(false)
	const [navigate, setNavigate] = useState(false)
  const [hasRoleCreateDeviceGroup, setHasRoleCreateDeviceGroup] = useState(false)

  const setComponentAccess = () => {
    // button create new device Group
		setHasRoleCreateDeviceGroup(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_CREATE));
  }

  useEffect(() => {
    setComponentAccess();
  }, []);



	const handleCloseCreateForm = () => {
		setCreateForm(false)
	}

	const handleOpenCreateForm = () => {
		setCreateForm(true)
	}

	const handleCreateGroup = async (values) => {
		await dispatch(iotActions.postCreateIotGroupOfDevices(values))
		setNavigate(true)
	}

	const handleRowClick = (rowData, rowMeta) => {
		const groupId = groups[rowMeta.dataIndex]?.id
		dispatch(push(`${ROUTES.IOT_DEVICE_GROUPS}/${groupId}`));
	}

	useEffect(() => {
		if (!groups) dispatch(iotActions.cleanIotGroupsOfDevices());
		dispatch(iotActions.getIotGroupsOfDevices());
		return () => {
      dispatch(iotActions.getIotGroupsOfDevices());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: 'name',
      label: t('devicesGroupsTableNameColumn'),
			options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
				customBodyRenderLite: data => {
          return (<div style={{ display: 'flex', alignItems: 'center', }}>
                  <FILLED_COLOR_SQUARE color={groups[data].color} style={{ fontSize: 14 }}></FILLED_COLOR_SQUARE>
              		<Typography style={{ marginLeft: 20}} >{groups[data].name}</Typography>
            	</div>)
        },
      },
		},
		{
      name: 'iot_group_type',
      label: t('devicesGroupsTableGroupTypeColumn'),
      options: {
        filter: true,
        filterType: 'textField',
				display: 'true',
				sort: true,
				sortCompare: (order) => (obj1, obj2) => {
					return order === 'asc'
            ? obj1.data.description.localeCompare(obj2.data.description)
            : -obj1.data.description.localeCompare(obj2.data.description);
        },
        customBodyRenderLite: (index) => {
					return <span>{groups[index]?.iot_group_type?.description}</span>
        },
      },
		},
		{
      name: 'total_members',
      label: t('devicesGroupsTableDevicesQuantityColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
		},
		{
      name: 'owner',
      label: t('devicesGroupsTableOwnerColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
		},
		{
      name: 'is_private',
      label: t('devicesGroupsTableVisibilityColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: isPrivate => {
					return (<div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
							{isPrivate ? (
								<LockRoundedIcon style={{ fontSize: 20 }}></LockRoundedIcon>
							) : (
								<LockOpenRoundedIcon style={{ fontSize: 20 }}></LockOpenRoundedIcon>
                )}
              </div>
						<Typography style={{ marginLeft: 6 }} >{(isPrivate ? t('devicesGroupsVisibilityPrivate') : t('devicesGroupsVisibilityPublic'))} </Typography>
            </div>)
        },
      },
		},
		{
      name: 'let_users_update',
      label: t('devicesGroupsTableEditColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: isEditable => {
          return <span>{isEditable ? t('devicesGroupsTableEditColumnValueTrue') : t('devicesGroupsTableEditColumnValueFalse')}</span>
        },
      },
    }
  ]


	const options = {
    elevation: 0,
    filter: false,
    print: false,
    sort: true,
    search: true,
    viewColumns: false,
    selectableRows: 'none',
    responsive: 'simple',
    pagination: false,
    download: false,
    tableBodyMaxHeight: '187px',
		onRowClick: handleRowClick,
    setTableProps: () => ({
      size: 'small',
    }),
    customToolbar: () => {
      if (hasRoleCreateDeviceGroup)
      return (
            <Button
              variant="outlined"
              size='small'
              startIcon={<AddRoundedIcon />}
              onClick={handleOpenCreateForm}
              style={{marginLeft:10}}
            >
            {t('devicesGroupsTableCreateButtonText')}
          </Button>
          );
      
      return (<></>);
      },
	textLabels: {
		body: {
		  noMatch: 'No hay registros que coincidan con la búsqueda',
		  toolTip: 'Ordernar',
		},
		toolbar: {
		  search: 'Buscar',}
		}
  };

	return (
		<>
			{navigate && !creating && newGroupId !== null && (
				<Redirect to={`${ROUTES.IOT_DEVICE_GROUPS}/${newGroupId}`} push />
			)}
			{loading ? (
				renderSkeleton()
			) : (
				<Card
					elevation={4}
					style={{ paddingTop: 5, paddingBottom: 10}}
				>
				<MUIDataTable
					title={
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-start',
								alignItems: 'center',
							}}
						>
							<BlurCircularRoundedIcon style={{ fontSize: 30 }}/>
							<Typography style={{ marginLeft: 10 }} variant="h6">
								{t('devicesGroupsTableTitle')}
							</Typography>
						</div>
					}
					data={groups}
					columns={columns}
					options={options}
				/>
				</Card>
			)}
			<CreateGroupModal
				open={createForm || creating}
				handleClose={handleCloseCreateForm}
				handleCreate={handleCreateGroup}
			/>
		</>
	);
}

export default DeviceGroups