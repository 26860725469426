/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { string, number, shape, any, func } from 'prop-types'
import { connect } from 'react-redux'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import EditableField from './components/editableField'
import { Button } from '@material-ui/core'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import { Skeleton } from '@material-ui/lab';

import { actionCreators as iotDeviceParamActions } from 'redux/iotDeviceParams/actions'

const DeviceParamsContainer = ({ deviceSelected, iotDeviceParams, onRefresh, userRoles, iotDeviceParamsLoading, putDeviceParamValueLoading, putDeviceParamValueOnlineLoading, dispatch }) => {
  const [t] = useTranslation('deviceParam')
  const [paramsConfig3, setParamsConfig3] = useState(null)
  const [editedParamCodes, setEditedParamCodes] = useState([])

  useEffect(() => {
    if(!iotDeviceParams || !iotDeviceParams.length) getDeviceParams(deviceSelected.id);      
  }, [])

  useEffect(() => {
    setValuesParams()      
  }, [iotDeviceParams])

  const renderSkeleton = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton animation='wave' height={60} width={180} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div style={{ width: '100%' }}>
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
          </div>
        </div>
      </div>
    )
  }


  const getDeviceParams = useCallback(async (deviceId) => {
    await dispatch(iotDeviceParamActions.getIotDeviceParams(deviceId))
  })


  const setValuesParams = () => {
    const listParams = JSON.parse(JSON.stringify(iotDeviceParams || []));
    // sort the array by property name
    listParams.sort((a,b) =>  (a.iot_device_param_type.name||0)-(b.iot_device_param_type.name||0) );
    setParamsConfig3(listParams)
  }

  const handleValueUpdate = async (deviceParamId, value, syncValueOption) => {
    if (syncValueOption === 'actual') {
      await dispatch(iotDeviceParamActions.putIotDeviceParamValue(deviceParamId, value))
    }
    
    if (syncValueOption === 'online') {
     await dispatch(iotDeviceParamActions.putIotDeviceParamValueOnline(deviceParamId, value))
    }

    await getDeviceParams(deviceSelected.id)
  }

  const columns = [
    {
      name: 'deviceParamTypeName',
      label: t('deviceParamTableCodeTitleColumn'),
      options: {
        filter: false,
        display: 'true',
      },
    },
    {
      name: 'deviceParamTypeDesciption',
      label: t('deviceParamTableParamTitleColumn'),
      options: {
        filter: false,
        display: 'true',
      },
    },
    {
      name: 'deviceParamValue',
      label: t('deviceParamTableValueTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.tableData[tableMeta.rowIndex].isReadonly) {
            return (
              <span></span>
            )
          } else {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <EditableField
                deviceParamId={tableMeta.tableData[tableMeta.rowIndex].deviceParamId}
                fieldValueA={value}
                fieldValueB={tableMeta.tableData[tableMeta.rowIndex].deviceParamValueOnline}
                fieldValueSync={'actual'}
                fieldType={tableMeta.tableData[tableMeta.rowIndex].deviceParamTypeData}
                onUpdate={handleValueUpdate}
              ></EditableField>
            </div>
            )
          }
        },
      },
    },
    {
      name: 'deviceParamValueOnline',
      label: t('deviceParamTableValueOnlineTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!tableMeta.tableData[tableMeta.rowIndex].deviceParamSyncOnline) {
            return (
              <span></span>
            )
          } else {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <EditableField
                deviceParamId={tableMeta.tableData[tableMeta.rowIndex].deviceParamId}
                fieldValueA={value}
                fieldValueB={tableMeta.tableData[tableMeta.rowIndex].deviceParamValue}
                fieldValueSync={'online'}
                fieldType={tableMeta.tableData[tableMeta.rowIndex].deviceParamTypeData}
                onUpdate={handleValueUpdate}
              ></EditableField>
            </div>
            )
          }
        },
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyHeight: '45vh',
    download: false,
    viewColumns: false,
    customToolbar: null,
    pagination: false,

    setTableProps: () => ({
      size: 'small',
    }),
    setRowProps: (row, index) => {
      return {
        className: editedParamCodes.includes(row[0]) ? styles.editedRow : styles.unEditedRow,
      }
    },
    textLabels: {
      body: {
        noMatch: 'No hay parámetros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Parámetros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      {iotDeviceParamsLoading || putDeviceParamValueLoading || putDeviceParamValueOnlineLoading ? (
        renderSkeleton()
      ) : (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {paramsConfig3 && <MUIDataTable columns={columns} options={options} data={paramsConfig3} />}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ marginLeft: 20 }}>
              <Button
                color='primary'
                style={{ marginRight: 10 }}
                onClick={() => onRefresh()}
                startIcon={<RefreshRoundedIcon></RefreshRoundedIcon>}
              >
                Refrescar
              </Button>
            </div>
          </div>
      </div>
      )}
    </>
  )
}

DeviceParamsContainer.propTypes = {
  rowData: shape(any),
  iotDeviceParams: shape(any),
  value: string,
  field: string,
  rowIndex: number,
  onRefresh: func,
  onSaveParams: func,
}

const mapStateToProps = store => ({
  deviceSelected: store.iot && store.iot.iotDeviceDetail,
  iotDeviceParams: store.iotDeviceParams && store.iotDeviceParams.iotDeviceParams,
  iotDeviceParamsLoading: store.iotDeviceParams && store.iotDeviceParams.iotDeviceParamsLoading,
  putDeviceParamValueLoading: store.iotDeviceParams && store.iotDeviceParams.putDeviceParamValueLoading,
  putDeviceParamValueOnlineLoading: store.iotDeviceParams && store.iotDeviceParams.putDeviceParamValueOnlineLoading,
})

export default connect(mapStateToProps)(DeviceParamsContainer)
