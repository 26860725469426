/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import moment from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIn } from 'seamless-immutable';

import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import { Skeleton } from '@material-ui/lab';

import MUIDataTable from 'mui-datatables';

import { actionCreators as iotActions } from 'redux/iotHandlers';

import { DEFAULT_PAGE_NUMBER, pageSizeOptions } from './constants';

const renderSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 275,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation="wave" height={60} width={180} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 15,
        }}
      >
        <div style={{ width: '100%' }}>
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
        </div>
      </div>
    </div>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 450,
    overflowY: 'scroll',
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'monospace',
  },
}))(Tooltip);

const NetworkServerEventsContainer = ({
  networkServerSelected,
  eventsList,
  dispatch,
  loading,
  totalEvents,
  totalPages,
}) => {
  const [t] = useTranslation('devices');
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [tableData, setTableData] = useState([]);

  const getEvents = useCallback(() => {
    dispatch(iotActions.getIotNetworkServerEvents(currentPage, pageSize));
  });

  useEffect(() => {
    if (!eventsList || (eventsList && !eventsList[`${currentPage}`]))
      getEvents();
    else setTableData(eventsList[`${currentPage}`]);
  }, [currentPage]);

  useEffect(() => {
    if (eventsList) {
      if (isFirstRun && eventsList[`1`]) {
        setIsFirstRun(false);
      }
      setTableData(eventsList[`${currentPage}`]);
    }
  }, [eventsList]);

  useEffect(() => {
    return () => {
      dispatch(iotActions.cleanNetworkServerEvents());
    };
  }, []);

  const handleChangePage = (_currentPage, _pageSize) => {
    setCurrentPage(_currentPage);
    if (_pageSize) setPageSize(_pageSize);
  };

  const columns = [
    {
      name: 'id',
      label: t('networkServerEventsTableIdTitleColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: (data) => {
          return <span>{data}</span>;
        },
      },
    },
    {
      name: 'createAt',
      label: t('networkServerEventsTableCreatedAtTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => {
          return <span>{moment(data).format('DD-MM-YYYY HH:mm:ss')}</span>;
        },
      },
    },
    {
      name: 'level',
      label: t('networkServerEventsTableLevelTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'type',
      label: t('networkServerEventsTableTypeTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
      },
    },
    {
      name: 'options',
      label: t('networkServerEventsTableOptionsTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => (
          <HtmlTooltip
            placement="left-start"
            title={JSON.stringify(data || { }, null, 4)}
          >
            <IconButton size="small">
              <CodeRoundedIcon fontSize="small" />
            </IconButton>
          </HtmlTooltip>
        ),
      },
    },
    {
      name: 'metadata',
      label: t('networkServerEventsTableMetaDataTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => (
          <HtmlTooltip
            placement="left-start"
            title={JSON.stringify(data || { }, null, 4)}
          >
            <IconButton size="small">
              <CodeRoundedIcon fontSize="small" />
            </IconButton>
          </HtmlTooltip>
        ),
      },
    },
  ];

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyHeight: 'calc(100% - 125px)',
    download: false,
    viewColumns: false,
    serverSide: true,
    count: totalEvents,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [],

    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page + 1, null);
          break;
        case 'sort':
          break;
        default:
      }
    },
    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar Excel', //'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  };

  return (
    <>
      {loading && isFirstRun ? (
        renderSkeleton()
      ) : (
        <MUIDataTable
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">
                Eventos
                {loading && (
                  <CircularProgress
                    size={18}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </Typography>
            </div>
          }
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  networkServerSelected: getIn(store, ['iot', 'iotNetworkServerDetail']),
  loading: getIn(store, ['iot', 'iotNetworkServerEventsLoading']),
  eventsList:
    store.iot.iotNetworkServerEvents && store.iot.iotNetworkServerEvents.rows,
  totalEvents:
    store.iot.iotNetworkServerEvents &&
    store.iot.iotNetworkServerEvents.totalNetworkServerEvents,
  totalPages:
    store.iot.iotNetworkServerEvents &&
    store.iot.iotNetworkServerEvents.totalNetworkServerPages,
});

export default connect(mapStateToProps)(memo(NetworkServerEventsContainer));
