import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sideBarNav_es from 'translations/es/sideBarNav.json'
import sideBarNav_en from 'translations/en/sideBarNav.json'
import selectorJerarquia_es from 'translations/es/selectorJerarquia.json'
import selectorJerarquia_en from 'translations/en/selectorJerarquia.json'
import search_es from 'translations/es/search.json'
import search_en from 'translations/en/search.json'
import statisticalOperationalPanel_es from 'translations/es/statisticalOperationalPanel.json'
import statisticalOperationalPanel_en from 'translations/en/statisticalOperationalPanel.json'
import logicUnits_es from 'translations/es/logicUnits.json'
import logicUnits_en from 'translations/en/logicUnits.json'
import devices_es from 'translations/es/devices.json'
import devices_en from 'translations/en/devices.json'
import logicUnitDetail_es from 'translations/es/logicUnitDetail.json'
import logicUnitDetail_en from 'translations/en/logicUnitDetail.json'
import operationStatus_es from 'translations/es/operationStatus.json'
import operationStatus_en from 'translations/en/operationStatus.json'
import detailPanel_es from 'translations/es/detailPanel.json'
import detailPanel_en from 'translations/en/detailPanel.json'
import deviceParam_es from 'translations/es/deviceParam.json'
import deviceParam_en from 'translations/en/deviceParam.json'
import deviceSensor_es from 'translations/es/deviceSensor.json'
import deviceSensor_en from 'translations/en/deviceSensor.json'

i18n
  // .use(LanguageDetector) // detects user browser language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        sideBarNav: sideBarNav_es,
        selectorJerarquia: selectorJerarquia_es,
        search: search_es,
        statisticalOperationalPanel: statisticalOperationalPanel_es,
        logicUnits: logicUnits_es,
        devices: devices_es,
        logicUnitDetail: logicUnitDetail_es,
        operationStatus: operationStatus_es,
        detailPanel: detailPanel_es,
        deviceParam: deviceParam_es,
        deviceSensor: deviceSensor_es,
      },
      en: {
        sideBarNav: sideBarNav_en,
        selectorJerarquia: selectorJerarquia_en,
        search: search_en,
        statisticalOperationalPanel: statisticalOperationalPanel_en,
        logicUnits: logicUnits_en,
        devices: devices_en,
        logicUnitDetail: logicUnitDetail_en,
        operationStatus: operationStatus_en,
        detailPanel: detailPanel_en,
        deviceParam: deviceParam_en,
        deviceSensor: deviceSensor_en,
      },
    },
  })
