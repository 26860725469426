import { Card, Grid, Typography } from '@material-ui/core'
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss'

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}


const DetailInformation = ({loading, group}) => {
	const {t} = useTranslation('devices')
	return (
		<>
			{loading ? (
				renderSkeleton()
			) : (
			<div
        style={{
          display: 'flex',
          height: '100%',
          flexGrow:1,
          flexDirection: 'column',
        }}
      >
        <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset' }} >
          <div style={{ display: 'flex', alignContent: 'flex-start' }}>
						<Grid style={{width: '80%'}} container direction="column" >
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 170, opacity: 0.7 }}>
                    {t('deviceGroupDeviceGroupDetailDetailInformationNameLabel')}
                  </Typography>
                  <Typography>{group?.name}</Typography>
                </>
              </Grid>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 170, opacity: 0.7 }}>
                    {t('deviceGroupDeviceGroupDetailDetailInformationTypeLabel')}
                  </Typography>
                  <Typography>{group?.iot_group_type?.description}</Typography>
                </>
              </Grid>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 170, opacity: 0.7 }}>
                    {t('deviceGroupDeviceGroupDetailDetailInformationLastUpdateLabel')}
                  </Typography>
                  <Typography>
                    {moment(group?.updatedAt).format('DD-MM-YYYY')}
                  </Typography>
                </>
              </Grid>
            </Grid>

            <Grid style={{width: '80%'}} container direction="column">
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 170, opacity: 0.7 }}>
                    {t('deviceGroupDeviceGroupDetailDetailInformationOwnerLabel')}
                  </Typography>
                  <Typography style={{ minWidth: 170 }}>
                    {group?.owner}
                  </Typography>
                </>
              </Grid>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 170, opacity: 0.7 }}>
                    {t('deviceGroupDeviceGroupDetailDetailInformationVisibilityLabel')}
                  </Typography>
                  <Typography>
                    <div style={{display: 'flex'}}>
                      {group?.is_private ? (
                        <LockRoundedIcon style={{ fontSize: 20 }}></LockRoundedIcon>
                      ) : (
                        <LockOpenRoundedIcon style={{ fontSize: 20 }}></LockOpenRoundedIcon>
                      )}
                      <Typography style={{ marginLeft: 6 }}>
                        {(group?.is_private ? 'Privado' : 'Público')}
                      </Typography>
                    </div>
                  </Typography>
                </>
              </Grid>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 170, opacity: 0.7 }}>
                    {t('deviceGroupDeviceGroupDetailDetailInformationEditionLabel')}
                  </Typography>
                  <Typography>
                    {group?.let_users_update ? 'Habilitada' : 'Deshabilitada'}
                  </Typography>
                </>
              </Grid>
            </Grid>


              <Grid
								className={styles.label}
                container
                direction="row"
              >
                <>
                  <Typography style={{ opacity: 0.7, marginRight: 40 }}>
                    {t('deviceGroupDeviceGroupDetailDetailInformationDescriptionLabel')}
                  </Typography>
                  <Typography >
                    {group?.description}
                  </Typography>
                </>
              </Grid>
          </div>
        </Card>
				</div>
			)}

    </>
  );
}

export default DetailInformation