
export const getTableData = (items, t) => {
  const data = items?.map(item => {
    return {
      attr: item.attr,
			valor: item.valor
    }
  })
  return data
}



