import { getIn } from 'seamless-immutable'

export const extractUserRoles = (authInfo) => {
  // extract form user token info roles
  // user realm roles
  const resourceAccess = authInfo ? authInfo.resourceAccess : null;
  const realmAccess = authInfo ? authInfo.realmAccess : null;
  const userAccountRoles = resourceAccess && resourceAccess.account ? resourceAccess.account.roles : [];
  const userRealmRoles = realmAccess ? realmAccess.roles : [];
  const userClientRoles =
    resourceAccess && resourceAccess[`${process.env.REACT_APP_KCK_CLIENT_ID}`]
      ? resourceAccess[`${process.env.REACT_APP_KCK_CLIENT_ID}`].roles
      : [];
  const userRoles = [...(userRealmRoles || []), ...(userClientRoles || []), ...(userAccountRoles || [])];

  return userRoles;
}


export const retrieveUserInfo = (authInfo = {}, companyId, userRoles) => {
  const { companyAvatar, name, email, preferred_username: preferredUsername, hierarchies } = getIn(authInfo, ['idTokenParsed'], {})
  const { logout, token } = authInfo
  return {
    authInformation: { name, email, preferredUsername, companyAvatar, companyId, token, hierarchies },
    userRoles,
    logout,
  }
}
