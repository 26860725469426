import React, { useCallback, useState } from 'react'
import { arrayOf } from 'prop-types'

import { deviceComponentType } from './constants'
import { useTranslation } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import { Button, Typography } from '@material-ui/core'
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded'
import MemoryRoundedIcon from '@material-ui/icons/MemoryRounded'

import DeviceComponentConfiguration from '../deviceComponent'

const DeviceComponents = ({ rows, userRoles }) => {
  const [deviceComponentConfigDialogOpened, setDeviceComponentConfigDialogOpened] = useState(false)
  const [selectedDeviceComponent, setSelectedDeviceComponent] = useState(null)

  const handleOpenDeviceComponentConfigDialog = useCallback(deviceComponentSelectedIndex => {
    // console.log('component', rows[deviceComponentSelectedIndex])
    setDeviceComponentConfigDialogOpened(true)
    setSelectedDeviceComponent(rows[deviceComponentSelectedIndex])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseDeviceComponentConfigDialog = useCallback(() => {
    setDeviceComponentConfigDialogOpened(false)
  }, [])

  const [t] = useTranslation('devices')

  const columns = [
    {
      name: 'componentName',
      label: t('component'),
      options: {
        isRowSelectable: false,
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'version',
      label: t('version'),
      options: {
        isRowSelectable: false,
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'firmwareName',
      label: t('firmware'),
      options: {
        isRowSelectable: false,
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'serialBadge',
      label: t('serialBadge'),
      options: {
        isRowSelectable: false,
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        sort: false,
        empty: true,
        customBodyRenderLite: data => {
          return (
            <Button
              startIcon={<SettingsRoundedIcon fontSize='small' />}
              size='small'
              variant='outlined'
              onClick={() => handleOpenDeviceComponentConfigDialog(data)}
            >
              Configurar
            </Button>
          )
        },
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    search: false,
    selectableRows: 'none',
    rowsPerPage: 10,
    responsive: 'simple',
    tableBodyHeight: '100%',
    //tableBodyMaxHeight: '100%',
    download: false,
    viewColumns: false,
    pagination: false,
    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      <MUIDataTable
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MemoryRoundedIcon fontSize='large' style={{ marginRight: 10 }}></MemoryRoundedIcon>
            <Typography variant='h6'>Componentes</Typography>
          </div>
        }
        data={rows}
        columns={columns}
        options={options}
      />
      <DeviceComponentConfiguration
        open={deviceComponentConfigDialogOpened}
        deviceComponent={selectedDeviceComponent}
        handleClose={handleCloseDeviceComponentConfigDialog}
        userRoles={userRoles}
      />
    </>
  )
}

DeviceComponents.propTypes = {
  rows: arrayOf(deviceComponentType),
}

DeviceComponents.defaulProps = {
  rows: [],
}

export default DeviceComponents
