import Immutable from 'seamless-immutable'

import { actions } from './actions'

const defaultState = {
	iotDeviceParams: [], //Types
	iotDeviceParamsLoading: false,
  putDeviceParamValueLoading: false,
  putDeviceParamValueOnlineLoading: false,
}

export function reducer(state = Immutable(defaultState), action) {
  switch (action.type) {
    //-----------------------------------------
    case actions.GET_IOT_DEVICE_PARAMS: {
      return Immutable.merge(state, { iotDeviceParamsLoading: true })
    }
    case actions.GET_IOT_DEVICE_PARAMS_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceParams: action.payload,
        iotDeviceParamsLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_PARAMS_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceParams: null,
        iotDeviceParamsLoading: false,
      })
    }
    //-----------------------------------------
    case actions.CLEAN_IOT_DEVICE_PARAMS: {
      return Immutable.merge(state, {
        iotDeviceParams: null,
      })
    }
    //-----------------------------------------
    case actions.PUT_IOT_DEVICE_PARAM_VALUE: {
      return Immutable.merge(state, { putDeviceParamValueLoading: true })
    }
    case actions.PUT_IOT_DEVICE_PARAM_VALUE_SUCCESS: {
      return Immutable.merge(state, {
        putDeviceParamValueLoading: false,
      })
    }
    case actions.PUT_IOT_DEVICE_PARAM_VALUE_FAILURE: {
      return Immutable.merge(state, {
        putDeviceParamValueLoading: false,
      })
    }
    //-----------------------------------------
    case actions.PUT_IOT_DEVICE_PARAM_VALUE_ONLINE: {
      return Immutable.merge(state, { putDeviceParamValueOnlineLoading: true })
    }
    case actions.PUT_IOT_DEVICE_PARAM_VALUE_ONLINE_SUCCESS: {
      return Immutable.merge(state, {
        putDeviceParamValueOnlineLoading: false,
      })
    }
    case actions.PUT_IOT_DEVICE_PARAM_VALUE_ONLINE_FAILURE: {
      return Immutable.merge(state, {
        putDeviceParamValueOnlineLoading: false,
      })
    }
    //-----------------------------------------
    default:
      return state
  }
}
