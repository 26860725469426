import { useEffect, useState } from 'react'
import { Card, IconButton, Tooltip, Typography } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import { Skeleton } from '@material-ui/lab';
import { FILLED_COLOR_SQUARE } from 'constants/icons';
//import { DEVICES_GROUPS_VISIBILITY } from 'constants/iot';
import MUIDataTable from 'mui-datatables';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actionCreators as iotActions } from 'redux/iotHandlers'; 
import  WTdialog, { WTDIALOG_TYPE } from 'app/components/WTDialog';
import { roles, validateAccess } from 'constants/userRoles';

const renderSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 275,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 15,
        }}
      >
        <div style={{ width: '100%' }}>
          <Skeleton animation="wave" height={35} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
        </div>
      </div>
    </div>
  );
};

const Groups = ({userRoles}) => {
	const [t] = useTranslation('devices')
  const dispatch = useDispatch();

  const [initDone, setInitDone] = useState(false)
  const authInformation = useSelector((state) => state.auth.authInformation);
  const deviceSelected = useSelector(state => state?.iot?.iotDeviceDetail);
  const loading = useSelector(state => state.iot.iotDeviceGroupsEntityLoading);
  const groups = useSelector(state => state?.iot?.iotDeviceGroupsEntity); 
  const [alert, setAlert] = useState(false)
  const [index, setIndex] = useState(-1);
	const [hasRoleRemoveDevice, setHasRoleRemoveDevice] = useState(false)

	const setComponentAccess = () => {
		// option button to remove device form group
		setHasRoleRemoveDevice(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_REMOVE_DEVICE));
	}


  useEffect(() => {
    setComponentAccess();
  }, []);

  useEffect(() => {
    if (!initDone){
      dispatch(iotActions.getIotDeviceGroupsEntity());
      setInitDone(true);
    }    
  }, [initDone]);

  const handleDeleteGroupOfDevice = async (event) => {      
      setAlert(false);
      await dispatch(iotActions.deleteDeviceFromGroup(groups[index].iot_group_type.entity_type, groups[index].id, deviceSelected.id));
      setIndex(-1);          
      setInitDone(false);    
  }

  const columns = [
    {
      name: 'name',
      label: t('tabGroupsTableNameColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRenderLite: (data) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FILLED_COLOR_SQUARE
                color={groups[data].color}
                style={{ fontSize: 14 }}
              ></FILLED_COLOR_SQUARE>
              <Typography style={{ marginLeft: 20 }}>
                {groups[data].name}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'iot_group_type',//'groupType',
      label: t('tabGroupsTableGroupTypeColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: (data) => {
          return <span>{data.description}</span>;
        },
      },
    },
    {
      name: 'total_members', //'devicesQuantity',
      label: t('tabGroupsTableDevicesQuantityColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: (data) => {
          return <span>{data}</span>;
        },
      },
    },
    {
      name: 'owner',
      label: t('tabGroupsTableOwnerColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: (data) => {
          return <span>{data}</span>;
        },
      },
    },
    {
      name: 'is_private', //'visibility',
      label: t('tabGroupsTableVisibilityColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: (data) => {
          return (
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* {data === DEVICES_GROUPS_VISIBILITY.PUBLIC ? (
                  <LockOpenRoundedIcon
                    style={{ fontSize: 20 }}
                  ></LockOpenRoundedIcon>
                ) : (
                  <LockRoundedIcon style={{ fontSize: 20 }}></LockRoundedIcon>
                )} */}
                {!data? (
                  <LockOpenRoundedIcon
                    style={{ fontSize: 20 }}
                  ></LockOpenRoundedIcon>
                ) : (
                  <LockRoundedIcon style={{ fontSize: 20 }}></LockRoundedIcon>
                )}
              </div>
              <Typography style={{ marginLeft: 6 }}>
                {/* {(data === DEVICES_GROUPS_VISIBILITY.PUBLIC &&
                  t('devicesGroupsVisibilityPublic')) ||
                  (data === DEVICES_GROUPS_VISIBILITY.PRIVATE &&
                    t('devicesGroupsVisibilityPrivate'))} */}
                  {(!data &&
                  t('devicesGroupsVisibilityPublic')) ||
                  (data &&
                    t('devicesGroupsVisibilityPrivate'))}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'let_users_update',
      label: t('tabGroupsTableEditColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: (data) => {
          
          return (
            <span>
              {data
                ? t('tabGroupsTableEditColumnValueTrue')
                : t('tabGroupsTableEditColumnValueFalse')}
            </span>
          );
        },
      },
    },
    {
      name: 'last_update_user',
      label: ' ',
      options: {
        sort: false,
        customBodyRenderLite: (data) => {
          return (
              <>
                {
                (hasRoleRemoveDevice && (data === authInformation.preferredUsername || groups[data].let_users_update)) &&
                  <Tooltip title={t('deviceGroupDevicesListDeleteDeviceTooltip')}>
                  <IconButton
                    size="small"
                    variant="outlined"
                    onClick={(event) => {setAlert(true); setIndex(data)}} 
                  >
                    <DeleteForeverRoundedIcon/>
                  </IconButton>
                  </Tooltip>
                }
              </>
          );
        },
      },
    },
  ];


	const options = {
    elevation: 0,
    filter: false,
    print: false,
    sort: true,
    search: false,
    viewColumns: false,
    selectableRows: 'none',
		responsive: 'simple',
		rowsPerPageOptions: [],
    pagination: true,
    download: false,
    tableBodyMaxHeight: '187px',
		setTableProps: () => ({
      size: 'small',
    }),
	textLabels: {
		body: {
		  noMatch: 'No hay registros que coincidan con la búsqueda',
		  toolTip: 'Ordenar',
		},
		pagination: {
			displayRows: 'de',
		},
		toolbar: {
		  search: 'Buscar',}
		}
  };

	return (
		<>
			{loading ? (
				renderSkeleton()
			) : (
        <>
				<Card
					elevation={4}
					style={{ width: '100%', paddingTop: 5, paddingBottom: 10}}
				>
				<MUIDataTable
					data={groups}
					columns={columns}
					options={options}
				/>
				</Card>
        <WTdialog
          title={t('deviceGroupDevicesListDialogTitle')}
          message={t('deviceGroupDevicesListDialogText')}
          type={WTDIALOG_TYPE.WARNING}
          primaryActionLabel={t('deviceGroupDevicesListDialogPrimary')}
          secondaryActionLabel={t('deviceGroupDevicesListDialogSecondary')}
          show={alert}
          onPrimaryAction={handleDeleteGroupOfDevice}
          onSecondaryAction={() => setAlert(false)}
          onClose={() => setAlert(false)} 
        />     
        </>
			)}
		</>
	);
}

export default Groups