/**
 * 
 * @param {*} deviceParamsList 
 * @returns 
 */
export const deviceParams = (deviceParamsList) => {
	const deviceParamsFormat = [];

	if (deviceParamsList && deviceParamsList.length > 0) {
		for (let i=0, len=deviceParamsList.length; i < len; i += 1) {
			const deviceParam = deviceParamsList[i];
			const {
				id: deviceParamId,
				iot_device_id: deviceId,
				data,
				data_online: dataOnline,
				available: deviceParamAvailable,
				internal: deviceParamInternal,
				private: deviceParamPrivate,
				sync_online: deviceParamSyncOnline,
				iot_device_param_type: iotDeviceParamType
			} = deviceParam;
			const { value: deviceParamValue = '' } = data;
			const { value: deviceParamValueOnline = '' } = dataOnline;
			const {
				id: deviceParamTypeId,
				name: deviceParamTypeName,
				description: deviceParamTypeDesciption,
				param_type_access: deviceParamTypeAccess,
				param_type_data: deviceParamTypeData,
				available: deviceParamTypeAvailable,
				internal: deviceParamTypeInternal,
				private: deviceParamTypePrivate
			} = iotDeviceParamType;

			deviceParamsFormat.push(
				{
					deviceParamId,
					deviceId,
					deviceParamValue,
					deviceParamValueOnline,
					deviceParamAvailable,
					deviceParamInternal,
					deviceParamPrivate,
					deviceParamSyncOnline,
					deviceParamTypeId,
					deviceParamTypeName,
					deviceParamTypeDesciption,
					deviceParamTypeAccess,
					deviceParamTypeData,
					deviceParamTypeAvailable,
					deviceParamTypeInternal,
					deviceParamTypePrivate
				}
			);
		}
	}

	return deviceParamsFormat;
}