// import axios from 'axios'
import apiIOT from 'config/api'
import store from 'redux/store'
import { SEARCH } from 'constants/apiResources'
import { AUTH_HEADER, UTILITY_HEADER } from 'constants/apiHeaders'

// const CancelToken = axios.CancelToken
// const source = CancelToken.source()
// const cancel = CancelToken.cancel
// let tokenRequest = null

/**
 * *async* **Search Service of logical units or devices**
 * - Search a logical unit: first search in redux state and if the logical unit isn't there,
 * search with API.
 * - Search a devices: search with API
 * @param {*} type type of search. [d=device|u=logical unit]
 * @param {*} idH Id hierarchy to search in state *iotlogicalunit*
 * @param {*} query - string
 * - If param type=u, query param = [external_id|external_name]
 * - If param type=d, query param = [devuid]
 * @returns {*} Object device or logical unit
 */
export const search = async (type, idH, query) => {
  const state = store.getState()
  const iotlogicalunit = state.iotLogicalUnit['iotlogicalunits']
  let auhtInformation = state.auth['authInformation']
  const iothierarchyActive = state.ioTHierarchy['iothierarchyActive']

  if (type === 'u') {
    const unit = iotlogicalunit[idH]
    //TODO: mejorar la busqueda incorporando el campo 'lastRequest: timestamp'
    //para forzar una busqueda por API
    if (unit)
      return unit.filter(
        x => x.external_id.toLowerCase().includes(query.toLowerCase()) || x.external_name.toLowerCase().includes(query.toLowerCase()),
      )
    else {
      //Busqueda por API porque la herarchy no esta en state

      apiIOT.setHeaders({
        ...apiIOT.headers,
        [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`,
        [UTILITY_HEADER]: auhtInformation['companyId'],
      })
      const response = await apiIOT.get(SEARCH.LOGICALUNIT, { externall_id: query }).catch(function (thrown) {
        if (apiIOT.isCancel(thrown)) {
          //  console.log('Request canceled', thrown.message)
        } else {
          // handle error
          // console.log('Request canceled err', thrown.message)
        }
      })
      return response?.data
    }
  }

  if (type === 'd') {
    //search with API.

    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`,
      [UTILITY_HEADER]: auhtInformation['companyId'],
    })

    const response = await apiIOT.get(SEARCH.DEVICE, { devuid: query }).catch(function (thrown) {
      if (apiIOT.isCancel(thrown)) {
        // console.log('Request canceled', thrown.message)
      } else {
        // handle error
        //  console.log('Request canceled err', thrown.message)
      }
    })

    //remove field unnecessary
    if (response?.data?.iot_device?.iot_firmware) delete response?.data?.iot_device?.iot_firmware
    if (response?.data?.iot_device?.iot_device_brief) delete response?.data?.iot_device?.iot_device_brief
    /////////////

    if (
      response?.data?.logical_unit_relations?.length > 0 &&
      response?.data?.logical_unit_relations[0].iot_logical_unit.iot_hierarchy.name === iothierarchyActive?.name
    )
      return response?.data
    else return null
  }
}

export default search
