import React from 'react'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import NotificationImportantRoundedIcon from '@material-ui/icons/NotificationImportantRounded'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded'
import Typography from '@material-ui/core/Typography'

import styles from './styles.module.scss'
import { OPERATION_STATUS } from 'constants/iot'

const getTypeClass = type => {
  switch (type) {
    case OPERATION_STATUS.ALERT:
      return styles.alert
    case OPERATION_STATUS.WARNING:
      return styles.warning
    case OPERATION_STATUS.OK:
      return styles.ok
    default:
      return styles.ok
  }
}

function OperationBadge({ type, label, ...props }) {
  return (
    <div className={styles.root} {...props}>
      <div className={[styles.container, getTypeClass(type), label ? styles.withLabel : ''].join(' ')}>
        {type === OPERATION_STATUS.ALERT && <ErrorRoundedIcon className={styles.icon} />}
        {type === OPERATION_STATUS.OK && <CheckCircleRoundedIcon className={styles.icon} />}
        {type === OPERATION_STATUS.WARNING && <NotificationImportantRoundedIcon className={styles.icon} />}
        {label && (
          <Typography className={styles.label} variant="subtitle2">
            {label}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default OperationBadge
