export const getTableData = (reports, t) => {
  const data = reports?.map(report => {
    return {
      id: report?.iot_report?.id,
      title: report?.iot_report?.title,
      method: report?.iot_report?.method,
      available: report?.iot_report?.available ? 'Si' : 'No',
      let_cron: report?.iot_report?.let_cron,
      reportObject: report?.iot_report,
    }
  })

  return data
}

