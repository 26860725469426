import { createSelector } from 'reselect'
import * as ReselectTools from 'reselect-tools'
import { registerSelectors, checkSelector } from 'reselect-tools'
import store from '../store'

const getIotLogicalUnits = state => state.iotLogicalUnit?.iotlogicalunits
const getActiveHierarchy = state => state.ioTHierarchy?.iothierarchyActive

const getLogicalUnitsActiveHierarchy = state => {
  //devolver las unidades de la jerarquia activa (ambos cargados en el state)
  const logicalUnits = getIotLogicalUnits(state)
  const activeH = getActiveHierarchy(state)
  if (logicalUnits[activeH?.id]) return logicalUnits[activeH?.id]
}

/**
 * **iotLogicalUnit Selector**.
 * Logical units to show at map view layer 2.
 * Information of each logical unit about description, status and geoposition
 * @function
 * @param state
 * @returns {array} array logical units
 */
export const logicalUnitsLayer = createSelector(
  state => getLogicalUnitsActiveHierarchy(state),
  resp => resp,
)

//reselect-tools to inspect console
ReselectTools.getStateWith(() => store.getState())
registerSelectors({ logicalUnitsLayer })
checkSelector('logicalUnitsLayer')
