export const COLORS = {
  PRIMARY: '#93f1ff',
  SECONDARY: '#93f1ff',
  OK: '#82f4d2',
  WARNING: '#fdaa29',
  DANGER: '#e80c2c',
  INFO: '#ffffff',
}

export const CHART_COLORS = [
  ['#527BCC', '#52B7CC', '#52CC8D', '#5ECC52'],
  ['#CCBE52', '#CC7752', '#CC5252', '#CC5299'],
  ['#B852CC', '#7152CC', '#527BCC', '#52C4CC'],
  ['#527BCC', '#52B7CC', '#52CC8D', '#5ECC52'],
]

export const CHART_DEVICE_DETAIL_ALARMS_COLORS = [
  ['#f14040', '#527BCC', '#52CC8D']
]