/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { memo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { pageSizeOptions, SORT } from '../../constants';
import { getIn } from 'seamless-immutable';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { generateArray, removeValueFromArray } from 'utils/arrayUtils';

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}


const DevicesList = ({ groupid, listDevices, totalPages, totalItems, loading, filters, selectedItems, setSelectedItems, currentPage, setCurrentPage, filtersChange, countSelectedItems, setCountSelectedItems, sort, setSort, rowsSelected, setRowsSelected, disableCheckbox }) => {
  const [t] = useTranslation('devices');
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [isFirstRun, setIsFirstRun] = useState(true)
	const [tableData, setTableData] = useState([]);
  const getDevicesCandidates = useCallback(() => {
    if (filters)
      dispatch(iotActions.getIotGroupDevicesCandidates(groupid, currentPage, pageSize,
        filters.sortOption,
        filters.isDevices? filters.input : null,
        !filters.isDevices? filters.input : null,
        filters.situation,
        filters.devType,
        filters.hierarchy
        ))
  })

  useEffect(() => {
    if (listDevices && !listDevices[`${currentPage}`]) getDevicesCandidates()
    else
      if (listDevices && listDevices[`${currentPage}`] && !filtersChange) setTableData(listDevices[`${currentPage}`])
      else getDevicesCandidates()
  }, [currentPage])

  useEffect(() => {
    if (listDevices) {
      if (isFirstRun && listDevices[`1`]) {
        setIsFirstRun(false)
      }
      setTableData(listDevices[`${currentPage}`])
    }
  },[listDevices]);

  useEffect(() => {
		if (!isFirstRun) {
			getDevicesCandidates()
			setTableData(listDevices[`${currentPage}`])
		}
	}, [sort])

  useEffect(() => {
    return () => {
      dispatch(iotActions.cleanIotGroupDevicesCandidates())
    }
  }, [])

  const handleChangePage = (_currentPage, _pageSize) => {
    setCurrentPage(_currentPage)
    if (_pageSize) setPageSize(_pageSize)
  }

  const handleSort = (page, sortName, sortDirection) => {
		let sortArray = [...SORT[sortName]]
		sortDirection === 'asc' ? sortArray.push('asc') : sortArray.push('desc')
		let newSort = [sortArray]
		setSort(newSort)
	}

  const columns = [
    {
      name: 'device_devuid', //'serialNumber',
      label: t('deviceGroupDevicesListSerialNumberLabel'),
      options: {
        sort: true,
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'device_type_description', //'type',
      label: t('deviceGroupDevicesListTypeLabel'),
      options: {
        sort: true,
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },

    {
      name: 'device_install_situation', //'condition',
      label: t('deviceGroupDevicesListConditionLabel'),
      options: {
        sort: true,
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'hierarchy_description', //'hierarchy',
      label: t('deviceGroupDevicesListHierarchyLabel'),
      options: {
        sort: true,
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },

  ];

	const options = {
		isRowSelectable: () => {
			return disableCheckbox ? false : true
		},
		selectableRowsHeader: disableCheckbox ? false : true,
		search: false,
    elevation: 1,
    filter: false,
    print: false,
    download: false,
    //filterType: 'textField',
    selectableRows: 'multiple',
    responsive: 'vertical',
    tableBodyHeight: '370px',
    confirmFilters: true,
    viewColumns: false,
    serverSide: true,
    count: totalItems,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [],
    selectToolbarPlacement:'none',
    setTableProps: () => ({
      size: 'small',
      padding:'none'
    }),
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page + 1, null)
          break
        case 'sort':
          const page = tableState.page
					const sortName = tableState.sortOrder.name;
					const sortDirection = tableState.sortOrder.direction;
          handleSort(page, sortName, sortDirection)
          break
        default:
        //console.log('action not handled.');
      }
    },
		rowsSelected: rowsSelected,
    onRowSelectionChange: (currentSelect, allSelected) => {
			if (currentSelect[0]) {
				if (rowsSelected.includes(currentSelect[0].index)) {
					const newRows = removeValueFromArray(rowsSelected, currentSelect[0].index)
					setRowsSelected(newRows)
				} else {
					setRowsSelected([...new Set([...rowsSelected, currentSelect[0].index])])
				}
			}
			if(allSelected.length === 0) setRowsSelected([])
			if (allSelected.length > 0 && allSelected.length === tableData.length) {
				const length = allSelected.length
				setRowsSelected(generateArray(length))
			}
			const result = allSelected.map(item => { return tableData.at(item.index) });
      const selectedIds = result.map(item => {
           return item.device_id;
      });
      setSelectedItems(selectedIds);
      selectedIds && selectedIds.length > 0? setCountSelectedItems(selectedIds.length) : setCountSelectedItems(null);
    },
    textLabels: {
      body: {
        noMatch: 'No hay registros que coincidan con la búsqueda',
        toolTip: 'Ordenar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  };

  return (
    <>
    {loading && isFirstRun ? (
        renderSkeleton()
      ) : (
      <>
      <MUIDataTable
        data={tableData}
        columns={columns}
        options={options}
      />
      <div style={{position:'absolute', marginTop:-40, marginLeft:28}}>
        <Typography variant='subtitle1' color='primary'>
        {loading && <CircularProgress size={18} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
        {/* {selectedItems.length > 0 ? `${selectedItems.length} dispositivos seleccionados`:''} */}
        {countSelectedItems && `${countSelectedItems} dispositivos seleccionados`}
        </Typography>
      </div>
      </>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  id: getIn(store, ['iot', 'iotDeviceDetail', 'id']),
  loading: store.iot?.iotDevicesGroupCandidatesLoading,
  listDevices: store.iot?.iotDevicesGroupCandidates?.rows,
  totalPages: store.iot?.iotDevicesGroupCandidates?.iotDevicesGroupCandidatesTotalPages,
  totalItems: store?.iot?.iotDevicesGroupCandidates?.iotDevicesGroupCandidatesTotalItems
});

DevicesList.propTypes = {
};

export default memo(connect(mapStateToProps)(DevicesList));