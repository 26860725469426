/* eslint-disable array-callback-return */
import Immutable from 'seamless-immutable'
//import { getErrorMessage } from '@widergy/web-utils/lib/errorHandling';
import { goBack, push } from 'connected-react-router'
import { ROUTES } from 'constants/routes'
import i18n from 'i18next'
import moment from 'moment'
import AlertActions from 'redux/alert/actions'
import ioTLogicalUnitService from 'services/ioTLogicalUnitService'
import iotService from 'services/IotService'

import { stringArrayToObject, getErrorMessage } from './utils'

import { MTR_CT, TELEC_PS, TELEC_PS_CON, TELEC_PS_SLAVE } from 'constants/groupsDevice'

import { createSelector } from 'reselect'
// import * as ReselectTools from 'reselect-tools'
// import { registerSelectors, checkSelector } from 'reselect-tools'
// import store from './store'
// import { forEach, times } from 'underscore'

/* ------------- ioTHandler actions ------------- */
export const actions = stringArrayToObject(
  [
    'INIT_DEVICES_TYPE',
    'INIT_DEVICES_TYPE_SUCCESS',
    'INIT_DEVICES_TYPE_FAILURE',
    'GET_IOT_TYPE_ID',
    'GET_IOT_TYPE_ID_SUCCESS',
    'GET_IOT_TYPE_ID_FAILURE',
    'GET_IOT_DEVICES',
    'GET_IOT_DEVICES_SUCCESS',
    'GET_IOT_DEVICES_FAILURE',
    'GET_IOT_DEVICE_MEASUREMENTS',
    'GET_IOT_DEVICE_MEASUREMENTS_SUCCESS',
    'GET_IOT_DEVICE_MEASUREMENTS_FAILURE',
    'GET_IOT_DEVICE_DETAIL',
    'GET_IOT_DEVICE_DETAIL_SUCCESS',
    'GET_IOT_DEVICE_DETAIL_FAILURE',
    'CLEAN_DEVICE_SELECTED',
    'CLEAN_DEVICES_TYPES',
    'CLEAN_IOT_DEVICES',
    'UPDATE_MEASURES',
    'CLEAN_MEASURES',
    'GET_IOT_DEVICES_TYPES',
    'GET_IOT_DEVICES_TYPES_FAILURE',
    'GET_IOT_DEVICES_TYPES_SUCCESS',
    'GET_IOT_DEVICES_TYPES_REPORTS',
    'GET_IOT_DEVICES_TYPES_REPORTS_FAILURE',
    'GET_IOT_DEVICES_TYPES_REPORTS_SUCCESS',
    'CLEAN_IOT_DEVICES_TYPES_REPORTS',
    'GET_IOT_ISALIVE',
    'GET_IOT_ISALIVE_SUCCESS',
    'GET_IOT_ISALIVE_FAILURE',
    'GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE',
    'GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE_SUCCESS',
    'GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE_FAILURE',
    'CLEAN_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE',
    'UPDATE_ISALIVES',
    'CLEAN_ISALIVES',
    'REGISTER_DEVICE',
    'REGISTER_DEVICE_FAILURE',
    'REGISTER_DEVICE_SUCCESS',
    'REGISTER_DEVICE_UPDATE_INFORMATION',
    'REGISTER_DEVICE_UPDATE_STEP',
    'REGISTER_DEVICE_RESET_INFO',
    'RETRIEVE_DEVICE_INFO_SUCCESS',
    'RETRIEVE_DEVICE_INFO_FAILURE',
    'RETRIEVE_DEVICE_INFO',
    'EDIT_DEVICE',
    'EDIT_DEVICE_FAILURE',
    'EDIT_DEVICE_SUCCESS',
    'GET_IOT_DEVICE_ALARMS',
    'GET_IOT_DEVICE_ALARMS_SUCCESS',
    'GET_IOT_DEVICE_ALARMS_FAILURE',
    'CLEAN_DEVICE_ALARMS',
    'GET_IOT_DEVICE_ALARMS_CANCEL',
    'GET_IOT_DEVICE_ALARMS_CANCEL_SUCCESS',
    'GET_IOT_DEVICE_ALARMS_CANCEL_FAILURE',
    'CLEAN_DEVICE_ALARMS_CANCEL',

    'GET_IOT_DEVICE_GROUPS_ENTITY',
    'GET_IOT_DEVICE_GROUPS_ENTITY_SUCCESS',
    'GET_IOT_DEVICE_GROUPS_ENTITY_FAILURE',
    'CLEAN_IOT_DEVICE_GROUPS_ENTITY',

    'GET_IOT_DEVICE_EVENTS',
    'GET_IOT_DEVICE_EVENTS_SUCCESS',
    'GET_IOT_DEVICE_EVENTS_FAILURE',
    'CLEAN_DEVICE_EVENTS',
    'GET_IOT_DEVICE_RELATIONS',
    'GET_IOT_DEVICE_RELATIONS_SUCCESS',
    'GET_IOT_DEVICE_RELATIONS_FAILURE',
    'CLEAN_DEVICE_RELATIONS',
    'GET_IOT_DEVICE_LAST_ISALIVE',
    'GET_IOT_DEVICE_LAST_ISALIVE_SUCCESS',
    'GET_IOT_DEVICE_LAST_ISALIVE_FAILURE',
    'GET_IOT_DEVICE_COMPONENT_DETAIL',
    'GET_IOT_DEVICE_COMPONENT_DETAIL_SUCCESS',
    'GET_IOT_DEVICE_COMPONENT_DETAIL_FAILURE',
    'PUT_IOT_DEVICE_COMPONENT_PARAMS',
    'PUT_IOT_DEVICE_COMPONENT_PARAMS_SUCCESS',
    'PUT_IOT_DEVICE_COMPONENT_PARAMS_FAILURE',
    'PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL',
    'PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL_SUCCESS',
    'PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL_FAILURE',
    'GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE',
    'GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE_SUCCESS',
    'GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE_FAILURE',
    'INIT_SET_IOT_DEVICE_COMPONENT_STATUS',
    'SET_IOT_DEVICE_COMPONENT_STATUS_SUCCESS',
    'SET_IOT_DEVICE_COMPONENT_STATUS_FAILURE',
    'GET_IOT_NETWORK_SERVERS',
    'GET_IOT_NETWORK_SERVERS_SUCCESS',
    'GET_IOT_NETWORK_SERVERS_FAILURE',
    'SELECT_NETWORK_SERVER',
    'CLEAN_NETWORK_SERVER_SELECTED',
    'GET_IOT_NETWORK_SERVER_TOPICS',
    'GET_IOT_NETWORK_SERVER_TOPICS_SUCCESS',
    'GET_IOT_NETWORK_SERVER_TOPICS_FAILURE',
    'GET_IOT_NETWORK_SERVER_EVENTS',
    'GET_IOT_NETWORK_SERVER_EVENTS_SUCCESS',
    'GET_IOT_NETWORK_SERVER_EVENTS_FAILURE',
    'CLEAN_IOT_NETWORK_SERVER_EVENTS',
    'GET_IOT_NETWORK_SERVER_ALARMS',
    'GET_IOT_NETWORK_SERVER_ALARMS_SUCCESS',
    'GET_IOT_NETWORK_SERVER_ALARMS_FAILURE',
    'CLEAN_IOT_NETWORK_SERVER_ALARMS',
    'POST_IOT_DEVICE_SYNC_TIMESTAMP',
    'POST_IOT_DEVICE_SYNC_TIMESTAMP_SUCCESS',
    'POST_IOT_DEVICE_SYNC_TIMESTAMP_FAILURE',
    'POST_IOT_DEVICE_RESTART',
    'POST_IOT_DEVICE_RESTART_SUCCESS',
    'POST_IOT_DEVICE_RESTART_FAILURE',
    'GET_IOT_TYPE_REPORTS',
    'GET_IOT_TYPE_REPORTS_SUCCESS',
    'GET_IOT_TYPE_REPORTS_FAILURE',
    'GET_IOT_REPORT_DETAIL',
    'GET_IOT_REPORT_DETAIL_SUCCESS',
    'GET_IOT_REPORT_DETAIL_FAILURE',
    'CLEAN_IOT_REPORT_DETAIL',
    'GET_IOT_TYPE_GRAPHICS',
    'GET_IOT_TYPE_GRAPHICS_SUCCESS',
    'GET_IOT_TYPE_GRAPHICS_FAILURE',
    'GET_IOT_GROUPS_OF_DEVICES',
    'GET_IOT_GROUPS_OF_DEVICES_SUCCESS',
    'GET_IOT_GROUPS_OF_DEVICES_FAILURE',
    'CLEAN_GROUPS_OF_DEVICES',
    'GET_IOT_GROUP_OF_DEVICES_DETAIL',
    'GET_IOT_GROUP_OF_DEVICES_DETAIL_SUCCESS',
    'GET_IOT_GROUP_OF_DEVICES_DETAIL_FAILURE',
    'CLEAN_IOT_GROUP_OF_DEVICES_DETAIL',
    'GET_IOT_GROUP_DEVICES',
    'GET_IOT_GROUP_DEVICES_SUCCESS',
    'GET_IOT_GROUP_DEVICES_FAILURE',
    'CLEAN_IOT_GROUP_DEVICES',
    'POST_CREATE_IOT_GROUP_ENTITY',
    'POST_CREATE_IOT_GROUP_ENTITY_SUCCESS',
    'POST_CREATE_IOT_GROUP_ENTITY_FAILURE',
    'PUT_IOT_GROUP_ENTITY',
    'PUT_IOT_GROUP_ENTITY_SUCCESS',
    'PUT_IOT_GROUP_ENTITY_FAILURE',
    'DELETE_DEVICE_FROM_IOT_GROUP_ENTITY',
    'DELETE_DEVICE_FROM_IOT_GROUP_ENTITY_SUCCESS',
    'DELETE_DEVICE_FROM_IOT_GROUP_ENTITY_FAILURE',
    'PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY',
    'PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY_SUCCESS',
    'PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY_FAILURE',
    'GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES',
    'GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES_SUCCESS',
    'GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES_FAILURE',
    'CLEAN_GROUP_ENTITY_DEVICES_CANDIDATES',

    'POST_IOT_DEVICES_GROUP_ENTITY',
    'POST_IOT_DEVICES_GROUP_ENTITY_SUCCESS',
		'POST_IOT_DEVICES_GROUP_ENTITY_FAILURE',

		'GET_GROUP_ENTITY_TYPES',
		'GET_GROUP_ENTITY_TYPES_SUCCESS',
		'GET_GROUP_ENTITY_TYPES_FAILURE',

		'GET_IOT_REPORT_CRONS',
    'GET_IOT_REPORT_CRONS_SUCCESS',
    'GET_IOT_REPORT_CRONS_FAILURE',
		'CLEAN_IOT_REPORT_CRONS',

		'GET_IOT_TYPE_REPORT_CRON_DETAIL',
    'GET_IOT_TYPE_REPORT_CRON_DETAIL_SUCCESS',
    'GET_IOT_TYPE_REPORT_CRON_DETAIL_FAILURE',
		'CLEAN_IOT_TYPE_REPORT_CRON_DETAIL',

		'GET_WORKER_EXECUTIONS',
		'GET_WORKER_EXECUTIONS_SUCCESS',
		'GET_WORKER_EXECUTIONS_FAILURE',
		'CLEAN_WORKER_EXECUTIONS',

		'POST_CREATE_CRON_REPORT',
    'POST_CREATE_CRON_REPORT_SUCCESS',
		'POST_CREATE_CRON_REPORT_FAILURE',

		'PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY',
		'PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY_SUCCESS',
		'PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY_FAILURE',

		'PUT_IOT_CRON_REPORT',
    'PUT_IOT_CRON_REPORT_SUCCESS',
    'PUT_IOT_CRON_REPORT_FAILURE',

		'POST_ADD_CONTACT_TO_CRON_REPORT',
		'POST_ADD_CONTACT_TO_CRON_REPORT_SUCCESS',
		'POST_ADD_CONTACT_TO_CRON_REPORT_FAILURE',

		'DELETE_CONTACT_FROM_CRON_REPORT',
		'DELETE_CONTACT_FROM_CRON_REPORT_SUCCESS',
		'DELETE_CONTACT_FROM_CRON_REPORT_FAILURE',
  ],
  '@@IOT_DEVICES',
)

const privateActionCreators = {
  initDeviceTypeSuccess: payload => ({ type: actions.INIT_DEVICES_TYPE_SUCCESS, payload }),
  initDeviceTypeFailure: () => ({ type: actions.INIT_DEVICES_TYPE_FAILURE }),
  getIotDevicesTypesSuccess: payload => ({
    type: actions.GET_IOT_DEVICES_TYPES_SUCCESS,
    payload,
  }),
  getIotDevicesTypesFailure: () => ({
    type: actions.GET_IOT_DEVICES_TYPES_FAILURE,
  }),
  retrieveDeviceInfoSuccess: payload => ({
    type: actions.RETRIEVE_DEVICE_INFO_SUCCESS,
    payload,
  }),
  retrieveDeviceInfoFailure: () => ({
    type: actions.RETRIEVE_DEVICE_INFO_FAILURE,
  }),
  registerDeviceSuccess: payload => ({
    type: actions.REGISTER_DEVICE_SUCCESS,
    payload,
  }),
  registerDeviceFailure: () => ({
    type: actions.REGISTER_DEVICE_FAILURE,
  }),
  editDeviceSuccess: payload => ({
    type: actions.EDIT_DEVICE_SUCCESS,
    payload,
  }),
  editDeviceFailure: () => ({
    type: actions.EDIT_DEVICE_FAILURE,
  }),
  setIotDeviceComponentStatusSuccess: payload => ({
    type: actions.SET_IOT_DEVICE_COMPONENT_STATUS_SUCCESS,
    payload,
  }),
  setIotDeviceComponentStatusFailure: () => ({
    type: actions.SET_IOT_DEVICE_COMPONENT_STATUS_FAILURE,
  }),
  getIotLogicalUnitRelatedToDeviceSuccess: payload => ({
    type: actions.GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE_SUCCESS,
    payload,
  }),
  getIotLogicalUnitRelatedToDeviceFailure: () => ({
    type: actions.GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE_FAILURE,
  }),
}

export const actionCreators = {
  /**
   * **Device Actions** Initialize the devices type array of store.
   * Information about id, name, description
   * @async
   * @action
   * @param {none}
   * @returns update state iotDevicesType (array)
   */
  initDevicesType: () => async (dispatch, getState) => {
    const { healthCheck } = getState().utility
    if (healthCheck?.healthy) {
      const { token, companyId } = getState().auth.authInformation
      dispatch({ type: actions.INIT_DEVICES_TYPE })
      await iotService.getDevicesType(token, companyId).then(async response => {
        if (response?.info) dispatch(privateActionCreators.initDeviceTypeSuccess(response.info))
        else dispatch(privateActionCreators.initDeviceTypeFailure)
      })
    }
  },
  // -------------------------------------------------------------------------------------------------
  getIotIsAlive:
    (deviceId, pageNumber, pageSize = 10) =>
    async dispatch => {
      dispatch({ type: actions.GET_IOT_ISALIVE })
      const response = await iotService.getIotDeviceIsAlive(deviceId, pageNumber, pageSize)
      if (response.ok) {
        dispatch(actionCreators.getIotIsAliveSuccess({ data: response.data, pageNumber }))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error getIotIsAlive'); //getErrorMessage(response.data, i18n.t('DefaultMessages:iotIsAliveFailure'));
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotIsAlivesFailure(errorMessage))
      }
    },
  updateIsAlives: (isalives, pageNumber, pageSize) => ({
    type: actions.UPDATE_ISALIVES,
    payload: { data: isalives, pageNumber, pageSize },
  }),
  cleanIsAlives: () => ({ type: actions.CLEAN_ISALIVES }),
  getIotIsAliveSuccess: isalive => ({
    type: actions.GET_IOT_ISALIVE_SUCCESS,
    payload: isalive,
  }),
  getIotIsAlivesFailure: () => ({
    type: actions.GET_IOT_ISALIVE_FAILURE,
  }),
  // -------------------------------------------------------------------------------------------------
  getIotTypeId: iotTypeName => async dispatch => {
    dispatch({ type: actions.GET_IOT_TYPE_ID })
    const response = await iotService.getIotTypeIdByName(iotTypeName)

    if (response.ok) {
      const { id: iotTypeId } = response.data.iotType
      dispatch(actionCreators.getIotTypeIdSuccess(iotTypeId))
      dispatch(actionCreators.getIotDevices(iotTypeId))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error getIotTypeId');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotTypeIdFailure(errorMessage))
    }
  },
  getIotTypeIdSuccess: id => ({
    type: actions.GET_IOT_TYPE_ID_SUCCESS,
    payload: id,
  }),
  getIotTypeIdFailure: () => ({
    type: actions.GET_IOT_TYPE_ID_FAILURE,
  }),
  // -------------------------------------------------------------------------------------------------
  getIotDevices: iotTypeId => async dispatch => {
    dispatch({ type: actions.GET_IOT_DEVICES })
    const response = await iotService.getDevicesByTypeId(iotTypeId)
    if (response.ok) {
      dispatch(actionCreators.getIotDevicesSuccess(response.data))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error getIotDevices');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotDevicesFailure(errorMessage))
    }
  },
  getIotDevicesSuccess: devices => ({
    type: actions.GET_IOT_DEVICES_SUCCESS,
    payload: devices,
  }),
  getIotDevicesFailure: () => ({
    type: actions.GET_IOT_DEVICES_FAILURE,
  }),
  // -------------------------------------------------------------------------------------------------
  getIotDeviceDetail: deviceId => async dispatch => {
    dispatch({ type: actions.GET_IOT_DEVICE_DETAIL })
    const response = await iotService.getIotDeviceDetail(deviceId)
    if (response.ok) {
      dispatch(actionCreators.getIotDeviceDetailSuccess(response.data))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error getIotDeviceDetail');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotDeviceDetailFailure(errorMessage))
    }
  },
  getIotDeviceDetailSuccess: deviceDetailData => ({
    type: actions.GET_IOT_DEVICE_DETAIL_SUCCESS,
    payload: deviceDetailData,
  }),
  getIotDeviceDetailFailure: () => ({
    type: actions.GET_IOT_DEVICE_DETAIL_FAILURE,
  }),
  // -------------------------------------------------------------------------------------------------
  getIotDeviceMeasurements:
    (pageNumber, pageSize = 10, filters, isGraphics) =>
    async (dispatch, getState) => {
      dispatch({ type: actions.GET_IOT_DEVICE_MEASUREMENTS })
      const { id: deviceId } = getState().iot.iotDeviceDetail
      let response = await iotService.getIotDeviceMeasurements(deviceId, pageNumber, pageSize, filters, isGraphics)
      let items = [...response.data.iotMeasurements.iot_payload_uls.items]
      items = items.filter(measure => measure.measure_values[0].value.length > 0)
      let iot_payload_uls
      if (isGraphics) {
        iot_payload_uls = {
          items,
          total_items: items.length,
          total_pages: 1,
        }
      } else {
        iot_payload_uls = {
          items,
          total_items: response?.data?.iotMeasurements?.iot_payload_uls?.total_items,
          total_pages: response?.data?.iotMeasurements?.iot_payload_uls?.total_pages,
        }
      }
      response = { ...response, data: { ...response.data, iotMeasurements: { ...response.data.iotMeasurements, iot_payload_uls } } }
      if (response.ok) {
        dispatch(actionCreators.getIotDeviceMeasurementsSuccess({ data: response.data, pageNumber }))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error getIotDeviceMeasurements');
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotDeviceMeasurementsFailure(errorMessage))
      }
    },
  getIotDeviceMeasurementsSuccess: deviceMeasures => ({
    type: actions.GET_IOT_DEVICE_MEASUREMENTS_SUCCESS,
    payload: deviceMeasures,
  }),
  getIotDeviceMeasurementsFailure: () => ({
    type: actions.GET_IOT_DEVICE_MEASUREMENTS_FAILURE,
  }),
  updateMeasures: (measures, pageNumber, pageSize) => ({
    type: actions.UPDATE_MEASURES,
    payload: { data: measures, pageNumber, pageSize },
  }),
  cleanMeasures: () => ({ type: actions.CLEAN_MEASURES }),
  // -------------------------------------------------------------------------------------------------
  cleanSelectedDevice: () => ({ type: actions.CLEAN_DEVICE_SELECTED }),
  cleanDevicesTypes: () => ({ type: actions.CLEAN_DEVICES_TYPES }),
  cleanIotDevices: () => ({ type: actions.CLEAN_IOT_DEVICES }),
  cleanDeviceRelations: () => ({ type: actions.CLEAN_DEVICE_RELATIONS }),
  cleanIotLogicalUnitRelatedToDevice: () => ({ type: actions.CLEAN_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE }),
// -------------------------------------------------------------------------------------------------
  getIotDevicesTypes: () => async dispatch => {
    dispatch({ type: actions.GET_IOT_DEVICES_TYPES })

    const response = await iotService.getIotDevicesTypes()
    if (response.ok) {
      dispatch(privateActionCreators.getIotDevicesTypesSuccess(response.data))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error getIotDevicesTypes');
      dispatch(AlertActions.error(errorMessage))
      dispatch(privateActionCreators.getIotDevicesTypesFailure(errorMessage))
    }
  },
  getIotLogicalUnitRelatedToDevice: deviceId => async (dispatch, getState) => {
    dispatch({ type: actions.GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE })

    const response = await ioTLogicalUnitService.getLogicalUnitByDeviceId(deviceId)
    if (response) {
      dispatch(privateActionCreators.getIotLogicalUnitRelatedToDeviceSuccess(response.logical_unit_relations))
    } else {
      if (getState()?.iot?.iotDeviceRelations[0]?.devuid) {
        const { devuid: childrenDevUid } = getState().iot.iotDeviceRelations[0]
        const childrenDevice = await ioTLogicalUnitService.getLogicalUnitByDeviceId(childrenDevUid)
        const parentId = childrenDevice?.logical_unit_relations[0]?.iot_logical_unit?.logical_unit_parent
        const response = await ioTLogicalUnitService.getLogicalUnitDetailById(parentId)
        if (response) dispatch(privateActionCreators.getIotLogicalUnitRelatedToDeviceSuccess(response))
        else {
          const errorMessage = getErrorMessage(response.data, 'msg error getIotLogicalUnitRelatedToDevice');
          dispatch(AlertActions.error(errorMessage))
          dispatch(privateActionCreators.getIotLogicalUnitRelatedToDeviceFailure(errorMessage))
        }
      } else {
        dispatch(privateActionCreators.getIotLogicalUnitRelatedToDeviceSuccess(response))
      }
    }
  },
  // -------------------------------------------------------------------------------------------------
  updateRegisterDeviceInfo: info => async dispatch => dispatch({ type: actions.REGISTER_DEVICE_UPDATE_INFORMATION, payload: info }),
  updateRegisterDeviceStep: () => async dispatch =>
    dispatch({
      type: actions.REGISTER_DEVICE_UPDATE_STEP,
    }),
  resetRegisterDeviceFlow: () => ({ type: actions.REGISTER_DEVICE_RESET_INFO }),
  cancelRegisterDevice: () => async dispatch => {
    dispatch(actionCreators.resetRegisterDeviceFlow())
    dispatch(push(ROUTES.IOT_DEVICE_TYPES))
  },
  retrieveDeviceInfo: number => async dispatch => {
    dispatch({ type: actions.RETRIEVE_DEVICE_INFO })

    const response = await iotService.retrieveDeviceInfo(number)
    if (response.ok) {
      dispatch(privateActionCreators.retrieveDeviceInfoSuccess(response.data))
      dispatch(actionCreators.updateRegisterDeviceStep())
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error retrieveDeviceInfo');
      dispatch(AlertActions.error(errorMessage))
      dispatch(privateActionCreators.retrieveDeviceInfoFailure(errorMessage))
    }
  },
  registerDevice: values => async dispatch => {
    dispatch({ type: actions.REGISTER_DEVICE })

    const response = await iotService.registerDevice(values)
    if (response.ok) {
      dispatch(actionCreators.resetRegisterDeviceFlow())
      dispatch(privateActionCreators.registerDeviceSuccess(response.data))
      dispatch(AlertActions.success(i18n.t('IoT:deviceRegistered')))
      dispatch(goBack())
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error registerDevice');
      dispatch(AlertActions.error(errorMessage))
      dispatch(privateActionCreators.registerDeviceFailure(errorMessage))
    }
  },
  editDevice: (id, values) => async dispatch => {
    dispatch({ type: actions.EDIT_DEVICE })

    const response = await iotService.editDevice(id, values)
    if (response.ok) {
      dispatch(privateActionCreators.editDeviceSuccess(response.data))
      dispatch(AlertActions.success(i18n.t('IoT:deviceEdited')))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error editDevice');
      dispatch(AlertActions.error(errorMessage))
      dispatch(privateActionCreators.editDeviceFailure(errorMessage))
    }
  },
  // -------------------------------------------------------------------------------------------------
  getIotDeviceAlarms:
    (pageNumber, pageSize = 10) =>
    async (dispatch, getState) => {
      dispatch({ type: actions.GET_IOT_DEVICE_ALARMS })
      const { id: deviceId } = getState().iot.iotDeviceDetail

				const response = await iotService.getIotDeviceAlarms(deviceId, pageNumber, pageSize)

      if (response.ok) {
        dispatch(actionCreators.getIotDeviceAlarmsSuccess({ data: response.data, pageNumber }))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error getIotDeviceAlarms');
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotDeviceAlarmsFailure(errorMessage))
      }
    },
  getIotDeviceAlarmsSuccess: deviceAlarms => ({
    type: actions.GET_IOT_DEVICE_ALARMS_SUCCESS,
    payload: deviceAlarms,
  }),
  getIotDeviceAlarmsFailure: () => ({
    type: actions.GET_IOT_DEVICE_ALARMS_FAILURE,
  }),
  cleanDeviceAlarms: () => ({ type: actions.CLEAN_DEVICE_ALARMS }),
  // ----------------------------------------------------------------------------------------------------------------
  getIotDeviceAlarmsCancel:
    (pageNumber, pageSize = 10) =>
    async (dispatch, getState) => {
      dispatch({ type: actions.GET_IOT_DEVICE_ALARMS_CANCEL })
      const { id: deviceId } = getState().iot.iotDeviceDetail

				const response = await iotService.getIotDeviceAlarmsCancel(deviceId, pageNumber, pageSize)

      if (response.ok) {
        dispatch(actionCreators.getIotDeviceAlarmsCancelSuccess({ data: response.data, pageNumber }))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error');
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotDeviceAlarmsCancelFailure(errorMessage))
      }
    },
  getIotDeviceAlarmsCancelSuccess: deviceAlarmsCancel => ({
    type: actions.GET_IOT_DEVICE_ALARMS_CANCEL_SUCCESS,
    payload: deviceAlarmsCancel,
  }),
  getIotDeviceAlarmsCancelFailure: () => ({
    type: actions.GET_IOT_DEVICE_ALARMS_CANCEL_FAILURE,
  }),
  cleanDeviceAlarmsCancel: () => ({ type: actions.CLEAN_DEVICE_ALARMS_CANCEL }),
  // ----------------------------------------------------------------------------------------------------------------
  getIotDeviceGroupsEntity: () =>
    async (dispatch, getState) => {
      dispatch({ type: actions.GET_IOT_DEVICE_GROUPS_ENTITY })
      const { id: deviceId } = getState().iot?.iotDeviceDetail
      const response = await iotService.getIotDeviceGrpupsEntity(deviceId);

      if (response.ok) {
        const payload = response.data.data;
        dispatch(actionCreators.getIotDeviceGrpupsEntitySuccess(payload))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error');
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotDeviceGrpupsEntityFailure(errorMessage))
      }
    },
  getIotDeviceGrpupsEntitySuccess: deviceGroups => ({
    type: actions.GET_IOT_DEVICE_GROUPS_ENTITY_SUCCESS,
    payload: deviceGroups,
  }),
  getIotDeviceGrpupsEntityFailure: () => ({
    type: actions.GET_IOT_DEVICE_GROUPS_ENTITY_FAILURE,
  }),
  cleanIotDeviceGroupsEntity: () => ({ type: actions.CLEAN_IOT_DEVICE_GROUPS_ENTITY }),
  // ----------------------------------------------------------------------------------------------------------
  getIotDeviceEvents:
    (pageNumber, pageSize = 10) =>
    async (dispatch, getState) => {
      dispatch({ type: actions.GET_IOT_DEVICE_EVENTS })
      const { id: deviceId } = getState().iot.iotDeviceDetail

				const response = await iotService.getIotDeviceEvents(deviceId, pageNumber, pageSize)

      if (response.ok) {
        dispatch(actionCreators.getIotDeviceEventsSuccess({ data: response.data, pageNumber }))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error'); //getErrorMessage(response.data, i18n.t('DefaultMessages:iotDeviceAlarmsFailure'));
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotDeviceEventsFailure(errorMessage))
      }
    },
  getIotDeviceEventsSuccess: deviceEvents => ({
    type: actions.GET_IOT_DEVICE_EVENTS_SUCCESS,
    payload: deviceEvents,
  }),
  getIotDeviceEventsFailure: () => ({
    type: actions.GET_IOT_DEVICE_EVENTS_FAILURE,
  }),
  cleanDeviceEvents: () => ({ type: actions.CLEAN_DEVICE_EVENTS }),
  //------------------------------------------------------------------------------------------------------------
  getIotDeviceRelations: () => async (dispatch, getState) => {
    dispatch({ type: actions.GET_IOT_DEVICE_RELATIONS })
    const { id: deviceId } = getState().iot.iotDeviceDetail

    const response = await iotService.getIotDeviceRelations(deviceId)
    if (response.ok) {
      dispatch(actionCreators.getIotDeviceRelationsSuccess({ data: response.data }))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error'); /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotDeviceRelationsFailure')
      );*/
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.getIotDeviceRelationsFailure(errorMessage))
		}
	},
	getIotDeviceRelationsSuccess: deviceRelations => ({
		type: actions.GET_IOT_DEVICE_RELATIONS_SUCCESS,
		payload: deviceRelations,
	}),
	getIotDeviceRelationsFailure: () => ({
		type: actions.GET_IOT_DEVICE_RELATIONS_FAILURE,
	}),
	//-----------------------------------------------------------------------------------------------------------
	getIotDeviceLastIsAlive: () => async (dispatch, getState) => {
		dispatch({ type: actions.GET_IOT_DEVICE_LAST_ISALIVE })
		const { id: deviceId } = getState().iot.iotDeviceDetail

    const response = await iotService.getIotDeviceLastIsAlive(deviceId)
    if (response.ok) {
      dispatch(actionCreators.getIotDeviceLastIsAliveSuccess({ data: response.data }))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error'); /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotDeviceRelationsFailure')
      );*/
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotDeviceLastIsAliveFailure(errorMessage))
    }
  },
  getIotDeviceLastIsAliveSuccess: deviceLastIsAlive => ({
    type: actions.GET_IOT_DEVICE_LAST_ISALIVE_SUCCESS,
    payload: deviceLastIsAlive,
  }),
  getIotDeviceLastIsAliveFailure: () => ({
    type: actions.GET_IOT_DEVICE_LAST_ISALIVE_FAILURE,
  }),
  //-----------------------------------------------------------------------------------------------------------
  getIotDeviceConponentDetail: deviceComponentId => async dispatch => {
    dispatch({ type: actions.GET_IOT_DEVICE_COMPONENT_DETAIL })
    const response = await iotService.getIotDeviceComponentDetail(deviceComponentId)
    if (response.ok) {
      dispatch(actionCreators.getIotDeviceComponentDetailSuccess(response.data))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error'); /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotDeviceComponentDetailFailure')
      );*/
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.getIotDeviceComponentDetailFailure(errorMessage))
		}
	},
	getIotDeviceComponentDetailSuccess: deviceComponentDetailData => ({
		type: actions.GET_IOT_DEVICE_COMPONENT_DETAIL_SUCCESS,
		payload: deviceComponentDetailData,
	}),
	getIotDeviceComponentDetailFailure: () => ({
		type: actions.GET_IOT_DEVICE_COMPONENT_DETAIL_FAILURE,
	}),
	setIotDeviceComponentStatus: (deviceId, alarmId, status, page) => async dispatch => {
		dispatch({ type: actions.INIT_SET_IOT_DEVICE_COMPONENT_STATUS })
		const response = await iotService.setIotDeviceStatus(deviceId, alarmId, status)
		if (response?.data?.ok) {
			dispatch(privateActionCreators.setIotDeviceComponentStatusSuccess({ alarm: response.data.alarm_status_action.alarm, pageNumber: page }))
		} else {
			const errorMessage = response?.data?.data?.errors?.message /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotDeviceComponentParamsFailure')
      );
      */
			dispatch(AlertActions.error(errorMessage))
			dispatch(privateActionCreators.setIotDeviceComponentStatusFailure())
		}
	},
	putIotDeviceComponentParams: (deviceComponentId, paramsToUpdate) => async dispatch => {
		dispatch({ type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS })
		const response = await iotService.putIotDeviceComponentParams(deviceComponentId, paramsToUpdate)
		if (response.ok) {
			dispatch(AlertActions.success(response.data.message))
			dispatch(actionCreators.putIotDeviceComponentParamsSuccess(response.data))
		} else {
			const errorMessage = response.data.errors[0].message /*'msg error' /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotDeviceComponentParamsFailure')
      );*/
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.putIotDeviceComponentParamsFailure(errorMessage))
		}
	},
	putIotDeviceComponentParamsSuccess: updateParamsData => ({
		type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_SUCCESS,
		payload: updateParamsData,
	}),
	putIotDeviceComponentParamsFailure: () => ({
		type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_FAILURE,
	}),
	putIotDeviceComponentParamsLocal: (deviceComponentId, paramsToUpdate) => async dispatch => {
		dispatch({ type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL })
		const response = await iotService.putIotDeviceComponentParamsLocal(deviceComponentId, paramsToUpdate)
		if (response.data.error === false) {
			dispatch(AlertActions.success(response.data.message))
			dispatch(actionCreators.putIotDeviceComponentParamsLocalSuccess(response.data))
		} else {
			const errorMessage = response.data.errors[0].message /*'msg error' /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotDeviceComponentParamsFailure')
      );*/
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.putIotDeviceComponentParamsLocalFailure(errorMessage))
		}
	},
	putIotDeviceComponentParamsLocalSuccess: updateParamsData => ({
		type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL_SUCCESS,
		payload: updateParamsData,
	}),
	putIotDeviceComponentParamsLocalFailure: () => ({
		type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL_FAILURE,
	}),
	getIotDeviceComponentParamsOnline: deviceComponentId => async dispatch => {
		dispatch({ type: actions.GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE })
		const response = await iotService.getIotDeviceComponentParamsOnline(deviceComponentId)
		if (response.ok) {
			dispatch(AlertActions.success(response.data.message))
			dispatch(actionCreators.getIotDeviceComponentParamsOnlineSuccess(response.data))
		} else {
			const errorMessage = response.data.errors[0].message /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotDeviceComponentParamsOnlineFailure')
      );*/
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotDeviceComponentParamsOnlineFailure(errorMessage))
    }
  },
  getIotDeviceComponentParamsOnlineSuccess: getParamsOnlineData => ({
    type: actions.GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE_SUCCESS,
    payload: getParamsOnlineData,
  }),
  getIotDeviceComponentParamsOnlineFailure: () => ({
    type: actions.GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE_FAILURE,
  }),
  //---------------------------------------------------------------------------------------------------
  postSyncTimestampIotDeviceOnline: deviceId => async dispatch => {
    dispatch({ type: actions.POST_IOT_DEVICE_SYNC_TIMESTAMP })
    const response = await iotService.postDeviceActionOnline(deviceId, 'SET_TIMESTAMP', { sincronizar: 1 })
    if (response.ok) {
      dispatch(AlertActions.success(response.data.message))
      dispatch(actionCreators.postSyncTimestampIotDeviceOnlineSuccess(response.data))
    } else {
      const errorMessage = response.data.errors[0].message
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.postSyncTimestampIotDeviceOnlineFailure(errorMessage))
    }
  },
  postSyncTimestampIotDeviceOnlineSuccess: postSyncTimestampOnlineData => ({
    type: actions.POST_IOT_DEVICE_SYNC_TIMESTAMP_SUCCESS,
  }),
  postSyncTimestampIotDeviceOnlineFailure: () => ({
    type: actions.POST_IOT_DEVICE_SYNC_TIMESTAMP_FAILURE,
  }),
  //---------------------------------------------------------------------------------------------------
  postRestartIotDeviceOnline: deviceId => async dispatch => {
    dispatch({ type: actions.POST_IOT_DEVICE_RESTART })
    const response = await iotService.postDeviceActionOnline(deviceId, 'SET_RESTART', { resetDevice: 1 })
    if (response.ok) {
      dispatch(AlertActions.success(response.data.message))
      dispatch(actionCreators.postRestartIotDeviceOnlineSuccess(response.data))
    } else {
      const errorMessage = response.data.errors[0].message
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.postRestartIotDeviceOnlineFailure(errorMessage))
    }
  },
  postRestartIotDeviceOnlineSuccess: postRestartOnlineData => ({
    type: actions.POST_IOT_DEVICE_RESTART_SUCCESS,
  }),
  postRestartIotDeviceOnlineFailure: () => ({
    type: actions.POST_IOT_DEVICE_RESTART_FAILURE,
  }),
  //---------------------------------------------------------------------------------------------------
  getIotNetworkServers: () => async dispatch => {
    dispatch({ type: actions.GET_IOT_NETWORK_SERVERS })
    const response = await iotService.getNetworkServers()
    if (response.ok) {
      dispatch(actionCreators.getIotNetworkServersSuccess(response.data))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error'); //getErrorMessage(response.data, i18n.t('DefaultMessages:iotNetworkServersFailure'));
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotNetworkServersFailure(errorMessage))
    }
  },
  getIotNetworkServersSuccess: networServersData => ({
    type: actions.GET_IOT_NETWORK_SERVERS_SUCCESS,
    payload: networServersData,
  }),
  getIotNetworkServersFailure: () => ({
    type: actions.GET_IOT_NETWORK_SERVERS_FAILURE,
  }),
  cleanSelectedNetworkServer: () => ({ type: actions.CLEAN_NETWORK_SERVER_SELECTED }),
  selectNetworkServer: networkServer => ({ type: actions.SELECT_NETWORK_SERVER, payload: networkServer }),
//---------------------------------------------------------------------------------------------------
  getIotNetworkServerTopics: networkServerId => async dispatch => {
    dispatch({ type: actions.GET_IOT_NETWORK_SERVER_TOPICS })
    const response = await iotService.getNetworkServerTopics(networkServerId)
    if (response.ok) {
      dispatch(actionCreators.getIotNetworkServerTopicsSuccess(response.data))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error'); /*getErrorMessage(
        response.data,
        i18n.t('DefaultMessages:iotNetworkServerTopicsFailure')
      );*/
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotNetworkServerTopicsFailure(errorMessage))
    }
  },
  getIotNetworkServerTopicsSuccess: networServersData => ({
    type: actions.GET_IOT_NETWORK_SERVER_TOPICS_SUCCESS,
    payload: networServersData,
  }),
  getIotNetworkServerTopicsFailure: () => ({
    type: actions.GET_IOT_NETWORK_SERVER_TOPICS_FAILURE,
  }),
  //---------------------------------------------------------------------------------------------------
  getIotNetworkServerEvents: (pageNumber, pageSize = 10) =>
    async (dispatch, getState) => {
      dispatch({ type: actions.GET_IOT_NETWORK_SERVER_EVENTS })
      const { id: serverId } = getState().iot.iotNetworkServerDetail;
      const response = await iotService.getNetworkServerEvents(serverId, pageNumber, pageSize)
      if (response.ok) {
        dispatch(actionCreators.getIotNetworkServerEventsSuccess({ data: response.data, pageNumber }))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error'); //getErrorMessage(response.data, i18n.t('DefaultMessages:iotDeviceAlarmsFailure'));
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotNetworkServerEventsFailure(errorMessage))
      }

		},
	getIotNetworkServerEventsSuccess: networkServerEvents => ({
		type: actions.GET_IOT_NETWORK_SERVER_EVENTS_SUCCESS,
		payload: networkServerEvents,
	}),
	getIotNetworkServerEventsFailure: () => ({
		type: actions.GET_IOT_NETWORK_SERVER_EVENTS_FAILURE,
	}),
	cleanNetworkServerEvents: () => ({ type: actions.CLEAN_IOT_NETWORK_SERVER_EVENTS }),
	//---------------------------------------------------------------------------------------------------
	getIotNetworkServerAlarms: (pageNumber, pageSize = 10) =>
		async (dispatch, getState) => {
			dispatch({ type: actions.GET_IOT_NETWORK_SERVER_ALARMS })
			const { id: serverId } = getState().iot.iotNetworkServerDetail;
			const response = await iotService.getNetworkServerAlarms(serverId, pageNumber, pageSize)

      if (response.ok) {
        dispatch(actionCreators.getIotNetworkServerAlarmsSuccess({ data: response.data, pageNumber }))
      } else {
        const errorMessage = getErrorMessage(response.data, 'msg error'); //getErrorMessage(response.data, i18n.t('DefaultMessages:iotDeviceAlarmsFailure'));
        dispatch(AlertActions.error(errorMessage))
        dispatch(actionCreators.getIotNetworkServerAlarmsFailure(errorMessage))
      }
    },
  getIotNetworkServerAlarmsSuccess: networkServerAlarms => ({
    type: actions.GET_IOT_NETWORK_SERVER_ALARMS_SUCCESS,
    payload: networkServerAlarms,
  }),
  getIotNetworkServerAlarmsFailure: () => ({
    type: actions.GET_IOT_NETWORK_SERVER_ALARMS_FAILURE,
  }),
  cleanNetworkServerAlarms: () => ({ type: actions.CLEAN_IOT_NETWORK_SERVER_ALARMS }),
  //---------------------------------------------------------------------------------------------------
  getIotDevicesTypesReports: (filters) => async dispatch => {
    dispatch({ type: actions.GET_IOT_DEVICES_TYPES_REPORTS })
    const response = await iotService.getIotTypesReports(filters)
    if (response.ok) {
      const payload = response.data.data;
      dispatch(actionCreators.getIotDevicesTypesReportsSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotDevicesTypesReportsFailure(errorMessage))
    }
  },
  getIotDevicesTypesReportsSuccess: (payload) => ({
    type: actions.GET_IOT_DEVICES_TYPES_REPORTS_SUCCESS,
    payload,
  }),
  getIotDevicesTypesReportsFailure: () => ({
    type: actions.GET_IOT_DEVICES_TYPES_REPORTS_FAILURE
  }),
  cleanIotDevicesTypesReports: () => ({ type: actions.CLEAN_IOT_DEVICES_TYPES_REPORTS}),
  //---------------------------------------------------------------------------------------------------
  getIotTypeReports: (iotTypeId, levelReport, filters) => async dispatch => {
    dispatch({ type: actions.GET_IOT_TYPE_REPORTS })
    const response = await iotService.getIotTypeReports(iotTypeId, filters)
    if (response.ok) {
      const payload = response.data.data.iotTypeReports.map(report => {
        return {
          code: report.id.toString(),
          name: report.title,
          filters: report.filters,
          variables: report.profiles.map(profile => {
            return {
              id: profile.id,
              title: profile.title,
              letMultipleOptions: profile.let_multiple_options,
              values: profile.profile_conf.map(config => {
                return {
                  id: config.id,
                  value: config.title,
                }
              }),
            }
          }),
        }
      })
      dispatch(actionCreators.getIotTypeReportsSuccess(levelReport, payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotTypeReportsFailure(levelReport,errorMessage))
    }
  },
  getIotTypeReportsSuccess: (levelReport, payload) => ({
    type: actions.GET_IOT_TYPE_REPORTS_SUCCESS,
    levelReport,
    payload,
  }),
  getIotTypeReportsFailure: (levelReport) => ({
    type: actions.GET_IOT_TYPE_REPORTS_FAILURE,
    levelReport
  }),
  //---------------------------------------------------------------------------------------------------
  getIotReportDetail: (reportId) => async dispatch => {
    dispatch({ type: actions.GET_IOT_REPORT_DETAIL })
    const response = await iotService.getReportById(reportId)
    if (response.ok) {
      const payload = response.data.data.iot_report.map(report => {
        return {
          code: report.id.toString(),
          name: report.title,
          filters: report.filters,
          variables: report.profiles.map(profile => {
            return {
              id: profile.id,
              title: profile.title,
              letMultipleOptions: profile.let_multiple_options,
              values: profile.profile_conf.map(config => {
                return {
                  id: config.id,
                  value: config.title,
                }
              }),
            }
          }),
        }
      })
      dispatch(actionCreators.getIotReportDetailSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotReportDetailFailure(errorMessage))
    }
  },
  getIotReportDetailSuccess: (payload) => ({
    type: actions.GET_IOT_REPORT_DETAIL_SUCCESS,
    payload,
  }),
  getIotReportDetailFailure: () => ({
    type: actions.GET_IOT_REPORT_DETAIL_FAILURE
  }),
  cleanIotReportDetail: () => ({ type: actions.CLEAN_IOT_REPORT_DETAIL}),
  //---------------------------------------------------------------------------------------------------
  getIotTypeGraphics: (iotTypeId, levelGraphic, filters) => async dispatch => {
    dispatch({ type: actions.GET_IOT_TYPE_GRAPHICS })
    const response = await iotService.getIotTypeGraphics(iotTypeId, filters)
    if (response.ok) {
      const payload = response.data.data.iotTypeGraphics
      dispatch(actionCreators.getIotTypeGraphicsSuccess(levelGraphic, payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotTypeGraphicsFailure(levelGraphic, errorMessage))
    }
  },
  getIotTypeGraphicsSuccess: (levelGraphic, payload) => ({
    type: actions.GET_IOT_TYPE_GRAPHICS_SUCCESS,
    levelGraphic,
    payload,
  }),
  getIotTypeGraphicsFailure: (levelGraphic) => ({
    type: actions.GET_IOT_TYPE_GRAPHICS_FAILURE,
    levelGraphic
  }),
  //---------------------------------------------------------------------------------------------------
  getIotGroupsOfDevices: () => async dispatch => {
    dispatch({ type: actions.GET_IOT_GROUPS_OF_DEVICES })
    const response = await iotService.getIotGroupsEntities('device')
    if (response.ok) {
			const payload = response.data.data;
      dispatch(actionCreators.getIotGroupsOfDevicesSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotGroupOfDevicesFailure(errorMessage))
    }
  },
  getIotGroupsOfDevicesSuccess: (payload) => ({
    type: actions.GET_IOT_GROUPS_OF_DEVICES_SUCCESS,
    payload,
  }),
  getIotGroupsOfDevicesFailure: () => ({
    type: actions.GET_IOT_GROUPS_OF_DEVICES_FAILURE
  }),
  cleanIotGroupsOfDevices: () => ({ type: actions.CLEAN_IOT_GROUPS_OF_DEVICES}),
  //---------------------------------------------------------------------------------------------------
  getIotGroupOfDeviceDetail: (groupEntityId) => async dispatch => {
    dispatch({ type: actions.GET_IOT_GROUP_OF_DEVICES_DETAIL })
    const response = await iotService.getIotGroupEntityDetail('device', groupEntityId)
    if (response.ok) {
      const payload = response.data.data;
      dispatch(actionCreators.getIotGroupOfDeviceDetailSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotGroupOfDeviceDetailFailure(errorMessage))
    }
  },
  getIotGroupOfDeviceDetailSuccess: (payload) => ({
    type: actions.GET_IOT_GROUP_OF_DEVICES_DETAIL_SUCCESS,
    payload,
  }),
  getIotGroupOfDeviceDetailFailure: () => ({
    type: actions.GET_IOT_GROUP_OF_DEVICES_DETAIL_FAILURE
  }),
  cleanIotGroupOfDeviceDetail: () => ({ type: actions.CLEAN_IOT_GROUP_OF_DEVICES_DETAIL}),
  //---------------------------------------------------------------------------------------------------
  getIotGroupDevices: (groupEntityId, page, paginate, sortOptions, filters) => async dispatch => {
    dispatch({ type: actions.GET_IOT_GROUP_DEVICES })
    const response = await iotService.getIotGroupDevices('device', groupEntityId, page, paginate, sortOptions, filters)
		if (response.ok) {
			// const payload = response.data.data.iot_group_entity_devices.map(device => device.iot_device)
      dispatch(actionCreators.getIotGroupDevicesSuccess({ data: response.data.data, pageNumber: page }))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotGroupDevicesFailure(errorMessage))
    }
  },
  getIotGroupDevicesSuccess: (payload) => ({
    type: actions.GET_IOT_GROUP_DEVICES_SUCCESS,
    payload,
  }),
  getIotGroupDevicesFailure: () => ({
    type: actions.GET_IOT_GROUP_DEVICES_FAILURE
  }),
  cleanIotGroupDevices: () => ({ type: actions.CLEAN_IOT_GROUP_DEVICES}),
  //---------------------------------------------------------------------------------------------------
  postCreateIotGroupOfDevices: (postPayload) => async dispatch => {
		dispatch({ type: actions.POST_CREATE_IOT_GROUP_ENTITY })
		const response = await iotService.postCreateIotGroupEntity(postPayload)
		if (response.ok) {
      const payload = response.data.data;
      dispatch(AlertActions.success('El grupo fue creado correctamente.'))
      dispatch(actionCreators.postCreateIotGroupOfDevicesSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.postCreateIotGroupOfDevicesFailure(errorMessage))
    }
  },
  postCreateIotGroupOfDevicesSuccess: (payload) => ({
    type: actions.POST_CREATE_IOT_GROUP_ENTITY_SUCCESS,
    payload,
  }),
  postCreateIotGroupOfDevicesFailure: () => ({
    type: actions.POST_CREATE_IOT_GROUP_ENTITY_FAILURE
  }),
  //---------------------------------------------------------------------------------------------------
  putGroupOfDevices: (groupEntityId, putPayload) => async dispatch => {
    dispatch({ type: actions.PUT_IOT_GROUP_ENTITY })
    const response = await iotService.putIotGroupEntity(groupEntityId, putPayload)
    if (response.ok) {
      const payload = response.data.data;
      dispatch(AlertActions.success('El grupo fue actualizado correctamente.'))
      dispatch(actionCreators.putGroupOfDevicesSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.putGroupOfDevicesFailure(errorMessage))
    }
  },
  putGroupOfDevicesSuccess: (payload) => ({
    type: actions.PUT_IOT_GROUP_ENTITY_SUCCESS,
    payload,
  }),
  putGroupOfDevicesFailure: () => ({
    type: actions.PUT_IOT_GROUP_ENTITY_FAILURE
  }),
  //---------------------------------------------------------------------------------------------------
  deleteDeviceFromGroup: (entityType, groupEntityId, deviceId) => async dispatch => {
    dispatch({ type: actions.DELETE_DEVICE_FROM_IOT_GROUP_ENTITY })
    const response = await iotService.deleteDeviceFromIotGroupEntity(entityType, groupEntityId, deviceId)
    if (response.ok) {
      const payload = response.data.data;
      dispatch(AlertActions.success('El dispositivo fue borrado del grupo de forma correcta.'))
      dispatch(actionCreators.deleteDeviceFromIotGroupEntitySuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.deleteDeviceFromIotGroupEntityFailure(errorMessage))
    }
  },
  deleteDeviceFromIotGroupEntitySuccess: (payload) => ({
    type: actions.DELETE_DEVICE_FROM_IOT_GROUP_ENTITY_SUCCESS,
    payload,
  }),
  deleteDeviceFromIotGroupEntityFailure: () => ({
    type: actions.DELETE_DEVICE_FROM_IOT_GROUP_ENTITY_FAILURE
  }),
  //---------------------------------------------------------------------------------------------------
  toogleAvailabilityGroup: (groupEntityId, toogleTo) => async dispatch => {
    dispatch({ type: actions.PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY })
    const response = await iotService.toogleAvailabilityIotGroupEntity(groupEntityId, {available: toogleTo})
    if (response.ok) {
      const payload = response.data.data;
      dispatch(AlertActions.success('Se actualizó el estado del grupo de forma correcta.'))
      dispatch(actionCreators.toogleAvailabilityGroupSuccess(payload))
    } else {
      // const errorMessage = getErrorMessage(response.data, 'msg error');
      const errorMessage = response?.data?.errors[0] && response?.data?.errors[0].message
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.toogleAvailabilityGroupFailure(errorMessage))
    }
  },
  toogleAvailabilityGroupSuccess: (payload) => ({
    type: actions.PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY_SUCCESS,
    payload,
  }),
  toogleAvailabilityGroupFailure: () => ({
    type: actions.PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY_FAILURE
  }),
  //---------------------------------------------------------------------------------------------------
  getIotGroupDevicesCandidates: (groupEntityId, page, paginate = 10, sortOptions, devuid, logicalUnitExternalName, deviceInstallSituation, deviceTypeName, hierarchyName) => async dispatch => {
    dispatch({ type: actions.GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES })
    const response = await iotService.getIotGroupDevicesCandidates('device',groupEntityId, page, paginate, sortOptions, devuid, logicalUnitExternalName, deviceInstallSituation, deviceTypeName, hierarchyName);
    if (response.ok) {
      const payload = {
        ...response.data.data,
        pageNumber: page
      }
      dispatch(actionCreators.getIotGroupDevicesCandidatesSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getIotGroupDevicesCandidatesFailure(errorMessage))
    }
  },
  getIotGroupDevicesCandidatesSuccess: (payload) => ({
    type: actions.GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES_SUCCESS,
    payload,
  }),
  getIotGroupDevicesCandidatesFailure: () => ({
    type: actions.GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES_FAILURE
  }),
  cleanIotGroupDevicesCandidates: () => ({ type: actions.CLEAN_GROUP_ENTITY_DEVICES_CANDIDATES}),

  //------------------------------------------------------------------
  postCreateIotDevicesGroup: (groupEntityId, postPayload) => async dispatch => {
    dispatch({ type: actions.POST_IOT_DEVICES_GROUP_ENTITY })
    const response = await iotService.postCreateIotDevicesGroup('device',groupEntityId, postPayload)
    if (response.ok) {
      const payload = response.data.data;
      dispatch(AlertActions.success('Los dispositivos se agregaron correctamente.'))
      dispatch(actionCreators.postCreateIotDevicesGroupSuccess(payload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error postCreateIotDevicesGroup');
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.postCreateIotDevicesGroupFailure(errorMessage))
    }
  },
  postCreateIotDevicesGroupSuccess: (payload) => ({
    type: actions.POST_IOT_DEVICES_GROUP_ENTITY_SUCCESS,
    payload,
  }),
  postCreateIotDevicesGroupFailure: () => ({
    type: actions.POST_IOT_DEVICES_GROUP_ENTITY_FAILURE
	}),

	// -------------------------------------------------------------------------------------------------
	getGroupEntityTypes: () => async dispatch => {
		dispatch({ type: actions.GET_GROUP_ENTITY_TYPES })
		const response = await iotService.getGroupEntityTypes()
		if (response.ok) {
      dispatch(actionCreators.getGroupEntityTypesSuccess(response.data.data))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error'); //getErrorMessage(response.data, i18n.t('DefaultMessages:iotDeviceByTypeIdFailure'));
      dispatch(AlertActions.error(errorMessage))
      dispatch(actionCreators.getGroupEntityTypesFailure(errorMessage))
    }
  },
  getGroupEntityTypesSuccess: groupTypes => ({
    type: actions.GET_GROUP_ENTITY_TYPES_SUCCESS,
    payload: groupTypes,
  }),
  getGroupEntityTypesFailure: () => ({
    type: actions.GET_GROUP_ENTITY_TYPES_FAILURE,
  }),

	//---------------------------------------------------------------------------------------------------
	getIotReportCrons: (reportId) => async dispatch => {
		dispatch({ type: actions.GET_IOT_REPORT_CRONS })
		const response = await iotService.getIotReportCrons(reportId)
		if (response.ok) {
			const payload = response.data.data.iot_report_crons
			dispatch(actionCreators.getIotReportCronsSuccess(payload))
		} else {
			const errorMessage = response.data.errors[0].message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.getIotReportCronsFailure(errorMessage))
		}
	},
	getIotReportCronsSuccess: (payload) => ({
		type: actions.GET_IOT_REPORT_CRONS_SUCCESS,
		payload,
	}),
	getIotReportCronsFailure: () => ({
		type: actions.GET_IOT_REPORT_CRONS_FAILURE
	}),
	cleanIotReportCrons: () => ({ type: actions.CLEAN_IOT_REPORT_CRONS }),

	//---------------------------------------------------------------------------------------------------
	getIotReportCronDetail: (cronId) => async dispatch => {
		dispatch({ type: actions.GET_IOT_TYPE_REPORT_CRON_DETAIL })
		const response = await iotService.getIotTypeReportCronDetail(cronId)
		if (response.ok) {
			const payload = response.data.data.iot_report_cron
			dispatch(actionCreators.getIotReportCronDetailSuccess(payload))
		} else {
			const errorMessage = response.data.errors[0].message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.getIotReportCronDetailFailure(errorMessage))
		}
	},
	getIotReportCronDetailSuccess: (payload) => ({
		type: actions.GET_IOT_TYPE_REPORT_CRON_DETAIL_SUCCESS,
		payload,
	}),
	getIotReportCronDetailFailure: () => ({
		type: actions.GET_IOT_TYPE_REPORT_CRON_DETAIL_FAILURE
	}),
	cleanIotReportCronDetail: () => ({ type: actions.CLEAN_IOT_TYPE_REPORT_CRON_DETAIL }),

	//---------------------------------------------------------------------------------------------------
	getWorkerExecutions: (cronId, pageNumber, pageSize = 10) => async dispatch => {
		dispatch({ type: actions.GET_WORKER_EXECUTIONS })
		const response = await iotService.getWorkerExecutions(cronId, pageNumber, pageSize)
		if (response.ok) {
			dispatch(actionCreators.getWorkerExecutionsSuccess({data: response.data, pageNumber}))
		} else {
			const errorMessage = response.data.errors[0].message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.getWorkerExecutionsFailure(errorMessage))
		}
	},
	getWorkerExecutionsSuccess: (payload) => ({
		type: actions.GET_WORKER_EXECUTIONS_SUCCESS,
		payload,
	}),
	getWorkerExecutionsFailure: () => ({
		type: actions.GET_WORKER_EXECUTIONS_FAILURE
	}),
	cleanWorkerExecutions: () => ({ type: actions.CLEAN_WORKER_EXECUTIONS }),

	//------------------------------------------------------------------
	postCreateCronReport: (reportId, deviceTypeId, postPayload) => async dispatch => {
		dispatch({ type: actions.POST_CREATE_CRON_REPORT })
		const response = await iotService.postCreateCronReport(reportId, deviceTypeId, postPayload)
		if (response.ok) {
			const payload = response.data.data;
			dispatch(AlertActions.success('El cron de reporte se creó correctamente.'))
			dispatch(actionCreators.postCreateCronReportSuccess(payload))
		} else {
			const errorMessage = response.data?.errors[0]?.message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.postCreateCronReportFailure(errorMessage))
		}
	},
	postCreateCronReportSuccess: (payload) => ({
		type: actions.POST_CREATE_CRON_REPORT_SUCCESS,
		payload,
	}),
	postCreateCronReportFailure: () => ({
		type: actions.POST_CREATE_CRON_REPORT_FAILURE
	}),

	//---------------------------------------------------------------------------------------------------
	toggleAvailabilityCronReport: (cronId, toggleTo) => async dispatch => {
		dispatch({ type: actions.PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY })
		const response = await iotService.toggleAvailabilityIotCronReport(cronId, { available: toggleTo })
		if (response.ok) {
			const payload = response.data.data;
			dispatch(AlertActions.success('Se actualizó el estado del Cron de forma correcta.'))
			dispatch(actionCreators.toggleAvailabilityCronReportSuccess(payload))
		} else {
			const errorMessage = response.data.errors[0].message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.toggleAvailabilityCronReportFailure(errorMessage))
		}
	},
	toggleAvailabilityCronReportSuccess: (payload) => ({
		type: actions.PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY_SUCCESS,
		payload,
	}),
	toggleAvailabilityCronReportFailure: () => ({
		type: actions.PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY_FAILURE
	}),

	//---------------------------------------------------------------------------------------------------
	putCronReport: (cronId, putPayload) => async dispatch => {
		dispatch({ type: actions.PUT_IOT_CRON_REPORT })
		const response = await iotService.putIotCronReport(cronId, putPayload)
		if (response.ok) {
			const payload = response.data.data;
			dispatch(AlertActions.success('El cron fue actualizado correctamente.'))
			dispatch(actionCreators.putCronReportSuccess(payload))
		} else {
			const errorMessage = response.data.errors[0].message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.putCronReportFailure(errorMessage))
		}
	},
	putCronReportSuccess: (payload) => ({
		type: actions.PUT_IOT_CRON_REPORT_SUCCESS,
		payload,
	}),
	putCronReportFailure: () => ({
		type: actions.PUT_IOT_CRON_REPORT_FAILURE
	}),
	//---------------------------------------------------------------------------------------------------
	postAddContactToCronReport: (cronId, postPayload) => async dispatch => {
		dispatch({ type: actions.POST_ADD_CONTACT_TO_CRON_REPORT})
		const response = await iotService.postAddContactToCronReport(cronId, postPayload)
		if (response.ok) {
			const payload = response.data.data;
			dispatch(AlertActions.success('El contacto fue agregado correctamente.'))
			dispatch(actionCreators.postAddContactToCronReportSuccess(payload))
		} else {
			const errorMessage = response.data.errors[0].message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.postAddContactToCronReportFailure(errorMessage))
		}
	},
	postAddContactToCronReportSuccess: (payload) => ({
		type: actions.POST_ADD_CONTACT_TO_CRON_REPORT_SUCCESS,
		payload,
	}),
	postAddContactToCronReportFailure: () => ({
		type: actions.POST_ADD_CONTACT_TO_CRON_REPORT_FAILURE
	}),
	//---------------------------------------------------------------------------------------------------
	deleteContactFromCronReport: (cronId, deletePayload) => async dispatch => {
		dispatch({ type: actions.DELETE_CONTACT_FROM_CRON_REPORT})
		const response = await iotService.deleteContactFromCronReport(cronId, deletePayload)
		if (response.ok) {
			dispatch(AlertActions.success('El contacto fue eliminado correctamente.'))
			dispatch(actionCreators.deleteContactFromCronReportSuccess())
		} else {
			const errorMessage = response.data.errors[0].message
			dispatch(AlertActions.error(errorMessage))
			dispatch(actionCreators.deleteContactFromCronReportFailure())
		}
	},
	deleteContactFromCronReportSuccess: () => ({
		type: actions.DELETE_CONTACT_FROM_CRON_REPORT_SUCCESS
	}),
	deleteContactFromCronReportFailure: () => ({
		type: actions.DELETE_CONTACT_FROM_CRON_REPORT_FAILURE
	}),
}
/* ------------- ioTHandler reducer ------------- */
const defaultState = {
  iotDevicesType: [],
  iotDeviceInitLoading: false,
  iotType: {},
  iotTypeLoading: false,
  iotDevicesTypes: [],
  iotDevicesTypesLoading: false,
  iotDevicesTypesReports: [],
  iotDevicesTypesReportsLoading: false,
  iotLogicalUnitsRelatedToDevice: [],
  iotLogicalUnitsRelatedToDeviceLoading: false,
  iotTypeName: '',
  iotDevices: [],
  iotDevicesLoading: false,
  iotDeviceMeasurementsLoading: false,
  iotDeviceMeasurements: {
    rows: {},
  },
  iotDeviceMeasurementsSort: [], //se usa para Graphics
  iotDeviceAlarmsLoading: false,
  iotDeviceAlarms: [],
  totalDeviceAlarms: null,
  iotDeviceAlarmsCancelLoading: false,
  iotDeviceAlarmsCancel: [],
  totalDeviceAlarmsCancel: null,
  iotDeviceGroupsEntityLoading: false,
  iotDeviceGroupsEntity: [],
  iotDeviceEventsLoading: false,
  iotDeviceEvents: [],
  totalDeviceEvents: null,
  iotDeviceDetailLoading: false,
  iotDeviceDetail: null,
  iotIsAlive: [],
  iotIsAliveLoading: false,
  total_isalives: null,
  iotDeviceRelationsLoading: false,
  iotDeviceRelations: [],
  totalDeviceRelations: null,
  iotDeviceLastIsAliveLoading: false,
  iotDeviceLastIsalive: null,
  registerDeviceFlow: {
    currentStep: 0,
    totalSteps: 1,
  },
  iotDeviceComponentDetailLoading: false,
  iotDeviceComponentDetail: null,
  iotDeviceComponentParamUpdateLoading: false,
  iotDeviceComponentParamLocalUpdateLoading: false,
  iotDeviceComponentParamOnlineLoading: false,
  iotNetworkServersLoading: false,

  iotNetworkServers: [],
  iotNetworkServerDetail: null,
  iotNetworkServerTopics: [],
  iotNetworkServerTopicsLoading: false,
  iotNetworkServerEvents: [],
  iotNetworkServerEventsLoading: false,
  iotNetworkServerAlarms: [],
  iotNetworkServerAlarmsLoading: false,

  iotDeviceSyncTimeStampOnlineLoading: false,
  iotDeviceRestartOnlineLoading: false,
  iotTypeReports: [],
  iotTypeReportsLoading: false,
  iotTypeGraphics: [],
  iotTypeGraphicsLoading: false,

  iotReportDetail: {},
  iotReportDetailLoading: false,

  iotGroupsOfDevices: [],
  iotGroupsOfDevicesLoading: false,
  iotGroupOfDeviceDetail: {},
  iotGroupOfDeviceDetailLoading: false,
  iotGroupDevices: {},
	iotGroupDevicesLoading: false,
	iotCreateGroupEntityId: null,
  iotCreateGroupEntityLoading: false,
  iotUpdateGroupEntityLoading: false,
  iotDeleteDeviceFromGroupEntity: false,
  iotToogleAvailabilityGroupEntity: false,
  iotDevicesGroupCandidates: {rows: {}},
  iotDevicesGroupCandidatesLoading: false,

	iotCreateDevicesGroupLoading: false,
	iotGroupEntityTypes: [],
	iotGroupEntityTypesLoading: false,

	iotReportCrons: [],
  iotReportCronsLoading: false,

	iotTypeReportCronDetail: {},
  iotTypeReportCronDetailLoading: false,

	iotWorkerExecutions: {},
  iotWorkerExecutionsLoading: false,

	iotCronReportSaved: {},
	iotCreateCronReportLoading: false,

	iotUpdateCronReportLoading: false,

	iotToggleAvailabilityCronReport: false,

	iotAddContactToCronReportLoading: false,
	iotDeleteContactFromCronReportLoading: false,
}

/* eslint-disable complexity */
export function reducer(state = Immutable(defaultState), action) {
  switch (action.type) {
    case actions.INIT_DEVICES_TYPE: {
      return Immutable.merge(state, { iotDeviceInitLoading: true })
    }
    case actions.INIT_DEVICES_TYPE_SUCCESS: {
      return Immutable.merge(state, {
        iotDevicesType: action.payload,
        iotDeviceInitLoading: false,
      })
    }
    case actions.INIT_DEVICES_TYPE_FAILURE: {
      return Immutable.merge(state, {
        iotDevicesType: [],
        iotDeviceInitLoading: false,
      })
    }
    case actions.INIT_DEVICE_DETAIL: {
      return Immutable.merge(state, { iotDeviceDetailLoading: action.payload })
    }
    // -----------------------------------
    // -- Get IoT isAlive
    // -----------------------------------
    case actions.GET_IOT_ISALIVE: {
      return Immutable.merge(state, {
        iotIsAliveLoading: true,
      })
    }
    case actions.GET_IOT_ISALIVE_SUCCESS: {
      return Immutable.merge(state, {
        iotIsAlive: action.payload.data.info.items,
        total_isalives: action.payload.data.info.total_items,
        iotIsAliveLoading: false,
      })
    }
    case actions.GET_IOT_ISALIVE_FAILURE: {
      return Immutable.merge(state, {
        iotIsAlive: [],
        iotIsAliveLoading: false,
      })
    }
    case actions.UPDATE_ISALIVES: {
      const { pageNumber, data, pageSize } = action.payload
      const currentIsAlives = state.iotIsAlive.rows[pageNumber]

      return Immutable.merge(state, {
        iotIsAlive: {
          ...state.iotIsAlive,
          rows: {
            ...state.iotIsAlive.rows,
            [pageNumber]: [data, ...(currentIsAlives && currentIsAlives.length < pageSize ? currentIsAlives : currentIsAlives.slice(0, -1))],
          },
        },
      })
    }
    case actions.CLEAN_ISALIVES: {
      return Immutable.merge(state, {
        iotIsAlive: {
          ...state.iotIsAlive,
          rows: {},
        },
      })
    }
    // -----------------------------------
    // -- Get IoT Type
    // -----------------------------------
    case actions.GET_IOT_TYPE_ID: {
      return Immutable.merge(state, {
        iotTypeLoading: true,
      })
    }
    case actions.GET_IOT_TYPE_ID_SUCCESS: {
      const iotTypeValue = action.payload.iotType || {}

      return Immutable.merge(state, {
        iotType: iotTypeValue,
        iotTypeLoading: false,
      })
    }
    case actions.GET_IOT_TYPE_ID_FAILURE: {
      return Immutable.merge(state, {
        iotType: {},
        iotTypeLoading: false,
      })
    }
    case actions.CLEAN_DEVICES_TYPES: {
      return Immutable.merge(state, {
        iotDevicesTypes: [],
      })
    }
    // -----------------------------------
    // -- Get Devices
    // -----------------------------------
    case actions.GET_IOT_DEVICES: {
      return Immutable.merge(state, {
        iotDevicesLoading: true,
      })
    }
    case actions.GET_IOT_DEVICES_SUCCESS: {
      return Immutable.merge(state, {
        iotDevices: (action.payload && action.payload.iotDevices && action.payload.iotDevices.items) || [],
        iotDevicesLoading: false,
      })
    }
    case actions.GET_IOT_DEVICES_FAILURE: {
      return Immutable.merge(state, {
        iotDevices: [],
        iotDevicesLoading: false,
      })
    }
    case actions.CLEAN_IOT_DEVICES: {
      return Immutable.merge(state, {
        iotDevices: [],
      })
    }
    // -----------------------------------
    // -- Get Devices Measures
    // -----------------------------------
    case actions.GET_IOT_DEVICE_MEASUREMENTS: {
      return Immutable.merge(state, {
        iotDeviceMeasurementsLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_MEASUREMENTS_SUCCESS: {
      let sortItems = action.payload.data.iotMeasurements.iot_payload_uls.items.slice()
      return Immutable.merge(state, {
        iotDeviceMeasurements: {
          rows: {
            ...state.iotDeviceMeasurements.rows,
            [action.payload.pageNumber]: action.payload.data.iotMeasurements.iot_payload_uls.items,
          },
          total: action.payload.data.iotMeasurements.iot_payload_uls.total_items,
        },
        iotDeviceMeasurementsSort: sortItems.sort((a, b) => (a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0)),
        iotDeviceMeasurementsLoading: false,
      })
    }
    case actions.UPDATE_MEASURES: {
      const { pageNumber, data, pageSize } = action.payload
      const currentMeasures = state.iotDeviceMeasurements.rows[pageNumber]

      return Immutable.merge(state, {
        iotDeviceMeasurements: {
          ...state.iotDeviceMeasurements,
          rows: {
            ...state.iotDeviceMeasurements.rows,
            [pageNumber]: [data, ...(currentMeasures && currentMeasures.length < pageSize ? currentMeasures : currentMeasures.slice(0, -1))],
          },
        },
      })
    }
    case actions.CLEAN_MEASURES: {
      return Immutable.merge(state, {
        iotDeviceMeasurements: {
          ...state.iotDeviceMeasurements,
          rows: {},
        },
        iotDeviceMeasurementsSort: [],
      })
    }
    case actions.GET_IOT_DEVICE_MEASUREMENTS_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceMeasurementsLoading: false,
      })
    }
    // -----------------------------------
    // -- Get Device Detail
    // -----------------------------------
    case actions.GET_IOT_DEVICE_DETAIL: {
      return Immutable.merge(state, {
        iotDeviceDetailLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_DETAIL_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceDetail: action.payload.iotDevice,
        iotDeviceDetailLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_DETAIL_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceMeasurements: [],
        iotDeviceMeasurementsSort: [],
        iotDeviceMeasurementsLoading: false,
        iotDeviceDetailLoading: false,
      })
    }
    case actions.CLEAN_DEVICE_SELECTED: {
      return Immutable.merge(state, {
        iotDeviceDetail: null,
      })
    }
    case actions.GET_IOT_DEVICES_TYPES: {
      return Immutable.merge(state, {
        iotDevicesTypesLoading: true,
      })
    }
    case actions.GET_IOT_DEVICES_TYPES_FAILURE: {
      return Immutable.merge(state, {
        iotDevicesTypesLoading: false,
      })
    }
    case actions.GET_IOT_DEVICES_TYPES_SUCCESS: {
      return Immutable.merge(state, {
        iotDevicesTypesLoading: false,
        iotDevicesTypes: action.payload.data,
      })
    }
    case actions.GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE: {
      return Immutable.merge(state, {
        iotLogicalUnitsRelatedToDeviceLoading: true,
      })
    }
    case actions.GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE_FAILURE: {
      return Immutable.merge(state, {
        iotLogicalUnitsRelatedToDeviceLoading: false,
      })
    }
    case actions.GET_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE_SUCCESS: {
      let orderedList = []
      
      if (action && action.payload)
        if(action.payload.length >= 2)
          orderedList = action.payload.sort((a, b) => Number(moment(b.start_date)) - Number(moment(a.start_date)));
        else
          orderedList = action.payload;

      return Immutable.merge(state, {
        iotLogicalUnitsRelatedToDevice: orderedList,
        iotLogicalUnitsRelatedToDeviceLoading: false,
      })
    }
    case actions.CLEAN_IOT_LOGICAL_UNIT_RELATED_TO_DEVICE: {
      return Immutable.merge(state, {
        iotLogicalUnitsRelatedToDevice: [],
        iotLogicalUnitsRelatedToDeviceLoading: false,
      })
    }
    case actions.REGISTER_DEVICE_UPDATE_INFORMATION: {
      return Immutable.merge(state, {
        registerDeviceFlow: {
          ...state.registerDeviceFlow,
          ...action.payload,
        },
      })
    }
    case actions.REGISTER_DEVICE_UPDATE_STEP: {
      const { totalSteps, currentStep } = state.registerDeviceFlow
      return Immutable.merge(state, {
        registerDeviceFlow: {
          ...state.registerDeviceFlow,
          currentStep: currentStep === totalSteps ? 1 : currentStep + 1,
        },
      })
    }
    case actions.REGISTER_DEVICE_RESET_INFO: {
      return Immutable.merge(state, {
        registerDeviceFlow: defaultState.registerDeviceFlow,
      })
    }
    case actions.RETRIEVE_DEVICE_INFO: {
      return Immutable.merge(state, {
        retrieveDeviceInfoLoading: true,
      })
    }
    case actions.RETRIEVE_DEVICE_INFO_SUCCESS: {
      const { iotStockDevice } = action.payload

      return Immutable.merge(state, {
        retrieveDeviceInfoLoading: false,
        registerDeviceFlow: {
          ...state.registerDeviceFlow,
          deviceInfo: iotStockDevice,
        },
      })
    }
    case actions.RETRIEVE_DEVICE_INFO_FAILURE: {
      return Immutable.merge(state, {
        retrieveDeviceInfoLoading: false,
      })
    }
    case actions.REGISTER_DEVICE: {
      return Immutable.merge(state, {
        registerDeviceLoading: true,
      })
    }
    case actions.REGISTER_DEVICE_FAILURE: {
      return Immutable.merge(state, {
        registerDeviceLoading: false,
      })
    }
    case actions.REGISTER_DEVICE_SUCCESS: {
      return Immutable.merge(state, {
        registerDeviceLoading: false,
      })
    }
    case actions.EDIT_DEVICE: {
      return Immutable.merge(state, {
        editDeviceLoading: true,
      })
    }
    case actions.EDIT_DEVICE_FAILURE: {
      return Immutable.merge(state, {
        editDeviceLoading: false,
      })
    }
    case actions.EDIT_DEVICE_SUCCESS: {
      return Immutable.merge(state, {
        editDeviceLoading: false,
      })
    }
    // -----------------------------------
    // -- Get Device Alarms
    // -----------------------------------
    case actions.GET_IOT_DEVICE_ALARMS: {
      return Immutable.merge(state, {
        iotDeviceAlarmsLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_ALARMS_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceAlarms: {
          rows: {
            ...state.iotDeviceAlarms.rows,
            [action.payload.pageNumber]: action.payload.data.data.items,
          },
          totalDeviceAlarms: action.payload.data.data.total_items,
          totalDevicePages: action.payload.data.data.total_pages,
        },
        iotDeviceAlarmsLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_ALARMS_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceAlarms: {
          ...state.iotDeviceAlarms,
          rows: {},
        },
        iotDeviceAlarmsLoading: false,
      })
    }
    case actions.CLEAN_DEVICE_ALARMS: {
      return Immutable.merge(state, {
        iotDeviceAlarms: {
          ...state.iotDeviceAlarms,
          rows: {},
        },
      })
    }
    // -----------------------------------
    // -- Get Device Events
    // -----------------------------------
    case actions.GET_IOT_DEVICE_EVENTS: {
      return Immutable.merge(state, {
        iotDeviceEventsLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_EVENTS_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceEvents: {
          rows: {
            ...state.iotDeviceEvents.rows,
            [action.payload.pageNumber]: action.payload.data.iotDeviceEvents.iot_events.items,
          },
          totalDeviceEvents: action.payload.data.iotDeviceEvents.iot_events.total_items,
          totalDevicePages: action.payload.data.iotDeviceEvents.iot_events.total_pages,
        },
        iotDeviceEventsLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_EVENTS_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceEvents: {
          ...state.iotDeviceEvents,
          rows: {},
        },
        iotDeviceEventsLoading: false,
      })
    }
    case actions.CLEAN_DEVICE_EVENTS: {
      return Immutable.merge(state, {
        iotDeviceEvents: {
          ...state.iotDeviceEvents,
          rows: {},
        },
      })
    }
    // -----------------------------------
    // -- Set Device Component Alarms Status
    // -----------------------------------
    case actions.INIT_SET_IOT_DEVICE_COMPONENT_STATUS: {
      return Immutable.merge(state, {
        iotDeviceComponentStatusLoading: true,
      })
    }
    case actions.SET_IOT_DEVICE_COMPONENT_STATUS_SUCCESS: {
      let page = 1
      let rowsAux = {}
      while (!!state.iotDeviceAlarms.rows[page]) {
        if (page === action.payload.pageNumber)
          rowsAux[action.payload.pageNumber] = state.iotDeviceAlarms.rows[action.payload.pageNumber].map(alarm => {
            if (alarm.id === action.payload.alarm.id) {
              action.payload.alarm['iot_alarm_type'] = alarm.iot_alarm_type
              return action.payload.alarm
            } else return alarm
          })
        else rowsAux[page] = state.iotDeviceAlarms.rows[page]
        page++
      }
      return Immutable.merge(state, {
        iotDeviceComponentStatusLoading: false,
        iotDeviceAlarms: {
          rows: rowsAux,
          ...state.iotDeviceAlarms.totalDeviceAlarms,
          ...state.iotDeviceAlarms.totalDevicePages,
        },
      })
    }
    case actions.SET_IOT_DEVICE_COMPONENT_STATUS_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceComponentStatusLoading: false,
      })
    }
    // -----------------------------------
    // -- Get Device Alarms Cancel
    // -----------------------------------
    case actions.GET_IOT_DEVICE_ALARMS_CANCEL: {
      return Immutable.merge(state, {
        iotDeviceAlarmsCancelLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_ALARMS_CANCEL_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceAlarmsCancel: {
          rows: {
            ...state.iotDeviceAlarmsCancel.rows,
            [action.payload.pageNumber]: action.payload.data.data.items,
          },
          totalDeviceAlarmsCancel: action.payload.data.data.total_items,
          totalDevicePages: action.payload.data.data.total_pages,
        },
        iotDeviceAlarmsCancelLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_ALARMS_CANCEL_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceAlarmsCancel: {
          ...state.iotDeviceAlarmsCancel,
          rows: {},
        },
        iotDeviceAlarmsCancelLoading: false,
      })
    }
    case actions.CLEAN_DEVICE_ALARMS_CANCEL: {
      return Immutable.merge(state, {
        iotDeviceAlarmsCancel: {
          ...state.iotDeviceAlarmsCancel,
          rows: {},
        },
      })
    }

    // -----------------------------------
    // -- Get Device Groups Entities
    // -----------------------------------
    case actions.GET_IOT_DEVICE_GROUPS_ENTITY: {
      return Immutable.merge(state, {
        iotDeviceGroupsEntityLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_GROUPS_ENTITY_SUCCESS: {
      const iotDeviceGroupsValue = action.payload.iot_group_entity || []
      return Immutable.merge(state, {
        iotDeviceGroupsEntity: iotDeviceGroupsValue,
        iotDeviceAlarmsCancelLoading: false,// -> ¿?
        iotDeviceGroupsEntityLoading: false
      })
    }
    case actions.GET_IOT_DEVICE_GROUPS_ENTITY_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceGroupsEntity: [],
        iotDeviceAlarmsCancelLoading: false,// -> ¿?
        iotDeviceGroupsEntityLoading: false
      })
    }
    case actions.CLEAN_IOT_DEVICE_GROUPS_ENTITY: {
      return Immutable.merge(state, {
        iotDeviceGroupsEntity: []
      })
    }

    // -----------------------------------
    // -- Get Device Relations
    // -----------------------------------
    case actions.GET_IOT_DEVICE_RELATIONS: {
      return Immutable.merge(state, {
        iotDeviceRelationsLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_RELATIONS_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceRelations: action.payload.data.data.sort((a, b) => a.slaveId - b.slaveId),
        totalDeviceRelations: action.payload.data ? action.payload.data.data.length : 0,
        iotDeviceRelationsLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_RELATIONS_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceRelations: [],
        iotDeviceRelationsLoading: false,
      })
    }
    case actions.CLEAN_DEVICE_RELATIONS: {
      return Immutable.merge(state, {
        iotDeviceRelations: [],
      })
    }
    // -----------------------------------
    // -- Get Device Last IsAlive
    // -----------------------------------
    case actions.GET_IOT_DEVICE_LAST_ISALIVE: {
      return Immutable.merge(state, {
        iotDeviceLastIsAliveLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_LAST_ISALIVE_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceLastIsAlive: action.payload.data.data,
        totalDeviceRelations: action.payload.data ? action.payload.data.data.length : 0,
        iotDeviceLastIsAliveLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_LAST_ISALIVE_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceLastIsAlive: null,
        iotDeviceLastIsAliveLoading: false,
      })
    }
    // -----------------------------------
    // -- Get Device Component Detail
    // -----------------------------------
    case actions.GET_IOT_DEVICE_COMPONENT_DETAIL: {
      return Immutable.merge(state, {
        iotDeviceComponentDetailLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_COMPONENT_DETAIL_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceComponentDetail: action.payload.device_component,
        iotDeviceComponentDetailLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_COMPONENT_DETAIL_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceComponentDetail: null,
        iotDeviceComponentDetailLoading: false,
      })
    }
    // -----------------------------------
    // -- Put Device Component Params
    // -----------------------------------
    case actions.PUT_IOT_DEVICE_COMPONENT_PARAMS: {
      return Immutable.merge(state, {
        iotDeviceComponentParamUpdateLoading: true,
      })
    }
    case actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceComponentParamUpdateLoading: false,
      })
    }
    case actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceComponentParamUpdateLoading: false,
      })
    }
    // -----------------------------------
    // -- Put Device Component Params Local
    // -----------------------------------
    case actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL: {
      return Immutable.merge(state, {
        iotDeviceComponentParamLocalUpdateLoading: true,
      })
    }
    case actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceComponentParamLocalUpdateLoading: false,
      })
    }
    case actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_LOCAL_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceComponentParamLocalUpdateLoading: false,
      })
    }
    // ---------------------------------------
    // -- Get Device Component Params Online
    // ---------------------------------------
    case actions.GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE: {
      return Immutable.merge(state, {
        iotDeviceComponentParamOnlineLoading: true,
      })
    }
    case actions.GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceComponentParamOnlineLoading: false,
      })
    }
    case actions.GET_IOT_DEVICE_COMPONENT_PARAMS_ONLINE_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceComponentParamOnlineLoading: false,
      })
    }
    // ---------------------------------------
    // -- Post Device Request Sync Timestamp
    // ---------------------------------------
    case actions.POST_IOT_DEVICE_SYNC_TIMESTAMP: {
      return Immutable.merge(state, {
        iotDeviceSyncTimeStampOnlineLoading: true,
      })
    }
    case actions.POST_IOT_DEVICE_SYNC_TIMESTAMP_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceSyncTimeStampOnlineLoading: false,
      })
    }
    case actions.POST_IOT_DEVICE_SYNC_TIMESTAMP_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceSyncTimeStampOnlineLoading: false,
      })
    }
    // ---------------------------------------
    // -- Post Device Request Restart
    // ---------------------------------------
    case actions.POST_IOT_DEVICE_RESTART: {
      return Immutable.merge(state, {
        iotDeviceRestartOnlineLoading: true,
      })
    }
    case actions.POST_IOT_DEVICE_RESTART_SUCCESS: {
      return Immutable.merge(state, {
        iotDeviceRestartOnlineLoading: false,
      })
    }
    case actions.POST_IOT_DEVICE_RESTART_FAILURE: {
      return Immutable.merge(state, {
        iotDeviceRestartOnlineLoading: false,
      })
    }
    // -----------------------------------
    // -- Get IoT Network Servers
    // -----------------------------------
    case actions.GET_IOT_NETWORK_SERVERS: {
      return Immutable.merge(state, {
        iotNetworkServersLoading: true,
      })
    }
    case actions.GET_IOT_NETWORK_SERVERS_SUCCESS: {
      const iotNetworkServersValue = action.payload.info || {}

      return Immutable.merge(state, {
        iotNetworkServers: iotNetworkServersValue,
        iotNetworkServersLoading: false,
      })
    }
    case actions.GET_IOT_NETWORK_SERVERS_FAILURE: {
      return Immutable.merge(state, {
        iotNetworkServers: [],
        iotNetworkServersLoading: false,
      })
    }
    case actions.CLEAN_NETWORK_SERVER_SELECTED: {
      return Immutable.merge(state, {
        iotNetworkServerDetail: null,
      })
    }
    case actions.SELECT_NETWORK_SERVER: {
      return Immutable.merge(state, {
        iotNetworkServerDetail: action.payload,
      })
    }
    // -----------------------------------
    // -- Get IoT Network Server Topics
    // -----------------------------------
    case actions.GET_IOT_NETWORK_SERVER_TOPICS: {
      return Immutable.merge(state, {
        iotNetworkServerTopicsLoading: true,
      })
    }
    case actions.GET_IOT_NETWORK_SERVER_TOPICS_SUCCESS: {
      const iotNetworkServerTopicsValue = action.payload.info && action.payload.info.length > 0 ? action.payload.info[0].iot_server_topics : []

      return Immutable.merge(state, {
        iotNetworkServerTopics: iotNetworkServerTopicsValue,
        iotNetworkServerTopicsLoading: false,
      })
    }
    case actions.GET_IOT_NETWORK_SERVER_TOPICS_FAILURE: {
      return Immutable.merge(state, {
        iotNetworkServerTopics: [],
        iotNetworkServerTopicsLoading: false,
      })
    }
    // -----------------------------------
    // -- Get IoT Network Server Events
    // -----------------------------------
    case actions.GET_IOT_NETWORK_SERVER_EVENTS: {
      return Immutable.merge(state, {
        iotNetworkServerEventsLoading: true,
      })
    }
    case actions.GET_IOT_NETWORK_SERVER_EVENTS_SUCCESS: {

      return Immutable.merge(state, {
        iotNetworkServerEvents: {
          rows: {
            ...state.iotNetworkServerEvents.rows,
            [action.payload.pageNumber]: action.payload.data.iotServerEvents.iot_events.items,
          },
          totalNetworkServerEvents: action.payload.data.iotServerEvents.iot_events.total_items,
          totalNetworkServerPages: action.payload.data.iotServerEvents.iot_events.total_pages,
        },
        iotNetworkServerEventsLoading: false,
      })

    }
    case actions.GET_IOT_NETWORK_SERVER_EVENTS_FAILURE: {
      return Immutable.merge(state, {
        iotNetworkServerEvents: [],
        iotNetworkServerEventsLoading: false,
      })
    }
    case actions.CLEAN_IOT_NETWORK_SERVER_EVENTS: {
      return Immutable.merge(state, {
        iotNetworkServerEvents: {
          ...state.iotNetworkServerEvents,
          rows: {},
        },
      })
    }
    // -----------------------------------
    // -- Get IoT Network Server Alarms
    // -----------------------------------
    case actions.GET_IOT_NETWORK_SERVER_ALARMS: {
      return Immutable.merge(state, {
        iotNetworkServerAlarmsLoading: true,
      })
    }
    case actions.GET_IOT_NETWORK_SERVER_ALARMS_SUCCESS: {
      return Immutable.merge(state, {
        iotNetworkServerAlarms: {
          rows: {
            ...state.iotNetworkServerAlarms.rows,
            [action.payload.pageNumber]: action.payload.data.iotServerAlamrs.iot_alarms.items,
          },
          totalNetworkServerAlarms: action.payload.data.iotServerAlamrs.iot_alarms.total_items,
          totalNetworkServerPages: action.payload.data.iotServerAlamrs.iot_alarms.total_pages,
        },
        iotNetworkServerAlarmsLoading: false,
      })
    }
    case actions.GET_IOT_NETWORK_SERVER_ALARMS_FAILURE: {
      return Immutable.merge(state, {
        iotNetworkServerAlarms: {
          ...state.iotNetworkServerAlarms,
          rows: {},
        },
        iotNetworkServerAlarmsLoading: false,
      })
    }
    case actions.CLEAN_IOT_NETWORK_SERVER_ALARMS: {
      return Immutable.merge(state, {
        iotNetworkServerAlarms: {
          ...state.iotNetworkServerAlarms,
          rows: {},
        },
      })
    }
    // -----------------------------------
    // -- Get Iot devices Types Reports
    // -----------------------------------
    case actions.GET_IOT_DEVICES_TYPES_REPORTS: {
      return Immutable.merge(state, {
        iotDevicesTypesReportsLoading: true,
      })
    }
    case actions.GET_IOT_DEVICES_TYPES_REPORTS_SUCCESS: {
      return Immutable.merge(state, {
        iotDevicesTypesReports: action.payload,
        iotDevicesTypesReportsLoading: false,
      })
    }
    case actions.GET_IOT_DEVICES_TYPES_REPORTS_FAILURE: {
      return Immutable.merge(state, {
        iotDevicesTypesReports: [],
        iotDevicesTypesReportsLoading: false,
      })
    }
    case actions.CLEAN_IOT_DEVICES_TYPES_REPORTS: {
      return Immutable.merge(state, {
        iotDevicesTypesReports: [],
      })
    }
    // -----------------------------------
    // -- Get Iot Type Reports
    // -----------------------------------
    case actions.GET_IOT_TYPE_REPORTS: {
      return Immutable.merge(state, {
        iotTypeReportsLoading: true,
      })
    }
    case actions.GET_IOT_TYPE_REPORTS_SUCCESS: {
      return Immutable.merge(state, {
        iotTypeReports: { [action.levelReport]: action.payload },
        iotTypeReportsLoading: false,
      })
    }
    case actions.GET_IOT_TYPE_REPORTS_FAILURE: {
      return Immutable.merge(state, {
        iotTypeReports: [],
        iotTypeReportsLoading: false,
      })
    }
    case actions.CLEAN_IOT_TYPE_REPORTS: {
      return Immutable.merge(state, {
        iotTypeReports: [],
      })
    }
    // -----------------------------------
    // -- Get Iot Report Detail
    // -----------------------------------
    case actions.GET_IOT_REPORT_DETAIL: {
      return Immutable.merge(state, {
        iotReportDetailLoading: true,
      })
    }
    case actions.GET_IOT_REPORT_DETAIL_SUCCESS: {
      return Immutable.merge(state, {
        iotReportDetail: action.payload,
        iotReportDetailLoading: false,
      })
    }
    case actions.GET_IOT_REPORT_DETAIL_FAILURE: {
      return Immutable.merge(state, {
        iorReportDetail: {},
        iotReportDetailLoading: false,
      })
    }
    case actions.CLEAN_IOT_REPORT_DETAIL: {
      return Immutable.merge(state, {
        iorReportDetail: {},
      })
    }
    // -----------------------------------
    // -- Get Iot Type Graphics
    // -----------------------------------
    case actions.GET_IOT_TYPE_GRAPHICS: {
      return Immutable.merge(state, {
        iotTypeGraphicsLoading: true,
      })
    }
    case actions.GET_IOT_TYPE_GRAPHICS_SUCCESS: {
      return Immutable.merge(state, {
        iotTypeGraphics: { [action.levelGraphic]: action.payload },
        iotTypeGraphicsLoading: false,
      })
    }
    case actions.GET_IOT_TYPE_GRAPHICS_FAILURE: {
      return Immutable.merge(state, {
        iotTypeGraphics: [],
        iotTypeGraphicsLoading: false,
      })
    }
    case actions.CLEAN_IOT_TYPE_GRAPHICS: {
      return Immutable.merge(state, {
        iotTypeGraphics: [],
      })
    }
    // -----------------------------------
    // -- Get Iot Group of Devices
    // -----------------------------------
    case actions.GET_IOT_GROUPS_OF_DEVICES: {
      return Immutable.merge(state, {
        iotGroupsOfDevicesLoading: true,
      })
    }
    case actions.GET_IOT_GROUPS_OF_DEVICES_SUCCESS: {
      const iotGropusOfDevicesValue = action.payload.iot_group_entity || []

      return Immutable.merge(state, {
        iotGroupsOfDevices: iotGropusOfDevicesValue,
        iotGroupsOfDevicesLoading: false,
      })
    }
    case actions.GET_IOT_GROUPS_OF_DEVICES_FAILURE: {
      return Immutable.merge(state, {
        iotGroupsOfDevices: [],
        iotGroupsOfDevicesLoading: false,
      })
    }
    case actions.CLEAN_IOT_GROUPS_OF_DEVICES: {
      return Immutable.merge(state, {
        iotGroupsOfDevices: [],
      })
    }
    // -----------------------------------
    // -- Get Iot Group of Device Detail
    // -----------------------------------
    case actions.GET_IOT_GROUP_OF_DEVICES_DETAIL: {
      return Immutable.merge(state, {
        iotGroupOfDeviceDetailLoading: true,
      })
    }
    case actions.GET_IOT_GROUP_OF_DEVICES_DETAIL_SUCCESS: {
      const iotGropusOfDevicesValue = action.payload.iot_group_entity || {}

      return Immutable.merge(state, {
        iotGroupOfDeviceDetail: iotGropusOfDevicesValue,
        iotGroupOfDeviceDetailLoading: false,
      })
    }
    case actions.GET_IOT_GROUP_OF_DEVICES_DETAIL_FAILURE: {
      return Immutable.merge(state, {
        iotGroupOfDeviceDetail: {},
        iotGroupOfDeviceDetailLoading: false,
      })
    }
    case actions.CLEAN_IOT_GROUP_OF_DEVICES_DETAIL: {
      return Immutable.merge(state, {
        iotGroupOfDeviceDetail: {},
      })
    }
    // -----------------------------------
    // -- Get Iot Group Devices List
    // -----------------------------------
    case actions.GET_IOT_GROUP_DEVICES: {
      return Immutable.merge(state, {
        iotGroupDevicesLoading: true,
      })
    }
    case actions.GET_IOT_GROUP_DEVICES_SUCCESS: {
			const iotGropusOfDevicesValue = action.payload.data.iot_group_entity_devices.items.map(device => device.iot_device) || []
      return Immutable.merge(state, {
        iotGroupDevices: {
          devices: {
            ...state.iotGroupDevices.devices,
            [action.payload.pageNumber]: iotGropusOfDevicesValue,
          },
          totalDevices: action.payload.data.iot_group_entity_devices.total_items,
          totalPages: action.payload.data.iot_group_entity_devices.total_pages,
        },
        iotGroupDevicesLoading: false,
      })
    }
    case actions.GET_IOT_GROUP_DEVICES_FAILURE: {
      return Immutable.merge(state, {
        iotGroupDevices: {},
        iotGroupDevicesLoading: false,
      })
    }
    case actions.CLEAN_IOT_GROUP_DEVICES: {
      return Immutable.merge(state, {
        iotGroupDevices: {},
      })
    }
    // -----------------------------------
    // -- Post Create Iot Group Entity
    // -----------------------------------
    case actions.POST_CREATE_IOT_GROUP_ENTITY: {
      return Immutable.merge(state, {
        iotCreateGroupEntityLoading: true,
      })
    }
    case actions.POST_CREATE_IOT_GROUP_ENTITY_SUCCESS: {
			return Immutable.merge(state, {
				iotCreateGroupEntityId: action.payload.iot_group_entity.id,
        iotCreateGroupEntityLoading: false,
      })
    }
    case actions.POST_CREATE_IOT_GROUP_ENTITY_FAILURE: {
      return Immutable.merge(state, {
        iotCreateGroupEntityLoading: false,
      })
    }
    // -----------------------------------------
    // -- Put Update detail Header Group Entity
    // -----------------------------------------
    case actions.PUT_IOT_GROUP_ENTITY: {
      return Immutable.merge(state, {
        iotUpdateGroupEntityLoading: true,
      })
    }
    case actions.PUT_IOT_GROUP_ENTITY_SUCCESS: {
      return Immutable.merge(state, {
        iotUpdateGroupEntityLoading: false,
      })
    }
    case actions.PUT_IOT_GROUP_ENTITY_FAILURE: {
      return Immutable.merge(state, {
        iotUpdateGroupEntityLoading: false,
      })
    }
    // -----------------------------------------
    // -- Delete Device from Group Entity
    // -----------------------------------------
    case actions.DELETE_DEVICE_FROM_IOT_GROUP_ENTITY: {
      return Immutable.merge(state, {
        iotDeleteDeviceFromGroupEntity: true,
      })
    }
    case actions.DELETE_DEVICE_FROM_IOT_GROUP_ENTITY_SUCCESS: {
      return Immutable.merge(state, {
        iotDeleteDeviceFromGroupEntity: false,
      })
    }
    case actions.DELETE_DEVICE_FROM_IOT_GROUP_ENTITY_FAILURE: {
      return Immutable.merge(state, {
        iotDeleteDeviceFromGroupEntity: false,
      })
    }
    // -----------------------------------------
    // -- Toogle Availavility Group Entity
    // -----------------------------------------
    case actions.PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY: {
      return Immutable.merge(state, {
        iotToogleAvailabilityGroupEntity: true,
      })
    }
    case actions.PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY_SUCCESS: {
      return Immutable.merge(state, {
        iotToogleAvailabilityGroupEntity: false,
      })
    }
    case actions.PUT_IOT_GROUP_ENTITY_TOOGLE_AVAILABILITY_FAILURE: {
      return Immutable.merge(state, {
        iotToogleAvailabilityGroupEntity: false,
      })
    }
    // ----------------------------------------
    // -- Get Iot Group Devices Candidates
    // ----------------------------------------
    case actions.GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES: {
      return Immutable.merge(state, {
        iotDevicesGroupCandidatesLoading: true,
      })
    }

    case actions.GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES_SUCCESS: {
      let page = 1;
      let rowAux = {};

      while (!!state?.iotDevicesGroupCandidates?.rows[page]) {
        rowAux[page] = state.iotDevicesGroupCandidates.rows[page]
        page++
      }
      rowAux[action.payload.pageNumber] = action.payload.iot_device_candidates || [];

      return Immutable.merge(state, {
        iotDevicesGroupCandidates: {
          rows: rowAux,
          iotDevicesGroupCandidatesTotalItems: action.payload.total_items || 0,
          iotDevicesGroupCandidatesTotalPages: action.payload.total_pages || 0
        },
        iotDevicesGroupCandidatesLoading: false,
      })
    }

    case actions.GET_IOT_GROUP_ENTITY_DEVICES_CANDIDATES_FAILURE: {
      return Immutable.merge(state, {
        iotDevicesGroupCandidates: {
          ...state.iotDevicesGroupCandidates,
          rows: {},
        },
        iotDevicesGroupCandidatesTotalPages: 0,
        iotDevicesGroupCandidatesTotalItems: 0,
        iotDevicesGroupCandidatesLoading: false,
      })
    }
    case actions.CLEAN_GROUP_ENTITY_DEVICES_CANDIDATES: {
      return Immutable.merge(state, {
        iotDevicesGroupCandidates: {
          rows: {},
        },
        iotDevicesGroupCandidatesTotalPages: 0,
        iotDevicesGroupCandidatesTotalItems: 0,
        iotDevicesGroupCandidatesLoading: false
      })
    }

    // -----------------------------------
    // -- Post Create Iot Devices Group
    // -----------------------------------
    case actions.POST_IOT_DEVICES_GROUP_ENTITY: {
      return Immutable.merge(state, {
        iotCreateDevicesGroupLoading: true,
      })
    }
    case actions.POST_IOT_DEVICES_GROUP_ENTITY_SUCCESS: {
      return Immutable.merge(state, {
        iotCreateDevicesGroupLoading: false,
      })
    }
    case actions.POST_IOT_DEVICES_GROUP_ENTITY_FAILURE: {
      return Immutable.merge(state, {
        iotCreateDevicesGroupLoading: false,
      })
		}

		// -----------------------------------
    // -- Get Group Entity Types
    // -----------------------------------
    case actions.GET_GROUP_ENTITY_TYPES: {
      return Immutable.merge(state, {
        iotTypeLoading: true,
      })
    }
    case actions.GET_GROUP_ENTITY_TYPES_SUCCESS: {
      const groupTypes = action.payload.iot_group_entity_type || []
      return Immutable.merge(state, {
        iotGroupEntityTypes: groupTypes,
        iotGroupEntityTypesLoading: false,
      })
    }
    case actions.GET_GROUP_ENTITY_TYPES_FAILURE: {
      return Immutable.merge(state, {
        iotGroupEntityTypes: [],
        iotTypeLoading: false,
      })
		}

		// -----------------------------------
    // -- Get Iot Type Report Crons
    // -----------------------------------
    case actions.GET_IOT_REPORT_CRONS: {
      return Immutable.merge(state, {
        iotReportCronsLoading: true,
      })
    }
    case actions.GET_IOT_REPORT_CRONS_SUCCESS: {
      const reportCrons = action.payload
			return Immutable.merge(state, {
        iotReportCrons: reportCrons,
        iotReportCronsLoading: false,
      })
    }
    case actions.GET_IOT_REPORT_CRONS_FAILURE: {
      return Immutable.merge(state, {
        iotReportCrons: [],
        iotReportCronsLoading: false,
      })
    }
    case actions.CLEAN_IOT_REPORT_CRONS: {
      return Immutable.merge(state, {
        iotReportCrons: [],
      })
    }

		// -----------------------------------
    // -- Get Iot Report Cron Detail
    // -----------------------------------
    case actions.GET_IOT_TYPE_REPORT_CRON_DETAIL: {
      return Immutable.merge(state, {
        iotTypeReportCronDetailLoading: true,
      })
    }
    case actions.GET_IOT_TYPE_REPORT_CRON_DETAIL_SUCCESS: {
      return Immutable.merge(state, {
        iotTypeReportCronDetail: action.payload,
        iotTypeReportCronDetailLoading: false,
      })
    }
    case actions.GET_IOT_TYPE_REPORT_CRON_DETAIL_FAILURE: {
      return Immutable.merge(state, {
        iotTypeReportCronDetail: {},
        iotTypeReportCronDetailLoading: false,
      })
    }
    case actions.CLEAN_IOT_TYPE_REPORT_CRON_DETAIL: {
      return Immutable.merge(state, {
        iotTypeReportCronDetail: {},
      })
    }

		// -----------------------------------
    // -- Get Iot Worker Executions
    // -----------------------------------
    case actions.GET_WORKER_EXECUTIONS: {
      return Immutable.merge(state, {
        iotWorkerExecutionsLoading: true,
      })
    }
    case actions.GET_WORKER_EXECUTIONS_SUCCESS: {
			return Immutable.merge(state, {
        iotWorkerExecutions: {
          rows: {
            ...state.iotWorkerExecutions.rows,
            [action.payload.pageNumber]: action.payload.data.data.iot_report_cron_executions.items,
          },
          totalWorkerExecutions: action.payload.data.data.iot_report_cron_executions.total_items,
          totalWorkerExecutionPages: action.payload.data.data.iot_report_cron_executions.total_pages,
        },
				iotWorkerExecutionsLoading: false,
      })
    }
    case actions.GET_WORKER_EXECUTIONS_FAILURE: {
      return Immutable.merge(state, {
        iotWorkerExecutions: {
          ...state.iotWorkerExecutions,
          rows: {},
        },
        iotWorkerExecutionsLoading: false,
      })
    }
    case actions.CLEAN_WORKER_EXECUTIONS: {
      return Immutable.merge(state, {
        iotWorkerExecutions: {
          ...state.iotWorkerExecutions,
          rows: {},
        },
      })
    }


		// -----------------------------------
    // -- Post Create Cron Report
    // -----------------------------------
    case actions.POST_CREATE_CRON_REPORT: {
      return Immutable.merge(state, {
        iotCreateCronReportLoading: true,
      })
    }
    case actions.POST_CREATE_CRON_REPORT_SUCCESS: {
      return Immutable.merge(state, {
        iotCronReportSaved: action.payload,
        iotCreateCronReportLoading: false,
      })
    }
    case actions.POST_CREATE_CRON_REPORT_FAILURE: {
      return Immutable.merge(state, {
        iotCronReportSaved: {},
				iotCreateCronReportLoading: false,
      })
		}

		// -----------------------------------------
    // -- Toggle Availavility Cron Report
    // -----------------------------------------
    case actions.PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY: {
      return Immutable.merge(state, {
        iotToggleAvailabilityCronReport: true,
      })
    }
    case actions.PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY_SUCCESS: {
      return Immutable.merge(state, {
        iotToggleAvailabilityCronReport: false,
      })
    }
    case actions.PUT_IOT_CRON_REPORT_TOGGLE_AVAILABILITY_FAILURE: {
      return Immutable.merge(state, {
        iotToggleAvailabilityCronReport: false,
      })
    }

		// -----------------------------------------
    // -- Put Update detail Header Cron Report
    // -----------------------------------------
    case actions.PUT_IOT_CRON_REPORT: {
      return Immutable.merge(state, {
        iotUpdateCronReportLoading: true,
      })
    }
    case actions.PUT_IOT_CRON_REPORT_SUCCESS: {
      return Immutable.merge(state, {
        iotCronReportSaved: action.payload,
				iotUpdateCronReportLoading: false,
      })
    }
    case actions.PUT_IOT_CRON_REPORT_FAILURE: {
      return Immutable.merge(state, {
        iotCronReportSaved: {},
        iotUpdateCronReportLoading: false,
      })
    }

		// -----------------------------------------
    // -- Post Add Contact To Cron Report
    // -----------------------------------------
		case actions.POST_ADD_CONTACT_TO_CRON_REPORT: {
			return Immutable.merge(state, {
				iotAddContactToCronReportLoading: true,
			})
		}
		case actions.POST_ADD_CONTACT_TO_CRON_REPORT_SUCCESS: {
			return Immutable.merge(state, {
				iotAddContactToCronReportLoading: false,
			})
		}
		case actions.POST_ADD_CONTACT_TO_CRON_REPORT_FAILURE: {
			return Immutable.merge(state, {
				iotAddContactToCronReportLoading: false,
			})
		}

		// -----------------------------------------
    // -- Delete Contact From Cron Report
    // -----------------------------------------
		case actions.DELETE_CONTACT_FROM_CRON_REPORT: {
			return Immutable.merge(state, {
				iotDeleteContactFromCronReportLoading: true,
			})
		}
		case actions.DELETE_CONTACT_FROM_CRON_REPORT_SUCCESS: {
			return Immutable.merge(state, {
				iotDeleteContactFromCronReportLoading: false,
			})
		}
		case actions.DELETE_CONTACT_FROM_CRON_REPORT_FAILURE: {
			return Immutable.merge(state, {
				iotDeleteContactFromCronReportLoading: false,
			})
		}
    default: {
      return state
    }
  }
}
/* eslint-enable complexity */

/* ------------- ioTHandler selectors ------------- */
/**
 * **ioTHandler Selector**.
 * Get data for measures Chart.
 * @function
 * @param state
 * @returns {array} array data ["time": [],
 *                              "channel1": {conf: {unit, label}, values: []},
 *                              "channel2": {conf: {unit, label}, values: []},
 *                              , ..., "channelN": {conf: {unit, label}, values: []}]
 */
export const getMeasuresChartSelector = createSelector(
  state => getData(state),
  resp => resp,
)

const getMeasuresSort = state => state.iot?.iotDeviceMeasurementsSort
const getDeviceSelected = state => state.iot?.iotDeviceDetail

const getData = state => {
  const measures = getMeasuresSort(state)
  let channelsCode = getChannelCodes(state)
  let data = {}

  data['time'] = getTimes(state)
  // eslint-disable-next-line array-callback-return
  channelsCode.map(channel => {
    let values = []
    //let conf = {}
    // eslint-disable-next-line array-callback-return
    measures.map(ms => {
      //const ms_conf = ms.measure_conf.filter(v => v.code === channel); //Channel data config
      //measure_conf viene vacio para TELEC
      const filter = ms.measure_values_base.filter(v => v.code === channel)
      if (filter[0]) {
        values.push(filter[0].value !== 'NA' ? Number(Number(filter[0].value).toFixed(filter[0].decimalView)) : null)
        /* conf["unit"] = filter[0].unit
        conf["label"] = filter[0].description   */
      }
    })
    if (values.length > 0) {
      data[`${channel}`] = values //{conf: conf, values: values}
    }
  })
  return data
}

const getTimes = state => {
  const measures = getMeasuresSort(state)
  let timeValues = [] //values in seconds
  if (measures) {
    // eslint-disable-next-line array-callback-return
    measures.map(item => {
      let seconds = new Date(moment(item.timestamp)).getTime() / 1000
      timeValues.push(seconds)
    })
  }
  return timeValues
}

const getChannelCodes = state => {
  const devSelected = getDeviceSelected(state)
  let channelsCode = []

  if (devSelected?.iot_type?.name === 'MTR_CT') {
    MTR_CT.groups.map(obj => {
      obj.channels.map(channel => channelsCode.push(channel.code))
    })
    return channelsCode
  }

  if (devSelected?.iot_type?.name === 'TELEC_PS') {
    TELEC_PS.groups.map(obj => {
      obj.channels.map(channel => channelsCode.push(channel.code))
    })
    return channelsCode
  }

  if (devSelected?.iot_type?.name === 'TELEC_PS_CON') {
    TELEC_PS_CON.groups.map(obj => {
      obj.channels.map(channel => channelsCode.push(channel.code))
    })
    return channelsCode
  }

  if (devSelected?.iot_type?.name === 'TELEC_PS_SLAVE') {
    TELEC_PS_SLAVE.groups.map(obj => {
      obj.channels.map(channel => channelsCode.push(channel.code))
    })
    return channelsCode
  }

  return channelsCode
}

