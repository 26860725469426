import { useEffect } from 'react';

import { ROUTES } from 'constants/routes';

function Contact() {  
  useEffect(() => {
      const url = `${ROUTES.IOT_DOC_SWAGGER_EXTERNAL}`;
      window.location.href = url;
  }, []);

  return (
    <div></div>
  );
}

export default Contact;