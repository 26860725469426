import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import Colors from './variables/_colors.module.scss'
import FontFamilies from './variables/_fontFamilies.module.scss'

const MuiTheme = responsiveFontSizes(
  createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: Colors.primary,
      },
      secondary: { main: Colors.secondary },
    },
    status: {
      danger: 'orange',
    },
    typography: {
      fontSize: 16,
      htmlFontSize: 20,
      fontFamily: [FontFamilies.systemFont, 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
      // h1: {
      //   fontWeight: 700,
      //   fontSize: 80,
      // },
      // h2: {
      //   fontWeight: 700,
      //   fontSize: 60,
      // },
      // h3: {
      //   fontWeight: 600,
      //   fontSize: 40,
      // },
      // h4: {
      //   // OK
      //   fontWeight: 600,
      //   fontSize: 34,
      // },
      // h5: {
      //   fontWeight: 600,
      //   fontSize: 24,
      // },
      // h6: {
      //   fontWeight: 600,
      //   fontSize: 20,
      // },
      // title1: {
      //   fontWeight: 700,
      //   fontSize: 22,
      // },
      // title2: {
      //   // No está en la api de Typography
      //   fontWeight: 600, // Los valores están según Figma
      //   fontSize: 18,
      // },
      // subtitle: {
      //   fontWeight: 600,
      //   fontSize: 18,
      // },
      // body1: {
      //   fontWeight: 600,
      //   fontSize: 16,
      // },
      // body2: {
      //   // OK
      //   fontWeight: 400,
      //   fontSize: 16,
      // },
      // button: {
      //   fontWeight: 400,
      //   fontSize: 14,
      //   letterSpacing: '0.03em',
      // },
      // caption: {
      //   // OK
      //   fontWeight: 600,
      //   fontSize: 14,
      // },
      // overline: {
      //   fontWeight: 400,
      //   fontSize: 16,
      // },
      // tiny1: {
      //   fontWeight: 600,
      //   fontSize: 13,
      // },
      // tiny2: {
      //   // No está en la api de Typography
      //   fontWeight: 400, // Los valores están según Figma
      //   fontSize: 13,
      // },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '.7rem',
          fontWeight: 500,
          color: '#EEE',
        },
      },
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.4em',
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255,255,255,.3)',
            outline: '1px solid slategrey',
          },
        },
      },
      MUIDataTable: {
        paper: {
          height: '100%',
          width: '100%',
        },
      },
      MuiTableCell: {
        footer: {
          border: 'none',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          backgroundColor: '#373737',
        },
      },
    },
  }),
)

export default MuiTheme
