import { useState, useEffect } from 'react'
import { Card, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { roles, validateAccess } from 'constants/userRoles';
import { DEVICE } from 'constants/icons';
import { useTranslation } from 'react-i18next';
import DevicesTypesPanel from './components/DevicesTypesPanel';
import NetworkServersPanel from './components/NetworkServersPanel';
import styles from './styles.module.scss';

/**
 *
 *
 * @param {*} props
 * @return {*}
 */
function DeviceTypesView(props) {
  const [t] = useTranslation('devices');
  const {
    iotDevicesTypesLoading,
    iotDevicesTypes,
    handleSelectDevicesTypes,
    iotDevicesTypesReportsLoading,
    iotDevicesTypesReports,
    handleSelectDevicesTypesReports,
    iotNetworkServers,
    iotNetworkServersLoading,
    userRoles
  } = props;

  const [hasRoleDevicegroupAccesss, setHasRoleDeviceGroupAccess] = useState(false)

	const setComponentAccess = () => {
		// Visualize panel of group's devices
		setHasRoleDeviceGroupAccess(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_ACCESS));
	}

  useEffect(() => {
    setComponentAccess()
  }, [])

  return (
    <div className={styles.root}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
        <DEVICE style={{ fontSize: '25', marginRight: 20 }}></DEVICE>
        <Typography variant="h5">{t('iotInfrastructure')}</Typography>
      </div>

      <div>
        <Card elevation={0} className={styles.blockContainer}>
          <CardContent>
            <DevicesTypesPanel
              iotDevicesTypes={iotDevicesTypes}
              iotDevicesTypesLoading={iotDevicesTypesLoading}
              handleSelectDevicesTypes={handleSelectDevicesTypes}
              iotDevicesTypesReportsLoading={iotDevicesTypesReportsLoading}
              iotDevicesTypesReports={iotDevicesTypesReports}
              handleSelectDevicesTypesReports={handleSelectDevicesTypesReports}
              userRoles={userRoles}
            />
          </CardContent>
        </Card>
        <Card elevation={0} className={styles.blockContainer}>
          <CardContent>
            <NetworkServersPanel
              networkServers={iotNetworkServers}
              loading={iotNetworkServersLoading}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
export default DeviceTypesView;
