import Keycloak from 'keycloak-js'

const backofficeIDP = {
  realm: process.env.REACT_APP_KCK_REALM,
  url: process.env.REACT_APP_KCK_URL,
  clientId: process.env.REACT_APP_KCK_CLIENT_ID
};

export function backofficeConf() {
  return Keycloak(backofficeIDP);
}

