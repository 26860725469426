import { useTranslation } from 'react-i18next'
import { memo, useMemo } from 'react'
import { connect } from 'react-redux'

import { Skeleton } from '@material-ui/lab'
import DeviceComponents from './components/deviceComponents'

const ComponentsContainer = ({ loading, deviceDetail, userRoles }) => {
  
  const [t] = useTranslation('devices')

  const deviceComponents = useMemo(() =>
      deviceDetail && deviceDetail.iot_device_components
        ? deviceDetail.iot_device_components.map(comp => ({
            id: comp.id,
            componentName: comp.iot_component.name,
            version: comp.version,
            firmwareName: comp.iot_firmware.version,
            serialBadge: comp.badge_number,
          }))
        : [],
    [deviceDetail],
  )

  return (
    <>
      <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
        {loading ? (
          <>
            <Skeleton animation="wave" style={{ height: 45, opacity: 0.7 }} />
            <Skeleton animation="wave" variant="rect" style={{ height: 200, opacity: 0.7 }} />
          </>
        ) : (
          <DeviceComponents rows={deviceComponents} userRoles={userRoles}/>
        )}
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  deviceDetail: store.iot.iotDeviceDetail,
  loading: store.iot.iotDeviceDetailLoading || store.iot.editDeviceLoading,
})

ComponentsContainer.propTypes = {}

export default connect(mapStateToProps)(memo(ComponentsContainer))
