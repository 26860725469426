import { Drawer, IconButton, Menu, MenuItem, Tooltip, Typography, withStyles, Zoom } from '@material-ui/core';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import UtilityImg from '../../../assets/img/logo_witac.svg';
import LogoImg from '../../../assets/img/logo_utility.svg';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { actionCreators as authActions } from '../../../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import AnnouncementRoundedIcon from '@material-ui/icons/AnnouncementRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import HttpRoundedIcon from '@material-ui/icons/HttpRounded';
import ContactSupportModal from 'app/components/ContactSupportModal';
import { ROUTES } from 'constants/routes';
import { roles, validateAccess } from 'constants/userRoles';

const drawerLeftWidth = 75
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 0',
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1.5),
    color: '#909090',
    '&.active': {
      color: '#FFF',
    },
  },
  accountButton: {
    marginBottom: theme.spacing(3),
    color: '#FFF',
  },
  mainLogo: {
    backgroundImage: `url(${LogoImg})`,
    width: 60,
    height: 60,
    backgroundSize: 50,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    filter: 'grayscale(1)',
    marginTop: 8,
  },
  utilityLogo: {
    backgroundImage: `url(${UtilityImg})`,
    width: 40,
    height: 40,
    backgroundSize: 40,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'center',
    opacity: 0.4,
    filter: 'grayscale(1)',
    marginBottom: 5,
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  softVersion: {
    opacity: 0.3,
  },
  drawerLeft: {
    width: drawerLeftWidth,
  },
  drawerPaperLeft: {
    width: drawerLeftWidth,
  },
}))

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip)

function SidebarMenu() {
  const classes = useStyles()
  const [t] = useTranslation('sideBarNav') //  se coloca el nombre del json que queremos usar, sin la extensión .json

  // const dispatch = useDispatch()
  const profileName = useSelector(state => state.auth.authInformation.name)
  const email = useSelector(state => state.auth.authInformation.email)
  const userRoles = useSelector(state => state.auth.userRoles)

  const [activeItem, setActiveItem] = React.useState(ROUTES.DASHBOARD)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [supportForm, setSupportForm] = React.useState(false)
  const [response, setResponse] = React.useState()
  const dispatch = useDispatch()

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleSupportClick = () => setSupportForm(!supportForm)

  const handleSupportFormClose = () => {
    setSupportForm(false)
    setTimeout(() => {
      setResponse(undefined)
    }, 500)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Drawer
      className={classes.drawerLeft}
      variant='permanent'
      classes={{
        paper: classes.drawerPaperLeft,
      }}
    >
      <div className={classes.root}>
        <div className={classes.mainLogo}></div>
        <div>
          <div className={classes.nav}>
            <CustomTooltip title='Jerarquías y Unidades' placement='right' TransitionComponent={Zoom}>
              <Link to={ROUTES.DASHBOARD}>
                <IconButton
                  className={`${classes.button} ${activeItem === ROUTES.DASHBOARD ? 'active' : ''}`}
                  aria-label=''
                  onClick={event => setActiveItem(ROUTES.DASHBOARD)}
                  name='unidades'
                >
                  <MapRoundedIcon />
                </IconButton>
              </Link>
            </CustomTooltip>
          </div>
          <div className={classes.nav}>
              <CustomTooltip title='Dispositivos' placement='right' TransitionComponent={Zoom}>
              <Link to={ROUTES.IOT_DEVICE_TYPES}>
                <IconButton
                  className={`${classes.button} ${activeItem === ROUTES.IOT_DEVICE_TYPES ? 'active' : ''}`}
                  aria-label=''
                  onClick={event => {
                    setActiveItem(ROUTES.IOT_DEVICE_TYPES)
                  }}
                  name={ROUTES.IOT_DEVICE_TYPES}
                >
                  <AccountTreeRoundedIcon />
                </IconButton>
              </Link>
            </CustomTooltip>
          </div>
          <div className={classes.nav}>
            { validateAccess(userRoles, roles.IOT_BACKOFFICE_ADMIN_ACCESS) && (
              <CustomTooltip title='Administración' placement='right' TransitionComponent={Zoom}>
                <Link to={ROUTES.IOT_ADMIN}>
                  <IconButton
                    className={`${classes.button} ${activeItem === ROUTES.IOT_ADMIN_ADMINBRO ? 'active' : ''}`}
                    aria-label=''
                    onClick={event => {
                      setActiveItem(ROUTES.IOT_ADMIN)
                    }}
                    name={ROUTES.IOT_ADMIN}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Link>
              </CustomTooltip>
            )}
          </div>
          <div className={classes.nav}>
            { validateAccess(userRoles, roles.IOT_BACKOFFICE_DOC_TECH_ACCESS) && (
              <CustomTooltip title='Documentación Técnica' placement='right' TransitionComponent={Zoom}>
                <Link to={ROUTES.IOT_DOC_TECH}>
                  <IconButton
                    className={`${classes.button} ${activeItem === ROUTES.IOT_DOC_TECH ? 'active' : ''}`}
                    aria-label=''
                    onClick={event => {
                      setActiveItem(ROUTES.IOT_DOC_TECH)
                    }}
                    name={ROUTES.IOT_DOC_TECH}
                  >
                    <HttpRoundedIcon />
                  </IconButton>
                </Link>
              </CustomTooltip>
            )}
          </div>
          {/* <div className={classes.nav}>
            <CustomTooltip title="Dashboard2" placement="right" TransitionComponent={Zoom}>
              <Link to="">
                <IconButton
                  className={`${classes.button} ${activeItem === 'opcion 2' ? 'active' : ''}`}
                  aria-label=""
                  onClick={event => setActiveItem('opcion 2')}
                  name="opcion 2"
                >
                  <AddToQueueIcon />
                </IconButton>
              </Link>
            </CustomTooltip>
          </div> */}
        </div>
        <div className={classes.bottom}>
          <CustomTooltip title={t('contact-support')} placement='right' TransitionComponent={Zoom}>
            <IconButton aria-label='' className={classes.accountButton} onClick={handleSupportClick}>
              <AnnouncementRoundedIcon />
            </IconButton>
          </CustomTooltip>
          <ContactSupportModal
            open={supportForm}
            handleClose={handleSupportFormClose}
            response={response}
            setResponse={setResponse}
            profileName={profileName}
            email={email}
          />
          <CustomTooltip title={profileName ? profileName : '--'} placement='right' TransitionComponent={Zoom}>
            <IconButton aria-label='' className={classes.accountButton} onClick={handleClick}>
              <AccountCircleIcon />
            </IconButton>
          </CustomTooltip>
          <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={() => dispatch(authActions.signOut())}>{t('logout')}</MenuItem>
          </Menu>
          {/* Dejo el código de abajo como referencia para saber cómo se cambia el idioma */}
          {/* <Button onClick={() => i18n.changeLanguage('es')} variant='text' color='default'>
            ES
          </Button>
          <Button onClick={() => i18n.changeLanguage('en')} variant='text' color='default'>
            EN
          </Button> */}
          <div className={classes.utilityLogo}></div>
          <Typography variant='caption' display='block' className={classes.softVersion}>
            v.1.0.5
          </Typography>
        </div>
      </div>
    </Drawer>
  )
}

export default SidebarMenu
