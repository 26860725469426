import moment from 'moment-timezone'

export const getTableData = (gateways, t) => {
  const data = gateways?.map(gateway => {
    return {
      id: gateway.id,
			eui: gateway.eui,
			name: gateway.name,
      connectionStatus: getConnectionStatus(gateway, t),
      last_review: gateway.last_review
    }
  })
  return data
}

const getConnectionStatus = (gateway, t) => {
  let status = gateway.connected ? t('on') : t('off')
  let date = gateway.last_connected_known ? moment(gateway.last_connected_known).format('DD/MM/YYYY HH:mm') : ''

  return new ConnectionStatus(status, date)
}
class ConnectionStatus {
  constructor(status, date) {
    this.status = status
    this.date = date
  }
  toString() {
    return `${this.status} - ${this.date}`
  }
}
