import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Card, CardActions, CardContent, Divider, Typography } from '@material-ui/core';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import PortableWifiOffRoundedIcon from '@material-ui/icons/PortableWifiOffRounded';
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';

import OperationBadge from 'app/components/OperationBadge';
import { roles, validateAccess } from 'constants/userRoles';

import {
  DEVICE_MTR_CT,
  DEVICE_TELEC_PS,
  DEVICE_TELEC_PS_CON,
  DEVICE_TELEC_PS_SLAVE,
  NOT_AVAILABLE,
  STOCK
} from '../../../../../../constants/icons';
import { OPERATION_COMPONENT, OPERATION_STATUS } from '../../../../../../constants/iot';
import DeviceGroups from '../../../DeviceGroups/components/DeviceGroups';
import styles from './styles.module.scss';

const DevicesTypesPanel = ({
  iotDevicesTypesLoading,
  iotDevicesTypes,
  handleSelectDevicesTypes,
  iotDevicesTypesReportsLoading,
  iotDevicesTypesReports,
  handleSelectDevicesTypesReports,
  userRoles
 }) => {
  const [t] = useTranslation('devices');
  const [hasAccessReport, setHasAccessReport] = useState(false) 
  const [hasRoleDevicegroupAccess, setHasRoleDeviceGroupAccess] = useState(false)

  const setComponentAccess = () => {
    // button access report
    setHasAccessReport(validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_ACCESS));
    // Visualize panel of group's devices
		setHasRoleDeviceGroupAccess(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_ACCESS));
  }

  useEffect(() => {
    setComponentAccess();
  }, []);

  const getDevicesByStatusOperation = (devices, statusOperation) => {
    const {connected_devices_detail, disconnected_devices_detail, installed_devices_detail} = devices;
    // disable because the variables will be used in the switch
		// eslint-disable-next-line no-unused-vars
    const { status_operation_green: sogConnected, status_operation_yellow: soyConnected, status_operation_red: sorConnected} = connected_devices_detail || {};
    // disable because the variables will be used in the switch
		// eslint-disable-next-line no-unused-vars
    const { status_operation_green: sogDisconnected, status_operation_yellow: soyDisonnected, status_operation_red: sorDisonnected} = disconnected_devices_detail || {};

		const { status_operation_green: sogInstalled, status_operation_yellow: soyInstalled, status_operation_red: sorInstalled } = installed_devices_detail || {};

    switch (statusOperation.toUpperCase()) {
      case OPERATION_STATUS.OK: {
        return sogInstalled || 0;
      }

      case OPERATION_STATUS.WARNING: {
        return soyInstalled || 0;
      }

      case OPERATION_STATUS.ALERT: {
        return sorInstalled || 0;
      }

      default: {
        return 0;
      }
    }
  }
  const getTotalDevices = (devices) => devices?.total_devices || 0;

  const getConnectedDevices = (devices) => devices?.connected_devices_detail?.total || 0;

  const getUnconnectedDevices = (devices) => devices?.disconnected_devices_detail?.total || 0;

  const getInstalledDevices = (devices) => devices?.installed_devices_detail?.total || 0;

  const getStockDevices = (devices) => devices?.stock_devices_detail?.total || 0;

  const getScratchDevices = (devices) => {
    const totalDevices = getTotalDevices(devices);
    const stockDevices = getStockDevices(devices);
    const installedDevices = getInstalledDevices(devices);

    return totalDevices - stockDevices - installedDevices;
  }

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: 15 }}>
        {t('tabDevices')}
      </Typography>
      {iotDevicesTypesLoading && renderSkeleton()}
      {!iotDevicesTypesLoading &&
        (iotDevicesTypes?.length >= 1 ? (
        <>

				<div
            className={styles.sectionWrapper}
          >
            {iotDevicesTypes.map((type) => (
                <Card
                  elevation={4}
                  className={styles.card}
                  key={type.name}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 5,
                      }}
                    >
                      {type.name === OPERATION_COMPONENT.MTR_CT && (
                        <DEVICE_MTR_CT style={{ height: 20, width: 20 }} />
                      )}
                      {type.name === OPERATION_COMPONENT.TELEC_PS_CON && (
                        <DEVICE_TELEC_PS_CON style={{ height: 20, width: 20 }} />
                      )}
                      {type.name === OPERATION_COMPONENT.TELEC_PS && (
                        <DEVICE_TELEC_PS style={{ height: 20, width: 20 }} />
                      )}
                      {type.name === OPERATION_COMPONENT.TELEC_PS_SLAVE && (
                        <DEVICE_TELEC_PS_SLAVE
                          style={{ height: 20, width: 20 }}
                        />
                      )}
                      <Typography variant="h6" style={{ marginLeft: 15 }}>
                        {type.description}
                      </Typography>
                    </div>
                    <Divider></Divider>
                    <div>
                      <div
                        style={{
                          marginTop: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="body1" style={{ opacity: 0.7 }}>
                          {t('deviceTypeCardTotalDevicesLabel')}
                        </Typography>
                        <Typography variant="h5" color="primary">
                          {getTotalDevices(type)}
                        </Typography>
                      </div>

                      <div
                        style={{
                          marginTop: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <WifiTetheringRoundedIcon style={{opacity:0.6}}></WifiTetheringRoundedIcon>
                          <Typography
                            variant="body1"
                            style={{ opacity: 0.7, marginLeft: 10 }}
                          >
                            {t('deviceTypeCardConnectedNetworkLabel')}
                          </Typography>
                        </div>
                        <Typography variant="h6">
                          {getConnectedDevices(type)}
                        </Typography>
                      </div>

                      <div
                        style={{
                          marginTop: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <PortableWifiOffRoundedIcon style={{opacity:0.6}}></PortableWifiOffRoundedIcon>
                          <Typography
                            variant="body1"
                            style={{ opacity: 0.7, marginLeft: 10 }}
                          >
                            {t('deviceTypeCardUnConnectedNetworkLabel')}
                          </Typography>
                        </div>
                        <Typography variant="h6">
                          {getUnconnectedDevices(type)}
                        </Typography>
                      </div>

                      <div
                        style={{
                          marginTop: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <STOCK style={{width:18,height:18, marginLeft:2, padding:1, opacity:0.6}}></STOCK>
                          <Typography
                            variant="body1"
                            style={{ opacity: 0.7, marginLeft: 10 }}
                          >
                            {t('deviceTypeCardStockLabel')}
                          </Typography>
                        </div>
                        <Typography variant="h6">
                          {getStockDevices(type)}
                        </Typography>
                      </div>

                      <div
                        style={{
                          marginTop: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <NOT_AVAILABLE style={{width:18,height:18, marginLeft:3, opacity:0.6}}></NOT_AVAILABLE>
                            <Typography
                              variant="body1"
                              style={{ opacity: 0.7, marginLeft: 10 }}
                            >
                              {t('deviceTypeCardScracthLabel')}
                            </Typography>
                        </div>
                        <Typography variant="h6">
                          {getScratchDevices(type)}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ marginTop: 15 }}>
                      <Divider></Divider>
                    </div>
                    <div style={{
                          marginTop: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}>
                      <div
                        style={{
                          marginTop: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <OperationBadge type={OPERATION_STATUS.OK} style={{ marginRight: 10 }}></OperationBadge>
                        <Typography variant="h6" className={styles.OperationBadgeLabel_ok}>
                          {getDevicesByStatusOperation(type, OPERATION_STATUS.OK)}
                        </Typography>
                      </div>

                      <div
                        style={{
                          marginTop: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <OperationBadge type={OPERATION_STATUS.WARNING} style={{ marginRight: 10 }}></OperationBadge>
                        <Typography variant="h6" className={styles.OperationBadgeLabel_warning}>
                          {getDevicesByStatusOperation(type, OPERATION_STATUS.WARNING)}
                        </Typography>
                      </div>

                      <div
                        style={{
                          marginTop: 5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <OperationBadge type={OPERATION_STATUS.ALERT} style={{ marginRight: 10 }}></OperationBadge>
                        <Typography variant="h6" className={styles.OperationBadgeLabel_alert}>
                          {getDevicesByStatusOperation(type, OPERATION_STATUS.ALERT)}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        marginTop: 0,
                      }}>
                    <CardActions>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<FormatListBulletedRoundedIcon/>}
                        onClick={() => handleSelectDevicesTypes(type)}
                      >
                        {t('deviceTypesCardStockButton')}
                      </Button>
                      { hasAccessReport && (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<AssessmentRoundedIcon/>}
                          onClick={() => handleSelectDevicesTypesReports(type)}
                        >
                          {t('deviceTypesCardReportsButton')}
                        </Button>
                      )}
                    </CardActions>
                  </div>
                </Card>
						))}
          </div>
          { hasRoleDevicegroupAccess && (
            <DeviceGroups userRoles={userRoles}/>
          )}
				</>
        ): (<Typography>{t('noTypesFound')}</Typography>)
      )}
    </>
  );

  function renderSkeleton() {
    return (
      <div
        className={styles.sectionWrapper}
      >
        <Card elevation={4} className={styles.cardSkeleton}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton
              animation="wave"
              variant="circle"
              width={40}
              height={40}
            />
            <Skeleton
              animation="wave"
              height={30}
              width="80%"
              style={{ marginBottom: 6, marginLeft: 20 }}
            />
          </div>
          <div>
            <div
              style={{
                marginTop: 15,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 0 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={60}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
          </div>
        </Card>

        <Card elevation={4} className={styles.cardSkeleton}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton
              animation="wave"
              variant="circle"
              width={40}
              height={40}
            />
            <Skeleton
              animation="wave"
              height={30}
              width="80%"
              style={{ marginBottom: 6, marginLeft: 20 }}
            />
          </div>
          <div>
            <div
              style={{
                marginTop: 15,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 0 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={60}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
          </div>
        </Card>

        <Card elevation={4} className={styles.cardSkeleton}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton
              animation="wave"
              variant="circle"
              width={40}
              height={40}
            />
            <Skeleton
              animation="wave"
              height={30}
              width="80%"
              style={{ marginBottom: 6, marginLeft: 20 }}
            />
          </div>
          <div>
            <div
              style={{
                marginTop: 15,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 0 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={60}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
          </div>
        </Card>

        <Card elevation={4} className={styles.cardSkeleton}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Skeleton
              animation="wave"
              variant="circle"
              width={40}
              height={40}
            />
            <Skeleton
              animation="wave"
              height={30}
              width="80%"
              style={{ marginBottom: 6, marginLeft: 20 }}
            />
          </div>
          <div>
            <div
              style={{
                marginTop: 15,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 0 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Skeleton
                animation="wave"
                height={60}
                width="100%"
                style={{ marginBottom: 6, marginLeft: 20 }}
              />
            </div>
          </div>
        </Card>

				<div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Skeleton animation='wave' height={60} width={180} />
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
						<div style={{ width: '100%' }}>
							<Skeleton animation='wave' height={30} />
							<Skeleton animation='wave' height={30} />
							<Skeleton animation='wave' height={30} />
							<Skeleton animation='wave' height={30} />
							<Skeleton animation='wave' height={30} />
						</div>
					</div>
    		</div>

			</div>


    );
  }
};

export default memo(DevicesTypesPanel);
