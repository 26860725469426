import emailjs from '@emailjs/browser'
import { Button, Card, Grid, IconButton, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import { CloseRounded } from '@material-ui/icons'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import { emailjsConf } from 'config/emailjsConfig'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import WTFormHeader from '../WTFormHeader'
import styles from './styles.module.scss'

const ContactSupportModal = ({ open, handleClose, response, setResponse, profileName, email }) => {
  const useStyles = makeStyles(theme => ({
    close: {
      position: 'absolute',
      right: 15,
      top: 8,
      zIndex: 99,
    },
  }))

  const [t] = useTranslation('devices')

  const { serviceId, templateId, userId } = emailjsConf()

  const [formValues, setFormValues] = useState({
    email: '',
    title: '',
    message: '',
  })

  const [sending, setSending] = useState(false)

  const classes = useStyles()

  const handleOnChange = e => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const sendEmail = () => {
    setFormValues({
      email: '',
      title: '',
      message: '',
    })
    setSending(true)
    const hiddenForm = document.getElementById('hiddenForm')
    emailjs.sendForm(serviceId, templateId, hiddenForm, userId).then(
      result => {
        setSending(false)
        setResponse(true)
      },
      error => {
        setSending(false)
        setResponse(false)
      },
    )
  }

  return (
    <Dialog className={styles.modal} open={open} scroll='paper' fullWidth={true} maxWidth='md' disableEscapeKeyDown>
      <DialogTitle id='responsive-dialog-title'>
        <Grid container justifyContent='flex-start' alignItems='center'>
          <span>{t('contact-support')}</span>
        </Grid>
        <IconButton className={classes.close} aria-label='close' onClick={() => handleClose()}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>

      {sending === false && response === undefined && (
        <>
          <DialogContent className={styles.dialogContent} dividers={true}>
            <Card className={styles.card}>
              <WTFormHeader line1={t('contact-support-title-line1')} line2={t('contact-support-title-line2')}/>
              <div className={styles.hidden}>
                <form id='hiddenForm'>
                  <label>Nombre de perfil</label>
                  <input type='text' name='profileName' value={profileName} />
                  <label>Correo electrónico de contacto</label>
                  <input type='email' name='email' value={email} />
                  <label>Título del mensaje</label>
                  <input type='text' name='title' value={formValues.title} />
                  <label>Detalle de su consulta o problema</label>
                  <textarea name='message' value={formValues.message} multiline minRows={5} />
                </form>
              </div>
              <FormGroup>
                <FormLabel className={styles.formLabel}>{t('contact-support-label1')}</FormLabel>
                <Card className={styles.cardInput} elevation={0}>
                  <Input
                    className={styles.input}
                    type='text'
                    name='email'
                    value={email}
                    onChange={e => handleOnChange(e)}
                    disabled
                    disableUnderline
                  />
                </Card>
                <FormLabel className={styles.formLabel}>{t('contact-support-label2')}</FormLabel>
                <Card className={styles.cardInput} elevation={0}>
                  <Input className={styles.input} type='text' name='title' onChange={e => handleOnChange(e)} disableUnderline />
                </Card>
                <FormLabel className={styles.formLabel}>{t('contact-support-label3')}</FormLabel>
                <Card className={styles.cardInputTextArea} elevation={0}>
                  <TextField
                    className={styles.input}
                    style={{ paddingLeft: 20 }}
                    name='message'
                    onChange={e => handleOnChange(e)}
                    multiline
                    minRows={5}
                    InputProps={{ disableUnderline: true }}
                  />
                </Card>
                <Input type='hidden' name='user' disableUnderline />
              </FormGroup>
            </Card>
            <div className={styles.containerButton}>
              <Button
                className={styles.button}
                // disabled={!(/.+@.+..+/.test(formValues.email) && formValues.title.length > 0 && formValues.message.length > 0)}
                disabled={!(formValues.title.length > 0 && formValues.message.length > 0)}
                variant='outlined'
                startIcon={<SendRoundedIcon></SendRoundedIcon>}
                onClick={() => {
                  sendEmail()
                }}
              >
                Enviar
              </Button>
            </div>
          </DialogContent>
        </>
      )}

      {sending === true && (
        <DialogContent className={styles.dialogContent} dividers={true}>
          <Card className={styles.cardSending}>
            <p>{t('contact-support-sending')}</p>
          </Card>
        </DialogContent>
      )}

      {sending === false && response === true && (
        <DialogContent className={styles.dialogContent} dividers={true}>
          <Card className={styles.cardOkResponse}>
            <CheckRoundedIcon style={{ fontSize: 50 }} />
            <p>{t('contact-support-success-line1')}</p>
            <p>{t('contact-support-success-line2')}</p>
            <p>{t('contact-support-success-line3')}</p>
          </Card>
        </DialogContent>
      )}

      {sending === false && response === false && (
        <DialogContent style={styles.dialogContent} dividers={true}>
          <Card className={styles.cardOkResponse}>
            <ClearRoundedIcon style={{ fontSize: 50 }} />
            <p>{t('contact-support-failure-line1')}</p>
            <p>{t('contact-support-failure-line2')}</p>
          </Card>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default ContactSupportModal
