export const IOTHIERARCHY = {
  HIERARCHY_LIST: `/api/v1/iothierarchies`,
  HIERARCHIES_UNITS: `/api/v1/iothierarchies/iotlogicalunits/map`, //
  HIERARCHIES_UNITS_DEVICE: `/api/v1/iothierarchies/map`, //?hierarchy_id=`
}

export const IOTLogicalUnit = {
  LOGICALUNIT_TYPES: `/api/v1/iotlogicalunittypes/`,
  LOGICALUNITS_MAP: `/api/v1/iotlogicalunits/map`, //?hierarchy_id=`
  LOGICALUNIT_DETAIL_BY_ID: `/api/v1/iotlogicalunits/`, //{{PATH-LOGICAL_UNIT_ID}}
  LOGICALUNIT_DEVICES_BY_ID: `/api/v1/iotlogicalunits/{{PATH-LOGICAL_UNIT_ID}}/devices?logicalUnitDeviceActive=true`, //?logicalUnitDeviceActive=`,
  LOGICALUNIT_BY_DEVICE_ID: `/api/v1/iotlogicalunitdevice/device`,
}

export const IOTDEVICE = {
  DEVICES_TYPE: `/api/v1/iottypes/`,
  DEVICES_ID: `/api/v1/iotdevices/`, //{{PATH-DEVICE_ID}}
  // DEVICES_ID_LOGICALUNITS: `/api/v1/iotdevices/{{PATH-DEVICE_ID}}/logicalunits/map`, // existe pero no responde
  DEVICE_REPORT: `/api/v1/iotdevices/export`,
  DEVICE_REPORT_ALARMS: `/api/v1/iotdevices/{{iotDeviceId}}/alarms/export`,
  DEVICE_REPORT_ALARMS_CANCEL: `/api/v1/iotdevices/{{iotDeviceId}}/alarmscancel/export`,
  DEVICE_REPORT_EVENTS: `/api/v1/iotdevices/{{iotDeviceId}}/events/export`,
  DEVICE_REPORT_ISALIVE: `/api/v1/iotdevices/{{iotDeviceId}}/isalives/export`,
  DEVICE_REPORT_MEASUREMENTS: `/api/v1/iotdevices/{{iotDeviceId}}/measurements/export`,
  DEVICE_MEASUREMENTS: `/api/v1/iotreports/{{PATH-IOT_GRAPHIC_ID}}/iotdevices/{{PATH-DEVICE_ID}}`,
  DEVICE_GRAPHIC_SERIES: `/api/v1/iotgraphics/{{PATH-IOT_GRAPHIC_ID}}/iotdevices/{{PATH-DEVICE_ID}}/series`,
  REPORTS: `/api/v1/iotreports/{{iotReportId}}/iotdevices`,
}

export const SEARCH = {
  LOGICALUNIT: `api/v1/iotlogicalunitdevice/logicalunit`, //?iotLogicalUnitId={{QUERY-LOGICAL_UNIT_ID}}&logicalUnitDeviceActive={{QUERY-LOGICAL_UNIT_DEVICE_ACTIVE}}`
  DEVICE: `/api/v1/iotlogicalunitdevice/device`, //?iotDeviceId={{QUERY-DEVICE_ID}}&logicalUnitDeviceActive={{QUERY-LOGICAL_UNIT_DEVICE_ACTIVE}}`
}

export const UTILITY = {
  HEALTHCHECK: `/api/public/healthcheck/all`,
}

export const IOTSERVER = {
  SERVER_REPORT_ALARMS: `/api/v1/iotservers/{{iotServerId}}/alarms/export`,
  SERVER_REPORT_EVENTS: `/api/v1/iotservers/{{iotServerId}}/events/export`,
}