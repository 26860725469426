/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CloseRounded } from '@material-ui/icons'
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded'
import WTdialog, { WTDIALOG_TYPE } from 'app/components/WTDialog'
import { bool, func } from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import AlertActions from 'redux/alert/actions'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { getIn } from 'seamless-immutable'
import { exportMeasurements } from 'services/ioTReportService'
import { arrayIntersection, containsTrue } from 'utils/arrayUtils'
import { validateDateTimeToGreaterThanDateTimeFrom } from 'utils/dateUtils'
import { normalizeString } from 'utils/stringUtils'
import WTFormHeader from '../../../../../../../../../components/WTFormHeader'
import styles from './styles.module.scss'

const LEVEL_REPORTS = 'DEVICE_DETAIL_ALARMS';

const DataExportComponent = ({ open, handleClose, dispatch, deviceSelected, iotTypeReports, user }) => {
  const [t] = useTranslation('devices')
  const useStyles = makeStyles(theme => ({
    close: {
      position: 'absolute',
      right: 15,
      top: 8,
      zIndex: 99,
    },
  }))
  const classes = useStyles()

  const [exportTypes, setExportTypes] = useState([])
  const [exportVariables, setExportVariables] = useState([])
  const [dataProfile, setDataProfile] = useState('')
  const [checkedState, setCheckedState] = useState([])
  const [checkedAllNoneState, setCheckedAllNoneState] = useState([])
  const [dateTimeFrom, setDateTimeFrom] = useState()
  const [dateTimeTo, setDateTimeTo] = useState()
  const [dateTimeSelectorError, setDateTimeSelectorError] = useState(false)
  const [alert, setAlert] = useState(false)
  const [downloading, setDownloading] = useState(false)

  React.useEffect(() => {
    if (iotTypeReports) {
      setExportTypes(iotTypeReports)
      setDataProfile('')
    }
  }, [iotTypeReports])

  React.useEffect(() => {
    const reportLevelFilter = [
      { field: 'report_level', operator: 'eq', value: LEVEL_REPORTS },
      { field: 'available', operator: 'eq', value: true }
    ];

    dispatch(iotActions.getIotTypeReports(deviceSelected?.type_id, LEVEL_REPORTS, reportLevelFilter))
  }, [])

  React.useEffect(() => {
    if (dateTimeFrom && dateTimeTo) {
      setDateTimeSelectorError(validateDateTimeToGreaterThanDateTimeFrom(dateTimeFrom, dateTimeTo))
    }
  }, [dateTimeFrom, dateTimeTo])

  const onSelectDataProfile = event => {
    let aux = exportTypes.filter(g => g.code === event.target.value)

    // Se setea el tipo de perfil de exportacion seleccionado
    setDataProfile(event.target.value)

    // Se inicializan los grupos y varibles (del perfil seleccionado) disponibles para seleccionar
    setExportVariables(aux[0] ? aux[0].variables : [])

    // Se inicializa en false un arreglo paralelo doble con el estado de seleccion individual de variables de cada grupo
    setCheckedState(
      aux[0].variables.map(group => {
        return [...group.values].fill(false)
      }),
    )
    // Se inicializa en false un arreglo paralelo con el estado de seleccion Todos/Ninguno de cada grupo
    setCheckedAllNoneState([...aux[0].variables].fill(false))
  }

  /** handler de checkbox para mantener el estado de seleccion individual de varialbles */
  const toggleVariableCheckboxValue = (group, index) => {
    setCheckedState(
      checkedState.map((el, i) => {
        return i === group ? el.map((v, j) => (j === index ? !v : v)) : el
      }),
    )
  }

  /** handler para mantener el estado de seleccion completa de cada grupo. Ademas actualiza el estado general de seleccion de variables */
  const toggleAllValues = group => {
    let currentStat = checkedAllNoneState[group]
    setCheckedState(
      checkedState.map((el, i) => {
        //el (array de estado de variables)
        return i === group ? el.map(v => !currentStat) : el
      }),
    )
    setCheckedAllNoneState(
      checkedAllNoneState.map((el, i) => {
        // el (true/false)
        return i === group ? !el : el
      }),
    )
  }

  const handleCloseLocal = () => {
    const isAnyTicked = checkedState.some(el => el.some(tick => tick === true))
    if (isAnyTicked || dateTimeTo || dateTimeFrom) return setAlert(true)
    setDataProfile('')
    handleClose()
  }

  const handleCloseGlobal = () => {
    setDataProfile('')
    setCheckedState([])
    setCheckedAllNoneState([])
    setDateTimeFrom()
    setDateTimeTo()
    setAlert(false)
    handleClose()
  }

  const onFocusOut = e => {
    e.target.id === 'datetime-from' && setDateTimeFrom(e.target.value)
    e.target.id === 'datetime-to' && setDateTimeTo(e.target.value)
  }

  const handleDownloadClick = () => {
    setDownloading(true)
    const requestIds = arrayIntersection(checkedState, exportVariables)
    const params = {
      channelsId: requestIds,
      startDate: dateTimeFrom,
      endDate: dateTimeTo,
    }
    exportMeasurements(dataProfile, deviceSelected.id, params)
      .then(resp => {
        if (resp && resp.ok) {
          const name = normalizeString(user.name)
          const reportType = normalizeString(exportTypes.find(type => type.code === dataProfile).name)
          let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.setAttribute('download', `${reportType}_${name}_id_${deviceSelected.id}.xlsx`)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a) // Eliminar el elemento después de descargar
          window.URL.revokeObjectURL(url) // Suelta el objeto blob
          setDownloading(false)
        } else {
          dispatch(AlertActions.error('Report not found'))
          setDownloading(false)
        }
      })
      .catch(err => {
        dispatch(AlertActions.error('Report not found'))
        setDownloading(false)
      })
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleCloseLocal()
        }
      }}
      scroll="paper"
      fullWidth={true}
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <DialogTitle id="responsive-dialog-title">
        <Grid container justifyContent="flex-start" alignItems="center">
          <span>{t('deviceDataExportTitle')}</span>
        </Grid>
        <IconButton className={classes.close} aria-label="close" onClick={() => handleCloseLocal()}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent style={styles.dialogContent} dividers={true}>
        <Card className={styles.card}>
          
          <WTFormHeader line1={t('deviceDataExportModalInfo')}/>

          <div>
            <div>
              {exportTypes.length > 0 ? (
                <FormControl className={styles.selectField} style={{ marginRight: 100 }}>
                  <InputLabel>{t('deviceDataExportTypeLabel')}</InputLabel>
                  <Select id="dataProfile" margin="dense" name="dataProfile" value={dataProfile} onChange={onSelectDataProfile}>
                    {exportTypes.map(exportType => {
                      return <MenuItem value={exportType.code} key={exportType.code}>{`${exportType.name}`}</MenuItem>
                    })}
                  </Select>
                  {!dataProfile ? <div className={styles.fieldHint}>{t('deviceDataExportTypeHint')}</div> : null}
                </FormControl>
              ) : null}

              {dataProfile ? (
                <>
                  <TextField
                    id="datetime-from"
                    label={t('deviceDataExportFromLabel')}
                    type="datetime-local"
                    className={styles.dateField}
                    style={{ marginRight: 30 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={e => onFocusOut(e)}
                  />

                  <TextField
                    id="datetime-to"
                    label={t('deviceDataExportToLabel')}
                    type="datetime-local"
                    className={styles.dateField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={e => onFocusOut(e)}
                    error={dateTimeSelectorError}
                    helperText={dateTimeSelectorError && t('dateTimeSelectorError')}
                  />
                </>
              ) : null}
            </div>
            {dataProfile && exportVariables?.length > 0 ? (
              <div className={styles.filterSection}>{exportVariables.map((variableGroup, index) => renderGroup(index, variableGroup))}</div>
            ) : null}
          </div>
        </Card>
        <div className={styles.containerButton}>
          <Button
            className={styles.button}
            disabled={!dataProfile || downloading || !containsTrue(checkedState)}
            variant="outlined"
            startIcon={!downloading && <CloudDownloadRoundedIcon />}
            onClick={handleDownloadClick}
          >
            {downloading ? t('deviceDataExportDownloading') : t('deviceDataExportDownload')}
          </Button>
        </div>
      </DialogContent>
      <WTdialog
        title={t('deviceDataExportDialogTitle')}
        message={t('deviceDataExportDialogText')}
        type={WTDIALOG_TYPE.WARNING}
        primaryActionLabel={t('deviceDataExportDialogPrimary')}
        secondaryActionLabel={t('deviceDataExportDialogSecondary')}
        show={alert}
        onPrimaryAction={handleCloseGlobal}
        onSecondaryAction={() => setAlert(false)}
        onClose={() => setAlert(false)}
      />
    </Dialog>
  )

  function renderGroup(group, variableGroup) {
    return (
      <div className={styles.varGroup} key={group}>
        <div className={styles.heading}>
          <div className={styles.title}>{variableGroup.title}</div>
          <div
            className={styles.allNone}
            onClick={() => {
              toggleAllValues(group)
            }}
          >
            {checkedAllNoneState[group] ? t('deviceDataExportToggleNone') : t('deviceDataExportToggleAll')}
          </div>
        </div>
        {renderVariableGroupOptions(group, variableGroup.values)}
      </div>
    )
  }

  function renderVariableGroupOptions(group, options) {
    return <FormGroup className={styles.content}>{options.map((variable, index) => renderVariable(variable, group, index))}</FormGroup>
  }

  function renderVariable(variable, group, index) {
    return (
      <FormControlLabel
        key={index}
        control={<Checkbox checked={checkedState[group][index]} onChange={() => toggleVariableCheckboxValue(group, index)} name={variable.id} />}
        label={variable.value}
      />
    )
  }
}

DataExportComponent.propTypes = {
  open: bool.isRequired,
  handleClose: func,
}

const mapStateToProps = store => ({
  iotTypeReports: store.iot.iotTypeReports[LEVEL_REPORTS],
  deviceSelected: getIn(store, ['iot', 'iotDeviceDetail']),
  user: getIn(store, ['auth', 'authInformation']),
})

export default connect(mapStateToProps)(DataExportComponent)
