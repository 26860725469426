// application ROUTES
const SIGN_IN = '/'
const DASHBOARD = '/'
const IOT_DEVICE_TYPES = '/iotDeviceTypes'
const IOT_DEVICE_TYPE = '/iotDeviceTypes/:type';
const IOT_DEVICE_TYPES_REPORTS = '/iotDeviceTypes/reports'
const IOT_DEVICE_TYPE_REPORTS = '/iotDeviceTypes/reports/:type'
const IOT_DEVICE_TYPE_REPORT_CRONS = '/iotDeviceTypes/reports/:type/crons/:report'
const IOT_DEVICE_GROUPS = '/iotDeviceTypes/groups'
const IOT_DEVICE_GROUP = '/iotDeviceTypes/groups/:id';
const IOT_ADMIN = '/admin/data';
const IOT_DOC_TECH = '/admin/doctech';

// external application ROUTES
const IOT_ADMIN_ADMINBRO_EXTERNAL = `${process.env.REACT_APP_IOT_API_BASE_URL}/admin/login`;
const IOT_DOC_SWAGGER_EXTERNAL = `${process.env.REACT_APP_IOT_API_BASE_URL}/api-docs`;

export const ROUTES = {
  //
  DASHBOARD,
  SIGN_IN,
  IOT_DEVICE_TYPES,
  IOT_DEVICE_TYPE,
  IOT_DEVICE_TYPES_REPORTS,
	IOT_DEVICE_TYPE_REPORTS,
	IOT_DEVICE_TYPE_REPORT_CRONS,
	IOT_DEVICE_GROUPS,
	IOT_DEVICE_GROUP,
  IOT_ADMIN,
  IOT_DOC_TECH,
  //
  IOT_ADMIN_ADMINBRO_EXTERNAL,
  IOT_DOC_SWAGGER_EXTERNAL
};
