/*PARA INTEGRACION CON IOTHANDLER*/
export const roles = {
  // IOT components roles
  IOT_BACKOFFICE_ADMIN_ACCESS: 'wiot-cu-backoffice-admin.can_access',
  IOT_BACKOFFICE_DOC_TECH_ACCESS: 'wiot-cu-backoffice-doctech.can_access',

  //----------------------------------------------------------------------------
  // Roles Components: wiot-cu-backoffice.*
  //----------------------------------------------------------------------------
  // device detail - PARAMS TAB
  IOT_BACKOFFICE_DEVICE_PARAMS_EDIT_VALUE: 'wiot-cu-backoffice.device.param.edit_value',  // let edit device param value
  IOT_BACKOFFICE_DEVICE_PARAMS_EDIT_VALUE_ONLINE: 'wiot-cu-backoffice.device.param.edit_value_online', // let edit device param value online
  // device detail - ALARM TAB
  IOT_BACKOFFICE_DEVICE_ALARMS_EDIT_STATUS: 'wiot-cu-backoffice.device.alarm.edit_status',  // let edit status of alarm
  // device detail - COMPONENTS TAB 
  IOT_BACKOFFICE_DEVICE_COMPONENTS_FIRMWARE_FETCH_ONLINE: 'wiot-cu-backoffice.device.components.firmware.fetchOnline',  // let execute fetch configuration params firmware
  IOT_BACKOFFICE_DEVICE_COMPONENTS_FIRMWARE_SET_ONLINE: 'wiot-cu-backoffice.device.components.firmware.setOnline', // let execute set configuration params firmware
  IOT_BACKOFFICE_DEVICE_COMPONENTS_FIRMWARE_SYNC_TIMESTAMP: 'wiot-cu-backoffice.device.components.firmware.syncTimestampOnline', // let execute sync timestamp device
  IOT_BACKOFFICE_DEVICE_COMPONENTS_FIRMWARE_RESTART: 'wiot-cu-backoffice.device.components.firmware.restartOnline', // let execute restart device
  IOT_BACKOFFICE_DEVICE_COMPONENTS_APPLICATION_SET_LOCAL: 'wiot-cu-backoffice.device.components.application.setLocal', // let execute set configuration params application
  // device groups
  IOT_BACKOFFICE_DEVICE_GROUP_ACCESS: 'wiot-cu-backoffice.device.group.access',  // let access to device's groups
  IOT_BACKOFFICE_DEVICE_GROUP_CREATE: 'wiot-cu-backoffice.device.group.create', // let create new device group
  IOT_BACKOFFICE_DEVICE_GROUP_EDIT: 'wiot-cu-backoffice.device.group.edit', // let edit device group header information
  IOT_BACKOFFICE_DEVICE_GROUP_STATUS_TOOGLE: 'wiot-cu-backoffice.device.group.status_toggle', // let chnage status of device group
  IOT_BACKOFFICE_DEVICE_GROUP_ADD_DEVICE: 'wiot-cu-backoffice.device.group.addDevice', // let Add new device to group
  IOT_BACKOFFICE_DEVICE_GROUP_REMOVE_DEVICE: 'wiot-cu-backoffice.device.group.removeDevice', // let Remove device from group
  // report
  IOT_BACKOFFICE_REPORT_ACCESS: 'wiot-cu-backoffice.report.access', // let access to device type reports
  // report cron
  IOT_BACKOFFICE_REPORT_CRON_ACCESS: 'wiot-cu-backoffice.report.cron.access', // let access to report cron
  IOT_BACKOFFICE_REPORT_CRON_CREATE: 'wiot-cu-backoffice.report.cron.create', // let create a new device report cron
  IOT_BACKOFFICE_REPORT_CRON_EDIT: 'wiot-cu-backoffice.report.cron.edit', // let edit a new device report cron
  IOT_BACKOFFICE_REPORT_CRON_EXECUTION_ACCESS: 'wiot-cu-backoffice.report.cron.executions.access', // let access to device report cron executions
  IOT_BACKOFFICE_REPORT_CRON_STATUS_TOOGLE: 'wiot-cu-backoffice.report.cron.status_toggle' // let toggle status of device report cron
};

export const validateAccess = (userRoles, rolesToCheck) => {
  if (!userRoles || !rolesToCheck ) return false;

  const rolesArray = Array.isArray(rolesToCheck) ? rolesToCheck : [rolesToCheck];
  return rolesArray.every(role => userRoles.includes(role));
};
