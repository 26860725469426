import { shape, arrayOf, string, number } from 'prop-types'

export const deviceComponentType = shape({
  categoryEvent: string,
  actionEvent: string,
  labelEvent: string,
  action: string,
  intentId: string,
  chatbotName: string,
  userSays: arrayOf(string),
  valueEvent: number,
})
