/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Button, CircularProgress, Typography } from '@material-ui/core'
import HdrStrongRoundedIcon from '@material-ui/icons/HdrStrongRounded'
import HdrWeakRoundedIcon from '@material-ui/icons/HdrWeakRounded'
import PortableWifiOffRoundedIcon from '@material-ui/icons/PortableWifiOffRounded'
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded'
import { Skeleton } from '@material-ui/lab'
import OperationBadge from 'app/components/OperationBadge'
import { OPERATION_STATUS } from 'constants/iot'
import MUIDataTable from 'mui-datatables'
import { arrayOf, bool, number } from 'prop-types'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { getIn } from 'seamless-immutable'
import { deviceRelation, pageSizeOptions } from './constants'
import styles from './styles.module.scss'

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation="wave" height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
        </div>
      </div>
    </div>
  )
}

const DeviceRelationsContainer = ({ deviceRelationsList, loading, totalDeviceRelations }) => {
  const [t] = useTranslation('devices')
  const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [currentPage, setCurrentPage] = useState(0)
  const dispatch = useDispatch()
  const [isFirstRun] = useState(true)
  useEffect(() => {
    dispatch(iotActions.getIotDeviceRelations())
  }, [])

  useEffect(() => {
    return () => {
      dispatch(iotActions.cleanDeviceRelations())
    }
  }, [])

  const handleChangePage = (_currentPage, _pageSize) => {
    setCurrentPage(_currentPage)
    if (_pageSize) setPageSize(_pageSize)
  }

  const startRowIndex = useMemo(() => currentPage * pageSize, [currentPage, pageSize])
  const endRowIndex = useMemo(() => currentPage * pageSize + pageSize, [currentPage, pageSize])

  const currentRows = useMemo(
    () => (deviceRelationsList && deviceRelationsList.length > 0 ? deviceRelationsList.slice(startRowIndex, endRowIndex) : []),
    [currentPage, pageSize, deviceRelationsList],
  )

  const handleSelectDevice = useCallback(value => {
    dispatch(iotActions.getIotDeviceDetail(value.id))
    dispatch(iotActions.cleanMeasures())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      name: 'slaveId',
      label: t('deviceRelationsTableSlaveIdTitleColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: deviceRelationsList && deviceRelationsList.length > 1 ? 'true' : 'false',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'uid',
      label: t('deviceRelationsTableDevuidTitleColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: '',
      label: t('deviceRelationsTableTypeTitleColumn'),
      options: {
        customBodyRenderLite: data => {
          return <span>{deviceRelationsList[data].iot_type.name}</span>
        },
      },
    },
    {
      name: '',
      label: t('deviceRelationsTableModelTitleColumn'),
      options: {
        customBodyRenderLite: data => {
          return <span>{deviceRelationsList[data].iot_model.name}</span>
        },
      },
    },
    {
      name: 'status_service',
      label: t('deviceRelationsTableStatusServiceTitleColumn'),
      options: {
        customBodyRender: data => {
          return data ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <WifiTetheringRoundedIcon style={{ fontSize: 20 }}></WifiTetheringRoundedIcon>
              <div style={{ marginLeft: 10 }}>{t('on')}</div>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <PortableWifiOffRoundedIcon style={{ fontSize: 20 }}></PortableWifiOffRoundedIcon>
              <div style={{ marginLeft: 10 }}>{t('off')}</div>
            </div>
          )
        },
      },
    },
    {
      name: '',
      label: t('deviceRelationsTableRelationTitleColumn'),
      options: {
        customBodyRenderLite: data => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {deviceRelationsList[data].relation_type === 'S' && <HdrWeakRoundedIcon style={{ fontSize: 20 }}></HdrWeakRoundedIcon>}
              {deviceRelationsList[data].relation_type === 'F' && <HdrStrongRoundedIcon style={{ fontSize: 20 }}></HdrStrongRoundedIcon>}
              <div style={{ marginLeft: 10 }}>{t(`relation_${deviceRelationsList[data].relation_type}`) || 'N/A'}</div>
            </div>
          )
          //   return <span>{t(`relation_${deviceRelationsList[data].relation_type}`) || 'N/A'}</span>
        },
      },
    },
    {
      name: 'status_operation',
      label: t('deviceRelationsTableStatusOperationTitleColumn'),
      options: {
        customBodyRender: data => {
          return (
            <div>
              {data === OPERATION_STATUS.OK && <OperationBadge type={data} label={t('ok')} />}
              {data === OPERATION_STATUS.WARNING && <OperationBadge type={data} label={t('warning')} />}
              {data === OPERATION_STATUS.ALERT && <OperationBadge type={data} label={t('alert')} />}
            </div>
          )
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: data => {
          return (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                handleSelectDevice(deviceRelationsList[data + currentPage * pageSize])
              }}
            >
              Ver ficha
            </Button>
          )
        },
      },
    },
  ]
  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    selectableRows: 'none',
    responsive: 'simple',
    //tableBodyHeight: '400px',
    tableBodyMaxHeight: '100%',
    download: false,
    viewColumns: false,
    count: totalDeviceRelations,
    serverSide: true,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [],
    setRowProps: (row, index) => {
      return {
        className:
          deviceRelationsList[index].status_operation === OPERATION_STATUS.ALERT
            ? styles.backRowAlert
            : deviceRelationsList[index].status_operation === OPERATION_STATUS.WARNING
            ? styles.backRowWarning
            : null,
      }
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page, null)
          break
        case 'sort':
          //this.sort(tableState.page, tableState.sortOrder);
          break
        default:
        //console.log('action not handled.');
      }
    },

    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      {loading && isFirstRun ? (
        renderSkeleton()
      ) : (
        <MUIDataTable
          title={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">
                Dispositivos relacionados
                {loading && <CircularProgress size={18} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
              </Typography>
            </div>
          }
          data={currentRows}
          columns={columns}
          options={options}
        />
      )}
    </>
  )
}

const mapStateToProps = store => ({
  loading: getIn(store, ['iot', 'iotDeviceRelationsLoading']),
  deviceRelationsList: getIn(store, ['iot', 'iotDeviceRelations']),
  totalDeviceRelations: getIn(store, ['iot', 'totalDeviceRelations']),
})

DeviceRelationsContainer.propTypes = {
  deviceRelationsList: arrayOf(deviceRelation),
  loading: bool,
  totalDeviceRelations: number,
}

export default connect(mapStateToProps)(memo(DeviceRelationsContainer))
