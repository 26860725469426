import { SvgIcon } from '@material-ui/core'

export const NO_SEARCH_RESULTS = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 19">
      <path
        d="M14.5 12H13.71L13.43 11.73C14.63 10.33 15.25 8.42003 14.91 6.39003C14.44 3.61003 12.12 1.40003 9.33003 1.05003C5.54003 0.580026 2.30003 3.38003 2.03003 7.00003H4.05003C4.29003 4.88003 5.97003 3.20003 8.11003 3.02003C10.65 2.80003 13 4.95003 13 7.50003C13 9.99003 10.99 12 8.50003 12C8.33003 12 8.17003 11.97 8.00003 11.95V13.97C8.00003 13.97 8.00003 13.97 8.01003 13.98C9.81003 14.11 11.48 13.51 12.73 12.43L13 12.71V13.5L17.25 17.75C17.66 18.16 18.33 18.16 18.74 17.75C19.15 17.34 19.15 16.67 18.74 16.26L14.5 12Z"
        fill="#323232"
      />
      <path
        d="M5.12002 9.17002L3.00002 11.29L0.88002 9.17002C0.68002 8.97002 0.37002 8.97002 0.17002 9.17002C-0.0299805 9.37002 -0.0299805 9.68002 0.17002 9.88002L2.29002 12L0.17002 14.12C-0.0299805 14.32 -0.0299805 14.63 0.17002 14.83C0.37002 15.03 0.68002 15.03 0.88002 14.83L3.00002 12.71L5.12002 14.83C5.32002 15.03 5.63002 15.03 5.83002 14.83C6.03002 14.63 6.03002 14.32 5.83002 14.12L3.71002 12L5.83002 9.88002C6.03002 9.68002 6.03002 9.37002 5.83002 9.17002C5.63002 8.98002 5.32002 8.98002 5.12002 9.17002Z"
        fill="#323232"
      />
    </SvgIcon>
  )
}

export const HIERARCHY = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM6 15.5C4.62 15.5 3.5 14.38 3.5 13C3.5 11.62 4.62 10.5 6 10.5C7.38 10.5 8.5 11.62 8.5 13C8.5 14.38 7.38 15.5 6 15.5ZM7.5 6C7.5 4.62 8.62 3.5 10 3.5C11.38 3.5 12.5 4.62 12.5 6C12.5 7.38 11.38 8.5 10 8.5C8.62 8.5 7.5 7.38 7.5 6ZM14 15.5C12.62 15.5 11.5 14.38 11.5 13C11.5 11.62 12.62 10.5 14 10.5C15.38 10.5 16.5 11.62 16.5 13C16.5 14.38 15.38 15.5 14 15.5Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const LOGICAL_UNIT = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 69 69">
      <path
        d="M34.5 0C15.4905 0 0 15.4905 0 34.5C0 53.5095 15.4905 69 34.5 69C53.5095 69 69 53.5095 69 34.5C69 15.4905 53.5095 0 34.5 0ZM34.5 62.1C19.2855 62.1 6.9 49.7145 6.9 34.5C6.9 19.2855 19.2855 6.9 34.5 6.9C49.7145 6.9 62.1 19.2855 62.1 34.5C62.1 49.7145 49.7145 62.1 34.5 62.1ZM44.85 34.5C44.85 40.227 40.227 44.85 34.5 44.85C28.773 44.85 24.15 40.227 24.15 34.5C24.15 28.773 28.773 24.15 34.5 24.15C40.227 24.15 44.85 28.773 44.85 34.5Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const DEVICE = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 79 70">
      <path
        d="M59.25 31.1111H71.1C75.4845 31.1111 79 27.6111 79 23.3333V7.77778C79 3.46111 75.445 0 71.1 0H59.25C54.8655 0 51.35 3.5 51.35 7.77778V11.6667H27.6895V7.77778C27.6895 3.46111 24.1345 0 19.7895 0H7.9C3.555 0 0 3.5 0 7.77778V23.3333C0 27.65 3.555 31.1111 7.9 31.1111H19.75C24.1345 31.1111 27.65 27.6111 27.65 23.3333V19.4444H35.55V46.7056C35.55 53.1222 40.843 58.3333 47.3605 58.3333H51.35V62.2222C51.35 66.5389 54.905 70 59.25 70H71.1C75.4845 70 79 66.5 79 62.2222V46.6667C79 42.35 75.445 38.8889 71.1 38.8889H59.25C54.8655 38.8889 51.35 42.3889 51.35 46.6667V50.5556H47.3605C45.2275 50.5556 43.45 48.8056 43.45 46.7056V19.4444H51.35V23.3333C51.35 27.6111 54.905 31.1111 59.25 31.1111Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const LOGICAL_UNIT_CT = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
      <path
        d="M4.5 0.85795V8.5795C4.5 9.05138 4.88883 9.43745 5.36407 9.43745H7.09221V15.5718C7.09221 16.0094 7.67113 16.1638 7.89579 15.7863L12.3803 8.15053C12.7173 7.5757 12.3025 6.8636 11.6372 6.8636H9.68442L11.8359 1.15823C12.052 0.600565 11.6372 0 11.0324 0H5.36407C4.88883 0 4.5 0.386078 4.5 0.85795Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const LOGICAL_UNIT_MTR = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const LOGICAL_UNIT_BUILD = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M0 10C0 9.44772 0.447715 9 1 9H6C6.55228 9 7 9.44772 7 10V15C7 15.5523 6.55228 16 6 16H1C0.447715 16 0 15.5523 0 15V10Z"
        fill="white"
      />
      <path d="M4 4C4 1.79086 5.79086 0 8 0V0C10.2091 0 12 1.79086 12 4V4C12 6.20914 10.2091 8 8 8V8C5.79086 8 4 6.20914 4 4V4Z" fill="white" />
      <path
        d="M9 10C9 9.44772 9.44772 9 10 9H15C15.5523 9 16 9.44772 16 10V15C16 15.5523 15.5523 16 15 16H10C9.44772 16 9 15.5523 9 15V10Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const DEVICE_MTR_CT = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99988 0C12.3999 0 16 3.6 16 8V14.8571C16 15.4857 15.4857 16 14.8573 16H1.14286C0.514286 16 0 15.4857 0 14.8571V8C0 3.6 3.59988 0 7.99988 0Z"
        fill="white"
      />
      <path
        d="M4 12.1549C4 12.6282 4.37714 13 4.85714 13C5.33714 13 5.72571 12.6282 5.72571 12.1549V8.2338L7.24571 10.5437C7.42857 10.8141 7.65714 10.9831 7.97714 10.9831C8.29714 10.9831 8.52571 10.8141 8.70857 10.5437L10.2514 8.2V12.1324C10.2514 12.6056 10.64 13 11.12 13C11.6114 13 12 12.6169 12 12.1324V5.86761C12 5.3831 11.6114 5 11.12 5H10.9257C10.5714 5 10.32 5.14648 10.1371 5.43944L8 8.86479L5.87429 5.4507C5.71429 5.19155 5.45143 5 5.07429 5H4.88C4.38857 5 4 5.3831 4 5.86761V12.1549Z"
        fill="#454444"
      />
    </SvgIcon>
  )
}

export const DEVICE_TELEC_PS = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 14 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12.0022H7.9875C9.6167 12.0022 11.0918 11.2332 12.1594 10.1662C13.227 9.09924 14 7.62496 14 5.99666C14 4.36837 13.227 2.89399 12.1594 1.82708C11.0918 0.760078 9.6167 0 7.9875 0V0.00889642H3H1C0.4503 0.00889642 0 0.459043 0 1.00824V15.0005C0 15.5501 0.45 16 1 16H3C3.55 16 4 15.5501 4 15.0005V12.0022Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const DEVICE_TELEC_PS_CON = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 12 16">
      <rect width="12" height="16" rx="1" fill="white" />
      <rect x="4" y="3" width="4" height="4" rx="1" fill="#454444" />
      <rect x="4" y="9" width="4" height="4" rx="1" fill="#454444" />
    </SvgIcon>
  )
}

export const DEVICE_TELEC_PS_SLAVE = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 12">
      <rect width="16" height="12" rx="2" fill="white" />
      <rect x="4" y="4" width="8" height="4" rx="2" fill="#454444" />
    </SvgIcon>
  )
}

export const INSTALLED = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M22.6667 23C22.6667 23.5523 22.219 24 21.6667 24H2.33333C1.78105 24 1.33333 23.5523 1.33333 23V9.83333C1.33333 9.55719 1.55719 9.33333 1.83333 9.33333H3.5C3.77614 9.33333 4 9.55719 4 9.83333V21.3333H20V9.83333C20 9.55719 20.2239 9.33333 20.5 9.33333H22.1667C22.4428 9.33333 22.6667 9.55719 22.6667 9.83333V23ZM0 1C0 0.447715 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1V7C24 7.55228 23.5523 8 23 8H1C0.447716 8 0 7.55228 0 7V1ZM2.66667 2.66667V5.33333H21.3333V2.66667H2.66667ZM10 18.6667V15.1667C10 14.8905 9.77614 14.6667 9.5 14.6667H7.87377C7.42832 14.6667 7.20524 14.1281 7.52022 13.8131L11.2929 10.0404C11.6834 9.64992 12.3166 9.64992 12.7071 10.0404L16.4798 13.8131C16.7948 14.1281 16.5717 14.6667 16.1262 14.6667H14.5C14.2239 14.6667 14 14.8905 14 15.1667V18.6667"
        fill="white"
      />
    </SvgIcon>
  )
}

export const STOCK = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M22.6667 23C22.6667 23.5523 22.219 24 21.6667 24H2.33333C1.78105 24 1.33333 23.5523 1.33333 23V9.83333C1.33333 9.55719 1.55719 9.33333 1.83333 9.33333H3.5C3.77614 9.33333 4 9.55719 4 9.83333V21.3333H20V9.83333C20 9.55719 20.2239 9.33333 20.5 9.33333H22.1667C22.4428 9.33333 22.6667 9.55719 22.6667 9.83333V23ZM0 1C0 0.447715 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1V7C24 7.55228 23.5523 8 23 8H1C0.447716 8 0 7.55228 0 7V1ZM8.66667 10.6667H15.3333C15.7067 10.6667 16 10.96 16 11.3333V12.8333C16 13.1095 15.7761 13.3333 15.5 13.3333H8.5C8.22386 13.3333 8 13.1095 8 12.8333V11.3333C8 10.96 8.29333 10.6667 8.66667 10.6667ZM2.66667 2.66667V5.33333H21.3333V2.66667H2.66667Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const NOT_AVAILABLE = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 27 27">
      <path
        d="M15.3333 10.6667C15.7067 10.6667 16 10.96 16 11.3333V12.8333C16 13.1095 15.7761 13.3333 15.5 13.3333H8.5C8.22386 13.3333 8 13.1095 8 12.8333V11.3333C8 10.96 8.29333 10.6667 8.66667 10.6667H15.3333ZM20.6667 12C21.3333 12 22 12.0933 22.6667 12.24V9.83333C22.6667 9.55719 22.4428 9.33333 22.1667 9.33333H20.5C20.2239 9.33333 20 9.55719 20 9.83333V12.04C20.2267 12 20.44 12 20.6667 12ZM4 21.3333V9.83333C4 9.55719 3.77614 9.33333 3.5 9.33333H1.83333C1.55719 9.33333 1.33333 9.55719 1.33333 9.83333V23C1.33333 23.5523 1.78105 24 2.33333 24H12.6667C12.32 23.1733 12.12 22.2667 12.04 21.3333H4ZM24 7C24 7.55228 23.5523 8 23 8H1C0.447716 8 0 7.55228 0 7V1C0 0.447715 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1V7ZM21.3333 2.66667H2.66667V5.33333H21.3333V2.66667ZM26.6667 20.6667C26.6667 24 24 26.6667 20.6667 26.6667C17.3333 26.6667 14.6667 24 14.6667 20.6667C14.6667 17.3333 17.3333 14.6667 20.6667 14.6667C24 14.6667 26.6667 17.3333 26.6667 20.6667ZM22.1165 24.3914C22.4119 24.2712 22.4535 23.8935 22.228 23.668L17.6653 19.1053C17.4398 18.8798 17.0621 18.9214 16.9419 19.2168C16.7629 19.6567 16.6667 20.1488 16.6667 20.6667C16.6667 22.88 18.4533 24.6667 20.6667 24.6667C21.1845 24.6667 21.6767 24.5705 22.1165 24.3914ZM24.6667 20.6667C24.6667 18.4533 22.88 16.6667 20.6667 16.6667C20.1488 16.6667 19.6567 16.7629 19.2168 16.9419C18.9214 17.0621 18.8798 17.4398 19.1053 17.6653L23.668 22.228C23.8935 22.4535 24.2712 22.4119 24.3914 22.1165C24.5705 21.6767 24.6667 21.1845 24.6667 20.6667Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export const ALERT_OCTAGON_OUTLINE = props => {
  return (
    <svg {...props} viewBox="0 0 38 38">
      <path
        d="M12.0576 0.25C11.5272 0.25 11.0185 0.460714 10.6434 0.835786L0.835787 10.6434C0.460714 11.0185 0.25 11.5272 0.25 12.0576V25.9424C0.25 26.4728 0.460714 26.9815 0.835786 27.3566L10.6434 37.1642C11.0185 37.5393 11.5272 37.75 12.0576 37.75H25.9457C26.4742 37.75 26.9791 37.543 27.3535 37.1701C30.4891 34.0476 35.4814 29.045 37.1701 27.3523C37.544 26.9774 37.75 26.4735 37.75 25.9441V12.0576C37.75 11.5272 37.5393 11.0185 37.1642 10.6434L27.3566 0.835787C26.9815 0.460714 26.4728 0.25 25.9424 0.25H12.0576ZM12.3725 5.00245C12.7476 4.62738 13.2563 4.41667 13.7868 4.41667H24.2132C24.7437 4.41667 25.2524 4.62738 25.6275 5.00245L32.9975 12.3725C33.3726 12.7476 33.5833 13.2563 33.5833 13.7868V24.2132C33.5833 24.7437 33.3726 25.2524 32.9975 25.6275L25.6275 32.9975C25.2524 33.3726 24.7437 33.5833 24.2132 33.5833H13.7868C13.2563 33.5833 12.7476 33.3726 12.3725 32.9975L5.00245 25.6275C4.62738 25.2524 4.41667 24.7437 4.41667 24.2132V13.7868C4.41667 13.2563 4.62738 12.7476 5.00245 12.3725L12.3725 5.00245ZM16.9167 27.25C16.9167 26.1454 17.8121 25.25 18.9167 25.25H19.0833C20.1879 25.25 21.0833 26.1454 21.0833 27.25V27.4167C21.0833 28.5212 20.1879 29.4167 19.0833 29.4167H18.9167C17.8121 29.4167 16.9167 28.5212 16.9167 27.4167V27.25ZM16.9167 10.5833C16.9167 9.47876 17.8121 8.58333 18.9167 8.58333H19.0833C20.1879 8.58333 21.0833 9.47876 21.0833 10.5833V19.0833C21.0833 20.1879 20.1879 21.0833 19.0833 21.0833H18.9167C17.8121 21.0833 16.9167 20.1879 16.9167 19.0833V10.5833Z"
        fill={props.color ? props.color : '#FFF'}
      />
    </svg>
  )
}

export const DIALOG_OCTAGON_OUTLINE = props => {
  return (
    <svg {...props} viewBox="0 0 38 38">
      <path
        d="M12.0576 0.25C11.5272 0.25 11.0185 0.460714 10.6434 0.835786L0.835787 10.6434C0.460714 11.0185 0.25 11.5272 0.25 12.0576V25.9424C0.25 26.4728 0.460714 26.9815 0.835786 27.3566L10.6434 37.1642C11.0185 37.5393 11.5272 37.75 12.0576 37.75H25.9457C26.4742 37.75 26.9791 37.543 27.3535 37.1701C30.4891 34.0476 35.4814 29.045 37.1701 27.3523C37.544 26.9774 37.75 26.4735 37.75 25.9441V12.0576C37.75 11.5272 37.5393 11.0185 37.1642 10.6434L27.3566 0.835787C26.9815 0.460714 26.4728 0.25 25.9424 0.25H12.0576ZM12.3725 5.00245C12.7476 4.62738 13.2563 4.41667 13.7868 4.41667H24.2132C24.7437 4.41667 25.2524 4.62738 25.6275 5.00245L32.9975 12.3725C33.3726 12.7476 33.5833 13.2563 33.5833 13.7868V24.2132C33.5833 24.7437 33.3726 25.2524 32.9975 25.6275L25.6275 32.9975C25.2524 33.3726 24.7437 33.5833 24.2132 33.5833H13.7868C13.2563 33.5833 12.7476 33.3726 12.3725 32.9975L5.00245 25.6275C4.62738 25.2524 4.41667 24.7437 4.41667 24.2132V13.7868C4.41667 13.2563 4.62738 12.7476 5.00245 12.3725L12.3725 5.00245ZM16.9167 27.25C16.9167 26.1454 17.8121 25.25 18.9167 25.25H19.0833C20.1879 25.25 21.0833 26.1454 21.0833 27.25V27.4167C21.0833 28.5212 20.1879 29.4167 19.0833 29.4167H18.9167C17.8121 29.4167 16.9167 28.5212 16.9167 27.4167V27.25ZM16.9167 10.5833C16.9167 9.47876 17.8121 8.58333 18.9167 8.58333H19.0833C20.1879 8.58333 21.0833 9.47876 21.0833 10.5833V19.0833C21.0833 20.1879 20.1879 21.0833 19.0833 21.0833H18.9167C17.8121 21.0833 16.9167 20.1879 16.9167 19.0833V10.5833Z"
        fill={props.color ? props.color : '#FFF'}
      />
    </svg>
  )
}

export const NETWORK_SERVER = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 23 23">
    <path d="M3.83333 0.958008H19.1667C19.4208 0.958008 19.6646 1.05897 19.8443 1.2387C20.024 1.41842 20.125 1.66218 20.125 1.91634V5.74967C20.125 6.00384 20.024 6.2476 19.8443 6.42732C19.6646 6.60704 19.4208 6.70801 19.1667 6.70801H3.83333C3.57917 6.70801 3.33541 6.60704 3.15569 6.42732C2.97597 6.2476 2.875 6.00384 2.875 5.74967V1.91634C2.875 1.66218 2.97597 1.41842 3.15569 1.2387C3.33541 1.05897 3.57917 0.958008 3.83333 0.958008ZM3.83333 8.62467H19.1667C19.4208 8.62467 19.6646 8.72564 19.8443 8.90536C20.024 9.08509 20.125 9.32884 20.125 9.58301V13.4163C20.125 13.6705 20.024 13.9143 19.8443 14.094C19.6646 14.2737 19.4208 14.3747 19.1667 14.3747H3.83333C3.57917 14.3747 3.33541 14.2737 3.15569 14.094C2.97597 13.9143 2.875 13.6705 2.875 13.4163V9.58301C2.875 9.32884 2.97597 9.08509 3.15569 8.90536C3.33541 8.72564 3.57917 8.62467 3.83333 8.62467ZM3.83333 16.2913H19.1667C19.4208 16.2913 19.6646 16.3923 19.8443 16.572C20.024 16.7518 20.125 16.9955 20.125 17.2497V21.083C20.125 21.3372 20.024 21.5809 19.8443 21.7607C19.6646 21.9404 19.4208 22.0413 19.1667 22.0413H3.83333C3.57917 22.0413 3.33541 21.9404 3.15569 21.7607C2.97597 21.5809 2.875 21.3372 2.875 21.083V17.2497C2.875 16.9955 2.97597 16.7518 3.15569 16.572C3.33541 16.3923 3.57917 16.2913 3.83333 16.2913ZM8.625 4.79134H9.58333V2.87467H8.625V4.79134ZM8.625 12.458H9.58333V10.5413H8.625V12.458ZM8.625 20.1247H9.58333V18.208H8.625V20.1247ZM4.79167 2.87467V4.79134H6.70833V2.87467H4.79167ZM4.79167 10.5413V12.458H6.70833V10.5413H4.79167ZM4.79167 18.208V20.1247H6.70833V18.208H4.79167Z" fill="white"/>
    </SvgIcon>
  )
}

export const NETWORK_SERVER_DETAIL = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 20">
      <path d="M2.16667 3.6H3.83333V6.3H12.1667V3.6H13.8333V8.1H15.5V3.6C15.5 2.61 14.75 1.8 13.8333 1.8H10.35C10 0.756 9.08333 0 8 0C6.91667 0 6 0.756 5.65 1.8H2.16667C1.25 1.8 0.5 2.61 0.5 3.6V16.2C0.5 17.19 1.25 18 2.16667 18H6.33333V16.2H2.16667V3.6ZM8 1.8C8.45833 1.8 8.83333 2.205 8.83333 2.7C8.83333 3.195 8.45833 3.6 8 3.6C7.54167 3.6 7.16667 3.195 7.16667 2.7C7.16667 2.205 7.54167 1.8 8 1.8Z" fill="#93F1FF"/>
			<path d="M15.2909 16.6455C15.6182 16.0727 15.8636 15.4182 15.8636 14.6818C15.8636 12.6364 14.2273 11 12.1818 11C10.1364 11 8.5 12.6364 8.5 14.6818C8.5 16.7273 10.1364 18.3636 12.1818 18.3636C12.9182 18.3636 13.5727 18.1182 14.1455 17.7909L16.3545 20L17.5 18.8545L15.2909 16.6455ZM12.1818 16.7273C11.0364 16.7273 10.1364 15.8273 10.1364 14.6818C10.1364 13.5364 11.0364 12.6364 12.1818 12.6364C13.3273 12.6364 14.2273 13.5364 14.2273 14.6818C14.2273 15.8273 13.3273 16.7273 12.1818 16.7273Z" fill="#93F1FF"/>
    </SvgIcon>
  )
}

export const NETWORK_SERVER_GATEWAYS = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path d="M9.83268 13.8333H10.666C10.887 13.8333 11.099 13.9211 11.2553 14.0774C11.4116 14.2337 11.4993 14.4457 11.4993 14.6667H17.3327V16.3333H11.4993C11.4993 16.5543 11.4116 16.7663 11.2553 16.9226C11.099 17.0789 10.887 17.1667 10.666 17.1667H7.33268C7.11167 17.1667 6.89971 17.0789 6.74343 16.9226C6.58715 16.7663 6.49935 16.5543 6.49935 16.3333H0.666016V14.6667H6.49935C6.49935 14.4457 6.58715 14.2337 6.74343 14.0774C6.89971 13.9211 7.11167 13.8333 7.33268 13.8333H8.16602V12.1667H2.33268C2.11167 12.1667 1.89971 12.0789 1.74343 11.9226C1.58715 11.7663 1.49935 11.5543 1.49935 11.3333V8C1.49935 7.77899 1.58715 7.56702 1.74343 7.41074C1.89971 7.25446 2.11167 7.16667 2.33268 7.16667H15.666C15.887 7.16667 16.099 7.25446 16.2553 7.41074C16.4116 7.56702 16.4993 7.77899 16.4993 8V11.3333C16.4993 11.5543 16.4116 11.7663 16.2553 11.9226C16.099 12.0789 15.887 12.1667 15.666 12.1667H9.83268V13.8333ZM2.33268 0.5H15.666C15.887 0.5 16.099 0.587797 16.2553 0.744078C16.4116 0.900358 16.4993 1.11232 16.4993 1.33333V4.66667C16.4993 4.88768 16.4116 5.09964 16.2553 5.25592C16.099 5.4122 15.887 5.5 15.666 5.5H2.33268C2.11167 5.5 1.89971 5.4122 1.74343 5.25592C1.58715 5.09964 1.49935 4.88768 1.49935 4.66667V1.33333C1.49935 1.11232 1.58715 0.900358 1.74343 0.744078C1.89971 0.587797 2.11167 0.5 2.33268 0.5ZM6.49935 3.83333H7.33268V2.16667H6.49935V3.83333ZM6.49935 10.5H7.33268V8.83333H6.49935V10.5ZM3.16602 2.16667V3.83333H4.83268V2.16667H3.16602ZM3.16602 8.83333V10.5H4.83268V8.83333H3.16602Z" fill="white" fill-opacity="0.7"/>
    </SvgIcon>
  )
}

export const NETWORK_SERVER_EVENTS = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 16">
      <path d="M14.6405 6.78628C14.5232 6.90414 14.3325 6.90414 14.2152 6.78628L11.1558 3.7116C11.0394 3.59457 11.0394 3.40543 11.1558 3.2884L12.1256 2.31372C12.2429 2.19586 12.4337 2.19586 12.551 2.31372L14.2152 3.98628C14.3325 4.10414 14.5232 4.10414 14.6405 3.98628L18.3943 0.213724C18.5116 0.0958601 18.7023 0.0958602 18.8196 0.213724L19.7894 1.1884C19.9059 1.30543 19.9059 1.49457 19.7894 1.6116L14.6405 6.78628ZM8.95522 4C8.95522 3.44772 8.50751 3 7.95522 3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H7.95522C8.50751 5 8.95522 4.55228 8.95522 4ZM18.6949 9.6116C18.8114 9.49457 18.8114 9.30543 18.6949 9.1884L17.7251 8.21372C17.6078 8.09586 17.4171 8.09586 17.2998 8.21372L15.138 10.3863C15.0208 10.5041 14.83 10.5041 14.7127 10.3863L12.551 8.21372C12.4337 8.09586 12.2429 8.09586 12.1256 8.21372L11.1558 9.1884C11.0394 9.30543 11.0394 9.49457 11.1558 9.6116L13.3218 11.7884C13.4382 11.9054 13.4382 12.0946 13.3218 12.2116L11.1558 14.3884C11.0394 14.5054 11.0394 14.6946 11.1558 14.8116L12.1256 15.7863C12.2429 15.9041 12.4337 15.9041 12.551 15.7863L14.7127 13.6137C14.83 13.4959 15.0208 13.4959 15.138 13.6137L17.2998 15.7863C17.4171 15.9041 17.6078 15.9041 17.7251 15.7863L18.6949 14.8116C18.8114 14.6946 18.8114 14.5054 18.6949 14.3884L16.529 12.2116C16.4125 12.0946 16.4125 11.9054 16.529 11.7884L18.6949 9.6116ZM8.95522 12C8.95522 11.4477 8.50751 11 7.95522 11H1C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13H7.95522C8.50751 13 8.95522 12.5523 8.95522 12Z" fill="white" fill-opacity="0.7"/>
    </SvgIcon>
  )
}

export const TYPE_ERROR_VALIDATION = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 18">
      <path d="M15.4987 4.41667C15.4987 6.4425 12.217 8.08333 8.16536 8.08333C4.1137 8.08333 0.832031 6.4425 0.832031 4.41667C0.832031 2.39083 4.1137 0.75 8.16536 0.75C12.217 0.75 15.4987 2.39083 15.4987 4.41667ZM8.16536 14.5C4.1137 14.5 0.832031 12.8592 0.832031 10.8333V13.5833C0.832031 15.6092 4.1137 17.25 8.16536 17.25C12.217 17.25 15.4987 15.6092 15.4987 13.5833V10.8333C15.4987 12.8592 12.217 14.5 8.16536 14.5ZM8.16536 9.91667C4.1137 9.91667 0.832031 8.27583 0.832031 6.25V9C0.832031 11.0258 4.1137 12.6667 8.16536 12.6667C12.217 12.6667 15.4987 11.0258 15.4987 9V6.25C15.4987 8.27583 12.217 9.91667 8.16536 9.91667ZM17.332 13.5833H19.1654V11.75H17.332V13.5833ZM17.332 4.41667V9.91667H19.1654V4.41667H17.332Z" fill="white" fill-opacity="0.7"/>
    </SvgIcon>
  )
}

export const TYPE_ERROR_INVALID = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path d="M11.173 12.5567L12.4746 11.255L14.418 13.2075L16.3613 11.2642L17.6538 12.5567L15.7105 14.5L17.663 16.4433L16.3613 17.745L14.418 15.7925L12.4746 17.7358L11.1821 16.4433L13.1255 14.5L11.173 12.5567ZM8.0013 0.75C12.053 0.75 15.3346 2.39083 15.3346 4.41667C15.3346 6.4425 12.053 8.08333 8.0013 8.08333C3.94964 8.08333 0.667969 6.4425 0.667969 4.41667C0.667969 2.39083 3.94964 0.75 8.0013 0.75ZM0.667969 6.25C0.667969 8.27583 3.94964 9.91667 8.0013 9.91667C12.053 9.91667 15.3346 8.27583 15.3346 6.25V9.07333L14.418 9C12.0438 9 10.018 10.5033 9.24797 12.6117L8.0013 12.6667C3.94964 12.6667 0.667969 11.0258 0.667969 9V6.25ZM0.667969 10.8333C0.667969 12.8592 3.94964 14.5 8.0013 14.5H8.91797C8.91797 15.4625 9.16547 16.37 9.60547 17.1583L8.0013 17.25C3.94964 17.25 0.667969 15.6092 0.667969 13.5833V10.8333Z" fill="white" fill-opacity="0.7"/>
    </SvgIcon>
  )
}

export const TYPE_ERROR_TIMESTAMP = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21">
      <path d="M13.125 13.8955L15.7467 15.4447L15.0592 16.563L11.75 14.583V9.99968H13.125V13.8955ZM19.0833 13.6663C19.0833 17.2138 16.2142 20.083 12.6667 20.083C11.6314 20.0838 10.6114 19.8341 9.69355 19.3553C8.77573 18.8764 7.9874 18.1826 7.39583 17.333C3.665 17.1405 0.75 15.573 0.75 13.6663V10.9163C0.75 12.6397 3.115 14.0697 6.305 14.473C6.2775 14.2072 6.25 13.9413 6.25 13.6663C6.25 13.318 6.28667 12.9788 6.34167 12.6397C3.13333 12.2455 0.75 10.8063 0.75 9.08301V6.33301C0.75 8.24884 3.68333 9.81634 7.41417 9.99968C7.96417 9.21134 8.67 8.56968 9.48583 8.10218C9.03667 8.13884 8.54167 8.16634 8.08333 8.16634C4.03167 8.16634 0.75 6.52551 0.75 4.49967C0.75 2.47384 4.03167 0.833008 8.08333 0.833008C12.135 0.833008 15.4167 2.47384 15.4167 4.49967C15.4167 5.59967 14.4358 6.58967 12.8958 7.24967C13.5833 7.28634 14.2708 7.42384 14.9033 7.66217C15.2242 7.24967 15.4167 6.79134 15.4167 6.33301V7.87301C17.58 8.90884 19.0833 11.1088 19.0833 13.6663ZM17.25 13.6663C17.25 11.1363 15.1967 9.08301 12.6667 9.08301C10.1367 9.08301 8.08333 11.1363 8.08333 13.6663C8.08333 16.1963 10.1367 18.2497 12.6667 18.2497C15.1967 18.2497 17.25 16.1963 17.25 13.6663Z" fill="white" fill-opacity="0.7"/>
    </SvgIcon>
  )
}

export const ERROR_DATA_VALIDATION = props => {
  return (
    <SvgIcon {...props}  width="12" height="12" viewBox="0 0 16 16" fill="none">
      <path d="M2.33203 7V5.25C2.33203 6.53333 4.43203 7.58333 6.9987 7.58333C9.56536 7.58333 11.6654 6.53333 11.6654 5.25V7C11.6654 7.29167 11.5487 7.525 11.3737 7.81667C10.907 7.64167 10.4404 7.58333 9.91536 7.58333C8.45703 7.58333 7.05703 8.225 6.18203 9.275C3.96536 9.1 2.33203 8.16667 2.33203 7ZM6.9987 6.41667C9.56536 6.41667 11.6654 5.36667 11.6654 4.08333C11.6654 2.8 9.56536 1.75 6.9987 1.75C4.43203 1.75 2.33203 2.8 2.33203 4.08333C2.33203 5.36667 4.43203 6.41667 6.9987 6.41667ZM5.30703 11.4917L5.13203 11.0833L5.30703 10.675C5.36536 10.5583 5.4237 10.5 5.4237 10.3833C3.61536 10.0333 2.33203 9.21667 2.33203 8.16667V9.91667C2.33203 10.9667 3.73203 11.8417 5.65703 12.1333C5.54036 11.9583 5.4237 11.725 5.30703 11.4917ZM9.91536 10.5C9.56536 10.5 9.33203 10.7333 9.33203 11.0833C9.33203 11.4333 9.56536 11.6667 9.91536 11.6667C10.2654 11.6667 10.4987 11.4333 10.4987 11.0833C10.4987 10.7333 10.2654 10.5 9.91536 10.5ZM13.4154 11.0833C12.8904 12.425 11.4904 13.4167 9.91536 13.4167C8.34036 13.4167 6.94036 12.425 6.41536 11.0833C6.94036 9.74167 8.34036 8.75 9.91536 8.75C11.4904 8.75 12.8904 9.74167 13.4154 11.0833ZM11.3737 11.0833C11.3737 10.2667 10.732 9.625 9.91536 9.625C9.0987 9.625 8.45703 10.2667 8.45703 11.0833C8.45703 11.9 9.0987 12.5417 9.91536 12.5417C10.732 12.5417 11.3737 11.9 11.3737 11.0833Z" fill="white" fill-opacity="0.7"/>
    </SvgIcon>
  )
}



export const FILLED_COLOR_SQUARE = props => {
	const { color } = props
	return (
		<SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14" fill="none">
			<rect width="14" height="14" rx="3" fill={color} />
		</SvgIcon>
	)
}



export const SEARCH_REFRESH = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 23 20">
      <path
        d="M8.26442 11.6791C8.5172 11.7693 8.7837 11.8459 9.06392 11.9087C9.34342 11.9723 9.62761 12.0041 9.9165 12.0041C11.2346 12.0041 12.3497 11.548 13.2618 10.6358C14.1733 9.72437 14.629 8.60962 14.629 7.29157C14.629 5.97351 14.1733 4.8584 13.2618 3.94624C12.3497 3.03479 11.2346 2.57907 9.9165 2.57907C8.68872 2.57907 7.6415 2.9716 6.77483 3.75665C5.90817 4.54243 5.40261 5.55824 5.25817 6.80407L5.82692 6.20824C6.04358 5.99157 6.31008 5.88757 6.62642 5.89624C6.94203 5.90562 7.19914 6.01865 7.39775 6.23532C7.61442 6.45199 7.72275 6.71812 7.72275 7.03374C7.72275 7.35007 7.61442 7.61657 7.39775 7.83324L4.82483 10.4062C4.60817 10.6409 4.32397 10.7582 3.97225 10.7582C3.61981 10.7582 3.32622 10.6409 3.0915 10.4062L0.4915 7.80615C0.292889 7.58949 0.198278 7.32768 0.207667 7.02074C0.216334 6.71379 0.319972 6.45199 0.518584 6.23532C0.753306 6.01865 1.02883 5.90562 1.34517 5.89624C1.66078 5.88757 1.92692 5.99157 2.14358 6.20824L2.7665 6.83115C2.87483 4.93532 3.61078 3.3374 4.97433 2.0374C6.33717 0.737402 7.98456 0.0874023 9.9165 0.0874023C11.9207 0.0874023 13.6226 0.786875 15.0223 2.18582C16.4212 3.58549 17.1207 5.2874 17.1207 7.29157C17.1207 8.06796 17.0033 8.81257 16.7686 9.5254C16.5339 10.239 16.1908 10.8575 15.7394 11.3812L21.779 17.4207C22.0137 17.6555 22.1268 17.94 22.1181 18.2744C22.1087 18.6081 21.9866 18.8923 21.7519 19.127C21.5353 19.3617 21.2511 19.4791 20.8993 19.4791C20.5469 19.4791 20.2533 19.3617 20.0186 19.127L14.0332 13.1145C13.4554 13.5659 12.8191 13.9089 12.1243 14.1437C11.4288 14.3784 10.6929 14.4957 9.9165 14.4957C9.429 14.4957 8.95956 14.4506 8.50817 14.3603C8.05678 14.27 7.63247 14.1346 7.23525 13.9541C6.91025 13.8277 6.70261 13.6063 6.61233 13.29C6.52206 12.9744 6.54914 12.6541 6.69358 12.3291C6.83803 12.0221 7.05036 11.8098 7.33058 11.6921C7.61008 11.5751 7.92136 11.5707 8.26442 11.6791Z"
        fill="white"
      />
    </SvgIcon>
  );
};


export const DELETE = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 14 16">
      <path
        d="M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33065 14.6842 1.16732 14.2917 1.16732 13.8333V3C0.931207 3 0.733151 2.92028 0.573151 2.76083C0.413707 2.60083 0.333984 2.40278 0.333984 2.16667C0.333984 1.93056 0.413707 1.7325 0.573151 1.5725C0.733151 1.41306 0.931207 1.33333 1.16732 1.33333H4.50065C4.50065 1.09722 4.58065 0.899167 4.74065 0.739167C4.9001 0.579722 5.09787 0.5 5.33398 0.5H8.66732C8.90343 0.5 9.10148 0.579722 9.26148 0.739167C9.42093 0.899167 9.50065 1.09722 9.50065 1.33333H12.834C13.0701 1.33333 13.2679 1.41306 13.4273 1.5725C13.5873 1.7325 13.6673 1.93056 13.6673 2.16667C13.6673 2.40278 13.5873 2.60083 13.4273 2.76083C13.2679 2.92028 13.0701 3 12.834 3V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0182 15.3369 11.6257 15.5 11.1673 15.5H2.83398ZM2.83398 3V13.8333H11.1673V3H2.83398ZM4.50065 11.3333C4.50065 11.5694 4.58065 11.7672 4.74065 11.9267C4.9001 12.0867 5.09787 12.1667 5.33398 12.1667C5.5701 12.1667 5.76815 12.0867 5.92815 11.9267C6.0876 11.7672 6.16732 11.5694 6.16732 11.3333V5.5C6.16732 5.26389 6.0876 5.06583 5.92815 4.90583C5.76815 4.74639 5.5701 4.66667 5.33398 4.66667C5.09787 4.66667 4.9001 4.74639 4.74065 4.90583C4.58065 5.06583 4.50065 5.26389 4.50065 5.5V11.3333ZM7.83398 11.3333C7.83398 11.5694 7.91398 11.7672 8.07398 11.9267C8.23343 12.0867 8.43121 12.1667 8.66732 12.1667C8.90343 12.1667 9.10148 12.0867 9.26148 11.9267C9.42093 11.7672 9.50065 11.5694 9.50065 11.3333V5.5C9.50065 5.26389 9.42093 5.06583 9.26148 4.90583C9.10148 4.74639 8.90343 4.66667 8.66732 4.66667C8.43121 4.66667 8.23343 4.74639 8.07398 4.90583C7.91398 5.06583 7.83398 5.26389 7.83398 5.5V11.3333Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const getDeviceIcon = (deviceCod, className) => {
  switch (deviceCod) {
    case 'TELEC_PS_SLAVE':
      return <DEVICE_TELEC_PS_SLAVE className={className}></DEVICE_TELEC_PS_SLAVE>

    case 'TELEC_PS_CON':
      return <DEVICE_TELEC_PS_CON className={className}></DEVICE_TELEC_PS_CON>

    case 'TELEC_PS':
      return <DEVICE_TELEC_PS className={className}></DEVICE_TELEC_PS>

    case 'MTR_CT':
      return <DEVICE_MTR_CT className={className}></DEVICE_MTR_CT>

    default:
      return <DEVICE className={className}></DEVICE>
  }
}

export const getLogicalUnitIcon = (logicalUnitCod, className) => {
  switch (logicalUnitCod) {
    case 'CT':
      return <LOGICAL_UNIT_CT className={className}></LOGICAL_UNIT_CT>

    case 'MTR':
      return <LOGICAL_UNIT_MTR className={className}></LOGICAL_UNIT_MTR>

    case 'BUILD':
      return <LOGICAL_UNIT_BUILD className={className}></LOGICAL_UNIT_BUILD>

    default:
      return <LOGICAL_UNIT className={className}></LOGICAL_UNIT>
  }
}
