import { shape, any } from 'prop-types'
import i18n from 'i18next'

export const pageSizeOptions = [10, 20, 100]
export const DEFAULT_PAGE_NUMBER = 1

const iotTypeDescriptionRenderer = item => item.iot_type.description

const iotModelDescriptionRenderer = item => item.iot_model.description

const statusServiceRenderer = item => (item.status_service ? i18n.t('IoT:on') : i18n.t('IoT:off'))

const hierarchyRelationRenderer = item => i18n.t(`IoT:relation_${item.relation_type}`) || 'N/A'

const statusOperationRenderer = item => (item.status_operation ? i18n.t('IoT:on') : i18n.t('IoT:off'))

export const columnData = [
  {
    key: 'slaveId',
    label: i18n.t('IoT:deviceRelationsTableSlaveIdTitleColumn'),
    align: 'center',
  },
  {
    key: 'uid',
    label: i18n.t('IoT:deviceRelationsTableDevuidTitleColumn'),
    align: 'center',
  },
  {
    key: 'iot_type.description',
    label: i18n.t('IoT:deviceRelationsTableTypeTitleColumn'),
    align: 'center',
    cellRenderer: iotTypeDescriptionRenderer,
  },
  {
    key: 'model',
    label: i18n.t('IoT:deviceRelationsTableModelTitleColumn'),
    align: 'center',
    cellRenderer: iotModelDescriptionRenderer,
  },
  {
    key: 'status_service',
    label: i18n.t('IoT:deviceRelationsTableStatusServiceTitleColumn'),
    align: 'center',
    cellRenderer: statusServiceRenderer,
  },
  {
    key: 'relation',
    label: i18n.t('IoT:deviceRelationsTableRelationTitleColumn'),
    align: 'center',
    cellRenderer: hierarchyRelationRenderer,
  },
  {
    key: 'status_operation',
    label: i18n.t('IoT:deviceRelationsTableStatusOperationTitleColumn'),
    align: 'center',
    cellRenderer: statusOperationRenderer,
  },
  {
    key: 'uid',
    label: 'ninguno',
    align: 'center',
  },
]

export const deviceRelation = shape(any)
