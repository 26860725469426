//import { isEmpty } from '@widergy/web-utils/lib/array';

import apiIOT from 'config/api'
import {
  AUTH_HEADER,
} from 'constants/apiHeaders'
import store from 'redux/store'

let headersNotInit = true;
const initHeaders = () => {
  if (headersNotInit) {
    const auhtInformation = store.getState().auth['authInformation']

    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`
    });

    headersNotInit = false;
  }

  return null;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDevicesType: async (token, companyId) => {
    const response = await apiIOT.get(`/api/v1/iottypes/`)
    return response?.data
  },

  getIotTypeIdByName: iotTypeName => {
    initHeaders();
    return apiIOT.get(`/api/v1/iottypes/name/${iotTypeName}`)
  },

  getDevicesByTypeId: iotTypeId => {
    initHeaders();
    return apiIOT.get(`/api/v1/iotdevices/iottypes/${iotTypeId}`)
  },

  getIotDeviceIsAlive: (deviceId, pageNumber, pageSize) => {
    initHeaders();

    const baseHeaders = {
      page: pageNumber,
      paginate: pageSize
    }

    return apiIOT.get(`/api/v1/iotisalive/iotdevices/${deviceId}`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
    })
  },

  getIotDeviceDetail: deviceId => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/details`)
  },

  getIotDeviceMeasurements: (deviceId, pageNumber, pageSize, filters, isGraphics) => {
    initHeaders();

    let baseHeaders;
    if (pageNumber) baseHeaders = {
      page: pageNumber,
      paginate: pageSize
    }
    if (isGraphics) baseHeaders = {
      paginate: 9999
    } //para que traiga toda la info que exista en el periodo indicado en filters
    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/measurements`, null, {
      headers: !filters ? baseHeaders : {
        ...apiIOT.headers,
        ...baseHeaders,
        searchparams: JSON.stringify(filters)
      },
    })
  },

  getIotDevicesTypes: () => {
    initHeaders();

    return apiIOT.get(`/api/v1/iottypes/utility`)
  },

  getIotTypesReports: (filters) => {
    initHeaders();

    return apiIOT.get(`/api/v1/iottypes/reports`, null, {
      headers: {
        ...apiIOT.headers,
        searchparams: JSON.stringify(filters)
      }
    })
  },

  retrieveDeviceInfo: serialNumber => {
    initHeaders();

    return apiIOT.get(`api/v1/iotdevices/serialNumber/${serialNumber}`)
  },

  registerDevice: values => {
    initHeaders();

    return apiIOT.post(`api/v1/iotregister`, { ...values })
  },

  editDevice: (deviceId, values) => {
    initHeaders();

    return apiIOT.put(`api/v1/iotdevices/${deviceId}`, { ...values })
  },

  getIotDeviceAlarms: (deviceId, pageNumber, pageSize) => {
    initHeaders();

    const baseHeaders = {
      page: pageNumber,
      paginate: pageSize
    }
    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/alarms`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
    })
  },

  getIotDeviceAlarmsCancel: (deviceId, pageNumber, pageSize) => {
    initHeaders();

    const baseHeaders = { page: pageNumber, paginate: pageSize }
    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/alarmscancel`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
    })
  },

  getIotDeviceEvents: (deviceId, pageNumber, pageSize) => {
    initHeaders();

    const baseHeaders = {
      page: pageNumber,
      paginate: pageSize
    }
    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/events`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
      //headers: !filters ? baseHeaders : { ...baseHeaders, searchparams: JSON.stringify(filters) },
    })
  },
  setIotDeviceStatus: (deviceId, alarmId, status) => {
    initHeaders();

    return apiIOT.post(`/api/v1/iotdevices/${deviceId}/alarms/${alarmId}/status`, {
      status_to_apply: status
    })
  },

  getIotDeviceRelations: deviceId => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/relations`)
  },

  getIotDeviceLastIsAlive: deviceId => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/isalives/actual`)
  },

  getIotDeviceComponentDetail: deviceComponentId => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotdevicecomponents/${deviceComponentId}`)
  },

  putIotDeviceComponentParams: (deviceComponentId, paramsToUpdate) => {
    initHeaders();

    return apiIOT.put(`/api/v1/iotdevicecomponents/${deviceComponentId}/config`, {
      configuration: {
        ...paramsToUpdate
      },
    })
  },

  putIotDeviceComponentParamsLocal: (deviceComponentId, paramsToUpdate) => {
    initHeaders();

    return apiIOT.put(`/api/v1/iotdevicecomponents/${deviceComponentId}/configlocal`, {
      configuration: {
        ...paramsToUpdate
      },
    })
  },

  getIotDeviceComponentParamsOnline: deviceComponentId => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotdevicecomponents/${deviceComponentId}/config/online`)
  },

  postDeviceActionOnline: (deviceId, commandDevice, payload) => {
    initHeaders();

    return apiIOT.post(`/api/v1/iotdevices/${deviceId}/downlink/${commandDevice}`, payload)
  },

  getNetworkServers: () => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotservers`)
  },

  getNetworkServerTopics: networkServerId => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotservers/${networkServerId}/topics`)
  },

  getNetworkServerEvents: (networkServerId, pageNumber, pageSize) => {
    initHeaders();

    const baseHeaders = {
      page: pageNumber,
      paginate: pageSize
    }
    return apiIOT.get(`/api/v1/iotservers/${networkServerId}/events`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
      //headers: !filters ? baseHeaders : { ...baseHeaders, searchparams: JSON.stringify(filters) },
    })
  },

  getNetworkServerAlarms: (networkServerId, pageNumber, pageSize) => {
    initHeaders();

    const baseHeaders = {
      page: pageNumber,
      paginate: pageSize
    }
    return apiIOT.get(`/api/v1/iotservers/${networkServerId}/alarms`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
      //headers: !filters ? baseHeaders : { ...baseHeaders, searchparams: JSON.stringify(filters) },
    })
  },

  getIotTypeReports: (iotTypeId, filters) => {
    initHeaders();

    return apiIOT.get(`/api/v1/iottypes/${iotTypeId}/reports`, null, {
      headers: filters ? { searchparams: JSON.stringify(filters) } : {},
    });
  },

  getIotTypeGraphics: (iotTypeId, filters) => {
    initHeaders();

    return apiIOT.get(`/api/v1/iottypes/${iotTypeId}/graphics`, null, {
      headers: filters ? { searchparams: JSON.stringify(filters) } : {},
    });
  },

  getReportById: reportId => {
    initHeaders();
    return apiIOT.get(`/api/v1/iotreports/${reportId}`)
  },

  getIotGroupsEntities: entityType => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotgroupentities`, null, {
      headers: { entitytype: entityType }
    });
  },

  getIotGroupEntityDetail: (entityType, groupEntityId) => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotgroupentities/${groupEntityId}`, null, {
      headers: { entitytype: entityType }
    });
  },

  getIotGroupDevices: (entityType, groupEntityId, page, paginate, sortOptions) => {
    initHeaders();

		const baseHeaders = { entitytype: entityType, page, paginate, sortOptions }
		if (!page) delete baseHeaders.page
		if (!paginate) delete baseHeaders.paginate
		if (!sortOptions || sortOptions?.length === 0) delete baseHeaders.sortOptions
    return apiIOT.get(`/api/v1/iotgroupentities/${groupEntityId}/devices`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
    })

  },

  postCreateIotGroupEntity: (payload) => {
    initHeaders();

    return apiIOT.post(`/api/v1/iotgroupentities`, payload);
  },

  putIotGroupEntity: (groupEntityId, payload) => {
    initHeaders();

    return apiIOT.put(`/api/v1/iotgroupentities/${groupEntityId}`, payload);
  },

  deleteDeviceFromIotGroupEntity: (entityType, groupEntityId, deviceId) => {
    initHeaders();

    const baseHeaders = { entitytype: entityType }
    return apiIOT.delete(`/api/v1/iotgroupentities/${groupEntityId}/devices/${deviceId}`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
    });
  },

  toogleAvailabilityIotGroupEntity: (groupEntityId, payload) => {
    initHeaders();

    return apiIOT.post(`/api/v1/iotgroupentities/${groupEntityId}/toogle/availability`, payload);
  },

  getIotGroupDevicesCandidates: (
      entityType,
      groupEntityId,
      page,
      paginate,
      sortoptions,
      devuid,
      logicalUnitExternalName,
      deviceInstallSituation,
      deviceTypeName,
      hierarchyName
    ) => {
      initHeaders();

      const baseHeaders = { entitytype: entityType, page, paginate, sortoptions };
			if (!page) delete baseHeaders.page
			if (!paginate) delete baseHeaders.paginate
			if (!sortoptions || sortoptions?.length === 0) delete baseHeaders.sortoptions

      let qryParams = '';
      qryParams += devuid ? `&devuid=${devuid}` : '';
      qryParams += logicalUnitExternalName ? `&external_name=${logicalUnitExternalName}` : '';
      qryParams += deviceInstallSituation ? `&device_situation=${deviceInstallSituation.toUpperCase()}` : '';
      qryParams += deviceTypeName ? `&device_type_name=${deviceTypeName}` : '';
      qryParams += hierarchyName ? `&hierarchy_name=${hierarchyName}` : '';

    return apiIOT.get(`/api/v1/iotgroupentities/${groupEntityId}/devices/search${qryParams.replace('&', '?')}`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
    });
  },

  getIotDeviceGrpupsEntity: (deviceId) => {
    initHeaders();
    return apiIOT.get(`/api/v1/iotdevices/${deviceId}/groupentities`);
  },

  postCreateIotDevicesGroup: (entityType, groupEntityId, payload) => {
    initHeaders();

    return apiIOT.post(`/api/v1/iotgroupentities/${groupEntityId}/devices`, payload, {
      headers: { entityType }
    });
	},

	getGroupEntityTypes: () => {
		initHeaders();
		const entitytype = 'device'
		return apiIOT.get(`/api/v1/iotgroupentitytypes`, null, {
      headers: { entitytype }
    });
	},

	getIotReportCrons: (reportId, filters) => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotreports/${reportId}/crons`, null, {
      headers: filters ? { searchparams: JSON.stringify(filters) } : {},
    });
	},

	getIotTypeReportCronDetail: (cronId) => {
    initHeaders();

    return apiIOT.get(`/api/v1/iotreportcrons/${cronId}`);
	},

	getWorkerExecutions: (
      cronId,
      page,
      paginate,
    ) => {
      initHeaders();

      const baseHeaders = { page, paginate };
			if (!page) delete baseHeaders.page
			if (!paginate) delete baseHeaders.paginate

    return apiIOT.get(`/api/v1/iotreportcrons/${cronId}/executions`, null, {
      headers: {...apiIOT.headers, ...baseHeaders}
    });
	},

	postCreateCronReport: (reportId, deviceTypeId, payload) => {
		initHeaders();

    return apiIOT.post(`/api/v1/iotreports/${reportId}/iottypes/${deviceTypeId}/crons`, payload, {
    });
	},

	toggleAvailabilityIotCronReport: (cronId, payload) => {
    initHeaders();

    return apiIOT.post(`/api/v1/iotreportcrons/${cronId}/toogle/availability`, payload);
  },

	putIotCronReport: (cronId, payload) => {
    initHeaders();

    return apiIOT.put(`/api/v1/iotreportcrons/${cronId}`, payload);
	},

	postAddContactToCronReport: (cronId, payload) => {
		initHeaders();

    return apiIOT.post(`/api/v1/iotreportcrons/${cronId}/contacts`, payload, {
    });
	},

	deleteContactFromCronReport: (cronId, payload) => {
		initHeaders();
		return apiIOT.delete(`/api/v1/iotreportcrons/${cronId}/contacts`, null, {
			headers: { ...apiIOT.headers },
			data: payload
    });
	},
}
