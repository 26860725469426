import { Grid, Typography } from '@material-ui/core'
import OperationBadge from 'app/components/OperationBadge'
import { getLogicalUnitIcon } from 'constants/icons'
import { OPERATION_STATUS } from 'constants/iot'
import React from 'react'
import { kFormatter } from 'utils/stringUtils'
import styles from './styles.module.scss'

function HierarchyTooltip({ tooltipContent }) {
  const renderItem = item => {
    return (
      <div className={styles.tooltipHierarchyRow}>
        <div className={styles.label}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {getLogicalUnitIcon(item.internal_name, styles.icon)}
            <Typography variant="subtitle2">{item.name}</Typography>
          </Grid>
          <Typography variant="subtitle2" className={styles.value}>
            {item.total_green + item.total_red + item.total_yellow}
          </Typography>
        </div>
        {item.total_red > 0 && <OperationBadge type={OPERATION_STATUS.ALERT} label={kFormatter(item.total_red, 2)} />}
      </div>
    )
  }

  return (
    <div
      id="tooltip_hierarchy"
      className={styles.tooltipHierarchy}
      style={{
        left: `${tooltipContent.x}`,
        top: `${tooltipContent.y}`,
        opacity: `${tooltipContent.opacity}`,
        zIndex: `${tooltipContent.zIndex}`,
      }}
    >
      <Typography className={styles.title}>{tooltipContent.hierarchy}</Typography>
      {tooltipContent?.content && (
        <div className={styles.detailContent}>{Object.keys(tooltipContent?.content).map(item => renderItem(tooltipContent?.content[item]))}</div>
      )}
    </div>
  )
}

export default HierarchyTooltip
