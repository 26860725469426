import { useTranslation } from 'react-i18next'

import { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { any, shape } from 'prop-types'

import { Card, Typography } from '@material-ui/core'
import FormatAlignJustifyRoundedIcon from '@material-ui/icons/FormatAlignJustifyRounded'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import { actionCreators as iotDeviceParamActions } from 'redux/iotDeviceParams/actions'

import DeviceParams from './components/Params'

const ParametersContainer = ({
  dispatch,
  deviceSelected,
  userRoles
}) => {
  const [panelType, setpanelType] = useState('detailDeviceParams')
  const [isFirstLoad, setIsFirstLoad] = useState()
  
  const [t] = useTranslation('devices')

  const handlePanelSelection = async (event, newSelection) => {
    if (newSelection) {
      await dispatch(iotDeviceParamActions.cleanDeviceParams()) //disparar
      setpanelType(newSelection)
    }
  }

  useEffect(() => {
    dispatch(iotDeviceParamActions.cleanDeviceParams()) //disparar
    setIsFirstLoad(true)
  }, [])

  useEffect(() => {
    handleRefresh();
  }, [isFirstLoad])

  const handleRefresh = async () => {
    await dispatch(iotDeviceParamActions.getIotDeviceParams(deviceSelected.id))
  }

  return (
    <>
      <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
        <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ToggleButtonGroup size='small' value={panelType} exclusive onChange={handlePanelSelection} style={{ marginRight: 20 }}>
                <ToggleButton value='detailDeviceParams'>
                  <FormatAlignJustifyRoundedIcon fontSize='small'></FormatAlignJustifyRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    Parametros
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </Card>
        {panelType === 'detailDeviceParams' && 
          <DeviceParams
            userRoles={userRoles}
            onRefresh={handleRefresh}
        ></DeviceParams>}
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  deviceSelected: store.iot.iotDeviceDetail
})

ParametersContainer.propTypes = {
  deviceSelected: shape(any)
}

export default connect(mapStateToProps)(memo(ParametersContainer))
