import { Fade, IconButton, makeStyles, Modal } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import { CloseRounded } from '@material-ui/icons'
import FullscreenExitRoundedIcon from '@material-ui/icons/FullscreenExitRounded'
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded'
import { useDispatch } from 'react-redux'
import { actionCreators as iotActions } from 'redux/iotHandlers'

function WTModal(props) {
  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'rgba(66,66,66,0.9)',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 5,
      width: '80%',
      //maxWidth: 1200,
      minHeight: 600,
      height: '80%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    paper_max: {
      backgroundColor: 'rgba(66,66,66,0.9)',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 5,
      width: '95%',
      height: '95%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    close: {
      position: 'absolute',
      right: 25,
      top: 18,
      zIndex: 99,
    },
    fullScreen: {
      position: 'absolute',
      right: 65,
      top: 18,
      zIndex: 99,
    },
  }))

  const classes = useStyles()

  const dispatch = useDispatch()

  const handleClose = () => {
    props.onClose()
    dispatch(iotActions.cleanMeasures())
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
      closeAfterTransition
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={props.fullscreen ? classes.paper_max : classes.paper}>
          <IconButton className={classes.close} aria-label="close" onClick={() => handleClose()}>
            <CloseRounded />
          </IconButton>
          <IconButton className={classes.fullScreen} aria-label="close" onClick={() => props.onFullscreen()}>
            {!props.fullscreen ? <FullscreenRoundedIcon /> : <FullscreenExitRoundedIcon />}
          </IconButton>
          {props.children}
        </div>
      </Fade>
    </Modal>
  )
}

export default WTModal
