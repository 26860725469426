import Immutable from 'seamless-immutable'

import { actions } from './actions'

const defaultState = {
  iothierarchiesLoading: false,
  iothierarchies: [],
  iothierarchiesBrief: {}, //Brief Logical Units status operation
  iothierarchyDetailLoading: false,
  iothierarchyActive: null,
  iothierarchiesUnitsDevice: new Map(), //Hierarchies Brief Logical Units and Device
}

export function reducer(state = Immutable(defaultState), action) {
  switch (action.type) {
    case actions.INIT_HIERARCHY: {
      return Immutable.merge(state, { iothierarchiesLoading: true })
    }
    case actions.INIT_HIERARCHY_DETAIL: {
      return Immutable.merge(state, { iothierarchyDetailLoading: action.payload })
    }
    case actions.INIT_HIERARCHY_LIST_SUCCESS: {
      return Immutable.merge(state, {
        iothierarchies: action.payload,
        iothierarchiesLoading: false,
      })
    }
    case actions.INIT_HIERARCHY_LIST_FAILURE: {
      return Immutable.merge(state, {
        iothierarchies: [],
        iothierarchiesLoading: false,
      })
    }
    case actions.BRIEF_LOGICALUNITS_SUCCESS: {
      return Immutable.merge(state, {
        iothierarchiesBrief: action.payload,
      })
    }
    case actions.BRIEF_LOGICALUNITS_FAILURE: {
      return Immutable.merge(state, {
        iothierarchiesBrief: {},
      })
    }
    case actions.SET_ACTIVE_HIERARCHY: {
      return Immutable.merge(state, {
        iothierarchyActive: action.payload,
      })
    }
    case actions.UNITS_DEVICE_STATS_SUCCESS: {
      return Immutable.merge(state, {
        iothierarchiesUnitsDevice: { ...state.iothierarchiesUnitsDevice.set(action.payload._id, action.payload.unit_device) },
        iothierarchyDetailLoading: false,
      })
    }
    case actions.UNITS_DEVICE_STATS_FAILURE: {
      return Immutable.merge(state, {
        iothierarchyDetailLoading: false,
      })
    }
    default:
      return state
  }
}
