export const hexToRgba = (hex, alpha) => {
	const r = (parseInt(hex.slice(1, 3), 16)).toString()
	const g = (parseInt(hex.slice(3, 5), 16)).toString()
	const b = (parseInt(hex.slice(5, 7), 16)).toString()
	const a = alpha || 1
	return { r, g, b, a }
}

export const rgbToHex = (r, g, b) => {
	const hex = (x) => {
		const hex = x.toString(16);
		return hex.length === 1 ? `0${hex}` : hex;
	};
	return `#${hex(r)}${hex(g)}${hex(b)}`;
}