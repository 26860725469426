import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClearIcon from '@material-ui/icons/Clear'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Skeleton from '@material-ui/lab/Skeleton'
import { Icon } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'

import styles from './styles.module.scss'

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 350,
    marginRight: 20,
    background: '#424242e6',
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  container: {
    display: 'flex',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 7,
    marginLeft: 24,
    marginRight: 12,
    height: 26,
    cursor: 'pointer',
  },
  groupWorkIcon: {
    marginRight: 10,
  },
  groupWorkIconDisabled: {
    marginRight: 10,
    opacity: 0.6,
  },
  searchIcon: {
    marginRight: 10,
    opacity: 0.6,
  },
  clearIcon: {
    cursor: 'pointer',
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    margin: 12,
    background: '#333',
  },
  jerarquias: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'center',
    maxHeight: 180,
    overflowY: 'scroll',
  },
  listItem: {
    paddingLeft: 24,
    paddingRight: 10,
    '&:last-child': {
      marginBottom: 10,
    },
  },
  listItemText: {
    paddingLeft: 5,
    fontSize: 16,
  },
  placeholder: {
    fontWeight: 300,
  },
  noResults: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
  },
  noResultsText: {
    textAlign: 'center',
    opacity: 0.6,
    fontWeight: 200,
  },
  input: {
    flexGrow: 1,
    fontWeight: 500,
  },
  button: {
    height: 40,
    backgroundColor: '#434343',
  },
}))

const HierarchySelectorView = ({ hierarchiesList, activeHierarchy, onHierarchySelect }) => {
  const [t] = useTranslation('selectorJerarquia')
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const input = document.getElementById('inputHierarchy')

  useEffect(() => {
    setFilterHierarchies(hierarchiesList)
  }, [hierarchiesList])

  useEffect(() => {
    if (hierarchiesList?.length > 0) {
      setLoading(false)
    }
  }, [hierarchiesList])

  useEffect(() => {
    if (!activeHierarchy) {
      setExpanded(false)
    }
  }, [activeHierarchy])

  const handleExpandClick = () => {
    if (inputValue.length === 0) setFilterHierarchies(hierarchiesList)
    setExpanded(!expanded)
    setTimeout(() => {
      input?.focus()
    }, 200)
  }

  const deleteAll = () => {
    setInputValue('')
    setFilterHierarchies(hierarchiesList)
  }

  const handleOnChange = e => {
    setInputValue(e.target.value)
    setFilterHierarchies([...hierarchiesList].filter(jerarquia => jerarquia.description.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const handleOnClick = e => {
    setTimeout(() => {
      setExpanded(false)
    }, 1000)
    onHierarchySelect(hierarchiesList.filter(el => el.description === e.target.textContent)[0].id)
    setInputValue('')
    setFilterHierarchies(hierarchiesList)
  }

  const [filterHierarchies, setFilterHierarchies] = useState(hierarchiesList)
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(true)

  return (
    <div className={styles.root}>
      {loading ? (
        <Skeleton variant="rect" width={350} height={44} />
      ) : (
        <Card className={classes.card} elevation={3}>
          <CardContent className={classes.cardContent}>
            <div className={[classes.topBar, styles.unselectable].join(' ')} onClick={() => handleExpandClick()}>
              {!activeHierarchy ? (
                <>
                  <GroupWorkIcon className={classes.groupWorkIconDisabled}></GroupWorkIcon>
                  <Typography className={classes.placeholder} color="textSecondary">
                    {t('select-a-hierarchy')}
                  </Typography>
                </>
              ) : (
                <>
                  <GroupWorkIcon className={classes.groupWorkIcon}></GroupWorkIcon>
                  <Typography color="textPrimary">
                    {hierarchiesList &&
                      hierarchiesList.filter(h => {
                        return h.name === activeHierarchy.name
                      })[0].description}
                  </Typography>
                </>
              )}
              <Icon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </Icon>
            </div>

            <Fade in={expanded}>
              <Divider variant="middle" />
            </Fade>

            <Collapse in={expanded} timeout="auto">
              <Paper elevation={0} className={classes.filter}>
                <SearchIcon className={classes.searchIcon} />
                <InputBase
                  id="inputHierarchy"
                  value={inputValue}
                  className={classes.input}
                  placeholder={t('find')}
                  inputProps={{ 'aria-label': 'Buscar' }}
                  autoComplete={'off'}
                  onChange={e => {
                    handleOnChange(e)
                  }}
                />

                <Fade direction="up" in={inputValue.length > 0}>
                  <ClearIcon className={classes.clearIcon} onClick={() => deleteAll()} />
                </Fade>
              </Paper>
              <div className={classes.jerarquias}>
                {filterHierarchies.length === 0 && inputValue.length > 0 ? (
                  <ListItem className={classes.noResults}>
                    <ListItemText className={classes.noResultsText} primary={t('no-search-results')} />
                  </ListItem>
                ) : null}
                {filterHierarchies &&
                  filterHierarchies.map(jerarquia => {
                    return (
                      <ListItem
                        key={jerarquia.name}
                        className={classes.listItem}
                        onClick={e => handleOnClick(e)}
                        selected={jerarquia.name === activeHierarchy?.name}
                        button
                      >
                        <ListItemText className={classes.listItemText} primary={jerarquia.description} />
                      </ListItem>
                    )
                  })}
              </div>
            </Collapse>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default HierarchySelectorView
