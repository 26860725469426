import { Typography } from '@material-ui/core'
import { CHART_NAMES } from 'constants/iot'
import { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { graphicSeries } from 'services/ioTGraphicService'

import Outage from './Outage_ALL';
import styles from './styles.module.scss'

const Charts = ({ id, typeId, chartName, startDate, endDate }) => {
  const [series1, setSeries1] = useState()
  const [series2, setSeries2] = useState()
  const [series3, setSeries3] = useState()
  const [loading, setLoading] = useState()
  const { width, height, ref } = useResizeDetector()

  const fetchSeries = async (startDate, endDate) => {
    const result = await graphicSeries(typeId, id, { startDate, endDate })

    switch (chartName) {
      case CHART_NAMES.ALL_OUTAGE_ALARMS_EPE:
        setSeries1(result.data)
        break
  
      default:
        break
    }

    setLoading(false)
  }

  useEffect(() => {
    setSeries1(null)
    setSeries2(null)
    setSeries3(null)

    setLoading(true)
    fetchSeries(startDate, endDate)
  }, [typeId, id, startDate, endDate, chartName])

  return (
    <div className={styles.container} ref={ref}>
      {loading ? (
        <div className={styles.loading}>
          <Typography variant="h6">Cargando datos ...</Typography>
        </div>
      ) : (
        <>
          { series1 && chartName === CHART_NAMES.ALL_OUTAGE_ALARMS_EPE ? (
            <Outage series={series1?.serie} filters={series1?.iot_graphic?.profiles} containerHeight={height} containerWidth={width} />
          ) : null}
        </>
      )}
    </div>
  )
}

export default Charts
