import Immutable from 'seamless-immutable'

import { actions } from './actions'

const defaultState = {
  iotlogicalunitType: [], //Types
  iotlogicalunitsTypeLoading: false,
  iotlogicalunits: new Map(), //units view map layer 2 (type Map()): pair (idhierarchy, units)
  iotlogicalunitsLoading: false,
  iotlogicalunitDetailLoading: false,
  iotlogicalunitSelected: null,
  iotlogicalunitchildrenLoading: false
}

export function reducer(state = Immutable(defaultState), action) {
  switch (action.type) {
    case actions.INIT_LOGICALUNITS_TYPE: {
      return Immutable.merge(state, { iotlogicalunitsTypeLoading: true })
    }
    case actions.INIT_LOGICALUNITS_TYPE_SUCCESS: {
      return Immutable.merge(state, {
        iotlogicalunitType: action.payload,
        iotlogicalunitsTypeLoading: false,
      })
    }
    case actions.INIT_LOGICALUNITS_TYPE_FAILURE: {
      return Immutable.merge(state, {
        iotlogicalunitType: [],
        iotlogicalunitsLoading: false,
      })
    }
    case actions.INIT_LOGICALUNITS: {
      return Immutable.merge(state, { iotlogicalunitsLoading: true })
    }
    case actions.INIT_LOGICALUNITS_SUCCESS : {     
      return Immutable.merge(state, {
        iotlogicalunits: {...state.iotlogicalunits.set(action.payload._id, action.payload.logical_units) } ,
        iotlogicalunitsLoading: false,
      })
    }
    case actions.INIT_LOGICALUNITS_FAILURE: {
      return Immutable.merge(state, {
        iotlogicalunitsLoading: false,
      })
    }
    case actions.SET_LOGICALUNIT_SELECTED: {     
      return Immutable.merge(state, {
        iotlogicalunitSelected: action.payload,          
      })
    }
    case actions.CLEAR_LOGICALUNIT_SELECTED: {
      return Immutable.merge(state, {
        iotlogicalunitSelected: null,
      })
    }
    case actions.INIT_LOGICALUNIT_DETAIL: {
      return Immutable.merge(state, {
        iotlogicalunitSelected: null,
        iotlogicalunitDetailLoading: true,
      })
    }
    case actions.END_LOGICALUNIT_DETAIL: {
      return Immutable.merge(state, {
        iotlogicalunitDetailLoading: false,
      })
    }    
    case actions.SET_DEVICE_LOGICALUNIT_CHILDREN: {
      return Immutable.merge(state, {
        iotlogicalunitSelected: { ...state.iotlogicalunitSelected,
                                 devices: state.iotlogicalunitSelected.devices.set(action.payload._id, action.payload.devices) } ,
      })
    }
    case actions.INIT_LOGICALUNIT_CHILDREN: {
      return Immutable.merge(state, {
        iotlogicalunitchildrenLoading: true,
      })
    }
    case actions.END_LOGICALUNIT_CHILDREN: {
      return Immutable.merge(state, {
        iotlogicalunitchildrenLoading: false,
      })
    }
    default:
      return state
  }
}
