export const MTR_CT = {
  groups: [
    {
      code: 'MTR_CT_EACT',
      name: 'Energía activa',
      unit: 'KWH',
      channels: [
        {
          code: 'EACTE',
          label: 'Entregada',
          name: 'Energía activa entregada',
        },
        {
          code: 'EACTR',
          label: 'Recibida',
          name: 'Energía activa recibida',
        },
      ],
    },
    {
      code: 'MTR_CT_EREAC',
      name: 'Energía reactiva',
      unit: 'KVArh',
      channels: [
        {
          code: 'EREACE',
          label: 'Entregada',
          name: 'Energía reactiva entregada',
        },
        {
          code: 'EREACR',
          label: 'Recibida',
          name: 'Energía reactiva recibida',
        },
      ],
    },
    {
      code: 'MTR_CT_PACT',
      name: 'Potencias Activa',
      unit: 'KW',
      channels: [
        {
          code: 'PACT1',
          label: 'Fase 1',
          name: 'Potencia activa de fase 1',
        },
        {
          code: 'PACT2',
          label: 'Fase 2',
          name: 'Potencia activa de fase 2',
        },
        {
          code: 'PACT3',
          label: 'Fase 3',
          name: 'Potencia activa de fase 3',
        },
      ],
    },
    {
      code: 'MTR_CT_PREAC',
      name: 'Potencias Reactiva',
      unit: 'KVA',
      channels: [
        {
          code: 'PRACT1',
          label: 'Fase 1',
          name: 'Potencia reactiva de fase 1',
        },
        {
          code: 'PRACT2',
          label: 'Fase 2',
          name: 'Potencia reactiva de fase 2',
        },
        {
          code: 'PRACT3',
          label: 'Fase 3',
          name: 'Potencia reactiva de fase 3',
        },
      ],
    },
    {
      code: 'MTR_CT_TENSION',
      name: 'Tension de fase',
      unit: 'V',
      channels: [
        {
          code: 'V1N',
          label: 'Fase 1',
          name: 'Tensión de fase 1',
        },
        {
          code: 'V2N',
          label: 'Fase 2',
          name: 'Tensión de fase 2',
        },
        {
          code: 'V3N',
          label: 'Fase 3',
          name: 'Tensión de fase 3',
        },
      ],
    },
    {
      code: 'MTR_CT_I',
      name: 'Corriente de fase',
      unit: 'A',
      channels: [
        {
          code: 'I1',
          label: 'Fase 1',
          name: 'Corriente de fase 1',
        },
        {
          code: 'I2',
          label: 'Fase 2',
          name: 'Corriente de fase 2',
        },
        {
          code: 'I3',
          label: 'Fase 3',
          name: 'Corriente de fase 3',
        },
        {
          code: 'I4',
          label: 'Fase 4',
          name: 'Corriente de fase 4',
        },
        {
          code: 'IN',
          label: 'rms neutro calculada',
          name: 'Corriente rms neutro calculada',
        },
      ],
    },
    /* {
            code: 'MTR_CT_CARGA',
            name: 'Nivel de carga',
            unit: 'kVA',  
            channels: [
                {
                    code: 'PP1',
                    label: 'Activa total',
                    name: 'Potencia activa total'
                },
                {
                    code:'PQ2',
                    label: 'Reactiva total',
                    name: 'Potencia Reactiva total'
                },
                {
                    code:'PP3',
                    label: 'Factor Total',
                    name: 'Factor de potencia Total'
                },
                {
                    code:'PC4',
                    label: 'Carga',
                    name: 'Nivel de carga'
                }
            ]      
        }   */
  ],
}

export const TELEC_PS = {
  groups: [
    {
      code: 'TL_PS_EACT',
      name: 'Energía activa',
      unit: 'kWh',
      channels: [
        {
          code: 'EACTENT',
          label: 'Directa total',
          name: 'Energía activa directa total',
        },
        {
          code: 'EACTREC',
          label: 'Inversa total',
          name: 'Energía activa inversa total',
        },
      ],
    },
    {
      code: 'TL_PS_ERACT',
      name: 'Energía reactiva',
      unit: 'kVArh',
      channels: [
        {
          code: 'EREACTENT',
          label: 'Positiva',
          name: 'Energía reactiva positiva',
        },
        {
          code: 'EREACTREC',
          label: 'Inversa total',
          name: 'Energía reactiva inversa total',
        },
      ],
    },
    {
      code: 'TL_PS_POTMAX',
      name: 'Potencia máxima',
      unit: 'kW',
      channels: [
        {
          code: 'POTMAX',
          label: 'Demanda',
          name: 'Potencia máxima demanda',
        },
      ],
    },
  ],
}

export const TELEC_PS_CON = {
  groups: [
    {
      code: 'TL_PS_EACT',
      name: 'Energía activa',
      unit: 'kWh',
      channels: [
        {
          code: 'EACTENT',
          label: 'Directa total',
          name: 'Energía activa directa total',
        },
        {
          code: 'EACTREC',
          label: 'Inversa total',
          name: 'Energía activa inversa total',
        },
      ],
    },
    {
      code: 'TL_PS_ERACT',
      name: 'Energía reactiva',
      unit: 'kVArh',
      channels: [
        {
          code: 'EREACTENT',
          label: 'Positiva',
          name: 'Energía reactiva positiva',
        },
        {
          code: 'EREACTREC',
          label: 'Inversa total',
          name: 'Energía reactiva inversa total',
        },
      ],
    },
    {
      code: 'TL_PS_POTMAX',
      name: 'Potencia máxima',
      unit: 'kW',
      channels: [
        {
          code: 'POTMAX',
          label: 'Demanda',
          name: 'Potencia máxima demanda',
        },
      ],
    },
  ],
}

export const TELEC_PS_SLAVE = {
  groups: [
    {
      code: 'TL_PS_EACT',
      name: 'Energía activa',
      unit: 'kWh',
      channels: [
        {
          code: 'EACTENT',
          label: 'Directa total',
          name: 'Energía activa directa total',
        },
        {
          code: 'EACTREC',
          label: 'Inversa total',
          name: 'Energía activa inversa total',
        },
      ],
    },
    {
      code: 'MTR_CHART04',
      name: 'Potencia Media',
      unit: 'W',
      channels: [
        {
          code: 'EREACTENT',
          label: 'Positiva',
          name: 'Energía reactiva positiva',
        },
        {
          code: 'EREACTREC',
          label: 'Inversa total',
          name: 'Energía reactiva inversa total',
        },
      ],
    },
    {
      code: 'TL_PS_ERACT',
      name: 'Energía reactiva',
      unit: 'kVArh',
      channels: [
        {
          code: 'EREACTENT',
          label: 'Positiva',
          name: 'Energía reactiva positiva',
        },
        {
          code: 'EREACTREC',
          label: 'Inversa total',
          name: 'Energía reactiva inversa total',
        },
      ],
    },
    {
      code: 'TL_PS_POTMAX',
      name: 'Potencia máxima',
      unit: 'kW',
      channels: [
        {
          code: 'POTMAX',
          label: 'Demanda',
          name: 'Potencia máxima demanda',
        },
      ],
    },
  ],
}
