/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { getIn } from 'seamless-immutable'
import { bool, string, arrayOf, func } from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Tabs, Tab, makeStyles, Card, Grid, IconButton } from '@material-ui/core'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import styles from './styles'
import { deviceComponentType } from './types'
import DeviceComponentDetailContainer from './components/deviceComponentDetail'
import TuneIcon from '@material-ui/icons/Tune'
import { Skeleton } from '@material-ui/lab'
import DeviceComponentParamsFirmwareContainer from './components/deviceComponentParamsFirmware'
import DeviceComponentParamsApplicationContainer from './components/deviceComponentParamsApplication'
import { CloseRounded } from '@material-ui/icons'

const DeviceComponentConfiguration = ({
  open,
  deviceComponent,
  handleClose,
  dispatch,
  userRoles,
  iotDeviceComponentDetailLoading,
  iotDeviceComponentDetail,
}) => {
  const [activeTab, setActiveTab] = useState(0)

  const useStyles = makeStyles(theme => ({
    close: {
      position: 'absolute',
      right: 15,
      top: 8,
      zIndex: 99,
    },
  }))

  const classes = useStyles()

  // Efect to get detail of the deviceComponent
  useEffect(() => {
    if (open && deviceComponent) dispatch(iotActions.getIotDeviceConponentDetail(deviceComponent.id))
  }, [deviceComponent])

  const handleRefreshDeviceComponent = useCallback(() => {
    if (open) dispatch(iotActions.getIotDeviceConponentDetail(deviceComponent.id))
  }, [deviceComponent])

  const handleSaveDeviceComponentParams = paramsToUpdate => {
    dispatch(iotActions.putIotDeviceComponentParams(deviceComponent.id, paramsToUpdate))
  }

  const handleSaveDeviceComponentParamsLocal = paramsToUpdate => {
    dispatch(iotActions.putIotDeviceComponentParamsLocal(deviceComponent.id, paramsToUpdate))
  }

  const handleRefreshOnlineDeviceComponentParams = () => {
    dispatch(iotActions.getIotDeviceComponentParamsOnline(deviceComponent.id))
  }

  const handleSyncTimestampOnlineDevice = () => {
    dispatch(iotActions.postSyncTimestampIotDeviceOnline(iotDeviceComponentDetail.device_id))
  }

  const handleRestartOnlineDevice = () => {
    dispatch(iotActions.postRestartIotDeviceOnline(iotDeviceComponentDetail.device_id))
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll='paper' fullWidth={true} maxWidth='lg' disableEscapeKeyDown>
      <DialogTitle id='responsive-dialog-title'>
        <Grid container justifyContent='flex-start' alignItems='center'>
          {/* <SettingsIcon style={{ marginRight: 10 }} /> */}
          <span>Configuración de componente</span>
        </Grid>
        <IconButton className={classes.close} aria-label='close' onClick={() => handleClose()}>
          <CloseRounded />
        </IconButton>
      </DialogTitle>
      <DialogContent style={styles.dialogContent} dividers={true}>
        <Card style={{ padding: 20 }}>
          <DeviceComponentDetailContainer
            loading={iotDeviceComponentDetailLoading}
            onRefreshOnline={handleRefreshOnlineDeviceComponentParams}
          ></DeviceComponentDetailContainer>
        </Card>

        <>
          <Tabs
            value={activeTab}
            className={styles.tabs}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            style={{ marginTop: 20 }}
          >
            <Tab
              disabled={false}
              label={
                <Grid container justifyContent='center' alignItems='center'>
                  <TuneIcon fontSize='small' style={{ marginRight: 10 }} />
                  <span>Firmware</span>
                </Grid>
              }
            />
            <Tab
              disabled={false}
              label={
                <Grid container justifyContent='center' alignItems='center'>
                  <TuneIcon fontSize='small' style={{ marginRight: 10 }} />
                  <span>Aplicación</span>
                </Grid>
              }
            />
          </Tabs>
          {activeTab === 0 && (
            <div style={{ height: 400, paddingTop: 20 }}>
              {!iotDeviceComponentDetailLoading ? (
                <DeviceComponentParamsFirmwareContainer
                  userRoles={userRoles}
                  onRefresh={handleRefreshDeviceComponent}
                  onSaveParams={handleSaveDeviceComponentParams}
                  onRefreshOnline={handleRefreshOnlineDeviceComponentParams}
                  onSyncTimestampOnline={handleSyncTimestampOnlineDevice}
                  onRestartOnline={handleRestartOnlineDevice}
                ></DeviceComponentParamsFirmwareContainer>
              ) : (
                <div style={{ opacity: 0.7 }}>
                  <Skeleton animation='wave' style={{ height: 45, opacity: 0.7 }} />
                  <Skeleton animation='wave' variant='rect' style={{ height: 250, opacity: 0.7 }} />
                </div>
              )}
            </div>
          )}
          {activeTab === 1 && (
            <div style={{ height: 400, paddingTop: 20 }}>
              {!iotDeviceComponentDetailLoading ? (
                <DeviceComponentParamsApplicationContainer
                  userRoles={userRoles}
                  onRefresh={handleRefreshDeviceComponent}
                  onSaveParams={handleSaveDeviceComponentParamsLocal}
                ></DeviceComponentParamsApplicationContainer>
              ) : (
                <div>
                  <Skeleton animation='wave' style={{ height: 45, opacity: 0.7 }} />
                  <Skeleton animation='wave' variant='rect' style={{ height: 250, opacity: 0.7 }} />
                </div>
              )}
            </div>
          )}
        </>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} variant="secondary">
          Cerrar
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

DeviceComponentConfiguration.propTypes = {
  open: bool.isRequired,
  iotDeviceComponentDetailLoading: bool,
  iotDeviceComponentDetail: deviceComponentType,
  userRoles: arrayOf(string),
  deviceComponent: deviceComponentType,
  handleClose: func,
}

const mapStateToProps = store => ({
  token: store.auth.authInformation.token,
  userName: store.auth.authInformation.preferredUsername,
  companyId: getIn(store, ['auth', 'authInformation', 'idTokenParsed', 'companyId']),
  iotDeviceComponentDetail: store.iot && store.iot.iotDeviceComponentDetail,
  iotDeviceComponentDetailLoading: store.iot && store.iot.iotDeviceComponentDetailLoading,
})

export default connect(mapStateToProps)(DeviceComponentConfiguration)
