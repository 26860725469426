import { useEffect } from 'react';

import { ROUTES } from 'constants/routes';
import { useSelector } from 'react-redux';

function Contact() {

  const authInformation = useSelector((state) => state.auth.authInformation);
  
  useEffect(() => {
    if (authInformation) {
      const url = `${ROUTES.IOT_ADMIN_ADMINBRO_EXTERNAL}?token=${authInformation.token}`;
      window.location.href = url;
    }
  }, [authInformation]);

  return (
    <div></div>
  );
}

export default Contact;