/* eslint-disable react-hooks/exhaustive-deps */
import { TableCell, TableRow } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ErrorBadge from 'app/components/ErrorBadge'
import { ERROR_TYPE, OPERATION_STATUS } from 'constants/iot'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import { any, bool, number, shape } from 'prop-types'
import { memo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import MeasureGrid from './components/MeasureGrid'
import { DEFAULT_PAGE_NUMBER, pageSizeOptions } from './constants'
import styles from './styles.module.scss'

const {ALERT, WARNING } = OPERATION_STATUS
const { VALIDATION, INVALID, TIMESTAMP } = ERROR_TYPE

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}

const MeasuresTableContainer = ({ filterValues, dispatch, deviceSelected, loading, measures, totalMeasures, companyId }) => {
  const [tableData, setTableData] = useState([])
  const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER)
  const [isFirstRun, setIsFirstRun] = useState(true)

  useEffect(() => {
    if (measures) {
      if (isFirstRun && measures[`1`]) {
        setIsFirstRun(false)
      }
      setTableData(measures[`${currentPage}`])
    }
  }, [measures])

  const getMeasures = useCallback(() => {
    dispatch(iotActions.getIotDeviceMeasurements(currentPage, pageSize, filterValues, false))
  })

  useEffect(() => {
    if (measures && !measures[`${currentPage}`]) {
      getMeasures()
    } else {
      setTableData(measures[`${currentPage}`])
    }
  }, [currentPage])

  const handleChangePage = (newPage, pageSizeToSet) => {
    setCurrentPage(newPage)
    if (pageSizeToSet) setPageSize(pageSizeToSet)
  }

  useEffect(() => {
    if (filterValues) {
      setIsFirstRun(true)
      const firstPage = 1
      dispatch(iotActions.cleanMeasures())
      setCurrentPage(firstPage)
      dispatch(iotActions.getIotDeviceMeasurements(firstPage, pageSize, filterValues, false))
    }
  }, [filterValues])

  useEffect(() => {
    return () => {
      dispatch(iotActions.cleanMeasures())
    }
  }, [])

  const columns = [
    {
      name: 'timestamp',
      label: 'Mediciones por fecha',
      options: {
        isRowSelectable: false,
        filter: false,
        display: 'true',
        customBodyRender: (time, data) => {
          const index = data.rowIndex
          const validation = tableData[index].error_validation
          const invalid = tableData[index].is_payload_invalid
          const timestamp = tableData[index].error_timestamp_device
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
              <span>{moment(time).format('DD-MM-YYYY, HH:mm')}</span>
              <div>
                {validation && <ErrorBadge type={VALIDATION} />}
                {invalid && <ErrorBadge type={INVALID} />}
                {timestamp && <ErrorBadge type={TIMESTAMP} />}
              </div>
            </div>
          )
        },
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    customToolbar: null,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyMaxHeight: '400px',
    download: false,
    viewColumns: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    serverSide: true,
    count: totalMeasures,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [],
    setRowProps: (row, index) => {
      let classN
      const status = tableData[index]?.status
      if (tableData[index]) classN = status === ALERT ? styles.alert : status === WARNING && styles.warning
      return {
        className: classN,
      }
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page + 1, null)
          break
        case 'sort':
          break
        default:
      }
    },

    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1
      return (
        <TableRow style={{ backgroundColor: '#292929' }}>
          <TableCell style={{ padding: 0 }} colSpan={colSpan}>
            {
              <MeasureGrid
                info={{ primaryData: tableData[rowMeta.dataIndex].measure_values_base, secondaryData: tableData[rowMeta.dataIndex].measure_values, validationData: tableData[rowMeta.dataIndex].validation_data }}
              />
            }
          </TableCell>
        </TableRow>
      )
    },

    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordenar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
   
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      {loading && isFirstRun ? (
        renderSkeleton()
      ) : (
        <>
          <MUIDataTable
            data={tableData}
            columns={columns}
            options={options}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = store => ({
  deviceSelected: store.iot.iotDeviceDetail,
  measures: store.iot.iotDeviceMeasurements && store.iot.iotDeviceMeasurements.rows,
  totalMeasures: store.iot.iotDeviceMeasurements && store.iot.iotDeviceMeasurements.total,
  loading: store.iot.iotDeviceMeasurementsLoading,
  companyId: store.auth.authInformation.companyId,
})

MeasuresTableContainer.propTypes = {
  deviceSelected: shape(any),
  measures: shape(any),
  loading: bool,
  totalMeasures: number,
  companyId: number,
  filterValues: shape(any),
}

export default connect(mapStateToProps)(memo(MeasuresTableContainer))
