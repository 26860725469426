/*PARA INTEGRACION CON IOTHANDLER*/

/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* import { onlyNumbers, normalizeAll, onlyLetters, normalizeDate } from '@widergy/web-utils/lib/normalize'; */
import i18n from 'i18next';
/* import { FIELD_TEXT, FIELD_SELECT } from '@widergy/utilitygo-forms-web/dist/constants/fieldTypes'; */
/* import { isEmpty } from '@widergy/web-utils/lib/array';
import { validDate, required } from '@widergy/web-utils/lib/validate'; */

import {
  CUSTOM_PROP_TYPES,
  COMPONENT_ORDER_IN_FORM,
  componentFieldsBase,
  FIRMWARE,
  VERSION,
  COMPONENT,
  BADGE_NUMBER_KEY
} from 'constants/iot';

const { TEXT, NUMBER, SELECT, DATE } = CUSTOM_PROP_TYPES;

export const getCustomPropKey = (propItem, nestedPropType = {}) =>
  `${nestedPropType.name || propItem.name}${nestedPropType.id || propItem.id}`;

export const getConfigKey = configItem => `${configItem.name}`;

export const getComponentKey = (baseItem, compItem) => `${baseItem.key}${compItem.id}`;

export const generateComponentField = (pos, compItem) => {
  const position = pos + 1;
  return componentFieldsBase.map((elem, ind) => ({
    ...elem,
    id: parseInt(`${position}${ind}`, 10),
    key: getComponentKey(elem, compItem),
    order: COMPONENT_ORDER_IN_FORM + position
    // TODO tooltip not displaying properly in form
    // tooltip: elem.key.includes(COMPONENT) ? compItem.iot_component.description : null
  }));
};

export const getComponentValue = (compItem, key) => {
  if (key.includes(COMPONENT)) return compItem[COMPONENT].name;
  if (key.includes(VERSION)) return compItem[VERSION].name;
  if (key.includes(FIRMWARE)) return compItem[FIRMWARE].version;
  if (key.includes(BADGE_NUMBER_KEY)) return compItem[BADGE_NUMBER_KEY];
};

const defaultFieldInfo = {
  normalizer: [],
  validator: [],
  placeholder: ''
};

const getFieldType = type => (type === SELECT ? FIELD_SELECT : FIELD_TEXT);

const restrictionsFieldInfo = {
  [TEXT]: {
    normalizer: [], //[onlyLetters],
    validator: [],
    placeholder: i18n.t('IoT:justText'),
    read_only: true
  },
  [NUMBER]: {
    normalizer: [], //[onlyNumbers],
    validator: [],
    placeholder: i18n.t('IoT:justNumbers'),
    read_only: true
  },
  [SELECT]: defaultFieldInfo,
  [DATE]: {
    validator: [], //[validDate(i18n.t('Validations:invalidDate'))],
    placeholder: i18n.t('IoT:justDate_DDMMYYY'),
    normalizer: [], // [normalizeDate],
    read_only: true
  }
};

export const generateCustomPropField = (userRoles, pos, propItem) => {
  const position = pos + 1;
  const key = getCustomPropKey(propItem, propItem.iot_props_type);
  const { type, name, iot_props_options, iot_props_type = {} } = propItem;
  const { placeholder, normalizer, validator } =
    restrictionsFieldInfo[type] || restrictionsFieldInfo[iot_props_type.type] || defaultFieldInfo;

  const propType = type || iot_props_type.type;

  const options = iot_props_options || iot_props_type.iot_props_options;

  return {
    id: position,
    type: getFieldType(propType),
    normalize: normalizeAll(normalizer),
    validate: validator,
    key,
    order: position,
    placeholder,
    title: iot_props_type.name || name,
    field_options: [], //!isEmpty(options) ? options : [],
    read_only: [], //!validateAccess(userRoles, roles.IOT_MTR_CT_PROPS_ACTUALIZAR)
  };
};

export const generateConfigField = (pos, configItem) => {
  const position = pos + 1;
  const key = getConfigKey(configItem);
  const { type, name, options } = configItem;

  const { title, normalizer, validator } = restrictionsFieldInfo[type] || defaultFieldInfo;

  return {
    id: position,
    type: getFieldType(type),
    normalize: normalizeAll(normalizer),
    // Config values are always required unlike config props
    validate: [...validator, required(i18n.t('Validations:required'))],
    key,
    order: position,
    placeholder: name,
    title: title || name,
    field_options: !isEmpty(options) ? options : []
  };
};

export const getMappedComponentInputValues = (formInputValues, deviceComponents) => {
  // Mapping values of badge_number-component_id- (e.g badge_number15) to an object
  // with structure { component_id: badge_number value }
  const compIdsWithSerialBadgesValues = Object.assign(
    {},
    ...Object.keys(formInputValues)
      .filter(fieldKeys => fieldKeys.includes(BADGE_NUMBER_KEY))
      .map(key => ({
        [key.replace(/^\D+/g, '')]: parseInt(formInputValues[key], 10)
      }))
  );

  return deviceComponents.map(compElem => {
    const {
      id,
      component_id,
      firmware_id: compFirmwareId,
      component_version_id,
      isMain,
      description,
      serial_number,
      version,
      pending_firm_update
    } = compElem;

    return {
      id,
      component_id,
      firmware_id: compFirmwareId,
      component_version_id,
      isMain,
      description,
      serial_number,
      version,
      pending_firm_update,
      [BADGE_NUMBER_KEY]: compIdsWithSerialBadgesValues[compElem.id]
    };
  });
};

export const getMappedCustomPropsInputValues = (inputValues, customProps) => {
  const inputValuesKeys = Object.keys(inputValues);
  return (
    customProps
      // Filter because not mandatory sent everyone
      .filter(customProp => inputValuesKeys.includes(getCustomPropKey(customProp, customProp.iot_props_type)))
      .map(customProp => ({
        prop_type_id: customProp.id || (customProp.iot_props_type && customProp.iot_props_type.id),
        value_info: { value: inputValues[getCustomPropKey(customProp, customProp.iot_props_type)] }
      }))
  );
};

export const getMappedConfigInputValues = (inputValues, configRegister) => {
  const configValues = configRegister.config.map(configItem => ({
    ...configItem,
    value: inputValues[getConfigKey(configItem)]
  }));

  return {
    id: configRegister.id,
    config: configValues
  };
};

// a function that receives a cron with 7 fields and returns a cron with 5 fields, removing first and last
const cronFormatterFromSevenFieldsToFiveFields = (sevenFieldsCron) => {
		const cronArray = sevenFieldsCron.split(' ')
		const fiveFieldsCron = `${cronArray[1]} ${cronArray[2]} ${cronArray[3]} ${cronArray[4]} ${cronArray[5]}`
		return fiveFieldsCron
}

// a function that receives a cron with 5 fields and returns a cron with 7 fields, adding first a 0 and last an *
const cronFormatterFromFiveFieldsToSevenFields = (fiveFieldsCron) => {
		const cronArray = fiveFieldsCron.split(' ')
		const sevenFieldsCron = `0 ${cronArray[0]} ${cronArray[1]} ${cronArray[2]} ${cronArray[3]} ${cronArray[4]} *`
		return sevenFieldsCron
}

const cronFormatterDaysOfWeekFromNumbersToLetters = (fiveFieldsCron) => {
	const daysOfWeekMap = {
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
    7: 'SUN'
  };

	const isNotAllIfCases = (field) => field !== '*' && field !== '?' && !field.includes('-') && !field.includes(',');
  const fields = fiveFieldsCron.split(' ');
  if(fields[4] === '*') fields[4] = '*';
	if (fields[4] === '?') fields[4] = '*';
	if (fields[4].includes('-')) fields[4] = fields[4].split('-').map(day => daysOfWeekMap[day]).join('-');
	if(fields[4].includes(',')) fields[4] = fields[4].split(',').map(day => daysOfWeekMap[day]).join(',');
	if(isNotAllIfCases(fields[4])) fields[4] = daysOfWeekMap[fields[4]];
  return fields.join(' ').trim();
}

const cronFormatterDaysOfWeekFromLettersToNumbers = (sevenFieldsCron) => {
	const daysOfWeekMap = {
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
    SUN: 7
  };

	const isNotAllIfCases = (field) => field !== '*' && field !== '?' && !field.includes('-') && !field.includes(',');
	const fields = sevenFieldsCron.split(' ');
  if(fields[4] === '*') fields[4] = '*';
	if (fields[5] === '?') fields[5] = '*';
	if (fields[5].includes('-')) fields[5] = fields[5].split('-').map(day => daysOfWeekMap[day]).join('-');
	if(fields[5].includes(',')) fields[5] = fields[5].split(',').map(day => daysOfWeekMap[day]).join(',');
	if(isNotAllIfCases(fields[5])) fields[5] = daysOfWeekMap[fields[5]];
  return fields.join(' ').trim();
}

const cronFormatterReplaceQuestionMarksWithAsterisks = (cron) => {
		return cron.replace(/\?/g, '*')
}

const cronFormatterReplaceAsteriskWithQuestionMarkOnFieldNumberFour = (cron) => {
	const fields = cron.split(' ');
		if(fields[3] === '*') fields[3] = '?';
		return fields.join(' ').trim();
}

const cronFormatterReplaceHoursFieldWithTwoDigitsHour = (cron) => {
	const fields = cron.split(' ');
	if (fields[1].length === 1) fields[1] = `0${fields[1]}`;
	return fields.join(' ').trim();
}

export const cronFormatterFromBackToFront = (cron) => {
	return cronFormatterReplaceAsteriskWithQuestionMarkOnFieldNumberFour(cronFormatterFromFiveFieldsToSevenFields(cronFormatterDaysOfWeekFromNumbersToLetters(cronFormatterReplaceHoursFieldWithTwoDigitsHour(cron))))
}

export const cronFormatterFromFrontToBack = (cron) => {
	return cronFormatterReplaceQuestionMarksWithAsterisks(cronFormatterFromSevenFieldsToFiveFields(cronFormatterDaysOfWeekFromLettersToNumbers(cron)))
}
