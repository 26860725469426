import React from 'react'
import { Tooltip, Typography } from '@material-ui/core'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { ERROR_TYPE } from 'constants/iot'
import { TYPE_ERROR_VALIDATION, TYPE_ERROR_INVALID, TYPE_ERROR_TIMESTAMP } from 'constants/icons'

const {VALIDATION, INVALID, TIMESTAMP} = ERROR_TYPE

function ErrorBadge({ type, includeLabel=true, includeTooltip=false, ...props }) {
  const [t] = useTranslation('devices')

  const getDescription = (type) => {
    return type === VALIDATION ? t('validation') : type === INVALID ? t('invalid') : t('timestamp')
  }

  const getIcon = (type) => {
    if(type === VALIDATION) return (<span><TYPE_ERROR_VALIDATION className={styles.icon} /></span>);

    if(type === INVALID) return (<span><TYPE_ERROR_INVALID className={styles.icon} /></span>);
    
    if(type === TIMESTAMP) return (<span><TYPE_ERROR_TIMESTAMP className={styles.icon} /></span>);

    return (<></>);
  }



  return (
    <div className={styles.root} {...props}>
      <div className={styles.container}>
        <>
        {includeTooltip && (
            <span>
            <Tooltip title={getDescription(type)}>{getIcon(type)}</Tooltip>
            {includeLabel && 
              <Typography className={styles.label} variant="subtitle2">{getDescription(type)}</Typography>
            }
            </span>
        )}
        {!includeTooltip && (
          <>
            {getIcon(type)}
            {includeLabel && 
              <Typography className={styles.label} variant="subtitle2">{getDescription(type)}</Typography>
            }
          </>
        )}
        </>
      </div>
    </div>
  )
}

export default ErrorBadge
