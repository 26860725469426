import HierarchySelectorView from './view'

const HierarchySelector = ({ hierarchiesList, activeHierarchy, onHierarchySelect }) => {
  return (
    <HierarchySelectorView
      hierarchiesList={hierarchiesList}
      activeHierarchy={activeHierarchy}
      onHierarchySelect={onHierarchySelect}
    ></HierarchySelectorView>
  )
}

export default HierarchySelector
