import { IconLayer } from '@deck.gl/layers'
import DeckGL from '@deck.gl/react';
import { StaticMap } from 'react-map-gl';

import { UTILITY_DEFAULT_MAP_LOCATION } from 'constants/utility'
import { mapboxConf } from 'config/mapboxConfig'
import { extractGeoInformation } from 'utils/geoUtils';

const { mapboxAccessToken, mapboxMapStyle } = mapboxConf()

const latitudeDefault = UTILITY_DEFAULT_MAP_LOCATION[0]
const longitudeDefault = UTILITY_DEFAULT_MAP_LOCATION[1]
const zoomDefault = 15

const getIconMarker = (logicalUnit, zoom) => {
	const { 
		status_operation: statusOperation = 'G', 
		iot_logical_unit_type: logicalUnitType
	} = logicalUnit || {};
	const { internal_name: logicalUnitTypeName = 'CT' } = logicalUnitType;

	return `marker-${statusOperation}-${logicalUnitTypeName}`;
}

const getIconSize = (logicalUnit, zoom) => {
	const { 
		status_operation: statusOperation = 'G', 
	} = logicalUnit || {};

	let size;

	if (statusOperation === 'R') {
	  if (zoom < 18) {
		if (zoom <= 12) {
			size = 3
		} else if (zoom < 17) {
			size = 7.5
		} else {
			size = 15
		}
	  } else {
		if (zoom < 18.5) {
			size = 30
		} else if (zoom < 19) {
			size = 40
		} else if (zoom < 19.5) {
			size = 60
		} else {
			size = 70
		}
	  }
	} else {
	  if (zoom < 18) {
		if (zoom <= 12) {
			size = 2
		} else if (zoom < 17) {
			size = 5
		} else {
			size = 10
		}
	  } else {
		if (zoom < 18.5) {
			size = 16.5
		} else if (zoom < 19) {
			size = 22
		} else if (zoom < 19.5) {
			size = 33
		} else {
			size = 38.5
		}
	  }
	}
	return size;
}

const getCoordinates = (logicalUnit) => {
	const { 
		geo_position: geoPosition = {}
	} = logicalUnit || {};
	const geoPositionInfo = extractGeoInformation(geoPosition)
	const { latitude, longitude } = geoPositionInfo;

	return [longitude, latitude];
}

export const MapLogicalUnit = ({logicalUnitParam, zoomParam}) => {
	const coordinates = getCoordinates(logicalUnitParam);
	const lng = coordinates[0] || longitudeDefault;
	const lat = coordinates[1] || latitudeDefault;

	const iconLayer = new IconLayer({
		id: 'icon-layer',
		data: [logicalUnitParam],
		pickable: true,
		iconAtlas: '/mapSprites/logical-units-icon-atlas.png',
		iconMapping: '/mapSprites/logical-units-icon-mapping.json',
		getIcon: d => getIconMarker(d, zoomParam),
		sizeScale: 5,
		getPosition: d => [lng, lat],
		getSize: d => getIconSize(d, zoomParam),
		//getColor: d => [Math.sqrt(4214), 140, 0]
		getColor: d => [0, 191, 255]
	});

	return (
		<>
			{logicalUnitParam && (
				<DeckGL
					initialViewState={{
						longitude: lng,
						latitude: lat,
						zoom: zoomParam || zoomDefault
					}}
					controller={true} // allows the user to move the map around
					layers={[iconLayer]}
				>
				<StaticMap
					mapStyle={mapboxMapStyle}
					mapboxApiAccessToken={mapboxAccessToken}
				/>
				</DeckGL>
			)};
		</>
	);
}
