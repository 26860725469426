import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import styles from './styles.module.scss'
import Tooltip from '@material-ui/core/Tooltip'
import PercentageBar from './PercentageBar/view'
import { useTranslation } from 'react-i18next'
import { DEVICE } from 'constants/icons'
import { kFormatter } from 'utils/stringUtils'
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded'
import { getDeviceIcon } from 'constants/icons'

const Devices = props => {
  const { data, children, value, index, ...other } = props

  const [t] = useTranslation('devices')

  let total = 0
  data &&
    data.forEach(element => {
      total += element.total_green
      total += element.total_yellow
      total += element.total_red
    })

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && total === 0 && (
        <div className={[styles.panelContainer, styles.devices].join(' ')}>
          <div className={styles.noResults}>
            <NotInterestedRoundedIcon className={styles.noResultsIcon}></NotInterestedRoundedIcon>
            <Typography className={styles.noResultsText}>{t('no-data-available')}</Typography>
          </div>
        </div>
      )}
      {value === index && total !== 0 && (
        <div className={[styles.panelContainer, styles.devices].join(' ')}>
          <div className={styles.dataRow} style={{ marginBottom: 25 }}>
            <DEVICE className={styles.mainIcon}></DEVICE>
            <div className={styles.fontTitleNumber}>{kFormatter(total, 2)}</div>
            <div className={styles.fontTitle}>{t('total-devices')}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.fontSubTitle} style={{ minWidth: 230 }}>
              {t('type-totals')}
            </div>
            <div className={styles.fontSubTitle}>{t('operation-status')}</div>
          </div>

          <div className={styles.detail}>
            {data &&
              data.map(el => (
                <div key={el.internal_name} className={styles.detailRow}>
                  <div className={styles.detailRowFirst}>
                    {getDeviceIcon(el.internal_name, styles.typeIcon)}
                    <Tooltip className={styles.tooltip} title={el.device_type_description} placement='top-start'>
                      <div className={[styles.typeText, styles.noFilter].join(' ')}>{el.name.replaceAll('_', ' ')}</div>
                    </Tooltip>
                    <div className={styles.typeNumber}>
                      <span className={styles.typeValue}>{kFormatter(el.total_green + el.total_yellow + el.total_red, 2)}</span>
                      <span className={styles.typeUnit}> unid.</span>
                    </div>
                  </div>
                  <PercentageBar alert={el.total_red} warning={el.total_yellow} correct={el.total_green} />
                </div>
              ))}
          </div>
          {/* <Divider light />
          <div className={styles.alertTypes}>
            <div className={styles.alertTypesRow}>
              <OperationBadge type={OPERATION_STATUS.ALERT} />
              <Typography variant="body2" className={styles.operationStatusTypo}>
                {t('alert')}
              </Typography>
            </div>
            <div className={styles.alertTypesRow}>
              <OperationBadge type={OPERATION_STATUS.WARNING} />
              <Typography variant="body2" className={styles.operationStatusTypo}>
                {t('warning')}
              </Typography>
            </div>
            <div className={styles.alertTypesRow}>
              <OperationBadge type={OPERATION_STATUS.OK} />
              <Typography variant="body2" className={styles.operationStatusTypo}>
                {t('ok')}
              </Typography>
            </div>
          </div> */}
        </div>
      )}
    </div>
  )
}

Devices.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default Devices
