import { Button, Grid, Tooltip, Typography } from '@material-ui/core'
import RoomRoundedIcon from '@material-ui/icons/RoomRounded'
import OperationBadge from 'app/components/OperationBadge'
import OperationStatus from 'app/components/OperationStatus'
import { getDeviceIcon, LOGICAL_UNIT_MTR } from 'constants/icons'
import moment from 'moment'

import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded'
import SettingsInputAntennaRoundedIcon from '@material-ui/icons/SettingsInputAntennaRounded'
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded'
import Skeleton from '@material-ui/lab/Skeleton'
import { OPERATION_STATUS } from 'constants/iot'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styles from '../styles.module.scss'

const MtrDetailView = ({ logicalUnit, viewDetail, onDeviceSelect }) => {
  const [t] = useTranslation('logicUnitDetail')

  const iotLogicalUnitDetailLoading = useSelector(state => state.iotLogicalUnit.iotlogicalunitDetailLoading)
  const id = logicalUnit.logical_unit.id
  const deviceId = logicalUnit.devices[id] && logicalUnit.devices[id][0].iot_device.id
  const deviceType = logicalUnit.devices[id] && logicalUnit.devices[id][0].iot_device.iot_type.name
  const deviceTypeName = logicalUnit.devices[id] && logicalUnit.devices[id][0].iot_device.iot_type.description
  const devuid = logicalUnit.devices[id] && logicalUnit.devices[id][0].iot_device.devuid
  const lastConnected = logicalUnit.devices[id] && logicalUnit.devices[id][0].iot_device?.iot_device_brief?.last_signal
  const lastStatus = logicalUnit.devices[id] && logicalUnit.devices[id][0].iot_device?.iot_device_brief?.iot_payload_ul?.timestamp
  const alertType = logicalUnit.devices[id] && logicalUnit.devices[id][0].iot_device?.status_operation
  const measureValues =
    logicalUnit.devices[id] &&
    logicalUnit?.devices[id][0]?.iot_device.iot_device_brief?.iot_payload_ul?.measure_values?.length > 0 &&
    logicalUnit.devices[id][0].iot_device.iot_device_brief.iot_payload_ul.measure_values
  const brief = logicalUnit.devices[id] && logicalUnit.devices[id][0]?.iot_device?.iot_device_brief
  const description = logicalUnit.devices[id] && logicalUnit?.devices[id][0]?.iot_device.iot_device_brief?.iot_alarm?.description
  const status = logicalUnit.devices[id] && logicalUnit?.devices[id][0]?.iot_device?.iot_device_brief?.iot_alarm?.status
  const alarmDate = logicalUnit.devices[id] && logicalUnit?.devices[id][0]?.iot_device?.iot_device_brief?.iot_alarm?.executedAt

  return (
    <>
      <div className={styles.detailWrapper}>
        <div className={styles.detailHeader}>
          <div className={styles.titleBlock}>
            <LOGICAL_UNIT_MTR className={styles.iconType} />
            <Typography className={styles.title}>{logicalUnit.logical_unit.iot_logical_unit_type.description}</Typography>
          </div>

          <div className={styles.addressBlock}>
            <Typography className={styles.subtitleId}>{logicalUnit.logical_unit.external_id}</Typography>
            <Typography className={styles.subtitleName}>{logicalUnit.logical_unit.external_name}</Typography>

            <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ marginBottom: 20, opacity: 0.8 }}>
              <RoomRoundedIcon style={{ marginRight: 4, height: 18, width: 18 }}></RoomRoundedIcon>
              <Typography className={styles.subtitleAddress}>
                {logicalUnit.logical_unit.geo_position?.geografic_info.address
                  ? `${logicalUnit.logical_unit.geo_position?.geografic_info.address}, `
                  : ''}
                {logicalUnit.logical_unit.geo_position?.geografic_info.city ? `${logicalUnit.logical_unit.geo_position?.geografic_info.city}, ` : ''}
                {logicalUnit.logical_unit.geo_position?.geografic_info.locality}
              </Typography>
            </Grid>
          </div>
        </div>
        {!logicalUnit?.devices[id] && !iotLogicalUnitDetailLoading ? (
          <Typography>Sin dispositivo relacionado</Typography>
        ) : (
          <div className={styles.dataPanel}>
            <div className={styles.row}>
              {getDeviceIcon(deviceType, styles.devicesIcon)}
              {devuid && deviceTypeName && !iotLogicalUnitDetailLoading && (
                <Tooltip title={deviceTypeName} placement="top-start">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={styles.deviceCod}>{devuid}</Typography>
                    <Typography className={styles.deviceCodLabel}>(Nro. de serie)</Typography>
                  </div>
                </Tooltip>
              )}
              {devuid && !deviceTypeName && !iotLogicalUnitDetailLoading && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={styles.deviceCod}>{devuid}</Typography>
                  <Typography className={styles.deviceCodLabel}>(Nro. de serie)</Typography>
                </div>
              )}

              {!devuid && !deviceTypeName && !iotLogicalUnitDetailLoading && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={styles.deviceCod}>{t('no-series-number')}</Typography>
                </div>
              )}
            </div>
            {iotLogicalUnitDetailLoading ? (
              <Skeleton animation="wave" width={100} height={27}></Skeleton>
            ) : (
              <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5 }}>
                <div className={styles.deviceCod} style={{ opacity: 0.6 }}>
                  <Typography className={styles.title}>{t('operation-status') + ':'}</Typography>
                </div>
                <div className={styles.deviceCodLabel} style={{ marginLeft: 110 }}>
                  {alertType === OPERATION_STATUS.OK && <OperationBadge type={alertType} label={t('ok')} />}
                  {alertType === OPERATION_STATUS.WARNING && <OperationBadge type={alertType} label={t('warning')} />}
                  {alertType === OPERATION_STATUS.ALERT && <OperationBadge type={alertType} label={t('alert')} />}
                </div>
              </div>
            )}
            {iotLogicalUnitDetailLoading ? (
              <Skeleton animation="wave" width={300} height={23}></Skeleton>
            ) : (
              <div style={{ display: 'flex', alignItems: 'flex-end', opacity: 0.6 }}>
                <SettingsInputAntennaRoundedIcon style={{ fontSize: 20, marginRight: 5, marginBottom: 1 }}></SettingsInputAntennaRoundedIcon>
                <Typography className={styles.title}>{t('last-conn-control')}</Typography>
              </div>
            )}

            <div className={styles.row}>
              {iotLogicalUnitDetailLoading ? (
                <Skeleton animation="wave" width={300} height={23}></Skeleton>
              ) : (
                <Typography className={styles.dateLabel}>{moment(lastConnected).format('D MMM YYYY - HH:mm')}</Typography>
              )}
            </div>

            {alertType !== OPERATION_STATUS.OK ? (
              iotLogicalUnitDetailLoading ? (
                <div className={styles.operationStatusWrapper}>
                  <Skeleton animation="wave" width={'100%'} height={130}></Skeleton>
                </div>
              ) : (
                <div className={styles.operationStatusWrapper}>
                  <OperationStatus alertType={alertType} alarmDate={alarmDate} description={description} status={status} />
                </div>
              )
            ) : null}
            {iotLogicalUnitDetailLoading ? (
              <Skeleton animation="wave" width={100} height={27}></Skeleton>
            ) : (
              <div style={{ display: 'flex', alignItems: 'flex-end', opacity: 0.6 }}>
                <SpeedRoundedIcon style={{ fontSize: 20, marginRight: 5, marginBottom: 1 }}></SpeedRoundedIcon>
                <Typography className={styles.title}>{t('last-status')}</Typography>
              </div>
            )}

            <div className={styles.row}>
              {iotLogicalUnitDetailLoading ? (
                <Skeleton animation="wave" width={300} height={23}></Skeleton>
              ) : (
                <Typography className={styles.dateLabel}>{lastStatus ? moment(lastStatus).format('D MMM YYYY - HH:mm') : 'No disponible'}</Typography>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                marginBottom: 20,
                marginTop: 5,
                maxHeight: 250,
                overflowY: 'scroll',
                paddingRight: 8,
              }}
            >
              {!measureValues?.length > 0 && iotLogicalUnitDetailLoading ? (
                <Skeleton animation="wave" width={300} height={23}></Skeleton>
              ) : (
                !measureValues?.length > 0 && <Typography>{t('no-data')}</Typography>
              )}
              {measureValues?.length > 0 &&
                measureValues.map(
                  measure =>
                    measure.value !== 'NA' && (
                      <div className={styles.measureRow}>
                        <Typography className={styles.label}>{measure.title}</Typography>
                        <div className={styles.separator}></div>
                        <Typography className={styles.value} color="secondary">{`${Number(measure.value).toLocaleString('es-AR', {
                          minimumFractionDigits: measure.decimalView,
                        })} ${measure.unit}`}</Typography>
                      </div>
                    ),
                )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              {brief ? (
                iotLogicalUnitDetailLoading ? (
                  <Skeleton animation="wave" width={144} height={36}></Skeleton>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => onDeviceSelect(deviceId)}
                    startIcon={<AccountTreeRoundedIcon size="small"></AccountTreeRoundedIcon>}
                  >
                    Ficha de dispositivo
                  </Button>
                )
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MtrDetailView
