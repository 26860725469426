import {
  Button, Card, Checkbox, DialogActions, Fade, FormControl, Grid,
  IconButton, InputBase,
  InputLabel, makeStyles, MenuItem, Select, Typography, Tooltip
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import { CloseRounded } from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import YoutubeSearchedForRoundedIcon from '@material-ui/icons/YoutubeSearchedForRounded';
import clsx from 'clsx';
import { DEVICE, LOGICAL_UNIT } from 'constants/icons';
import { OPERATION_COMPONENT, DEVICE_CONDITION } from 'constants/iot';
import { DEFAULT_PAGE_NUMBER, pageSizeOptions, SORT } from './constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DevicesList from './components/DevicesList';
import styles from './styles.module.scss';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { generateArray } from 'utils/arrayUtils';


const AddDeviceModal = ({ open, handleClose, handleAdd, group, init, handleInit }) => {

  const useStyles = makeStyles((theme) => ({
    close: {
      position: 'absolute',
      right: 15,
      top: 8,
      zIndex: 99,
    },
    root: {
      color: '#2E2E2E',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }));

  const [t] = useTranslation('devices');
  const classes = useStyles();
  const dispatch = useDispatch();
  const input = document.getElementById('inputSearch');

  const [logicalUnits, setLogicalUnits] = useState(true);
  const [devices, setDevices] = useState(false);
  const [deviceTypeName, setDeviceTypeName] = useState('')
	const [deviceInstallSituation, setDeviceInstallSituation] = useState('')
	const [hierarchyName, setHierarchyName] = useState('')
	const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState(null);
  const [filtersChange, setFiltersChange] = useState(false);
  const [find, setFind] = useState(false);
  const [checkboxAllSelected , setCheckboxAllSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([])
  const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER)
  const [countSelectedItems, setCountSelectedItems] = useState(null)
	const [sort, setSort] = useState([])
	const [rowsSelected, setRowsSelected] = useState([]);
	const [disableCheckbox, setDisableCheckbox] = useState(false)

	const hierarchies = useSelector((state) => state.ioTHierarchy.iothierarchies);
	const deviceTypes = useSelector((state) => state.iot.iotDevicesType);
  const loading = useSelector((state) => state?.iot?.iotDevicesGroupCandidatesLoading);
  const loadingAdd = useSelector((state) => state?.iot?.iotCreateDevicesGroupLoading);
  const deviceslist = useSelector((state) => state?.iot?.iotDevicesGroupCandidates?.rows);
  const total_pages = useSelector((state) => state?.iot?.iotDevicesGroupCandidates?.iotDevicesGroupCandidatesTotalPages);
  const total_items = useSelector((state) => state?.iot?.iotDevicesGroupCandidates?.iotDevicesGroupCandidatesTotalItems);
  const enabledFind = (inputValue !== '' || hierarchyName !== '' || deviceInstallSituation !== '' || deviceTypeName !== '') && find;
  const showCheckAll = (total_items && total_items > 0) ? true: false;

  useEffect(() => {
    if (init){
      deleteAll();//clear filters
    }
    handleInit(false);
  }, [init])

	const deleteAll = () => {
    setInputValue('');
    setDeviceTypeName('');
    setDeviceInstallSituation('');
    setHierarchyName('');
    setFilters(null);
    setFiltersChange(false);
    setFind(false);
    setSelectedItems([]);
    setCheckboxAllSelected(false);
    setCountSelectedItems(null);
    setSort([]);
    const checkbox = document.getElementById('selectAll');
    if (checkbox) checkbox.checked = false;
    input?.focus();
    dispatch(iotActions.cleanIotGroupDevicesCandidates())
  };

  const handleChange = (e) => {
   	const id = e.target.name;
		id === 'deviceTypeName' && setDeviceTypeName(e.target.value);
		id === 'deviceInstallSituation' && setDeviceInstallSituation(e.target.value);
		id === 'hierarchyName' && setHierarchyName(e.target.value);
		id === 'inputSearch' && setInputValue(e.target.value);
    setFind(true);
  };

  const handleChangeAllSelected = (e) => {
    const checkbox = document.getElementById('selectAll')
    checkbox.checked = e.target.checked
    setCheckboxAllSelected(e.target.checked)
    if (checkbox.checked) {
			setCountSelectedItems(total_items)
			setRowsSelected(generateArray(10))
			setDisableCheckbox(true)
		} else {
			setCountSelectedItems(null)
			setRowsSelected([])
			setDisableCheckbox(false)
		}
  }

  const clean = (e) => {
    selectedItems.length=0;
    const checkbox = document.getElementById('selectAll');
    checkbox.checked = false;
    setCheckboxAllSelected(false);
    setFiltersChange(false);
    setCountSelectedItems(null)
    setSort([]);
  }

  const handleFindDevices = async (e) => {
    setFiltersChange(true);
    setFind(false);
    setFilters({
      isDevices: devices,
      input: inputValue,
      situation: deviceInstallSituation,
      devType: deviceTypeName,
      hierarchy: hierarchyName,
      sortOption: sort
    })
    await dispatch(iotActions.getIotGroupDevicesCandidates(group.id,
                        currentPage,
                        pageSize,
                        sort,
                        devices? inputValue : null,
                        logicalUnits? inputValue : null ,
                        deviceInstallSituation,
                        deviceTypeName,
                        hierarchyName
                        ));
    clean(e);
  }

	const handleAddDevices = async (e) => {
    if (checkboxAllSelected && filters) {
      const payload ={
        "use_filters": true,
        "devuid": filters.isDevices? filters.input: null,
        "external_name": !filters.isDevices? filters.input: null,
        "device_situation": filters.situation,
        "device_type_name": filters.devType,
        "hierarchy_name": filters.hierarchy
			}
			// remove key if value is null or undefined or empty string
			Object.keys(payload).forEach(key => (payload[key] == null || payload[key] === '') && delete payload[key]);
      await dispatch(iotActions.postCreateIotDevicesGroup(group.id, payload))
		} else {
			if (selectedItems.length > 0){
				const payload = {
					"use_filters": false,
					"devices_id": selectedItems
				}
				await dispatch(iotActions.postCreateIotDevicesGroup(group.id, payload))
			}
		}
		setRowsSelected([]);
    if ((checkboxAllSelected && filters) || (selectedItems.length > 0)) {
      handleFindDevices(e);
      clean(e);
    }
  }

  return (
    <Dialog
      className={styles.dialog}
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="xd"
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Grid container justifyContent="flex-start" alignItems="center">
          <span>{t('devicesGroupsAddGroupModalTitle')}</span>
        </Grid>
        <IconButton
          className={classes.close}
          aria-label="close"
          onClick={() => handleClose()}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.dialogContent} dividers={true}>

        <Card>
          <div style={{padding:20, display:'flex', flexDirection:'column', gap:20}}>
            {/* <WTFormHeader line1={t('devicesGroupsAddGroupModalWarning')}/> */}
            <FormGroup className={styles.formGroupRow} row >

                  {/* panel de selector e input de dispositivo */}
                  <div style={{display:'flex', flexDirection:'column', gap:10}}>
                    <div className={styles.selectorButtonContainer}>
                      <div
                        className={clsx(styles.selectButton, {
                          [styles.inactive]: devices,
                        })}
                        onClick={() => {
                          setLogicalUnits(true);
                          setDevices(false);
                        }}
                      >
                        <LOGICAL_UNIT className={styles.findIcons} />
                        <Typography variant='subtitle2' className={styles.findTypos}>
                          {t('deviceGroupAddDeviceModalLogicalUnits')}
                        </Typography>
                      </div>
                      <div
                        className={clsx(styles.selectButton, {
                          [styles.inactive]: logicalUnits,
                        })}
                        onClick={() => {
                          setLogicalUnits(false);
                          setDevices(true);
                        }}
                      >
                        <DEVICE className={styles.findIcons} />
                        <Typography variant='subtitle2' className={styles.findTypos}>
                          {t('deviceGroupAddDeviceModalDevices')}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems:'center', width: 295 }}>
                    <InputBase
                      id="inputSearch"
                      name='inputSearch'
                      value={inputValue}
                      className={styles.inputSearch}
                      autoComplete={'off'}
                      onChange={handleChange}
                      placeholder={devices? t('deviceGroupAddDeviceModalDeviceSerieNumber'):t('deviceGroupAddDeviceModalLogicalUnits')}
                    />
                    <Fade direction="up" in={inputValue.length > 0}>
                      <ClearIcon
                        className={styles.clearIcon}
                        onClick={() => deleteAll()}
                      />
                    </Fade>
                    </div>
                  </div>

                {/* inputs de seleccion */}
                <FormControl className={styles.formControl}>
                  <InputLabel id="label-1">{t('deviceGroupAddDeviceModalDeviceType')}</InputLabel>
                  <Select
                    labelId="label-1"
                    className={styles.select}
                    name="deviceTypeName"
                    value={deviceTypeName}
                    onChange={handleChange}
                    displayEmpty
                  >
                    {deviceTypes.map((type) => (
                      <MenuItem key={type.id} value={type.name}>
                        {type.description}
                      </MenuItem>
                    ))}
                  </Select>
                  </FormControl>
                  <FormControl className={styles.formControl}>
                  <InputLabel id="label-2">
                    {t('deviceGroupAddDeviceModalDeviceCondition')}
                  </InputLabel>
                  <Select
                    labelId="label-2"
                    className={styles.select}
                    name="deviceInstallSituation"
                    value={deviceInstallSituation}
                    onChange={handleChange}
                    displayEmpty
                  >
                    {DEVICE_CONDITION.map((condition) => (
                      <MenuItem key={condition.value} value={condition.value}>
                        {condition.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={styles.formControl}>
                <InputLabel id="label-3">
                    {t('deviceGroupAddDeviceModalDeviceHierarchies')}
                  </InputLabel>
                  <Select
                    labelId="label-3"
                    className={styles.select}
                    name="hierarchyName"
                    value={hierarchyName}
                    onChange={handleChange}
                  >
                    {hierarchies.map((hierarchy) => (
                      <MenuItem key={hierarchy.id} value={hierarchy.name}>
                        {hierarchy.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="outlined"
                        size='small'
                        startIcon={<YoutubeSearchedForRoundedIcon />}
                        style={{marginLeft:10}}
                        disabled={!enabledFind}
                        onClick={e => handleFindDevices(e)}>
                        {t('deviceGroupAddDeviceModalDeviceButtonFind')}
                </Button>

            </FormGroup>
          </div>
        <DevicesList groupid={group.id}
                    filters={filters} selectedItems={selectedItems} setSelectedItems={setSelectedItems}
                    loading={loading} listDevices={deviceslist} totalPages={total_pages} totalItems={total_items}
                    currentPage={currentPage} setCurrentPage={setCurrentPage} filtersChange={filtersChange}
                    countSelectedItems={countSelectedItems} setCountSelectedItems={setCountSelectedItems}
										sort={sort} setSort={setSort}
										rowsSelected={rowsSelected} setRowsSelected={setRowsSelected}
										disableCheckbox={disableCheckbox}
					/>
        </Card>
      </DialogContent>



      <DialogActions>
        <div
          style={{
            display: 'flex',
            marginTop: 8,
            marginLeft: 20,
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
            {!showCheckAll && <div style={{ display: 'flex', alignItems: 'left' }}></div>}

            {showCheckAll &&
            <div style={{ display: 'flex', alignItems: 'left' }}>
              <Tooltip
                title={t(
                  'deviceGroupAddDeviceModalDeviceAddAllSelected'
                )}
                placement="top"
              >
              <Checkbox
                id="selectAll"
                name="check_all_selected"
                className={classes.root}
                onClick={handleChangeAllSelected}
                checked={checkboxAllSelected}
              />
              </Tooltip>
              <Typography variant="body2" style={{ marginLeft: 10, marginTop: 10, }}>
              {t('deviceGroupAddDeviceModalDeviceAddAllSelected')}
              </Typography>
            </div>
            }

            <div className={styles.dialogContainerActionButton}>
            <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                  disabled={loadingAdd || !countSelectedItems }
                  onClick={(e) => handleAddDevices(e) }>
                    {t('deviceGroupAddDeviceModalDeviceButtonAddSelected')}
            </Button>
            <Button
              variant="outlined"
              onClick={handleClose}>
              {t('deviceGroupAddDeviceModalDeviceButtonEnding')}
            </Button>
            </div>
          </div>
        </DialogActions>

    </Dialog>
  );
};

export default AddDeviceModal;