import i18n from 'i18next'

export const OPERATION_STATUS = {
  ALERT: 'R',
  WARNING: 'Y',
  OK: 'G',
}

export const ERROR_TYPE = {
  VALIDATION: 'VALIDATION',
  INVALID: 'INVALID',
  TIMESTAMP: 'TIMESTAMP',
}

export const OPERATION_COMPONENT = {
  CT: 'CT',
  MTR: 'MTR',
  BUILD: 'BUILD',
  MTR_CT: 'MTR_CT',
  TELEC_PS_CON: 'TELEC_PS_CON',
  TELEC_PS: 'TELEC_PS',
  TELEC_PS_SLAVE: 'TELEC_PS_SLAVE',
}

//para iotHandler

const TEXT = 'TEXT'
const SELECT = 'SELECT'
const NUMBER = 'NUMBER'
const DATE = 'DATE'

export const CUSTOM_PROP_TYPES = {
  TEXT,
  SELECT,
  NUMBER,
  DATE,
}

export const DEVICE_CONDITION = [
	 {
		label: 'Instalado',
		value: 'instalado',
	},
	 {
		label: 'Stock',
		value: 'stock',
	},
	 {
		label: 'Scratch',
		value: 'scratch',
	},
]

export const CHART_NAMES = {
  // level: DEVICE_DETAIL_MEASURE
  AIO_PERFIL_CARGA_EPE: 'AIO_PERFIL_CARGA_EPE',
  AIO_PERFIL_INSTRUMENTACION_EPE: 'AIO_PERFIL_INSTRUMENTACION_EPE',
  TELECPS_PERFIL_CARGA_PACTT_EPE: 'TELECPS_PERFIL_CARGA_PACTT_EPE',
  TELECPS_PERFIL_CARGA_PACTTABS_EPE: 'TELECPS_PERFIL_CARGA_PACTTABS_EPE',
  TELECPSSL_PERFIL_CARGA_PACTT_EPE: 'TELECPSSL_PERFIL_CARGA_PACTT_EPE',
  TELECPSSL_PERFIL_CARGA_PACTTABS_EPE: 'TELECPSSL_PERFIL_CARGA_PACTTABS_EPE',
  // level: DEVICE_DETAIL_ALARM
  ALL_OUTAGE_ALARMS_EPE: 'ALL_OUTAGE_ALARMS_EPE'
}

export const COMPONENT = 'component'
export const VERSION = 'version'
export const FIRMWARE = 'firmware'
export const COMPONENT_ORDER_IN_FORM = 6
export const IOT_UID = 'uid'
export const IOT_AVAILABLE = 'available'
export const IOT_TYPE_KEY = 'iot_type'
export const IOT_TYPE_DESC_KEY = 'iot_type_desc'
export const IOT_MODEL_KEY = 'iot_model'
export const IOT_MODEL_DESC_KEY = 'iot_model_desc'
export const IOT_STATUS_SERVICE = 'status_service'
export const IOT_LAST_STATUS_SERVICE = 'last_status-service'
export const IOT_LAST_CONNECTED_NETWORK = 'last_connected_network'
export const COMPONENT_NAME_KEY = 'component_name'
export const BADGE_NUMBER_KEY = 'badge_number'
export const DEVICES_GROUPS_VISIBILITY = {
	PUBLIC: 'PUBLIC',
	PRIVATE: 'PRIVATE',
}

export const componentFieldsBase = [
  {
    //...textField,
    key: COMPONENT_NAME_KEY,
    read_only: true,
    title: i18n.t('IoT:component'),
    placeholder: i18n.t('IoT:component'),
    subOrder: 1,
  },
  {
    //...textField,
    key: 'version',
    read_only: true,
    title: i18n.t('IoT:version'),
    placeholder: i18n.t('IoT:version'),
    subOrder: 2,
  },
  {
    //...textField,
    key: 'firmware',
    read_only: true,
    title: i18n.t('IoT:firmware'),
    placeholder: i18n.t('IoT:firmware'),
    subOrder: 3,
  },
  {
    //...textField,
    key: BADGE_NUMBER_KEY,
    read_only: true,
    title: i18n.t('IoT:serialBadge'),
    placeholder: i18n.t('IoT:serialBadge'),
    subOrder: 4,
    // normalize: normalizeAll([onlyNumbers])
  },
]

export const getTypeNameByTypeCode = (typeCode) => {
	switch (typeCode) {
		case 'CT':
			return 'Subestación'
		case 'MTR':
			return 'Medidor Energía Electrica'
		case 'BUILD':
			return 'Edificio'
		case 'MTR_CT':
			return 'Medidor CT'
		case 'TELEC_PS_CON':
			return 'Telelectura Concentrador PS'
		case 'TELEC_PS':
			return 'Telelectura Medidor PS'
		case 'TELEC_PS_SLAVE':
			return 'Telelectura Esclavo PS'
		default:
			return ''
	}
}

export const ALWAYS_VISIBLE = 'ALWAYS_VISIBLE'
export const ENTER = 'Enter'