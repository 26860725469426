import apiIOT from 'config/api'
import { IOTHIERARCHY } from 'constants/apiResources'

import { AUTH_HEADER, UTILITY_HEADER } from 'constants/apiHeaders'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getHierarchyList: async (token, companyId) => {
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${token}`,
      [UTILITY_HEADER]: companyId,
    })
    const response = await apiIOT.get(IOTHIERARCHY.HIERARCHY_LIST)
    return response?.data
  },
  getBriefLogicalUnits: async (token, companyId) => {
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${token}`,
      [UTILITY_HEADER]: companyId,
    })
    const response = await apiIOT.get(IOTHIERARCHY.HIERARCHIES_UNITS)
    return response?.data
  },
  getHierarchyUnitsDeviceStaticts: async (id, token, companyId) => {
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${token}`,
      [UTILITY_HEADER]: companyId,
    })
    const response = await apiIOT.get(IOTHIERARCHY.HIERARCHIES_UNITS_DEVICE, { hierarchy_id: id })
    return response?.data
  },
}
