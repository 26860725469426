import { React } from 'react';
import { Skeleton } from '@material-ui/lab';

import DeviceTypeCronsList from './components/DeviceTypeCronsList';

const renderSkeleton = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton animation='wave' height={60} width={600} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div style={{ width: '100%' }}>
            <Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
						<Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
          </div>
        </div>
      </div>
    )
  }

const DeviceTypeCronsDetail = ({onGoBack, deviceType, reportId, loading}) => {

	return (
		<div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
			{loading ? renderSkeleton() : (
					<DeviceTypeCronsList onGoBack={onGoBack } deviceType={deviceType} reportId={reportId} />
			)}
		</div>
  );
}

export default DeviceTypeCronsDetail