import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ClearIcon from '@material-ui/icons/Clear'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import ListItem from '@material-ui/core/ListItem'
import Skeleton from '@material-ui/lab/Skeleton'
import styles from './styles.module.scss'
import { Icon, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import search from 'services/SearchService'
import Fade from '@material-ui/core/Fade'
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded'
import {
  setLocalLogicalUnitsRecentSearches,
  getLocalLogicalUnitsRecentSearches,
  setLocalDevicesRecentSearches,
  getLocalDevicesRecentSearches,
} from 'services/LocalStorageService'

import {
  LOGICAL_UNIT,
  DEVICE,
  LOGICAL_UNIT_CT,
  LOGICAL_UNIT_MTR,
  LOGICAL_UNIT_BUILD,
  DEVICE_MTR_CT,
  DEVICE_TELEC_PS,
  DEVICE_TELEC_PS_CON,
  DEVICE_TELEC_PS_SLAVE,
} from 'constants/icons'
import { OPERATION_COMPONENT } from 'constants/iot'

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 380,
    marginRight: 20,
    background: '#424242e6',
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  container: {
    display: 'flex',
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 7,
    marginLeft: 24,
    marginRight: 12,
    height: 26,
    cursor: 'pointer',
  },
  tooltip: {
    zIndex: 99,
  },
  groupWorkIcon: {
    marginRight: 10,
  },
  groupWorkIconDisabled: {
    marginRight: 10,
    opacity: 0.6,
  },
  searchIcon: {
    marginRight: 10,
    opacity: 0.6,
  },
  clearIcon: {
    cursor: 'pointer',
  },

  placeholder: {
    fontWeight: 300,
  },
  expanded: {},
  inactive: {
    opacity: 0.5,
    color: '#E4E4E4',
    '&:hover': {
      opacity: 0.6,
    },
  },
}))

const SearchView = ({ activeHierarchy, onSelect }) => {
  const [t] = useTranslation('search')
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)
  const [active, setActive] = useState(null)

  const [logicalUnitsData, setLogicalUnitsData] = useState([])
  const [devicesData, setDevicesData] = useState([])

  const [logicalUnits, setLogicalUnits] = useState(true)
  const [devices, setDevices] = useState(false)

  const [logicalUnitRecentSearches, setLogicalUnitRecentSearches] = useState(getLocalLogicalUnitsRecentSearches() || [])
  const [deviceRecentSearches, setDeviceRecentSearches] = useState(getLocalDevicesRecentSearches() || [])

  const [inputValue, setInputValue] = useState('')
  const [loader, setLoader] = useState(false)
  const [deviceLoader, setDeviceLoader] = useState(false)
  const input = document.getElementById('inputSearch')

  useEffect(() => {
    setLoader(false)
  }, [activeHierarchy])

  useEffect(() => {
    if (logicalUnitsData.length > 0 && devicesData.length > 0) {
      setLoader(false)
    }
  }, [logicalUnitsData, devicesData])

  const handleExpandClick = () => {
    setExpanded(!expanded)
    setTimeout(() => {
      input?.focus()
    }, 200)
  }

  const deleteAll = () => {
    setInputValue('')
    input?.focus()
  }

  const handleOnChange = e => {
    setInputValue(e.target.value)
    logicalUnits && search(logicalUnits ? 'u' : devices && 'd', activeHierarchy.id, e.target.value).then(data => setLogicalUnitsData(data))
    devices && setDevicesData([])
    if (devices && e.target.value.length >= 6) {
      setDeviceLoader(true)
      setTimeout(() => {
        search(logicalUnits ? 'u' : devices && 'd', activeHierarchy.id, e.target.value).then(data => {
          if (data) {
            setDevicesData([data])
          } else {
            setDevicesData([])
          }
          setDeviceLoader(false)
        })
      }, 500)
    }
  }

  const handleOnClick = id => {
    if (logicalUnits) {
      if (!logicalUnitRecentSearches.find(el => el.id === id)) {
        const recentSearch = logicalUnitsData.filter(el => el.id === id)
        setLogicalUnitRecentSearches([...recentSearch, ...logicalUnitRecentSearches])
        setLocalLogicalUnitsRecentSearches([...recentSearch, ...logicalUnitRecentSearches])
      }
      setActive(logicalUnitsData.filter(el => el.id === id)[0])
      onSelect(id)
    } else if (devices) {
      if (!deviceRecentSearches.find(el => el.iot_device.id === id)) {
        const recentSearch = devicesData.filter(el => el.iot_device.id === id)
        setDeviceRecentSearches([...recentSearch, ...deviceRecentSearches])
        setLocalDevicesRecentSearches([...recentSearch, ...deviceRecentSearches])
        const deviceOnClick = devicesData.filter(el => el.iot_device.id === id)[0]
        setActive(deviceOnClick)
        if (deviceOnClick?.logical_unit_relations[0] && deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.logical_unit_parent)
          onSelect(
            deviceOnClick?.logical_unit_relations[0] && deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.logical_unit_parent,
            deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.id,
          )
        else onSelect(deviceOnClick?.logical_unit_relations[0] && deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.id)
      } else {
        const deviceOnClick = deviceRecentSearches.filter(el => el.iot_device.id === id)[0]
        setActive(deviceOnClick)
        if (deviceOnClick?.logical_unit_relations[0] && deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.logical_unit_parent)
          onSelect(
            deviceOnClick?.logical_unit_relations[0] && deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.logical_unit_parent,
            deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.id,
          )
        else onSelect(deviceOnClick?.logical_unit_relations[0] && deviceOnClick?.logical_unit_relations[0].iot_logical_unit?.id)
      }
    }
    setInputValue('')
  }

  const changeTypeOfSearch = typeOfSearch => {
    if (typeOfSearch === 'logicalUnits') {
      setLogicalUnitsData([])
      setDevicesData([{}])
      setLogicalUnits(true)
      setDevices(false)
    }
    if (typeOfSearch === 'devices') {
      setLogicalUnitsData([])
      setDevicesData([{}])
      setDevices(true)
      setLogicalUnits(false)
    }
    deleteAll()
    input?.focus()
  }

  const deleteRecentSearch = (e, id) => {
    if (!e) e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    logicalUnits && setLogicalUnitRecentSearches([...logicalUnitRecentSearches].filter(el => el.id !== id))
    devices && setDeviceRecentSearches([...deviceRecentSearches].filter(el => el.iot_device.id !== id))
  }

  return (
    <div className={[styles.root, expanded && classes.expanded].join(' ')}>
      {loader ? (
        <div>
          <Skeleton variant='rect' width={430} height={44} />
          <div />
          <Skeleton variant='rect' width={430} height={306} />
        </div>
      ) : (
        <Card className={classes.card} elevation={3}>
          <CardContent className={classes.cardContent}>
            <div className={[classes.topBar, styles.unselectable].join(' ')} onClick={handleExpandClick}>
              <SearchIcon className={classes.searchIcon} />
              <Typography className={classes.placeholder} color='textSecondary'>
                {t('search')}
              </Typography>
              <Icon
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                aria-expanded={expanded}
                aria-label='show more'
              >
                <ExpandMoreIcon />
              </Icon>
            </div>
            <Fade in={expanded}>
              <Divider variant='middle' />
            </Fade>

            <Collapse in={expanded} timeout='auto'>
              <div className={styles.selectorButtonContainer}>
                <div
                  className={clsx(styles.button, {
                    [styles.inactive]: devices,
                  })}
                  onClick={() => changeTypeOfSearch('logicalUnits')}
                >
                  <LOGICAL_UNIT className={styles.findIcons} />
                  <Typography className={styles.findTypos}>{t('logical-units')}</Typography>
                </div>
                <div
                  className={clsx(styles.button, {
                    [styles.inactive]: logicalUnits,
                  })}
                  onClick={() => changeTypeOfSearch('devices')}
                >
                  <DEVICE className={styles.findIcons} />
                  <Typography className={styles.findTypos}>{t('devices')}</Typography>
                </div>
              </div>
              <Paper elevation={0} className={styles.filter}>
                <InputBase
                  id='inputSearch'
                  value={inputValue}
                  className={styles.inputSearch}
                  placeholder={(logicalUnits && t('findLU')) || (devices && t('findD'))}
                  inputProps={{ 'aria-label': 'Buscar' }}
                  autoComplete={'off'}
                  onChange={e => {
                    handleOnChange(e)
                  }}
                />

                <Fade direction='up' in={inputValue.length > 0}>
                  <ClearIcon className={classes.clearIcon} onClick={() => deleteAll()} />
                </Fade>
              </Paper>

              {/** LOGICAL UNITS PANEL */}
              {logicalUnits && (
                <>
                  {!inputValue && logicalUnitRecentSearches.filter(el => el.iot_hierarchy.name === activeHierarchy.name).length > 0 && (
                    <Fade in={true}>
                      <Typography className={styles.recentSearchesTitle}>{t('recent-searches')}</Typography>
                    </Fade>
                  )}
                  {!inputValue && logicalUnitRecentSearches.filter(el => el.iot_hierarchy.name === activeHierarchy.name)?.length > 0 && (
                    <div className={styles.resultadosBlock}>
                      <Fade in={true}>
                        <>
                          {logicalUnitRecentSearches
                            .filter(el => el.iot_hierarchy.name === activeHierarchy.name)
                            .slice(0, 5)
                            .map(recentSearch => {
                              return (
                                <ListItem key={recentSearch.id} className={styles.listItem} onClick={() => handleOnClick(recentSearch.id)} button>
                                  {recentSearch.iot_logical_unit_type.internal_name === OPERATION_COMPONENT.CT && (
                                    <LOGICAL_UNIT_CT className={styles.listItemIcon} />
                                  )}
                                  {recentSearch.iot_logical_unit_type.internal_name === OPERATION_COMPONENT.MTR && (
                                    <LOGICAL_UNIT_MTR className={styles.listItemIcon} />
                                  )}
                                  {recentSearch.iot_logical_unit_type.internal_name === OPERATION_COMPONENT.BUILD && (
                                    <LOGICAL_UNIT_BUILD className={styles.listItemIcon} />
                                  )}

                                  {recentSearch.external_id.length < 15 ? (
                                    <Typography className={styles.listItemTextId}>{recentSearch.external_id}</Typography>
                                  ) : (
                                    <Tooltip className={classes.tooltip} title={recentSearch.external_id} placement='top-start'>
                                      <Typography className={styles.listItemTextId}>{recentSearch.external_id}</Typography>
                                    </Tooltip>
                                  )}
                                  <Typography className={styles.listItemTextDetail}>{recentSearch.external_name}</Typography>

                                  <ClearIcon onClick={e => deleteRecentSearch(e, recentSearch.id)} className={styles.clearRecentButton} />
                                </ListItem>
                              )
                            })}
                        </>
                      </Fade>
                    </div>
                  )}
                  {inputValue.length > 0 && logicalUnitsData.length > 0 && (
                    <Fade in={true}>
                      <div className={styles.searchResults}>
                        <Typography>
                          <span className={styles.number}>{logicalUnitsData.length}</span>
                          {logicalUnitsData.length > 1 ? t('search-results') : t('one-search-result')}
                        </Typography>
                      </div>
                    </Fade>
                  )}

                  {logicalUnitsData && logicalUnitsData.length === 0 && inputValue.length > 0 ? (
                    <Fade in={true}>
                      <div className={styles.noResults}>
                        <NotInterestedRoundedIcon className={styles.noResultsIcon}></NotInterestedRoundedIcon>
                        <Typography className={styles.noResultsText}>{t('no-search-results')}</Typography>
                      </div>
                    </Fade>
                  ) : null}
                  {logicalUnits && inputValue.length !== 0 && logicalUnitsData?.length > 0 && (
                    <div className={styles.resultadosBlock}>
                      <Fade in={true}>
                        <>
                          {logicalUnitsData.map(logicUnit => {
                            return (
                              <ListItem
                                key={logicUnit.id}
                                className={styles.listItem}
                                onClick={() => handleOnClick(logicUnit.id)}
                                selected={logicUnit.external_name === active}
                                button
                              >
                                {logicUnit.iot_logical_unit_type.internal_name === OPERATION_COMPONENT.CT && (
                                  <LOGICAL_UNIT_CT className={styles.listItemIcon} />
                                )}
                                {logicUnit.iot_logical_unit_type.internal_name === OPERATION_COMPONENT.MTR && (
                                  <LOGICAL_UNIT_MTR className={styles.listItemIcon} />
                                )}
                                {logicUnit.iot_logical_unit_type.internal_name === OPERATION_COMPONENT.BUILD && (
                                  <LOGICAL_UNIT_BUILD className={styles.listItemIcon} />
                                )}
                                {logicUnit.external_id.length < 15 ? (
                                  <Typography className={styles.listItemTextId}>{logicUnit.external_id}</Typography>
                                ) : (
                                  <Tooltip className={classes.tooltip} title={logicUnit.external_id} placement='top-start'>
                                    <Typography className={styles.listItemTextId}>{logicUnit.external_id}</Typography>
                                  </Tooltip>
                                )}
                                <Typography className={styles.listItemTextDetail}>{logicUnit.external_name}</Typography>
                              </ListItem>
                            )
                          })}
                        </>
                      </Fade>
                    </div>
                  )}
                </>
              )}

              {/** DEVICES PANEL */}
              {devices && (
                <>
                  {!inputValue &&
                    deviceRecentSearches.filter(el => el.logical_unit_relations[0].iot_logical_unit.iot_hierarchy.name === activeHierarchy.name)
                      .length > 0 && (
                      <Fade in={true}>
                        <Typography className={styles.recentSearchesTitle}>{t('recent-searches')}</Typography>
                      </Fade>
                    )}
                  {!inputValue &&
                    deviceRecentSearches.filter(el => el.logical_unit_relations[0].iot_logical_unit.iot_hierarchy.name === activeHierarchy.name)
                      .length > 0 && (
                      <div className={styles.resultadosBlock}>
                        <Fade in={true}>
                          <>
                            {deviceRecentSearches
                              .filter(el => el.logical_unit_relations[0].iot_logical_unit.iot_hierarchy.name === activeHierarchy.name)
                              .slice(0, 3)
                              .map(recentSearch => {
                                return (
                                  <ListItem
                                    key={recentSearch.iot_device.id}
                                    className={styles.listItem}
                                    button
                                    onClick={() => handleOnClick(recentSearch.iot_device.id)}
                                  >
                                    {recentSearch.iot_device.iot_type.name === OPERATION_COMPONENT.MTR_CT && (
                                      <DEVICE_MTR_CT className={styles.listItemIcon} />
                                    )}
                                    {recentSearch.iot_device.iot_type.name === OPERATION_COMPONENT.TELEC_PS && (
                                      <DEVICE_TELEC_PS className={styles.listItemIcon} />
                                    )}
                                    {recentSearch.iot_device.iot_type.name === OPERATION_COMPONENT.TELEC_PS_CON && (
                                      <DEVICE_TELEC_PS_CON className={styles.listItemIcon} />
                                    )}
                                    {recentSearch.iot_device.iot_type.name === OPERATION_COMPONENT.TELEC_PS_SLAVE && (
                                      <DEVICE_TELEC_PS_SLAVE className={styles.listItemIcon} />
                                    )}

                                    {recentSearch.iot_device.uid.length < 15 ? (
                                      <Typography className={styles.listItemTextId}>{recentSearch.iot_device.uid}</Typography>
                                    ) : (
                                      <Tooltip className={classes.tooltip} title={recentSearch.iot_device.uid} placement='top-start'>
                                        <Typography className={styles.listItemTextId}>{recentSearch.iot_device.uid}</Typography>
                                      </Tooltip>
                                    )}
                                    <Typography className={styles.listItemTextDetail}>{recentSearch.iot_device.iot_type.description}</Typography>

                                    <ClearIcon
                                      onClick={e => deleteRecentSearch(e, recentSearch.iot_device.id)}
                                      className={styles.clearRecentButton}
                                    />
                                  </ListItem>
                                )
                              })}
                          </>
                        </Fade>
                      </div>
                    )}

                  {inputValue.length >= 6 && devicesData[0] && Object.keys(devicesData[0]).length > 0 && (
                    <Fade in={true}>
                      <div className={styles.searchResults}>
                        <Typography>
                          <span className={styles.number}>{devicesData.length}</span>
                          {devicesData.length > 1 ? t('search-results') : t('one-search-result')}
                        </Typography>
                      </div>
                    </Fade>
                  )}
                  {deviceLoader && inputValue.length >= 6 && (
                    <Fade in={true}>
                      <div style={{ paddingLeft: 15, paddingRight: 15, minHeight: 200 }}>
                        <Skeleton animation='wave' width={'100%'} height={40} />
                        <Skeleton animation='wave' width={'100%'} height={40} />
                        <Skeleton animation='wave' width={'100%'} height={40} />
                      </div>
                    </Fade>
                  )}

                  {!deviceLoader && devicesData?.length === 0 && inputValue.length > 0 ? (
                    <Fade in={true}>
                      <div className={styles.noResults}>
                        <NotInterestedRoundedIcon className={styles.noResultsIcon}></NotInterestedRoundedIcon>
                        {inputValue.length >= 6 ? (
                          <Typography className={styles.noResultsText}>{t('no-search-results')}</Typography>
                        ) : (
                          <Typography className={styles.noResultsText}>{t('findD')}</Typography>
                        )}
                      </div>
                    </Fade>
                  ) : null}

                  {!deviceLoader && devices && inputValue.length !== 0 && !!devicesData[0] && !!devicesData[0].iot_device.uid && (
                    <div className={styles.resultadosBlock}>
                      <Fade in={true}>
                        <>
                          {devicesData.map(device => {
                            return (
                              <ListItem
                                key={device.iot_device.id}
                                className={styles.listItem}
                                onClick={() => handleOnClick(device.iot_device.id)}
                                selected={device.iot_device.uid === active}
                                button
                              >
                                {device.iot_device.iot_type.name === OPERATION_COMPONENT.MTR_CT && <DEVICE_MTR_CT className={styles.listItemIcon} />}
                                {device.iot_device.iot_type.name === OPERATION_COMPONENT.TELEC_PS && (
                                  <DEVICE_TELEC_PS className={styles.listItemIcon} />
                                )}
                                {device.iot_device.iot_type.name === OPERATION_COMPONENT.TELEC_PS_CON && (
                                  <DEVICE_TELEC_PS_CON className={styles.listItemIcon} />
                                )}
                                {device.iot_device.iot_type.name === OPERATION_COMPONENT.TELEC_PS_SLAVE && (
                                  <DEVICE_TELEC_PS_SLAVE className={styles.listItemIcon} />
                                )}

                                {device.iot_device.uid.length < 15 ? (
                                  <Typography className={styles.listItemTextId}>{device.iot_device.uid}</Typography>
                                ) : (
                                  <Tooltip className={classes.tooltip} title={device.iot_device.uid} placement='top-start'>
                                    <Typography className={styles.listItemTextId}>{device.iot_device.uid}</Typography>
                                  </Tooltip>
                                )}
                                <Typography className={styles.listItemTextDetail}>{device.iot_device.iot_type.description}</Typography>
                              </ListItem>
                            )
                          })}
                        </>
                      </Fade>
                    </div>
                  )}
                </>
              )}
            </Collapse>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

// proptypes

export default SearchView
