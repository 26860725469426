import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'

import styles from './styles.module.scss'
import { OPERATION_STATUS } from 'constants/iot'

const useStyles = makeStyles(theme => ({
  tooltip: {
    cursor: 'pointer',
  },
  noValue: {
    paddingRight: 0,
  },
}))

const max = 0.15

const PercentageBar = ({ alert, warning, correct, filter }) => {
  const classes = useStyles()

  const width = {}
  let total = alert + warning + correct
  if (!alert && !warning && !correct) {
    width.alert = 0
    width.warning = 0
    width.correct = 0
  } else {
    width.alert = (alert / total) * 100 + '%'
    width.warning = (warning / total) * 100 + '%'
    width.correct = (correct / total) * 100 + '%'
  }

  return (
    <div className={styles.root}>
      {alert !== 0 && (
        <Tooltip
          placement="top"
          leaveDelay={200}
          disableHoverListener={alert / total > max}
          disableFocusListener
          disableTouchListener
          title={((alert / total) * 100).toFixed(0) + '%'}
        >
          <div
            className={[
              styles.alert,
              alert / total <= max && classes.tooltip,
              width.alert === 0 && classes.noValue,
              filter && filter.includes(OPERATION_STATUS.ALERT) && styles.onFilter,
            ].join(' ')}
            style={{ width: width.alert }}
          >
            {alert / total > max ? ((alert / total) * 100).toFixed(0) + '%' : null}
          </div>
        </Tooltip>
      )}
      {warning !== 0 && (
        <Tooltip
          placement="top"
          leaveDelay={200}
          disableHoverListener={warning / total > max}
          disableFocusListener
          disableTouchListener
          title={((warning / total) * 100).toFixed(0) + '%'}
        >
          <div
            className={[
              styles.warning,
              warning / total <= max && classes.tooltip,
              width.warning === 0 && classes.noValue,
              filter && filter.includes(OPERATION_STATUS.WARNING) && styles.onFilter,
            ].join(' ')}
            style={{ width: width.warning }}
          >
            {warning / total > max ? ((warning / total) * 100).toFixed(0) + '%' : null}
          </div>
        </Tooltip>
      )}
      {correct !== 0 && (
        <Tooltip
          placement="top"
          leaveDelay={200}
          disableHoverListener={correct / total > max}
          disableFocusListener
          disableTouchListener
          title={((correct / total) * 100).toFixed(0) + '%'}
        >
          <div
            className={[
              styles.correct,
              correct / total <= max && classes.tooltip,
              width.correct === 0 && classes.noValue,
              filter && filter.includes(OPERATION_STATUS.OK) && styles.onFilter,
            ].join(' ')}
            style={{ width: width.correct }}
          >
            {correct / total > max ? ((correct / total) * 100).toFixed(0) + '%' : null}
          </div>
        </Tooltip>
      )}
    </div>
  )
}

PercentageBar.propTypes = {
  alert: PropTypes.number,
  warning: PropTypes.number,
  correct: PropTypes.number,
}

export default PercentageBar
