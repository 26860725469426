import i18n from 'i18next'
import AlertActions from 'redux/alert/actions'
import AuthService from 'services/AuthService'
import { stringArrayToObject } from '../utils'
import { extractUserRoles, retrieveUserInfo } from './selectors'
import {
  removeLocalLogicalUnitsRecentSearches,
  removeLocalDevicesRecentSearches,
  removeSessionStatisticsOperationalPanelFilters,
} from 'services/LocalStorageService'

/* ------------- Auth actions ------------- */
export const actions = stringArrayToObject(['AUTH_INIT', 'AUTH_INIT_SUCCESS', 'AUTH_INIT_FAILURE', 'SIGN_OUT'], '@@AUTH')

const privateActionCreators = {
  authInitSuccess: payload => ({ type: actions.AUTH_INIT_SUCCESS, payload }),
  authInitFailure: () => ({ type: actions.AUTH_INIT_FAILURE }),
  logInFailure: (kcLogoutCb, mssg) => async dispatch => {
    dispatch(AlertActions.error(mssg || i18n.t('DefaultMessages:logInFailure')))
    dispatch(privateActionCreators.authInitFailure())
    setTimeout(() => kcLogoutCb(), 750) // To prevent redirect to quickly and display the message to the user
  },
}

export const actionCreators = {
  /**
   * authActions. Authenticacion with keycloak
   * @async
   * @param {boolean} shouldEnableIframeCheck
   * @returns
   */
  authInit: shouldEnableIframeCheck => async (dispatch, getState) => {
    dispatch({ type: actions.AUTH_INIT })
    const response = await AuthService.initialize(shouldEnableIframeCheck)
    const { healthCheck } = getState().utility
    if (response && response.authenticated && healthCheck?.healthy) {
      // validate actual Realm session client to check token's correct application web
      const { resourceAccess, clientId } = response;
      if (!clientId || !resourceAccess || !resourceAccess?.[clientId])
        return dispatch(privateActionCreators.logInFailure(response?.logout)); // token diferent application force session logout


      let companyId = process.env.REACT_APP_COMPANY_ID
      let userRoles = extractUserRoles(response)
      const userInfo = retrieveUserInfo(response, companyId, userRoles)
      
      await dispatch(privateActionCreators.authInitSuccess(userInfo))
    } else {
      dispatch(privateActionCreators.logInFailure(response.logout))
    }
  },
  signOut: () => async (dispatch, getState) => {
    const { logout } = getState().auth
    if (logout) logout()
    removeLocalLogicalUnitsRecentSearches()
    removeLocalDevicesRecentSearches()
    removeSessionStatisticsOperationalPanelFilters()
    dispatch({ type: actions.SIGN_OUT })
  },
}

export default actionCreators
