import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { ArrowBackRounded } from '@material-ui/icons';
import BlurCircularRoundedIcon from '@material-ui/icons/BlurCircularRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import { Skeleton } from '@material-ui/lab';
import { FILLED_COLOR_SQUARE } from 'constants/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { push } from 'connected-react-router';
import { ROUTES } from 'constants/routes';
import WTdialog from 'app/components/WTDialog';
import { roles, validateAccess } from 'constants/userRoles';
import UpdateGroupModal from './components/UpdateGroupModal';

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 60 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={'100%'} />
      </div>
    </div>
  )
}


const DeviceGroupHeader = ({loading, group, onGoBack, userRoles}) => {
	const [t] = useTranslation('devices')
	const menuActions = [
    {
      name: t('deviceGroupHeaderMenuActionEdit'),
    },
    {
      name: t('deviceGroupHeaderMenuActionDisable'),
    },
  ];

	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [disableModalOpen, setDisableModalOpen] = useState(false)
	const [updateModalOpen, setUpdateModalOpen] = useState(false)
	const [isOwner, setIsOwner] = useState(null)
	const [hasRoleEditDeviceGroup, setHasRoleEditDeviceGroup] = useState(false)
	const [hasRoleToogleStatusDeviceGroup, setHasRoleToogleStatusDeviceGroup] = useState(false)

	const updating = useSelector((state) => state.iot.iotUpdateGroupEntityLoading);
	const owner = useSelector((state) => state.iot.iotGroupOfDeviceDetail?.owner);
	const user = useSelector((state) => state.auth.authInformation?.preferredUsername);
	const totalDevices = useSelector((state) => state.iot.iotGroupDevices?.totalDevices);

	const setComponentAccess = () => {
		// option button to Edit header device group information
		setHasRoleEditDeviceGroup(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_EDIT));
		// option button to enable/disable device group
		setHasRoleToogleStatusDeviceGroup(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_STATUS_TOOGLE));
	  }
	
	useEffect(() => {
		setComponentAccess();
	}, []);

	useEffect(() => {
		setIsOwner(owner === user)
	}, [owner, user])

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

	const handleCloseUpdateForm = () => {
			setUpdateModalOpen(false)
	}

	const handleUpdateGroup = async (values) => {
		await dispatch(iotActions.putGroupOfDevices(group.id, values))
		dispatch(iotActions.getIotGroupOfDeviceDetail(group.id));
	}

  const handleClose = () => {
    setAnchorEl(null);
	};

	const handleCloseDisableModal = () => {
		setDisableModalOpen(false)
	}

	const disableAction = async () => {
		await dispatch(iotActions.toogleAvailabilityGroup(group.id, false))
		setDisableModalOpen(false)
		if (totalDevices === 0) {
			dispatch(push(ROUTES.IOT_DEVICE_TYPES));
		}
	}

	const handleAction = async (action) => {
		action === t('deviceGroupHeaderMenuActionDisable') && setDisableModalOpen(true)
		action === t('deviceGroupHeaderMenuActionEdit') && setUpdateModalOpen(true)
		handleClose()
	}
	
	const userHasRoleForAnyAction = () => {
		return hasRoleEditDeviceGroup || hasRoleToogleStatusDeviceGroup
	}

	const userHasRoleForAction = (action) => {
		if (action === t('deviceGroupHeaderMenuActionEdit'))
			return hasRoleEditDeviceGroup
	
		if (action === t('deviceGroupHeaderMenuActionDisable') || action === t('deviceCronsListMenuActionEnable'))
			return hasRoleToogleStatusDeviceGroup
			
		return false;
	}

	const actionAvailableForUser = (action) => {
		const { name: actionName } = action;
	
		// check role's user
		const userHasRole = userHasRoleForAction(actionName)	
		return userHasRole;
	}


	return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
      }}>
		{loading ? 
			(renderSkeleton()) : 
			(
				<>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<IconButton style={{ marginRight: 10 }} onClick={onGoBack}>
							<ArrowBackRounded></ArrowBackRounded>
					</IconButton>
					<BlurCircularRoundedIcon style={{ fontSize: 30 }}/>
					<Typography variant="h5" style={{ marginLeft: 10, marginRight:10 }}>Grupo - </Typography>
					<FILLED_COLOR_SQUARE color={group?.color} style={{ fontSize: 14 }}></FILLED_COLOR_SQUARE>
					<Typography variant="h5" style={{ marginLeft: 10 }}>{group?.name}</Typography>
				</div>
				{ userHasRoleForAnyAction() && isOwner &&
					<>
						<Tooltip title={t('deviceGroupHeaderTooltipOptions')}>
							<IconButton
								onClick={handleClick}
								style={{ display: 'flex', alignItems: 'center'}}
							>
								<MoreVertRoundedIcon />
							</IconButton>
						</Tooltip>
						<Menu
							id="fade-menu"
							anchorEl={anchorEl}
							keepMounted
							open={open}
							onClose={handleClose}
							TransitionComponent={Fade}
						>
							{menuActions.map((action, i) => {
								return (actionAvailableForUser(action) ?
								<MenuItem onClick={() => handleAction(action.name)}>
									{action.name}
								</MenuItem> : null);
							})}
						</Menu>
					</>
				}
				</>
			)}
			<WTdialog
				type={'warning'}
				title={'Aviso de confirmación'}
				message={'Estás por deshabilitar el grupo de dispositivos en su totalidad. Esta acción no se puede deshacer.'}
				show={disableModalOpen}
				primaryActionLabel={'Deshabilitar grupo'}
				onPrimaryAction={disableAction}
				secondaryActionLabel={'Cancelar'}
				onSecondaryAction={handleCloseDisableModal}
				onClose={handleCloseDisableModal}
      >
			</WTdialog>
			<UpdateGroupModal group={group} open={updateModalOpen || updating} handleClose={handleCloseUpdateForm} handleUpdate={handleUpdateGroup}/>
    </div>
  );
}

export default DeviceGroupHeader