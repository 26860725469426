/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { push } from 'connected-react-router';

import { ROUTES } from 'constants/routes';
import DeviceGroupDetail from './components/DeviceGroupDetail';
import DeviceGroupDevicesList from './components/DeviceGroupDevicesList';
import DeviceGroupHeader from './components/DeviceGroupHeader';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { Skeleton } from '@material-ui/lab';
import { DEFAULT_PAGE_NUMBER, PAGE_SIZE_OPTIONS } from './components/DeviceGroupDevicesList/constants';

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}


const DeviceGroup = ({ match }) => {
	const dispatch = useDispatch();

	const userRoles = useSelector((state) => state.auth.userRoles);
	const group = useSelector(
    (state) => state.iot.iotGroupOfDeviceDetail
	);
	const loading = useSelector((state) => state.iot.iotGroupOfDeviceDetailLoading);
	const deleting = useSelector((state) => state.iot.iotDeleteDeviceFromGroupEntity);
	const updating = useSelector((state) => state.iot.iotUpdateGroupEntityLoading);


	useEffect(() => {
		if (match.params.id) {
			dispatch(iotActions.getIotGroupOfDeviceDetail(match.params.id));
			dispatch(iotActions.getIotGroupDevices(match.params.id, DEFAULT_PAGE_NUMBER, PAGE_SIZE_OPTIONS[0]));
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (match.params.id) {
			dispatch(iotActions.getIotGroupDevices(match.params.id, DEFAULT_PAGE_NUMBER, PAGE_SIZE_OPTIONS[0]));
		}
	}, [deleting, dispatch, match.params.id])

	useEffect(() => {
		return () => {
			dispatch(iotActions.cleanIotGroupOfDeviceDetail());
		};
	}, [dispatch]);

	const handleGoBack = () => {
		dispatch(push(ROUTES.IOT_DEVICE_TYPES));
  };

	return (
		<div style={{ padding: 40, display:'flex', flexDirection:'column', flexGrow:1 }} >
			<DeviceGroupHeader loading={loading} group={group} onGoBack={handleGoBack} userRoles={userRoles}/>
			<DeviceGroupDetail loading={loading} group={group} userRoles={userRoles}/>
			<DeviceGroupDevicesList group={group} userRoles={userRoles}/>
		</div>
  );
};

export default DeviceGroup;
