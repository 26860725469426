export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]
export const DEFAULT_PAGE_NUMBER = 1
export const TIMESTAMP = 'timestamp';
export const GT = 'gt';
export const LT = 'lt';
export const SORT = {
	iot_type: ["iot_device", "iot_type", "name"],
	devuid: ["iot_device", "devuid"],
	device_install_situation: ["iot_device", "device_install_situation"],
	status_service: ["iot_device", "status_service"],
	last_signal: ["iot_device", "iot_device_brief", "last_signal"],
	last_payload_ul: ["iot_device", "iot_device_brief", "last_payload_ul"],
	status_operation_badge: ["iot_device", "status_operation"],
	iot_hierarchy: ["iot_device", "iot_hierarchy", "description"],
}