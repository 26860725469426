const emailjsDevelopment = {
  serviceId: 'service_tcdlaqo',
  templateId: 'template_0qzosac',
  userId: 'user_ejQ8foK3lEkjy1KiBwiMl',
}

const emailjsStaging = {
  serviceId: 'service_tcdlaqo',
  templateId: 'template_0qzosac',
  userId: 'user_ejQ8foK3lEkjy1KiBwiMl',
}

const emailjsProduction = {
  serviceId: 'service_tcdlaqo',
  templateId: 'template_0qzosac',
  userId: 'user_ejQ8foK3lEkjy1KiBwiMl',
}

export function emailjsConf() {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return emailjsDevelopment
    case 'stage':
      return emailjsStaging
    case 'prod':
      return emailjsProduction
    default:
      return emailjsDevelopment
  }
}
