import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, TextField, Typography } from '@material-ui/core'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import Tooltip from '@material-ui/core/Tooltip'
import WTdialog, { WTDIALOG_TYPE } from 'app/components/WTDialog'
import { roles, validateAccess } from 'constants/userRoles';

import styles from './style.module.scss'

function EditableField({ fieldValueA, fieldValueB, fieldValueSync, onUpdate, deviceParamId, fieldType, letEmptyValue, changesInProgress, dispatch }) {
  const [editOn, setEditOn] = React.useState(false)
  const [value, setValue] = React.useState('')
  const [origValue, setOrigValue] = React.useState('')
  const [showEditLabel, setShowEditLabel] = React.useState(false)
  const [type, setType] = React.useState('')
  const [allowEmptyValue, setAllowEmptyValue] = React.useState(false)
  const [alertSync, setAlertSync] = React.useState(false)
  const [syncMessage, setSyncMessage] = React.useState('NA')
  const [syncTooltip, setSyncTooltip] = React.useState('NA')
  const [accessUpdate, setAccessUpdate] = React.useState(false)

  // get actual userRoles
  const userRoles = useSelector(state => state.auth.userRoles)

  const [t] = useTranslation('deviceParam')


  React.useEffect(() => {
    setComponentAccess()
  }, [])

  React.useEffect(() => {
    setValue(fieldValueA)
    setOrigValue(fieldValueA)
    setType(fieldType)
    setAllowEmptyValue(!!letEmptyValue)
    setShowEditLabel(false)
    setSyncToolTipButton()
  }, [fieldValueA, fieldType, letEmptyValue])

  const setComponentAccess = () => {
    // functions update device param value (Button Edit && Button sync)
    if (fieldValueSync === 'actual') {
      const accessValueUpdate = validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_PARAMS_EDIT_VALUE);
      setAccessUpdate(accessValueUpdate);
    }
    // functions update device param value online (Button Button sync)
    if (fieldValueSync === 'online') {
      const accessValueOnlineUpdate = validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_PARAMS_EDIT_VALUE_ONLINE);
      setAccessUpdate(accessValueOnlineUpdate);
    }
  }

  const handleEdit = () => {
    setEditOn(true)
  }

  const handleSave = () => {
    if ((value && !origValue) || value !== origValue) {
      if (((type === 'number' && isPositiveInteger(value)) || type !== 'number') && isNotEmptyValue(value)) {
        setEditOn(false)
        setOrigValue(value)
        onUpdate(deviceParamId, value, fieldValueSync)
        setAlertSync(false)
        setShowEditLabel(true)
      }
    } else {
      setEditOn(false)
      setOrigValue(value)
    }
  }

  const handleCancel = () => {
    setValue(origValue)
    setEditOn(false)
  }

  const keyPress = e => {
    if (e.keyCode === 13) {
      handleSave()
    }
    if (e.keyCode === 27) {
      e.preventDefault()
      handleCancel()
    }
  }

  const isNotEmptyValue = e => {
    return allowEmptyValue || e.trim().length > 0
  }

  const isPositiveInteger = val => {
    let str = String(val)
    str = str.trim()
    if (!str) {
      return false
    }
    str = str.replace(/^0+/, '') || '0'
    const n = Math.floor(Number(str))
    return n !== Infinity && String(n) === str && n >= 0
  }

  const handleSyncAction = () => {
    setSyncMessageDialog();
    setAlertSync(true)
  }

  const handleConfirmSync = () => {
    onUpdate(deviceParamId, fieldValueB, fieldValueSync)
    setAlertSync(false)
  }

  const setSyncToolTipButton = () => {
    if (fieldValueSync === 'actual') {
      setSyncTooltip(t('deviceParamSyncTooltipButtonValue'));
    }

    if (fieldValueSync === 'online') {
      setSyncTooltip(t('deviceParamSyncTooltipButtonValueOnline'));
    }
  } 

  const setSyncMessageDialog = () => {
    let message = '';

    if (fieldValueSync === 'actual') {
      message = t('deviceParamSyncModalConfirmationTextValueToValueActual');
      message = message.toString().replace('ACTUAL_VALUE', (value || 'NA')).replace('ONLINE_VALUE', (fieldValueB || 'NA'));
    }

    if (fieldValueSync === 'online') {
      message = t('deviceParamSyncModalConfirmationTextValueToValueOnline');
      message = message.toString().replace('ACTUAL_VALUE', (fieldValueB || 'NA')).replace('ONLINE_VALUE', (value || 'NA'));
    }

    setSyncMessage(message);
  }

  const handleLetEditValue = () => {
    const isValueActual = fieldValueSync === 'actual'; 

    return isValueActual;
  }


  const handleLetSyncValue = () => {
    const differentValue = value !== fieldValueB;

    return differentValue;
  }



  return (
    <div>
      {!editOn? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div>
              <Typography color={showEditLabel ? 'primary' : ''} variant='body2'>
                {value}
              </Typography>
            </div>
            {!changesInProgress && (
              <div>
                {handleLetEditValue() && accessUpdate && (
                  <Tooltip title='Editar valor' aria-label='edit' placement='right'>
                    <EditRoundedIcon
                      className={styles.actionButton}
                      onClick={() => handleEdit()}
                      style={{ marginLeft: 10 }}
                      color='primary'
                      fontSize='small'
                    ></EditRoundedIcon>
                  </Tooltip>
                )}
                {handleLetSyncValue() && accessUpdate && (
                  <Tooltip title={syncTooltip} aria-label='edit' placement='right'>
                    <SyncAltOutlinedIcon
                      className={styles.actionButton}
                      onClick={() => handleSyncAction('valueActualFromValueOnline')}
                      style={{ marginLeft: 10 }}
                      color='primary'
                      fontSize='small'
                    ></SyncAltOutlinedIcon>
                  </Tooltip>
                )
                }
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
            <FormControlLabel
              className={styles.editField}
              value={value}
              control={<TextField onKeyDown={e => keyPress(e)} autoFocus value={value} />}
              onChange={event => setValue(event.target.value)}
            />
            <DoneRoundedIcon fontSize='small' className={styles.actionButton} onClick={() => handleSave()}></DoneRoundedIcon>
            <ClearRoundedIcon fontSize='small' className={styles.actionButton} onClick={() => handleCancel()}></ClearRoundedIcon>
          </div>
        )
      }
      {
        alertSync && (
          <WTdialog
          title={t('deviceParamSyncModalConfirmationTitle')}
          message={syncMessage}
          type={WTDIALOG_TYPE.WARNING}
          primaryActionLabel={t('deviceParamSyncModalConfirmationPrimaryActionLabel')}
          secondaryActionLabel={t('deviceParamSyncModalConfirmationSecondaryActionLabel')}
          show={alert}
          onPrimaryAction={handleConfirmSync}
          onSecondaryAction={() => setAlertSync(false)}
          onClose={() => setAlertSync(false)}
          />          
        )
      }
    </div>
  )
}

export default EditableField
