import Typography from '@material-ui/core/Typography'
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded'
import { formatLocale, formatSpecifier, precisionFixed } from 'd3-format'
import moment from 'moment'
import { Index, TimeSeries } from 'pondjs'
import React, { useEffect } from 'react'
import { BarChart, ChartContainer, ChartRow, Charts, YAxis } from 'react-timeseries-charts'
import Resizable from 'react-timeseries-charts/lib/components/Resizable'
import _ from 'underscore'
import ChartFilter from '../ChartFilter'
import styles from './styles.module.scss'

const getColors = filters => {
  const map = new Map()
  for (let i = 0; i < filters.length; i++) {
    for (let j = 0; j < filters[i].profile_conf.length; j++) {
      map.set(filters[i].profile_conf[j].aggregate_var, '#93f1ff')
    }
  }
  return map
}

const style = (column, event) => {
  const color = '#93f1ff'
  return {
    normal: {
      fill: color,
      opacity: 1.0,
    },
    highlighted: {
      fill: '#CCFAFF',
      stroke: 'none',
      opacity: 1.0,
    },
    selected: {
      fill: '#CCFAFF',
      opacity: 1.0,
    },
    muted: {
      stroke: 'none',
      opacity: 0.4,
      fill: color,
    },
  }
}

// d3-format local config
const locale = formatLocale({
  decimal: ',',
  thousands: '.',
  grouping: [3],
})

//Format decimal notation with an SI prefix, rounded to significant digits.
const s = formatSpecifier('f')
s.precision = precisionFixed(0.0001)
const format = locale.format(s)

//Format fixed point notation
const l = formatSpecifier('f')
l.precision = precisionFixed(0.0001)
const formatL = locale.format(l)

function checkAllEmpty(seriesObject) {
  for (var key in seriesObject) {
    if (seriesObject[key] !== null && seriesObject[key].length > 0) {
      return false
    }
    return true
  }
}

/**
 * Componente de grafica para Perfil de Carga. Tipo de dispositivo TELEC PS (slave y standalone)
 */

const CargaTelecPs = ({ filters, series, containerHeight }) => {
  const [y, setY] = React.useState(null)
  const [max, setMax] = React.useState(null)
  const [timerange, setTimerange] = React.useState(null)
  const [highlight, setHighlight] = React.useState(null)
  const [selection, setSelection] = React.useState(null)
  const [infoValues, setInfoValues] = React.useState([])
  const [powerSeries, setPowerSeries] = React.useState(null)
  const [visible, setVisible] = React.useState(true)
  const [units, setUnits] = React.useState('')
  const [noData, setNoData] = React.useState(false)

  useEffect(() => {
    if (series && !checkAllEmpty(series)) {
      setNoData(false)
      // extract only one serie data
      const serieDataKeys = Object.keys(series);
      const serieDataKey = serieDataKeys[0];
      const serieValues = series[`${serieDataKey}`];

      if (!serieValues.length)
        setNoData(true);
      else
        handleData(series[`${serieDataKey}`]); 
    } else {
      setNoData(true);
    }
  }, [series])

  useEffect(() => {
    if (filters) {
      setUnits(filters[0].profile_conf[0].aggregate_var_unit)
    }
  }, [filters])

  useEffect(() => {
    if (highlight) {
      const type = highlight.event.get('type') === 'E' ? '*' : ''
      const powerValue = `${format(highlight.event.get(highlight.column))}${type} ${units}`
      setInfoValues([{ label: 'Pot. Media', value: powerValue }])
    }
  }, [highlight])

  const handleMouseMove = (x, y) => {
    setY(y)
  }

  const handleTimeRangeChange = timerange => {
    setTimerange(timerange)
  }

  const handleChartVariablesSelectionChange = (groupId, variableStates) => {
    setVisible(variableStates[0])
  }

  const handleData = seriesData => {
    const power = []
    let max = 0
    let timePrev = ''
    _.each(seriesData, val => {
      const timestamp = val['time']
      if (timestamp !== timePrev) {
        let powerValues = val['value']
        let valueType = val['type']
        if (max < powerValues) {
          max = powerValues
        }
        power.push([timestamp, powerValues, valueType])
        timePrev = timestamp
      }
    })
    max = Number(max)

    const powerSeries = new TimeSeries({
      name: 'Power',
      columns: ['index', 'power', 'type'],
      points: power.map(([d, value, type]) => [Index.getIndexString('15m', new Date(d)), value, type]),
    })

    let rangeTime
    if (powerSeries) {
      rangeTime = powerSeries.range()
    }
    setPowerSeries(powerSeries)
    setTimerange(rangeTime)
    setMax(max)
  }

  const begin = moment(selection?.event.timerange().begin()).format('D MMM HH:mm')
  const end = moment(selection?.event.timerange().end()).format('D MMM HH:mm')
  const selectedDate = selection ? `${begin} - ${end} ` : '--'
  const selectedValue = selection ? `${formatL(+selection.event.value(selection.column))} ${units}` : ''
  const selectedValueType = selection ? `${selection.event.value('type') === 'E' ? 'E' : 'R'}` : ''

  return powerSeries && !noData ? (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', marginBottom: 30 }}>
        {filters?.map((profile, index) => (
          <ChartFilter
            key={profile.id}
            visible={true}
            groupIndex={index}
            groupId={profile.id}
            colors={getColors(filters)}
            groupTitle={profile.title}
            variables={profile.profile_conf.map(conf => ({ id: conf.aggregate_var, name: conf.title }))}
            onSelectionChange={handleChartVariablesSelectionChange}
          />
        ))}
      </div>
      {powerSeries ? (
        <div style={{ flex: 1 }}>
          <Resizable>
            <ChartContainer
              timeRange={timerange}
              enablePanZoom={true}
              onMouseMove={(x, y) => handleMouseMove(x, y)}
              onTimeRangeChanged={timeRange => handleTimeRangeChange(timeRange)}
              onBackgroundClick={() => setSelection(null)}
              minDuration={180 * 60 * 1000}
              format={'%d-%b %H:%M'}
            >
              <ChartRow height={containerHeight - 160} trackerShowTime={false}>
                <YAxis
                  id="powerAxis"
                  label={filters[0].title}
                  min={0}
                  max={max * 1.02}
                  width="50"
                  format={n => format(n)}
                  type="linear"
                  style={{
                    ticks: {
                      stroke: '#AAA',
                      opacity: 0.25,
                      'stroke-dasharray': '1,1',
                    },
                  }}
                  showGrid
                />
                <Charts>
                  <BarChart
                    axis="powerAxis"
                    style={style}
                    spacing={0.5}
                    minBarHeight={1}
                    columns={['power']}
                    series={powerSeries}
                    info={infoValues}
                    infoWidth={140}
                    infoStyle={{ box: { strokeWidth: 1, stroke: '#CCC', fill: '#4E5153', opacity: 0.9, pointerEvents: 'none' } }} // you have to pass the box object inside the first object to make it work
                    markerRadius={3}
                    markerStyle={{ fill: '#fff' }}
                    infoTimeFormat={index => moment(index.begin()).format('D MMM HH:mm')}
                    highlighted={highlight}
                    onHighlightChange={highlight => setHighlight(highlight)}
                    selected={selection}
                    onSelectionChange={selection => setSelection(selection)}
                    visible={visible}
                  />
                  <HorizontalLine y={y} />
                </Charts>
              </ChartRow>
            </ChartContainer>
          </Resizable>
          <div style={{ minHeight: 30, position: 'absolute', top: 10, right: 10, fontSize: 13, fontWeight: 500 }}>
            {selection ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AccessAlarmsRoundedIcon style={{ marginRight: 10 }}></AccessAlarmsRoundedIcon>
                  {selectedDate}
                </div>
                <div style={{ color: '#93f1ff', fontSize: '1.5vw' }}>{selectedValue}</div>
                <div style={{ color: '#93f1ff', fontSize: 11, opacity: 0.7 }}>{selectedValueType === 'E' ? '* Valor estimado' : ''}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <div className={styles.noData}>
      <Typography variant="subtitle">No hay registros</Typography>
    </div>
  )
}
export default CargaTelecPs

function HorizontalLine({ y, width }) {
  const style = { pointerEvents: 'none', stroke: '#ccc', strokeDasharray: '1,5' }
  return y ? (
    <g>
      <line style={style} x1={0} y1={y} x2={width} y2={y} />
    </g>
  ) : (
    <g />
  )
}
