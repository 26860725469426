import { Button, IconButton, Typography } from '@material-ui/core'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import PortableWifiOffRoundedIcon from '@material-ui/icons/PortableWifiOffRounded'
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded'
import OperationBadge from 'app/components/OperationBadge'
import WTModal from 'app/components/WTModal'
import { getDeviceIcon } from 'constants/icons'
import { OPERATION_STATUS } from 'constants/iot'
import moment from 'moment-timezone'
import MUIDataTable from 'mui-datatables'
import { any, arrayOf, func, shape } from 'prop-types'
import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { getIn } from 'seamless-immutable'
import DeviceDetailInfo from '../../../DeviceDetailInfo'
import styles from './styles.module.scss'
import { getTableData } from './tableProvider'
import { PAGE_SIZE_OPTIONS } from './constants'

const Devices = ({ listDevices, onDeviceSelect, handleOpenSidebarInfo, iotTypeName, iotTypeDescription, onGoBack, id }) => {
  const [t] = useTranslation('devices')

  const [tableData, setTableData] = useState([])
  const dispatch = useDispatch()
  const [fullscreen, setFullscreen] = React.useState(false)

  const [detailModalOpen, setDetailModalOpen] = React.useState(false)

  const handleToggleFullScreen = () => {
    setFullscreen(!fullscreen)
  }

  const handleDetailModalOpen = () => {
    setDetailModalOpen(true)
  }

  const handleDetailModalClose = () => {
    setDetailModalOpen(false)
  }

  useEffect(() => {
    let data = getTableData(listDevices, t)
    setTableData(data)
    return () => {
      dispatch(iotActions.cleanIotDevices())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDevices])

  const columns = [
    {
      name: 'uid',
      label: t('devicesTableDevuidTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'installed',
      label: t('devicesTableConnectedNetworkTitleColumn'),
      options: {
        sort: true,
      },
    },

    {
      name: 'connectionStatus',
      label: t('devicesTableStatusServiceTitleColumn'),
      options: {
        sort: true,
        sortCompare: order => (obj1, obj2) => {
          return order === 'asc' ? obj1.data.status.localeCompare(obj2.data.status) : -obj1.data.status.localeCompare(obj2.data.status)
        },
        customBodyRender: data => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.status === t('on') ? (
                  <WifiTetheringRoundedIcon style={{ fontSize: 20 }}></WifiTetheringRoundedIcon>
                ) : (
                  <PortableWifiOffRoundedIcon style={{ fontSize: 20 }}></PortableWifiOffRoundedIcon>
                )}
                <div style={{ marginLeft: 10 }}>{data.status}</div>
              </div>
              <Typography className={styles.statusDate}>{data.date}</Typography>
            </div>
          )
        },
      },
    },
    {
      name: 'deviceLastSignal',
      label: t('deviceLastSignal'),
      options: {
        sort: true,
        sortCompare: order => (obj1, obj2) => {
          let val1 = obj1.data === '' ? Infinity : moment(obj1.data, 'DD/MM/YYYY').valueOf()
          let val2 = obj2.data === '' ? Infinity : moment(obj2.data, 'DD/MM/YYYY').valueOf()
          let res = val1 - val2
          return order === 'asc' ? res : -res
        },
      },
    },
    {
      name: 'deviceLastPayload',
      label: t('deviceLastMeasure'),
      options: {
        sort: true,
        sortCompare: order => (obj1, obj2) => {
          let val1 = obj1.data === 'No disponible' ? Infinity : moment(obj1.data, 'DD/MM/YYYY').valueOf()
          let val2 = obj2.data === 'No disponible' ? Infinity : moment(obj2.data, 'DD/MM/YYYY').valueOf()
          let res = val1 - val2
          return order === 'asc' ? res : -res
        },
      },
    },
    {
      name: 'available',
      label: t('devicesTableAvailableTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'hierarchy',
      label: 'Jerarquía',
      options: {
        sort: true,
      },
    },
    {
      name: 'statusOperation',
      label: 'Estado de operación',
      options: {
        sort: true,
        customBodyRender: data => {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {data !== OPERATION_STATUS.OK && (
                <OperationBadge type={data} label={data === OPERATION_STATUS.ALERT ? t('alert') : t('warning')}></OperationBadge>
              )}
            </div>
          )
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        sort: false,
        customBodyRenderLite: data => {
          return (
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                onDeviceSelect(tableData[data].deviceObject)
                handleDetailModalOpen()
              }}
            >
              Ver ficha
            </Button>
          )
        },
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    download: true,
    filterType: 'textField',
    selectableRows: 'none',
		rowsPerPage: 50,
		rowsPerPageOptions: PAGE_SIZE_OPTIONS,
    responsive: 'vertical',
    tableBodyHeight: 'calc(100% - 120px)',
    confirmFilters: true,
    viewColumns: false,
    setTableProps: () => ({
      size: 'small',
    }),
    setRowProps: (row, index) => {
      return {
        className:
          tableData[index].statusOperation === 'R' ? styles.backRowAlert : tableData[index].statusOperation === 'Y' ? styles.backRowWarning : null,
      }
    },
    downloadOptions: {
      filename: `${iotTypeName}-${moment().format('DD_MM_YYYYY_HH_mm')}.csv`,
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const csvData = data.map(row => {
        return {
          index: row.index,
          data: row.data.map((cell, i) => {
            if (i === 2) {
              return `${cell.status} ${cell.date ? ' - ' : ''} ${cell.date}`
            }
            if (i === 7) {
              switch (cell) {
                case OPERATION_STATUS.ALERT:
                  return t('alert')
                case OPERATION_STATUS.WARNING:
                  return t('warning')
                case OPERATION_STATUS.OK:
                  return t('ok')
                default:
                  break
              }
            }
            return cell
          }),
        }
      })
      let val = `${buildHead(columns)}${buildBody(csvData)}`.trim()
      return val
    },
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button variant="contained" onClick={applyNewFilters}>
            Aplicar filtros
          </Button>
        </div>
      )
    },
    textLabels: {
      body: {
        noMatch: 'No hay registros que coincidan con la búsqueda',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      <MUIDataTable
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton style={{ marginRight: 20 }} onClick={onGoBack}>
              <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
            </IconButton>
            {getDeviceIcon(iotTypeName, styles.deviceIcon)}
            <Typography variant="h5" style={{ marginLeft: 20 }}>
              {iotTypeDescription ? iotTypeDescription : iotTypeName}
            </Typography>
          </div>
        }
        data={tableData}
        columns={columns}
        options={options}
      />
      <WTModal open={detailModalOpen} onClose={handleDetailModalClose} onFullscreen={handleToggleFullScreen} fullscreen={fullscreen}>
        <DeviceDetailInfo key={id} fullscreen={fullscreen}></DeviceDetailInfo>
      </WTModal>
    </>
  );
}

const mapStateToProps = store => ({
  id: getIn(store, ['iot', 'iotDeviceDetail', 'id']),
})

Devices.propTypes = {
  listDevices: arrayOf(shape(any)),
  onDeviceSelect: func,
  handleOpenSidebarInfo: func,
}

export default memo(connect(mapStateToProps)(Devices))
