import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Fade, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core'
import AddAlarmRoundedIcon from '@material-ui/icons/AddAlarmRounded';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import moment from 'moment-timezone'
import MUIDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { ArrowBackRounded } from '@material-ui/icons';
import { push } from 'connected-react-router'

import WTdialog from 'app/components/WTDialog'
import { WTDIALOG_TYPE } from 'app/components/WTDialog';
import SaveCronModal from './components/SaveCronModal'
import { ALWAYS_VISIBLE, getTypeNameByTypeCode } from 'constants/iot';
import CronExecutionsModal from './components/CronExecutionsModal';
import { getDeviceIcon } from 'constants/icons';
import { ROUTES } from 'constants/routes';
import { Skeleton } from '@material-ui/lab';
import { roles, validateAccess } from 'constants/userRoles';

const renderSkeleton = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton animation='wave' height={60} width={600} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div style={{ width: '100%' }}>
            <Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
						<Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
          </div>
        </div>
      </div>
    )
  }

const DeviceTypeCronsList = ({onGoBack, deviceType, reportId}) => {
	const [t] = useTranslation('devices')

  	const dispatch = useDispatch()
	const [tableData, setTableData] = useState([])
	const [anchorEl, setAnchorEl] = useState(null);
	const [cronIdSelected, setCronIdSelected] = useState(null)
	const [openElem, setOpenElem] = useState(null);
	const [disableModalOpen, setDisableModalOpen] = useState(false)
	const [enableModalOpen, setEnableModalOpen] = useState(false)
	const [saveCron, setSaveCron] = useState(false)
	const [editing, setEditing] = useState(false)
	const [cronExecutions, setCronExecutions] = useState(false)
	const [hasRoleCreateReportCron, setHasRoleCreateReportCron] = useState(false)
	const [hasRoleToogleAvailabityReportCron, setHasRoleToogleAvailabityReportCron] = useState(false)
	const [hasRoleEditReportCron, setHasRoleEditReportCron] = useState(false)
	const [hasRoleAccessExecutionsReportCron, setHasRoleAccessExecutionsReportCron] = useState(false)

	const deviceTypeId = useSelector((state) => state.iot.iotDevicesType).find(item => item.name === deviceType)?.id
	const cronsList = useSelector((state) => state.iot.iotReportCrons);
	const loading = useSelector((state) => state.iot.iotReportCronsLoading);
	const reportName = useSelector(
    (state) => state.iot.iotReportDetail[0]?.name
	);
	const iotDevicesTypesReports = useSelector(state => state.iot.iotDevicesTypesReports)
	const iotReportDetailLoading = useSelector(state => state.iot.iotReportDetailLoading)
	const userName = useSelector((state) => state.auth.authInformation?.preferredUsername);
	const userRoles = useSelector(state => state.auth.userRoles)

	const setComponentAccess = () => {
		// button create report cron
		setHasRoleCreateReportCron(validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_CRON_CREATE));
		// option action Edit report Cron
		setHasRoleEditReportCron(validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_CRON_EDIT));
		// option action toogle status availability 
		setHasRoleToogleAvailabityReportCron(validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_CRON_STATUS_TOOGLE));
		// option access to executions modal table
		setHasRoleAccessExecutionsReportCron(validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_CRON_EXECUTION_ACCESS));
	}

	useEffect(() => {
		setTableData(cronsList)
		setComponentAccess()
	}, [])

	useEffect(() => {
		if (iotDevicesTypesReports.length > 0) {
			let devTypeReport = iotDevicesTypesReports.find(type => type.id === parseInt(deviceTypeId))
			if (!devTypeReport) {
				dispatch(push(ROUTES.IOT_DEVICE_TYPES))
			} else {
				dispatch(iotActions.getIotReportDetail(reportId))
			}
		}
	}, [iotDevicesTypesReports, reportName]);

	const menuActions = [
		{
			name: t('deviceCronsListMenuActionExecutions'),
			visibility: ALWAYS_VISIBLE
		},
   	 	{
			name: t('deviceCronsListMenuActionEdit'),
			visibility: true
		},
		{
			name: t('deviceCronsListMenuActionEnable'),
			visibility: false
		},
    	{
			name: t('deviceCronsListMenuActionDisable'),
			visibility: true
		},
	];

	const handleClose = () => {
		setAnchorEl(null);
		setOpenElem(null);
	};

	const handleOpenCreateCron = () => {
		setSaveCron(true)
	}

	const handleOpenEditCron = async (values) => {
		setSaveCron(true)
	}

	const handleCloseDisableModal = () => {
		setDisableModalOpen(false)
	}

	const handleCloseEnableModal = () => {
		setEnableModalOpen(false)
	}

	const handleCloseEditCron = () => {
		setSaveCron(false)
	}

	const handleCloseCronExecutions = () => {
		setCronExecutions(false)
	}

	const disableAction = async () => {
		await dispatch(iotActions.toggleAvailabilityCronReport(cronIdSelected, false))
		await dispatch(iotActions.getIotReportCrons(reportId));
		setDisableModalOpen(false)
	}

	const enableAction = async () => {
		await dispatch(iotActions.toggleAvailabilityCronReport(cronIdSelected, true))
		await dispatch(iotActions.getIotReportCrons(reportId));
		setEnableModalOpen(false)
	}

const handleAction = async (action, cronId) => {
		setCronIdSelected(cronId)
		if (action === t('deviceCronsListMenuActionEdit')) {
			setEditing(true)
			await dispatch(iotActions.getIotReportCronDetail(cronId))
			setSaveCron(true)
		}
		if (action === t('deviceCronsListMenuActionDisable')) {
			setDisableModalOpen(true)
		}
		if (action === t('deviceCronsListMenuActionEnable')) {
			setEnableModalOpen(true)
		}
		if (action === t('deviceCronsListMenuActionExecutions')) {
			await dispatch(iotActions.getIotReportCronDetail(cronId))
			setCronExecutions(true)
		}
		handleClose()
}

const handleClick = (elem) => (event) => {
	setOpenElem(elem);
	setAnchorEl(event.currentTarget);
};

const userHasRoleForAnyAction = () => {
	return hasRoleEditReportCron || hasRoleToogleAvailabityReportCron || hasRoleAccessExecutionsReportCron
}

const userHasRoleForAction = (action) => {
	if (action === t('deviceCronsListMenuActionEdit'))
		return hasRoleEditReportCron

	if (action === t('deviceCronsListMenuActionDisable') || action === t('deviceCronsListMenuActionEnable'))
		return hasRoleToogleAvailabityReportCron

	if (action === t('deviceCronsListMenuActionExecutions'))
		return hasRoleAccessExecutionsReportCron
	
	return false;
}

const isUserOwnerForAction = (action, reportCronOwner) => {
	switch (action) {
		case t('deviceCronsListMenuActionEdit'):
		case t('deviceCronsListMenuActionDisable'):	
		case t('deviceCronsListMenuActionEnable'):	
			return reportCronOwner === userName
		
		case t('deviceCronsListMenuActionExecutions'):
			return true;
		
		default:
			return false;
	}
}

const actionAvailableForUser = (action, reportCron) => {
	const { name: actionName } = action;
	const { owner: reportCronOwner } = reportCron;

	// check role's user
	const userHasRole = userHasRoleForAction(actionName)

	// check reportCron owner
	const userOwnerAction = isUserOwnerForAction (actionName, reportCronOwner)

	return userHasRole && userOwnerAction;
}

const columns = [
    {
      	name: 'title',
      	label: t('deviceCronsListTitleColumn'),
      	options: {
        	sort: true,
      	},
    },
    {
    	name: 'description',
      	label: t('deviceCronsListDescriptionColumn'),
      	options: {
        	sort: true,
      	},
	},
	{
    	name: 'owner',
      	label: t('deviceCronsListOwnerColumn'),
      	options: {
        	sort: true,
      	},
	},
	{
      	name: 'cron_literal',
      	label: t('deviceCronsListCronLiteralColumn'),
      	options: {
        	sort: true,
      	},
    },
    {
      	name: 'next_executionAt_expected',
      	label: t('deviceCronsListNextExecutionColumn'),
		options: {
			customBodyRenderLite: (dataIndex) => {
				const dateTime = tableData[dataIndex].next_executionAt_expected
				return dateTime ? moment(dateTime, 'YYYY/MM/DDTHH:mm').format('DD/MM/YYYY HH:mm') : ''
			},
        sort: true,
        sortCompare: order => (obj1, obj2) => {
			let val1 = obj1.data === null ? Infinity : moment(obj1.data, 'YYYY/MM/DD').valueOf()
          	let val2 = obj2.data === null ? Infinity : moment(obj2.data, 'YYYY/MM/DD').valueOf()
          	let res = val1 - val2
          	return order === 'asc' ? res : -res
        },
      },
	},
	{
    	name: 'last_executionAt_completed',
      	label: t('deviceCronsListLastExecutionColumn'),
		options: {
			customBodyRenderLite: (dataIndex) => {
				const dateTime = tableData[dataIndex].last_executionAt_completed
				return dateTime ? moment(dateTime, 'YYYY/MM/DDTHH:mm').format('DD/MM/YYYY HH:mm') : ''
			},
       	 	sort: true,
        	sortCompare: order => (obj1, obj2) => {
				let val1 = obj1.data === null ? Infinity : moment(obj1.data, 'YYYY/MM/DD').valueOf()
          		let val2 = obj2.data === null ? Infinity : moment(obj2.data, 'YYYY/MM/DD').valueOf()
          		let res = val1 - val2
          		return order === 'asc' ? res : -res
        	},
      	},
    },
	{
    	name: 'total_contacts',
      	label: t('deviceCronsListTotalContactsColumn'),
      	options: {
			sort: true,
			customBodyRender: destinations => {
				return (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{destinations}
					</div>
				)
			},
			},
	},
	{
    	name: 'available',
      	label: t('deviceCronsListAvailableColumn'),
      	options: {
			sort: true,
			customBodyRenderLite: dataIndex => {
				const available = tableData[dataIndex].available ? 'Habilitado' : 'Deshabilitado'
				return (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						{available}
					</div>
				)
			},
		},
    },
    {
      name: '',
      label: '',
      options: {
        sort: false,
        customBodyRenderLite: index => {
			const userHasAnyRoleForAction = userHasRoleForAnyAction()
			return ( userHasAnyRoleForAction ? (
	            	<>
					<Tooltip title={t('deviceGroupHeaderTooltipOptions')}>
						<IconButton onClick={handleClick(index)} style={{ display: 'flex', alignItems: 'center' }}>
							<MoreVertRoundedIcon />
						</IconButton>
					</Tooltip>
					<Menu
						id="fade-menu"
						anchorEl={anchorEl}
						// keepMounted
						open={openElem === index}
						onClose={handleClose}
						TransitionComponent={Fade}
					>
						{menuActions.filter(action => action.visibility === tableData[index].available || action.visibility === ALWAYS_VISIBLE).map((action, i) => {
							return (actionAvailableForUser(action, tableData[index]) ?
								<MenuItem
									key={tableData[index].id}
									onClick={() => handleAction(action.name, tableData[index].id)}
								>
									{action.name}
								</MenuItem> : null);
						})}
					</Menu>
					</>) : 
					<></>
			)
        },
      },
    },
]

const options = {
    elevation: 1,
    filter: false,
		print: false,
		download: false,
		search: false,
    filterType: 'textField',
    selectableRows: 'none',
    rowsPerPage: 10,
		responsive: 'vertical',
		tableBodyHeight: 'calc(100% - 120px)',
    confirmFilters: true,
    viewColumns: false,
    setTableProps: () => ({
      size: 'small',
    }),
    setRowProps: (row, index) => {
      return {
        className:
          !tableData[index].available ? styles.notActive : null,
      }
	},
	customToolbar: () => {
		return (
			hasRoleCreateReportCron ? (
				<div style={{ display: 'inline-flex' }}>
					<div>
						<Tooltip title='Agregar programación'>
							<IconButton onClick={handleOpenCreateCron}>
								<AddAlarmRoundedIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			) : (<div></div>)
      	);
	},
    textLabels: {
      body: {
        noMatch: 'Sin registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
}

  return (
    <>
      	{
			loading || iotReportDetailLoading || !reportName
			? renderSkeleton()
			: <MUIDataTable
				title={
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<IconButton onClick={() => onGoBack(deviceType)}>
							<ArrowBackRounded></ArrowBackRounded>
						</IconButton>
						<Tooltip title={deviceType}>
							{getDeviceIcon(deviceType)	}
						</Tooltip>
							{ iotReportDetailLoading || !reportName ? 
								<Skeleton style={{marginLeft: 20}} variant="rect" width={500} height={30} /> : 
								<Typography variant="h5" style={{ marginLeft: 10, marginRight: 10 }}>{`${getTypeNameByTypeCode(deviceType)} - Reportes - ${reportName} - Programación`}</Typography>
							}
						</div>
        		}
				data={tableData}
				columns={columns}
				options={options}
			/>
		}
		<WTdialog
			type={WTDIALOG_TYPE.WARNING}
			title={t('deviceCronsListDisableDialogTitle')}
			message={t('deviceCronsListDisableDialogMessage')}
			show={disableModalOpen}
			primaryActionLabel={t('deviceCronsListDisableDialogPrimary')}
			onPrimaryAction={disableAction}
			secondaryActionLabel={t('deviceCronsListDisableDialogSecondary')}
			onSecondaryAction={handleCloseDisableModal}
			onClose={handleCloseDisableModal}
      	>
		</WTdialog>
		<WTdialog
			type={WTDIALOG_TYPE.WARNING}
			title={t('deviceCronsListEnableDialogTitle')}
			message={t('deviceCronsListEnableDialogMessage')}
			show={enableModalOpen}
			primaryActionLabel={t('deviceCronsListEnableDialogPrimary')}
			onPrimaryAction={enableAction}
			secondaryActionLabel={t('deviceCronsListEnableDialogSecondary')}
			onSecondaryAction={handleCloseEnableModal}
			onClose={handleCloseEnableModal}
      	>
		</WTdialog>
		{saveCron ? 
			<SaveCronModal
				open={saveCron}
				handleClose={handleCloseEditCron}
				handleEdit={handleOpenEditCron}
				deviceTypeId={deviceTypeId}
				cronId={cronIdSelected}
				editing={editing}
			/> : null }
			{cronExecutions
				? <CronExecutionsModal
				reportCronId={cronIdSelected}
				open={cronExecutions}
				handleClose={handleCloseCronExecutions}
			/> : null }
    </>
  );
}

export default DeviceTypeCronsList;
