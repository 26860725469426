import apiIOT from 'config/api'
import { AUTH_HEADER } from 'constants/apiHeaders'
import { IOTDEVICE } from 'constants/apiResources'
import store from 'redux/store'

/**
 * *async* **Graphic Service of devices**
 * @param {*} type graphic type id
 * @param {*} id device id 
 * @param {*} params
 
 * @returns {*} 
 */
export const graphicSeries = async (type, id, params) => {
  const state = store.getState()
  let auhtInformation = state.auth['authInformation']

  let endpoint = IOTDEVICE.DEVICE_GRAPHIC_SERIES
  endpoint = endpoint.replace('{{PATH-IOT_GRAPHIC_ID}}', type)
  endpoint = endpoint.replace('{{PATH-DEVICE_ID}}', id)

  if (params?.startDate && params?.endDate) {
    const startDate = params?.startDate
    const endDate = params?.endDate
    let queryParams = `?startDate={{PARAMS-START_DATE}}&endDate={{PARAMS-END_DATE}}`
    queryParams = queryParams.replace('{{PARAMS-START_DATE}}', JSON.parse(JSON.stringify(startDate)))
    queryParams = queryParams.replace('{{PARAMS-END_DATE}}', JSON.parse(JSON.stringify(endDate)))
    endpoint = endpoint + queryParams
  }

  apiIOT.setHeaders({
    ...apiIOT.headers,
    [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`,
  })
  const response = await apiIOT.get(endpoint).catch(thrown => {
    console.log('Request graphicIoT - error', thrown)
    if (apiIOT.isCancel(thrown)) {
      console.log('Request graphicIoT - cancelled', thrown.message)
    } else {
      console.log('Request reportIoT - cancelled err', thrown.message)
    }
  })
  return response?.data
}
