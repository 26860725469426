/* eslint-disable react/prop-types */

import { Grid } from '@material-ui/core'
import { push } from 'connected-react-router'
import { ROUTES } from 'constants/routes'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import Devices from './screens/Devices'

function DeviceType({ match }) {
  const dispatch = useDispatch()
  const userRoles = useSelector(state => state.auth.userRoles)
  const deviceSelected = useSelector(state => state.iot.iotDeviceDetail)
  const [iotTypeDescription, setIotTypeDescription] = React.useState('')

	const iotDevicesType = useSelector(state => state.iot.iotDevicesType)

	React.useEffect(() => {
		if (iotDevicesType.length > 0) {
			let devTypeReport = iotDevicesType.find(type => type.name === match.params.type)
			if (!devTypeReport) {
				dispatch(push(ROUTES.IOT_DEVICE_TYPES))
			} else {
				setIotTypeDescription(devTypeReport?.description)
			}
		}
	 }, [iotDevicesType])

  const handleGoBack = () => {
    if (deviceSelected) dispatch(iotActions.cleanSelectedDevice())
    dispatch(push(ROUTES.IOT_DEVICE_TYPES))
  }

  return (
    <Grid container style={{ padding: 40 }} direction="column">
      <Devices userRoles={userRoles} iotTypeName={match.params.type} iotTypeDescription={iotTypeDescription} onGoBack={handleGoBack}></Devices>
    </Grid>
  )
}

export default DeviceType
