import { Button, Divider, Grid, Typography } from '@material-ui/core'
import OperationBadge from 'app/components/OperationBadge'
import { LOGICAL_UNIT_BUILD, LOGICAL_UNIT_CT, LOGICAL_UNIT_MTR } from 'constants/icons'
import { OPERATION_STATUS } from 'constants/iot'
import React from 'react'
import styles from './styles.module.scss'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import RoomRoundedIcon from '@material-ui/icons/RoomRounded'

function IconTag({ tag, ...props }) {
  const components = {
    CT: LOGICAL_UNIT_CT,
    MTR: LOGICAL_UNIT_MTR,
    BUILD: LOGICAL_UNIT_BUILD,
  }
  const TagName = components[tag]
  return TagName ? <TagName {...props} /> : null
}

function LogicalUnitTooltip({ tooltipContent, onClose, onDetail }) {
  if (tooltipContent.content) {
    return (
      <div
        id='tooltip_hierarchy'
        className={styles.tooltipLogicalUnit}
        style={{
          left: `${tooltipContent.x}`,
          top: `${tooltipContent.y}`,
          opacity: `${tooltipContent.opacity}`,
          zIndex: `${tooltipContent.zIndex}`,
        }}
      >
        <Grid container direction='row' justifyContent='space-between' alignItems='center' style={{ marginBottom: 10 }}>
          <Typography className={styles.title}>
            <IconTag className={styles.iconType} tag={tooltipContent.content?.iot_logical_unit_type?.internal_name}></IconTag>
            {tooltipContent.content.external_id}
          </Typography>
          <ClearRoundedIcon className={styles.closeIcon} onClick={() => onClose()} />
        </Grid>
        <Divider className={styles.divider}></Divider>
        <Grid container direction='row' justifyContent='space-between' alignItems='center' style={{ marginTop: 10 }}>
          <Typography variant='body1'>{tooltipContent.content.external_name}</Typography>
          {tooltipContent.content.status_operation === 'Y' && <OperationBadge type={OPERATION_STATUS.WARNING} label={'Atención'} />}
          {tooltipContent.content.status_operation === 'R' && <OperationBadge type={OPERATION_STATUS.ALERT} label={'Alerta'} />}
        </Grid>

        <Grid container direction='row' justifyContent='space-between' alignItems='center' style={{ marginTop: 10, opacity: 0.5 }}>
          <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
            <RoomRoundedIcon style={{ marginRight: 4, height: 18 }}></RoomRoundedIcon>
            <Typography variant='body2'>
              {tooltipContent.content.geo_position?.geografic_info.address ? `${tooltipContent.content.geo_position?.geografic_info.address}, ` : ''}
              {tooltipContent.content.geo_position?.geografic_info.city ? `${tooltipContent.content.geo_position?.geografic_info.city}, ` : ''}
              {tooltipContent.content.geo_position?.geografic_info.locality}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='flex-end' alignItems='center' style={{ marginTop: 12 }}>
          <Button variant='outlined' onClick={() => onDetail(tooltipContent.content, tooltipContent.coordinates)}>
            Ver Detalle
          </Button>
        </Grid>
      </div>
    )
  } else {
    return null
  }
}

export default LogicalUnitTooltip
