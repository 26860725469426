import Immutable from 'seamless-immutable'

import { actions } from './actions'

const defaultState = {
  authInformation: {},
  authInformationLoading: true,
  logout: null,
}

export function reducer(state = Immutable(defaultState), action) {
  switch (action.type) {
    case actions.AUTH_INIT: {
      return Immutable.merge(state, { authInformationLoading: true })
    }
    case actions.AUTH_INIT_SUCCESS: {
      return Immutable.merge(state, { ...action.payload, authInformationLoading: false })
    }
    case actions.AUTH_INIT_FAILURE: {
      return Immutable.merge(state, { authInformationLoading: false })
    }
    case actions.SIGN_OUT: {
      return state.merge({ authInformation: null, authInformationLoading: false })
    }
    default:
      return state
  }
}
