import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Card, Grid, Tooltip, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import PortableWifiOffRoundedIcon from '@material-ui/icons/PortableWifiOffRounded'
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded'
import AnnouncementRoundedIcon from '@material-ui/icons/AnnouncementRounded';
import OperationBadge from 'app/components/OperationBadge'
import ErrorBadge from 'app/components/ErrorBadge'
import { OPERATION_STATUS, ERROR_TYPE } from 'constants/iot'
import { formatDateTime } from 'utils/dateUtils';
import styles from './styles.module.scss'

const DetailInformation = ({ loading, deviceDetail, userRoles }) => {
  const [t] = useTranslation('devices')

  const formatDeviceAvailable = isAvailable => {
    const deviceAvailable = isAvailable ? 'Si' : 'No';
    return deviceAvailable;
  }

  const formatIsAliveLevel = (isalive) => {
    if (!isalive) return '';

    const {signal_level: signalLevel, conn_type: connType, signal_level_unit: signalLevelUnit} = isalive;

    let isAliveLevel = '';

    if (signalLevel) isAliveLevel += signalLevel;
    if (signalLevelUnit) isAliveLevel += ` ${signalLevelUnit}`;
    if (connType) isAliveLevel += ` (${connType})`;

    return isAliveLevel;
  }

  return (
    <>
      <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
        <Card style={{ height: '40%', padding: 20, marginBottom: 20, overflow: 'unset' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
            <AssignmentRoundedIcon fontSize='large' style={{ marginRight: 10 }}></AssignmentRoundedIcon>
            <Typography variant='h6'>General</Typography>
          </div>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <div style={{ minWidth: '30%', marginRight: '5%' }}>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '70%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Id de dispositivo:</Typography> <Typography>{deviceDetail.devuid}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Tipo:</Typography>{' '}
                    <Tooltip title={deviceDetail.iot_type.description}>
                      <Typography style={{ width: '140px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {deviceDetail.iot_type.description}
                      </Typography>
                    </Tooltip>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '100%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Modelo:</Typography> <Typography>{deviceDetail.iot_model.name}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '80%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Descripción de modelo:</Typography>{' '}
                    <Tooltip title={deviceDetail.iot_model.description}>
                      <Typography style={{ width: '170px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {deviceDetail.iot_model.description}
                      </Typography>
                    </Tooltip>
                  </>
                )}
              </Grid>
            </div>

            <div style={{ minWidth: '30%', marginRight: '5%' }}>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '70%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Disponible para uso:</Typography>
                    <Typography>{formatDeviceAvailable(deviceDetail.available)}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Instalado:</Typography>{' '}
                    <Typography>{deviceDetail.device_install_situation}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '100%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Jerarquía:</Typography>
                    <Typography>{deviceDetail.iot_hierarchy.description}</Typography>
                  </>
                )}
              </Grid>
            </div>

            <div style={{ minWidth: '30%' }}>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Estado conexión:</Typography>
                    {deviceDetail.status_service ? (
                      <WifiTetheringRoundedIcon style={{ fontSize: 20, marginRight: 10 }}></WifiTetheringRoundedIcon>
                    ) : (
                      <PortableWifiOffRoundedIcon style={{ fontSize: 20, marginRight: 10 }}></PortableWifiOffRoundedIcon>
                    )}
                    <Typography>{deviceDetail.status_service ? 'Conectado' : 'Desconectado'}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '100%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Conectado desde:</Typography>{' '}
                    <Typography style={{ minWidth: 170 }}>{moment(deviceDetail.last_connected_network).format('DD-MM-YYYY HH:mm')}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '95%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Estado actualizado:</Typography>{' '}
                    <Typography>{moment(deviceDetail.last_status_service).format('DD-MM-YYYY HH:mm')}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                {loading || !deviceDetail ? (
                  <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 170, opacity: 0.7 }}>Estado de operación:</Typography>
                    {deviceDetail.status_operation === OPERATION_STATUS.OK && <OperationBadge type={deviceDetail.status_operation} label={t('ok')} />}
                    {deviceDetail.status_operation === OPERATION_STATUS.WARNING && (
                      <OperationBadge type={deviceDetail.status_operation} label={t('warning')} />
                    )}
                    {deviceDetail.status_operation === OPERATION_STATUS.ALERT && (
                      <OperationBadge type={deviceDetail.status_operation} label={t('alert')} />
                    )}
                  </>
                )}
              </Grid>
            </div>
          </Grid>
        </Card>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset', width: '50%', marginRight: '1%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
              <WifiTetheringRoundedIcon fontSize='large' style={{ marginRight: 10 }}></WifiTetheringRoundedIcon>
              <Typography variant='h6'>Ultimas señales</Typography>
            </div>
            <div>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {loading || !deviceDetail ? (
                      <Skeleton animation="wave" style={{ width: '70%', height: 21 }} />
                    ) : (
                      <>
                        <Typography style={{ minWidth: 110, opacity: 0.7 }}>Ultima señal:</Typography> 
                        <Typography>{formatDateTime(deviceDetail?.iot_device_brief?.last_signal)}</Typography>                      
                      </>
                    )}
                  </Grid>              

                  <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {loading || !deviceDetail ? (
                      <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                    ) : (
                      <div style={{width: '100%', display: 'flex'}}>
                        <div style={{width: '50%', display: 'flex'}}>
                          <Typography style={{ minWidth: 110, opacity: 0.7 }}>Medición:</Typography> 
                          <Typography>{formatDateTime(deviceDetail?.iot_device_brief?.iot_payload_ul?.timestamp)}</Typography>                        
                        </div>
                        <div style={{width: '50%', display: 'flex'}}>
                          <Typography style={{ minWidth: 70, opacity: 0.7 }}>Estado:</Typography>
                          {deviceDetail?.iot_device_brief?.iot_payload_ul?.status === OPERATION_STATUS.OK && <OperationBadge type={deviceDetail.status_operation} label={t('ok')} />}
                          {deviceDetail?.iot_device_brief?.iot_payload_ul?.status === OPERATION_STATUS.WARNING && (
                            <OperationBadge type={deviceDetail?.iot_device_brief?.iot_payload_ul?.status} label={t('warning')} />
                          )}
                          {deviceDetail?.iot_device_brief?.iot_payload_ul?.status === OPERATION_STATUS.ALERT && (
                            <OperationBadge type={deviceDetail?.iot_device_brief?.iot_payload_ul?.status} label={t('alert')} />
                          )}  
                          {(deviceDetail?.iot_device_brief?.iot_payload_ul?.error_timestamp_device) && <ErrorBadge type={ERROR_TYPE.TIMESTAMP} includeLabel={false} includeTooltip={true}/>}
                          {(deviceDetail?.iot_device_brief?.iot_payload_ul?.error_validation) && <ErrorBadge type={ERROR_TYPE.VALIDATION} includeLabel={false} includeTooltip={true}/>}
                          {(deviceDetail?.iot_device_brief?.iot_payload_ul?.is_payload_invalid) && <ErrorBadge type={ERROR_TYPE.INVALID} includeLabel={false} includeTooltip={true}/>}
                        </div>
                      </div>
                    )}
                  </Grid>

                <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  {loading || !deviceDetail ? (
                    <Skeleton animation="wave" style={{ width: '100%', height: 21 }} />
                  ) : (
                    <div style={{width: '100%', display: 'flex'}}>
                      <div style={{width: '50%', display: 'flex'}}>
                        <Typography style={{ minWidth: 110, opacity: 0.7 }}>Isalive:</Typography> 
                        <Typography>{formatDateTime(deviceDetail?.iot_device_brief?.iot_isalive?.timestamp)}</Typography>
                      </div>
                      <div style={{width: '50%', display: 'flex'}}>
                        <Typography style={{ minWidth: 70, opacity: 0.7 }}>Nivel:</Typography> 
                        <Typography>{formatIsAliveLevel(deviceDetail?.iot_device_brief?.iot_is_alive)}</Typography>
                      </div>
                    </div>
                  )}
                </Grid>

                <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  {loading || !deviceDetail ? (
                    <Skeleton animation="wave" style={{ width: '100%', height: 21 }} />
                  ) : (
                    <div style={{width: '100%', display: 'flex'}}>
                      <div style={{width: '50%', display: 'flex'}}>
                        <Typography style={{ minWidth: 110, opacity: 0.7 }}>Alarma:</Typography> 
                        <Typography>{formatDateTime(deviceDetail?.iot_device_brief?.iot_alarm?.last_occurrence)}</Typography>
                      </div>
                      <div style={{width: '50%', display: 'flex'}}>
                        <Typography style={{ minWidth: 70, opacity: 0.7 }}>Tipo:</Typography> 
                        <Tooltip title={deviceDetail?.iot_device_brief?.iot_alarm?.description}>
                          <Typography style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            {deviceDetail?.iot_device_brief?.iot_alarm?.description}
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Grid>

                <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  {loading || !deviceDetail ? (
                    <Skeleton animation="wave" style={{ width: '100%', height: 21 }} />
                  ) : (
                    <div style={{width: '100%', display: 'flex'}}>
                      <div style={{width: '50%', display: 'flex'}}>
                        <Typography style={{ minWidth: 110, opacity: 0.7 }}>Cancel. alarma:</Typography> 
                        <Typography>{formatDateTime(deviceDetail?.iot_device_brief?.iot_alarm_cancel?.executedAt)}</Typography>
                      </div>
                      <div style={{width: '50%', display: 'flex'}}>
                        <Typography style={{ minWidth: 70, opacity: 0.7 }}>Tipo:</Typography> 
                        <Tooltip title={deviceDetail?.iot_device_brief?.iot_alarm_cancel?.description}>
                          <Typography style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                            {deviceDetail?.iot_device_brief?.iot_alarm_cancel?.description}
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Card>

          <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset', width: '50%', marginLeft: '1%' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
              <AnnouncementRoundedIcon fontSize='large' style={{ marginRight: 10 }}></AnnouncementRoundedIcon>
              <Typography variant='h6'>Indicadores de Alerta</Typography>
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <div style={{ minWidth: 300, marginRight: 170 }}>
                <Grid className={styles.label} container direction="row" justifyContent="flex-start" alignItems="flex-start">
                  {loading || !deviceDetail ? (
                    <Skeleton animation="wave" style={{ width: '95%', height: 21 }} />
                  ) : (
                    <>
                      <Typography style={{ minWidth: 170, opacity: 0.7 }}>Mediciones Inválidas:</Typography>
                      {deviceDetail.invalidate_payloadul && 
                        (<OperationBadge type={'R'} label={`Si - Desde: ${formatDateTime(deviceDetail?.invalidate_payloadul_since)}`} />)}                
                      {!deviceDetail.invalidate_payloadul && (<OperationBadge type={'G'} label={'No'} />)}                  
                    </>
                  )}
                </Grid>
              </div>
            </Grid>
          </Card>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = store => ({
  userRoles: store.auth.userRoles,
  deviceDetail: store.iot.iotDeviceDetail,
  loading: store.iot.iotDeviceDetailLoading || store.iot.editDeviceLoading,
})
export default connect(mapStateToProps)(DetailInformation)
