import { createSelector } from 'reselect'
import { registerSelectors, checkSelector } from 'reselect-tools'
import * as ReselectTools from 'reselect-tools'
import store from '../store'

//States
const isHierarchiesLoading = state => state?.ioTHierarchy?.iothierarchiesLoading
const getHierarchiesList = state => state?.ioTHierarchy?.iothierarchies
const getUnits_By_hierarchy = state => state?.ioTHierarchy?.iothierarchiesBrief?.by_hierarchy
const getLogicalUnitsType = state => state?.iotLogicalUnit?.iotlogicalunitType
const isHierarchyStatsLoading = state => state?.ioTHierarchy?.iothierarchyDetailLoading
const getUnitsDevice = state => state?.ioTHierarchy?.iothierarchiesUnitsDevice //Hierarchies Brief Logical Units and Device
const getActiveHierarchy = state => state?.ioTHierarchy?.iothierarchyActive
///////////

const getBriefHierarchies = state => {
  const unitsbyHierarchies = getUnits_By_hierarchy(state)
  const list_hierarchies = getHierarchiesList(state)
  const list_logicalUnits = getLogicalUnitsType(state)

  if (!isHierarchiesLoading(state) && unitsbyHierarchies && list_hierarchies) {
    let aux = []
    Object.keys(unitsbyHierarchies).forEach(function (val, idx, array) {
      let jerarquia = list_hierarchies.filter(x => x.name === val)
      let obj = unitsbyHierarchies[val]

      //agregar properties 'name' and 'internal_name' to object logical_unit_type_id
      let object1 = obj['by_logical_unit_type']
      let aux2 = Object.keys(object1).map(function (key, index) {
        let unit = list_logicalUnits.filter(x => x.id === object1[key]['logical_unit_type_id'])[0]
        let object2 = Object.create(object1[key])
        object2 = { ...object1[key] }
        object2.name = unit?.name
        object2.internal_name = unit?.internal_name
        return object2
      })

      //indexar objeto logical_unit por name: SET, EDIFICIO, MTR_ELEC
      aux2 = Object.entries(aux2).reduce((ret, entry) => {
        const [, value] = entry
        ret[value.name] = value
        return ret
      }, {})

      aux.push({
        hierarchy: jerarquia[0] ? jerarquia[0] : null,
        total_green: !!obj ? (obj['total_green'] ? obj['total_green'] : null) : null,
        total_yellow: !!obj ? (obj['total_yellow'] ? obj['total_yellow'] : null) : null,
        total_red: !!obj ? (obj['total_red'] ? obj['total_red'] : null) : null,
        logical_unit: !!aux2 ? aux2 : null,
      })
    })

    //para aquellas Jerarquias listadas que no tienen datos de unidades logicas, generar un objeto con valores por default
    Object.keys(list_hierarchies).forEach(function (val, idx, array) {
      let jerarquia = list_hierarchies[val]
      let existHierarchy = aux.filter(x => x.hierarchy.name === jerarquia['name'])[0]
      if (!existHierarchy)
        aux.push({
          hierarchy: jerarquia,
          total_green: null,
          total_yellow: null,
          total_red: null,
          logical_unit: null,
        })
    })
    return aux
  }
}
/**
 * **iotHierarchy Selector**
 * Brief of hierarchies to show at map view layer 1
 * @function
 * @param state
 * @returns {array} - array
 */
export const briefHierarchiesLayer = createSelector(
  state => getBriefHierarchies(state),
  resp => resp,
)
/*******************************************************/

const getUnitsDeviceHierarchy_Stats = state => {
  const activeH = getActiveHierarchy(state)
  const ud_obj = getUnitsDevice(state)
  const logicalUnits_types = getLogicalUnitsType(state)

  let units = null,
    dev = null

  if (!isHierarchyStatsLoading(state) && ud_obj) {
    if (ud_obj[activeH?.id]?.logical_units?.by_hierarchy) {
      const byh = ud_obj[activeH?.id]?.logical_units?.by_hierarchy
      Object.keys(byh).forEach(function (val, idx, array) {
        units = byh[val]['by_logical_unit_type'] //val=hierarchy name
      })
      //add properties 'name' and 'internal_name' to object logical_unit_type_id
      units = Object.keys(units).map(function (key, index) {
        let unitType = logicalUnits_types.filter(x => x.id === units[key]['logical_unit_type_id'])[0]
        let obj = Object.create(units[key])
        obj = { ...units[key] }
        obj.name = unitType?.name
        obj.internal_name = unitType?.internal_name
        return obj
      })
      units = units.sort((a, b) => a.logical_unit_type_id - b.logical_unit_type_id)
    }
    if (ud_obj[activeH?.id]?.devices?.by_hierarchy) {
      const byh = ud_obj[activeH?.id]?.devices?.by_hierarchy
      Object.keys(byh).forEach(function (val, idx, array) {
        dev = byh[val]['by_device_type']
      })
      //add properties 'name' and 'internal_name' to device (it's key value)
      dev = Object.keys(dev).map(function (key, index) {
        let obj = Object.create(dev[key])
        obj = { ...dev[key] }
        obj.name = key
        obj.internal_name = key
        return obj
      })
      dev = dev.sort((a, b) => a.device_type_id - b.device_type_id)
    }
    return {
      logicalunits: units,
      devices: dev,
    }
  }
}
/**
 * **iotHierarchy Selector**
 * Statistics of units and devices for a hierarchy to show on card map view layer 2
 * @function
 * @param state
 * @returns {object} - object
 */
export const unitsDeviceHierarchyStats = createSelector(
  state => getUnitsDeviceHierarchy_Stats(state),
  resp => resp,
)

//reselect-tools to inspect console
ReselectTools.getStateWith(() => store.getState())
registerSelectors({ briefHierarchiesLayer })
registerSelectors({ unitsDeviceHierarchyStats })
checkSelector('briefHierarchiesLayer')
checkSelector('unitsDeviceHierarchyStats')
