import { Button, Card, FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded'
import EventRoundedIcon from '@material-ui/icons/EventRounded'
import FormatAlignJustifyRoundedIcon from '@material-ui/icons/FormatAlignJustifyRounded'
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import WTDateRangePicker from 'app/components/WTDateRangePicker'
import moment from 'moment'
import { any, bool, number, shape } from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import Charts from './components/Charts'
import DataExportComponent from './components/DataExportModal/index'
import MeasuresTable from './components/MeasuresTable'
import styles from './styles.module.scss'

const LEVEL_GRAPHICS = 'DEVICE_DETAIL_MEASURES';

const MeasuresContainer = ({
  dispatch,
  deviceSelected,
  loading,
  measures,
  measuresSort,
  totalMeasures,
  companyId,
  fullscreen,
  iotTypeGraphics,
  iotTypeGraphicsLoading,
}) => {
  const [filterValues, setFilterValues] = useState([])

  const [customDateFilter] = useState({ startDate: null, endDate: null })
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [customDateLabel, setCustomDateLabel] = useState('Otro período')
  const [panelType, setpanelType] = useState('chart')
  const [timeRange, settimeRange] = useState('24h')
  const [isLoading, setIsLoading] = useState(true)
  const [iotTypeGraphicSelected, setIotTypeGraphicSelected] = useState(null)
  const [reset, setReset] = useState(false)
  const [dataExportDialogOpened, setDataExportDialogOpened] = useState(false)

  const customDateButtonRef = useRef(null)
  const TIMESTAMP = 'timestamp'

  const [t] = useTranslation('devices')

  const handleChangeGroup = event => {
    let aux = iotTypeGraphics.filter(graphic => graphic.id === event.target.value)
    setIotTypeGraphicSelected(aux[0] ? aux[0] : null)
    setReset(true) //reset Chart
  }

  const formatDate = d =>
    d.toLocaleDateString('es-ES', { type: 'medium', year: 'numeric', month: 'short', day: 'numeric' }).replace('.', '').replace('sept', 'sep')

  const handleCustomDateChange = dateRange => {
    if (dateRange) {
      setReset(true) //reset Graphics
      dispatch(iotActions.cleanMeasures()) //disparar
      let newDateRangeLabel = formatDate(dateRange.startDate) + ' - ' + formatDate(dateRange.endDate)
      setCustomDateLabel(newDateRangeLabel)
      updateDateTimeFilter(dateRange)
    }
  }

  const updateDateTimeFilter = dateRange => {
    let dateFilters = []
    if (dateRange) {
      dateFilters = [
        { field: TIMESTAMP, operator: 'gt', value: dateRange.startDate },
        { field: TIMESTAMP, operator: 'lt', value: dateRange.endDate },
      ]
      dateFilters[0].value.toJSON = function () {
        return moment(this).format()
      } //preservar zona horaria
      dateFilters[1].value.toJSON = function () {
        return moment(this).format()
      } //preservar zona horaria
    }
    setFilterValues(prevFilters => [...prevFilters.filter(filter => filter.field !== TIMESTAMP), ...dateFilters])
  }

  const handleCloseDatePicker = () => {
    setDatePickerOpen(false)
  }

  useEffect(() => {
    calcTimeFilter(timeRange)

    const graphicsLevelFilter = [
      { field: 'graph_level', operator: 'eq', value: LEVEL_GRAPHICS },
      { field: 'available', operator: 'eq', value: true }
    ];
    dispatch(iotActions.getIotTypeGraphics(deviceSelected.type_id, LEVEL_GRAPHICS, graphicsLevelFilter));
  }, [])

  useEffect(() => {
    if (filterValues && filterValues.length > 0) {
      dispatch(iotActions.cleanMeasures()) //disparar
      dispatch(iotActions.getIotDeviceMeasurements(0, null, filterValues, true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues])

  useEffect(() => {
    if (iotTypeGraphics && iotTypeGraphics.length > 0) {
      setIotTypeGraphicSelected(iotTypeGraphics[0])
      setIsLoading(false)
    }
  }, [iotTypeGraphics, iotTypeGraphicsLoading])

  const handleTimeBarSelection = (event, newSelection) => {
    if (newSelection) {
      setReset(true)
      settimeRange(newSelection)
      if (newSelection === 'custom') {
        setDatePickerOpen(true)
      } else {
        setDatePickerOpen(false)
        calcTimeFilter(newSelection)
      }
    } else {
      if (timeRange === 'custom' && !datePickerOpen) {
        setDatePickerOpen(true)
      }
    }
  }

  const calcTimeFilter = period => {
    let start
    switch (period) {
      case '12h':
        start = moment().subtract(12, 'hours').toDate()
        updateDateTimeFilter({ startDate: start, endDate: moment().toDate() })
        break
      case '24h':
        start = moment().subtract(24, 'hours').toDate()
        updateDateTimeFilter({ startDate: start, endDate: moment().toDate() })
        break
      case '7d':
        start = moment().subtract(7, 'days').toDate()
        updateDateTimeFilter({ startDate: start, endDate: moment().toDate() })
        break

      default:
        break
    }
  }

  const handlePanelSelection = (event, newSelection) => {
    if (newSelection) {
      setReset(true)
      dispatch(iotActions.cleanMeasures()) //disparar
      setpanelType(newSelection)
    }
  }

  const handleExportarDatos = () => {
    setDataExportDialogOpened(true)
  }

  const handleCloseDataExportDialog = () => {
    setDataExportDialogOpened(false)
  }

  return (
    <>
      <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
        <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ToggleButtonGroup size='small' value={panelType} exclusive onChange={handlePanelSelection} style={{ marginRight: 20 }}>
                <ToggleButton value='datalist'>
                  <FormatAlignJustifyRoundedIcon fontSize='small'></FormatAlignJustifyRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    Registros
                  </Typography>
                </ToggleButton>
                <ToggleButton value='chart'>
                  <TimelineRoundedIcon fontSize='small'></TimelineRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    Gráficos
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
              {panelType === 'chart' && iotTypeGraphicSelected !== null && !isLoading && (
                <FormControl className={styles.selectField}>
                  <Select id='select-outlined' variant='outlined' value={iotTypeGraphicSelected?.id} onChange={handleChangeGroup}>
                    {iotTypeGraphics && iotTypeGraphics.map(graphics => <MenuItem value={graphics.id}>{`${graphics.title}`}</MenuItem>)}
                  </Select>
                </FormControl>
              )}
            </div>

            <div style={{ display: 'flex', gap: 20 }}>
              <ToggleButtonGroup size='small' value={timeRange} exclusive onChange={handleTimeBarSelection}>
                <ToggleButton value='12h'>
                  <Typography variant='body'>12 h.</Typography>
                </ToggleButton>
                <ToggleButton value='24h'>
                  <Typography variant='body'>24 h.</Typography>
                </ToggleButton>
                <ToggleButton value='7d'>
                  <Typography variant='body'>7 días</Typography>
                </ToggleButton>
                <ToggleButton value='custom' ref={customDateButtonRef}>
                  <EventRoundedIcon fontSize='small'></EventRoundedIcon>
                  <Typography style={{ marginLeft: 5, marginRight: 5 }} variant='body'>
                    {customDateLabel}
                  </Typography>

                  <WTDateRangePicker
                    onChange={handleCustomDateChange}
                    dates={customDateFilter}
                    open={datePickerOpen}
                    anchorEl={customDateButtonRef?.current}
                    customAnchor={true}
                    onClose={handleCloseDatePicker}
                  ></WTDateRangePicker>
                </ToggleButton>
              </ToggleButtonGroup>
              <Button variant='outlined' startIcon={<DescriptionRoundedIcon />} onClick={handleExportarDatos}>
                {t('deviceDataExportTitle')}
              </Button>
            </div>
          </div>
        </Card>
        {panelType === 'datalist' && <MeasuresTable filterValues={filterValues}></MeasuresTable>}
        {panelType === 'chart' && iotTypeGraphicSelected && (
          <Charts
            id={deviceSelected.id}
            typeId={iotTypeGraphicSelected?.id}
            chartName={iotTypeGraphicSelected?.name}
            startDate={filterValues[0]?.value}
            endDate={filterValues[1]?.value}
          />
        )}
      </div>
      <DataExportComponent open={dataExportDialogOpened} handleClose={handleCloseDataExportDialog}></DataExportComponent>
    </>
  )
}

const mapStateToProps = store => ({
  deviceSelected: store.iot.iotDeviceDetail,
  measures: store.iot.iotDeviceMeasurements && store.iot.iotDeviceMeasurements.rows,
  measuresSort: store.iot.iotDeviceMeasurementsSort,
  totalMeasures: store.iot.iotDeviceMeasurements && store.iot.iotDeviceMeasurements.total,
  loading: store.iot.iotDeviceMeasurementsLoading,
  companyId: store.auth.authInformation.companyId,
  iotTypeGraphics: store.iot.iotTypeGraphics[LEVEL_GRAPHICS],
  iotTypeGraphicsLoading: store.iot.iotTypeGraphicsLoading,
})

MeasuresContainer.propTypes = {
  deviceSelected: shape(any),
  measures: shape(any),
  loading: bool,
  totalMeasures: number,
  companyId: number,
}

export default connect(mapStateToProps)(memo(MeasuresContainer))
