import { Button, Card, Typography } from '@material-ui/core';
import PortableWifiOffRoundedIcon from '@material-ui/icons/PortableWifiOffRounded';
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import WTModal from 'app/components/WTModal';

import MUIDataTable from 'mui-datatables';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import NetworkServerDetailInfo from '../../../NetworkServer/NetworkServerDetailInfo';
import styles from './styles.module.scss';
import { getTableData } from './tableProvider';

const NetworkServersPanel = ({ networkServers, loading }) => {
  const [t] = useTranslation('devices');

  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const [fullscreen, setFullscreen] = React.useState(false);

  const handleNetworkServerSelect = (networkServer) => {
    dispatch(iotActions.selectNetworkServer(networkServer));
  };

  const [detailModalOpen, setDetailModalOpen] = React.useState(false);

  const handleToggleFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleDetailModalOpen = () => {
    setDetailModalOpen(true);
  };

  const handleDetailModalClose = () => {
    setDetailModalOpen(false);
  };

  useEffect(() => {
    let data = getTableData(networkServers, t);
    setTableData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkServers]);

  const columns = [
    {
      name: 'networkServer',
      label: t('networkServerTableTitleColumnServer'),
      options: {
        sort: true,
      },
    },
    {
      name: 'platform',
      label: t('networkServerTableTitleColumnPlatform'),
      options: {
        sort: true,
      },
    },
    {
      name: 'applicationId',
      label: t('networkServerTableTitleColumnApplicationId'),
      options: {
        sort: true,
      },
    },
    {
      name: 'connectionStatus',
      label: t('networkServerTableTitleColumnStatusService'),
      options: {
        sort: true,
        sortCompare: (order) => (obj1, obj2) => {
          return order === 'asc'
            ? obj1.data.status.localeCompare(obj2.data.status)
            : -obj1.data.status.localeCompare(obj2.data.status);
        },
        customBodyRender: (data) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.status === t('on') ? (
                  <WifiTetheringRoundedIcon
                    style={{ fontSize: 20 }}
                  ></WifiTetheringRoundedIcon>
                ) : (
                  <PortableWifiOffRoundedIcon
                    style={{ fontSize: 20 }}
                  ></PortableWifiOffRoundedIcon>
                )}
                <div style={{ marginLeft: 10 }}>{data.status}</div>
              </div>
              <Typography className={styles.statusDate}>{data.date}</Typography>
            </div>
          );
        },
      },
    },
    {
      name: '',
      label: '',
      options: {
        sort: false,
        customBodyRenderLite: (data) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  handleNetworkServerSelect(networkServers[data]);
                  handleDetailModalOpen();
                }}
              >
                Ver ficha
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    elevation: 0,
    pagination: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    selectableRows: 'none',
    responsive: 'vertical',
    tableBodyHeight: 'calc(100% - 120px)',
    viewColumns: false,
    setTableProps: () => ({
      size: 'small',
    }),
    setRowProps: (row, index) => {
      return {
        className:
          tableData[index].connectionStatus.status === t('off')
            ? styles.backRowAlert
            : null,
      };
    },
    textLabels: {
      body: {
        toolTip: 'Ordernar',
      },
    },
  };

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: 15 }}>
        {t('networkServers')}
      </Typography>
      {loading && renderSkeleton()}
      {!loading &&
        (networkServers?.length > 0 ? (
          <Card
            elevation={4}
            style={{ paddingTop: 5, paddingBottom: 10}}
          >
            <MUIDataTable
              data={tableData}
              columns={columns}
              options={options}
            />
          </Card>
        ) : (
          <Typography>{t('noNetworkServersFound')}</Typography>
        ))}

      <WTModal
        open={detailModalOpen}
        onClose={handleDetailModalClose}
        onFullscreen={handleToggleFullScreen}
        fullscreen={fullscreen}
      >
        <NetworkServerDetailInfo></NetworkServerDetailInfo>
      </WTModal>
    </>
  );

  function renderSkeleton() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 275,
          paddingTop: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 15,
          }}
        >
          <div style={{ width: '100%' }}>
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
          </div>
        </div>
      </div>
    );
  }
};

export default memo(NetworkServersPanel);
