import { shape, any } from 'prop-types';
import i18n from 'i18next';

export const columnData = [
  {
    key: 'componentName',
    label: i18n.t('IoT:component'),
    align: 'center'
  },
  {
    key: 'version',
    label: i18n.t('IoT:version'),
    align: 'center'
  },
  {
    key: 'firmwareName',
    label: i18n.t('IoT:firmware'),
    align: 'center'
  },
  {
    key: 'serialBadge',
    label: i18n.t('IoT:serialBadge'),
    align: 'center'
  },
  { key: 'buttons', label: '' }
];

export const deviceComponentType = shape(any);
