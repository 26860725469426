/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Button, CircularProgress, IconButton, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { bool, number } from 'prop-types';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { getIn } from 'seamless-immutable';
import { DEFAULT_PAGE_NUMBER, pageSizeOptions } from './constants';

import AlertActions from 'redux/alert/actions';
import reportIoT from '../../../../../../../../services/ioTReportService';

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 450,
    overflowY: 'scroll',
    fontSize: theme.typography.pxToRem(12),
    fontFamily:'monospace',
  },
}))(Tooltip);

const DeviceEventsContainer = ({ deviceSelected, deviceEventsList, dispatch, loading, totalDeviceEvents, totalDevicePages }) => {
  const [t] = useTranslation('devices')
  const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER)
  const [isFirstRun, setIsFirstRun] = useState(true)
  const [tableData, setTableData] = useState([])



  const getEvents = useCallback(() => {
    dispatch(iotActions.getIotDeviceEvents(currentPage, pageSize))
  })

  useEffect(() => {
    if (!deviceEventsList || (deviceEventsList && !deviceEventsList[`${currentPage}`])) getEvents()
    else setTableData(deviceEventsList[`${currentPage}`])
  }, [currentPage])

  useEffect(() => {
    if (deviceEventsList) {
      if (isFirstRun && deviceEventsList[`1`]) {
        setIsFirstRun(false)
      }
      setTableData(deviceEventsList[`${currentPage}`])
    }
  }, [deviceEventsList])

  useEffect(() => {
    return () => {
      dispatch(iotActions.cleanDeviceEvents())
    }
  }, [])

  const handleChangePage = (_currentPage, _pageSize) => {
    setCurrentPage(_currentPage)
    if (_pageSize) setPageSize(_pageSize)
  }

  const handleDownloadReport = () => {
    reportIoT(deviceSelected.id, [], 'events')
      .then(resp => {
        if (resp && resp.ok) {
          let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.setAttribute('download', 'events_' + deviceSelected.devuid + '.xlsx')
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a) // Eliminar el elemento después de descargar
          window.URL.revokeObjectURL(url) // Suelta el objeto blob
        } else dispatch(AlertActions.error('Report not found'))
      })
      .catch(err => dispatch(AlertActions.error('Report not found')))
  }

  const columns = [
    {
      name: 'id',
      label: t('deviceEventsTableIDTitleColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'executedAt',
      label: t('deviceEventsTableExecutedAtTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{moment(data).format('DD-MM-YYYY HH:mm:ss')}</span>
        },
      },
    },
    {
      name: 'source',
      label: t('deviceEventsTableSourceTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>
            {
              data === 'device' ? t('deviceEventSourceDevice') :
              data === 'rest_api' ? t('deviceEventSourceRestapi') : 
              data === 'worker' ? t('deviceEventSourceWorker') : 
              data
            }
          </span>
        },
      },
    },
    {
      name: 'resource',
      label: t('deviceEventsTableResourceTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>
            {
              data === 'network_server' ? t('deviceEventResourceNetworkServer') :
              data === 'device' ? t('deviceEventResourceDevice') : 
              data
            }
          </span>
        },
      },
    },
    {
      name: 'iot_device_event_action',
      label: t('deviceEventsTableActionDescriptionTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{data.description}</span>
        },
      },
    },
    {
      name: 'result',
      label: t('deviceEventsTableResultTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>
            {
              data === 'failure' ? t('deviceEventResultFailure') :
              data === 'success' ? t('deviceEventResultSuccess') : 
              data
            }
          </span>
        },
      },
    },
    {
      name: 'user',
      label: t('deviceEventsTableUserTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'data',
      label: t('deviceEventsTableDataTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => (
             
          <HtmlTooltip
            placement="left-start"
            title={JSON.stringify(data || { }, null, 4)}
          >
            <IconButton size="small" >
              <CodeRoundedIcon  fontSize="small" />
            </IconButton>
          </HtmlTooltip>
      ),
      },
    },
    {
      name: 'event_parent',
      label: t('deviceEventsTableEventParentTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => (
             
          <HtmlTooltip
            placement="left-start"
            title={JSON.stringify(data || { }, null, 4)}
          >
            <IconButton size="small" >
              <CodeRoundedIcon  fontSize="small" />
            </IconButton>
          </HtmlTooltip>
      ),
        
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyHeight: 'calc(100% - 125px)',
    //tableBodyMaxHeight: '100%',
    download: false,
    viewColumns: false,
    serverSide: true,
    count: totalDeviceEvents,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [],
    customToolbar: () => {
      if (tableData?.length > 0) {
        return (
          <Tooltip title='Descargar archivo CSV'>
            <Button variant='outlined'  startIcon={<DescriptionRoundedIcon />} onClick={handleDownloadReport}>
              Exportar datos
            </Button>
          </Tooltip>
        )
      }
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page + 1, null)
          break
        case 'sort':
          //this.sort(tableState.page, tableState.sortOrder);
          break
        default:
        //console.log('action not handled.');
      }
    },
    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar Excel', //'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      {loading && isFirstRun ? (
        renderSkeleton()
      ) : (
        <MUIDataTable
          title={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h6'>
                Eventos
                {loading && <CircularProgress size={18} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
              </Typography>
            </div>
          }
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
    </>
  )
}

const mapStateToProps = store => ({
  deviceSelected: getIn(store, ['iot', 'iotDeviceDetail']),
  loading: getIn(store, ['iot', 'iotDeviceEventsLoading']),
  deviceEventsList: store.iot.iotDeviceEvents && store.iot.iotDeviceEvents.rows,
  totalDeviceEvents: store.iot.iotDeviceEvents && store.iot.iotDeviceEvents.totalDeviceEvents,
  totalDevicePages: store.iot.iotDeviceEvents && store.iot.iotDeviceEvents.totalDevicePages,
})

DeviceEventsContainer.propTypes = {
  loading: bool,
  totalDeviceEvents: number,
}

export default connect(mapStateToProps)(memo(DeviceEventsContainer))
