import Immutable from 'seamless-immutable';

import { actions as AlertActions } from 'redux/alert/actions';

export function stringArrayToObject(actionsArray, namespace = '') {
  if (actionsArray.some(actionName => !actionName || typeof actionName !== 'string')) {
    throw new Error('Action names must be strings and must not be empty');
  }
  return Immutable(actionsArray).asObject(actionName => [actionName, `${namespace}:${actionName}`]);
}

export const snackbarDispatcher = (dispatch, action, payload) => {
  dispatch({ type: AlertActions.CLOSE_ALERT_SNACKBAR });
  return setTimeout(
    () =>
      dispatch({
        type: action,
        payload
      }),
    300
  );
};

export const getErrorMessage = (data, defaultMessage) => {
  const errorMessage = data?.errors[0] ? data?.errors[0].message : defaultMessage;
  return errorMessage;
}
