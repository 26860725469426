/* eslint-disable camelcase */
import { Tab, Tabs } from '@material-ui/core'
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded'
import ListRoundedIcon from '@material-ui/icons/ListRounded'
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded'
import RouterRoundedIcon from '@material-ui/icons/RouterRounded'
import { any, shape } from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getIn } from 'seamless-immutable'
import Alarms from './components/Alarms'
import DetailInformationContainer from './components/DetailInformation'
import Events from './components/Events'
import Gateways from './components/Gateways'
import Summary from './components/Summary'
import styles from './styles.module.scss'


const NetworkServerDetailInfo = ({ networkServerSelected }) => {
  const [activeTab, setActiveTab] = React.useState(1) //Tab gateways seleccionado por defecto
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Summary networkServerSelected={networkServerSelected} />
      <Tabs
        value={activeTab}
        className={styles.tabs}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          style={{ minWidth: '140px' }}
          className={styles.tab}
          label={
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <ListRoundedIcon style={{ fontSize: 25, marginRight: 10 }}/> 
              <span>Detalle</span>
            </div>
          }
        />
        <Tab
          style={{ minWidth: '140px' }}
          className={styles.tab}
          label={
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <RouterRoundedIcon style={{ fontSize: 25, marginRight: 10 }}></RouterRoundedIcon>
              <span>Gateways</span>
            </div>
          }
        />
        <Tab
          style={{ minWidth: '140px' }}
          className={styles.tab}
          label={
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <PlaylistAddCheckRoundedIcon style={{ fontSize: 25, marginRight: 10 }}></PlaylistAddCheckRoundedIcon>
              <span>Eventos</span>
            </div>
          }
        />
        <Tab
          style={{ minWidth: '140px' }}
          className={styles.tab}
          label={
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ErrorOutlineRoundedIcon style={{ fontSize: 20, marginRight: 10 }}></ErrorOutlineRoundedIcon>
              <span>Alarmas</span>
            </div>
          }
        />
      </Tabs>
      <div className={styles.tabContent}>
				{activeTab === 0 && <DetailInformationContainer></DetailInformationContainer>}
				{activeTab === 1 && <Gateways></Gateways>}
        {activeTab === 2 && <Events></Events>}
        {activeTab === 3 && <Alarms></Alarms>}
      </div>
    </div>
  )
}

const mapStateToProps = store => ({
  networkServerSelected: getIn(store, ['iot', 'iotNetworkServerDetail']),
})

NetworkServerDetailInfo.propTypes = {
  networkServerSelected: shape(any),
}

export default connect(mapStateToProps)(NetworkServerDetailInfo)
