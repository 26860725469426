import Typography from '@material-ui/core/Typography'
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded'
import { CHART_COLORS } from 'app/styles/colors'
import { format, formatLocale, formatSpecifier, precisionFixed } from 'd3-format'
import moment from 'moment'
import { TimeRange, TimeSeries } from 'pondjs'
import React, { useEffect } from 'react'
import { Baseline, ChartContainer, ChartRow, Charts, LineChart, styler, YAxis } from 'react-timeseries-charts'
import Resizable from 'react-timeseries-charts/lib/components/Resizable'
import ChartFilter from '../ChartFilter'
import styles from './styles.module.scss'

const breakline = true
const smooth = false
const minDuration = 180 * 60 * 1000 // 15 minutes
const labels = ['Potencias', 'Factor de pot.']
const childHeightProportions = [0.7, 0.3]
const chartRowsAxisMargins = [10, 5]
const interpolation = 'linear'
//Type: enum ("curveBasis", "curveBasisOpen", "curveBundle", "curveCardinal", "curveCardinalOpen", "curveCatmullRom", "curveCatmullRomOpen", "curveLinear", "curveMonotoneX", "curveMonotoneY", "curveNatural", "curveRadial", "curveStep", "curveStepAfter", "curveStepBefore")

const locale = formatLocale({
  decimal: ',',
  thousands: '.',
  grouping: [3],
})

const s = formatSpecifier('f')
s.precision = precisionFixed(0.001)
const formatF = locale.format(s)

const formats = [',.2f', formatF]

const getOrderedSeriesNames = filters => {
  const orderedSeriesNames = []
  for (let i = 0; i < filters.length; i++) {
    const seriesNames = []
    for (let j = 0; j < filters[i].profile_conf.length; j++) {
      seriesNames.push(filters[i].profile_conf[j].aggregate_var)
    }
    orderedSeriesNames.push(seriesNames)
  }
  const orderedSeriesNamesIntegrated = [[...orderedSeriesNames[0], ...orderedSeriesNames[1], ...orderedSeriesNames[2]], orderedSeriesNames[3]]
  return orderedSeriesNamesIntegrated
}

const getColors = filters => {
  const map = new Map()
  for (let i = 0; i < filters.length; i++) {
    const colors = [...CHART_COLORS[i]]
    for (let j = 0; j < filters[i].profile_conf.length; j++) {
      const white = '#ffffff'
      const aggregate_var = filters[i].profile_conf[j].aggregate_var
      const lastLetter = aggregate_var.charAt(aggregate_var.length - 1)
      if (lastLetter === 'T') {
        map.set(filters[i].profile_conf[j].aggregate_var, white)
      } else {
        const color = colors.shift()
        map.set(filters[i].profile_conf[j].aggregate_var, color)
      }
    }
  }
  return map
}

const createInputStyler = filters => {
  const colors = getColors(filters)
  const inputStyler = []
  for (let i = 0; i < filters.length; i++) {
    for (let j = 0; j < filters[i].profile_conf.length; j++) {
      const aggregate_var = filters[i].profile_conf[j].aggregate_var
      const color = colors.get(aggregate_var)
      inputStyler.push({
        key: aggregate_var,
        color,
        width: 1,
      })
    }
  }
  return inputStyler
}

function checkAllEmpty(seriesObject) {
  for (var key in seriesObject) {
    if (seriesObject[key] !== null && seriesObject[key].length > 0) {
      return false
    }
  }
  return true
}

const createAllTimeSeries = (allSeries, allSeriesNames, timeIntervalInSeconds) => {
  let allTimeSeriesArray = []
  allSeriesNames.forEach(seriesNames => {
    let timeSeriesArray = []
    seriesNames.forEach(seriesName => {
      const timeSeries = createTimeSeries(allSeries[seriesName], seriesName, timeIntervalInSeconds)
      timeSeriesArray.push(timeSeries)
    })
    allTimeSeriesArray.push(timeSeriesArray)
  })
  return allTimeSeriesArray
}

/**
 *
 * @param {array} series Array of objects with the following structure: {time, type, value}
 * @param {string} seriesName The name of the series
 * @param {number} timeIntervalInSeconds The time interval in miliseconds
 * @returns {TimeSeries}
 */
const createTimeSeries = (series, seriesName, timeIntervalInSeconds) => {
  const seriesWithNulls = createPointsArrayWithNulls(series, timeIntervalInSeconds)
  const points = seriesWithNulls.map(point => [new Date(point.time).getTime(), point.value])
  const timeSeries = new TimeSeries({
    name: seriesName,
    columns: ['time', seriesName],
    points,
  })
  return timeSeries
}

const createPointsArrayWithNulls = (series, timeIntervalInSeconds) => {
  if (!series || series?.length === 0) return []
  let firstRun = true
  const obj = {}
  const data = []
  let prevTime, prevValue
  series.forEach(el => {
    if (firstRun) {
      obj.time = el?.time
      obj.value = el?.value
      prevTime = el?.time
      prevValue = el?.value
      data.push({ ...obj })
      firstRun = false
    } else {
      const currentTime = moment(el.time).startOf('minute')
      prevTime = moment(prevTime).clone().startOf('minute')
      // if (currentTime.unix() - prevTime.unix() > timeIntervalInSeconds) {
      if (prevTime.unix() + timeIntervalInSeconds === currentTime.unix()) {
        obj.time = moment(el?.time).startOf('minute').utc().format('YYYY-MM-DD[T]HH:mm:ss.sss') + 'Z'
        obj.value = el?.value
        prevTime = currentTime
        data.push({ ...obj })
      } else {
        let prev2 = prevTime.add(timeIntervalInSeconds, 'seconds').startOf('minute')
        while (prev2 < currentTime) {
          obj.time = prev2.utc().format('YYYY-MM-DD[T]HH:mm:ss.sss') + 'Z'
          obj.value = null
          data.push({ ...obj })
          prev2 = prev2.add(timeIntervalInSeconds, 'seconds').startOf('minute')
        }
        prevTime = currentTime
        obj.time = currentTime.utc().format('YYYY-MM-DD[T]HH:mm:ss.sss') + 'Z'
        obj.value = el?.value
        data.push({ ...obj })
      }
    }
  })
  return data
}

const getTimeSeriesForEachProfile = (allTimeSeries, profile) => {
  let timeSeriesForEachProfile = []
  const allTimeSeriesFlat = allTimeSeries.flat()
  const { profile_conf } = profile
  profile_conf.forEach(conf => {
    const timeSeries = allTimeSeriesFlat.find(timeSeries => timeSeries.name() === conf.aggregate_var)
    timeSeriesForEachProfile.push(timeSeries)
  })
  return timeSeriesForEachProfile
}

const getBeginDate = timeSeries => {
  const timeSeriesFlat = timeSeries.flat()
  let beginDate = timeSeriesFlat[0].count() > 0 ? timeSeriesFlat[0].begin() : new Date();
  for (let i = 1; i < timeSeriesFlat.length; i++) {
    if (timeSeriesFlat[i].size() > 0) {
      if (timeSeriesFlat[i].begin() < beginDate) {
        beginDate = timeSeriesFlat[i].begin()
      }
    }
  }
  return beginDate
}

const getEndDate = timeSeries => {
  const timeSeriesFlat = timeSeries.flat()
  let endDate = timeSeriesFlat[0].count() > 0 ? timeSeriesFlat[0].end() : new Date();
  for (let i = 1; i < timeSeriesFlat.length; i++) {
    if (timeSeriesFlat[i].size() > 0) {
      if (timeSeriesFlat[i].end() > endDate) {
        endDate = timeSeriesFlat[i].end()
      }
    }
  }
  return endDate
}

const getTimeRange = timeSeries => {
  const beginDate = getBeginDate(timeSeries)
  const endDate = getEndDate(timeSeries)
  return new TimeRange(beginDate, endDate)
}

const getMinValue = (timeSeries, orderedSeriesNames, timerange) => {
  if (timerange) {
    timeSeries = timeSeries.map(timeSerie => timeSerie.crop(timerange))
  }
  let minValue = null
  for (let i = 0; i < timeSeries.length; i++) {
    if (timeSeries[i].size() > 0) {
      const newValue = timeSeries[i].min(orderedSeriesNames[i])

      if(!minValue) minValue = newValue
      else if (newValue < minValue) {
        minValue = newValue
      }
    }
  }
  return minValue || 0
}

const getMaxValue = (timeSeries, orderedSeriesNames) => {
  let maxValue = null
  for (let i = 0; i < timeSeries.length; i++) {
    if (timeSeries[i].size() > 0) {
      const newValue = timeSeries[i].max(orderedSeriesNames[i])

      if (!maxValue) maxValue = newValue
      else if (newValue > maxValue) {
        maxValue = newValue
      }
    }
  }
  return maxValue || 0
}

const getMinTime = timeSeries => {
  const timeSeriesFlat = timeSeries.flat()
  let minTime = null
  for (let i = 0; i < timeSeriesFlat.length; i++) {
    if (timeSeriesFlat[i].size() > 0) {
      const newValue = timeSeriesFlat[i].range().begin();

      if (!minTime) minTime = newValue;
      else if (newValue < minTime) {
        minTime = timeSeriesFlat[i].range().begin()
      }
    }
  }
  return minTime || new Date()
}

const getMaxTime = timeSeries => {
  const timeSeriesFlat = timeSeries.flat()
  let maxTime = null
  for (let i = 0; i < timeSeriesFlat.length; i++) {
    if (timeSeriesFlat[i].size() > 0) {
      const newValue = timeSeriesFlat[i].range().end()
      
      if (!maxTime) maxTime = newValue
      if (newValue > maxTime) {
        maxTime = newValue
      }
    }
  }
  return maxTime || new Date()
}

const CargaMtrCt = ({ series, filters, containerHeight }) => {
  const [timeSeries, setTimeSeries] = React.useState()
  const [tracker, setTracker] = React.useState(null)
  const [x, setX] = React.useState(null)
  const [y, setY] = React.useState(null)
  const [timerange, setTimerange] = React.useState(null)
  const [highlight, setHighlight] = React.useState(null)
  const [selection, setSelection] = React.useState(null)
  const [visible, setVisible] = React.useState(null)
  const [chartStyles, setChartStyles] = React.useState([])
  const [min, setMin] = React.useState([])
  const [max, setMax] = React.useState([])
  const [date, setDate] = React.useState(null)
  const [orderedSeriesNames, setOrderedSeriesNames] = React.useState(null)
  const [noData, setNoData] = React.useState(false)

  useEffect(() => {
    if (filters && series && !checkAllEmpty(series)) {
      setNoData(false)
      const orderedSeriesNames = getOrderedSeriesNames(filters)
      setOrderedSeriesNames(orderedSeriesNames)
      const allTimeSeries = createAllTimeSeries(series, orderedSeriesNames, 15 * 60)
      setTimeSeries(allTimeSeries)
      setTimerange(getTimeRange(allTimeSeries))
      const inputStyler = createInputStyler(filters)
      setChartStyles(styler(inputStyler))
      const orderedNames = Object.keys(series)
      const auxObject = {}
      for (let i = 0; i < orderedNames.length; i++) {
        auxObject[orderedNames[i]] = true
      }
      setVisible(auxObject)
      const minArray = []
      const maxArray = []
      for (let i = 0; i < orderedSeriesNames.length; i++) {
        minArray.push(getMinValue(allTimeSeries[i], orderedSeriesNames[i]))
        maxArray.push(getMaxValue(allTimeSeries[i], orderedSeriesNames[i]))
      }
      setMin(minArray)
      setMax(maxArray)
    } else {
      setNoData(true)
    }
  }, [filters, series])

  const handleChartVariablesSelectionChange = (groupId, variableStates) => {
    const profileConf = filters.find(filter => filter.id === groupId).profile_conf
    let auxObject = { ...visible }
    for (let i = 0; i < variableStates.length; i++) {
      auxObject = { ...auxObject, [profileConf[i].aggregate_var]: variableStates[i] }
    }
    setVisible(auxObject)
  }

  const getTrackerValues = (profile, series) => {
    const { profile_conf } = profile
    if (tracker) {
      const f = format('.2f')
      const values = profile_conf.map((config, i) => {
        const index = series[i].bisect(tracker)
        if (index) {
          const trackerEvent = series[i].at(index)
          return { id: config.aggregate_var, value: f(trackerEvent.get(config.aggregate_var)) }
        } else return { id: config.aggregate_var, value: ' ' }
      })
      return values
    }
  }

  const handleTrackerChanged = tracker => {
    if (!tracker) {
      setTracker(tracker)
      setX(null)
      setY(null)
    } else {
      setTracker(tracker)
      setDate(moment(tracker).format('D MMM YYYY - HH:mm').toUpperCase())
    }
  }

  const handleTimeRangeChange = timerange => {
    const minArray = []
    const maxArray = []
    for (let i = 0; i < orderedSeriesNames.length; i++) {
      minArray.push(getMinValue(timeSeries[i], orderedSeriesNames[i]), timerange)
      maxArray.push(getMaxValue(timeSeries[i], orderedSeriesNames[i]), timerange)
    }
    setMin(minArray)
    setMax(maxArray)
    setTimerange(timerange)
  }

  const handleMouseMove = (x, y) => {
    setX(x)
    setY(y)
  }

  const selectionChangeHandler = newSelection => {
    if (selection && selection === newSelection) {
      setSelection(null)
    } else {
      setSelection(newSelection)
    }
  }

  return timeSeries && !noData ? (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', marginBottom: 15, height: 80 }}>
        {filters?.map((profile, i) => (
          <ChartFilter
            key={profile.id}
            groupIndex={i}
            groupId={profile.id}
            groupTitle={profile.title}
            variables={profile.profile_conf.map(conf => ({ id: conf.aggregate_var, name: conf.title }))}
            values={getTrackerValues(profile, getTimeSeriesForEachProfile(timeSeries, profile))}
            onSelectionChange={handleChartVariablesSelectionChange}
            colors={getColors(filters)}
          />
        ))}
      </div>

      <div style={{ flex: 1 }}>
        <Resizable>
          <ChartContainer
            timeRange={timerange}
            paddingRight={30}
            maxTime={getMaxTime(timeSeries)}
            minTime={getMinTime(timeSeries)}
            onTrackerChanged={handleTrackerChanged}
            trackerPosition={tracker}
            enablePanZoom={true}
            onTimeRangeChanged={handleTimeRangeChange}
            onMouseMove={(x, y) => handleMouseMove(x, y)}
            minDuration={minDuration}
            format={'%d-%b %H:%M'}
          >
            {timeSeries.map((profile, i) => {
              return (
                <ChartRow height={containerHeight * childHeightProportions[i] - 80} axisMargin={chartRowsAxisMargins[i]}>
                  <YAxis
                    id={`y${i}`}
                    label={labels[i]}
                    min={getMinValue(profile, orderedSeriesNames[i])}
                    max={getMaxValue(profile, orderedSeriesNames[i])}
                    style={{
                      ticks: {
                        stroke: '#AAA',
                        opacity: 0.25,
                        'stroke-dasharray': '1,1',
                        // Note: this isn't in camel case because this is
                        // passed into d3's style
                      },
                    }}
                    showGrid
                    width="60"
                    type="linear"
                    format={formats[i]}
                  />
                  <Charts>
                    {profile.map((series, j) => {
                      return (
                        <LineChart
                          axis={`y${i}`}
                          breakLine={breakline}
                          series={series}
                          columns={[orderedSeriesNames[i][j]]}
                          style={chartStyles}
                          //interpolation={interpolation}
                          highlight={highlight}
                          onHighlightChange={highlight => setHighlight(highlight)}
                          selection={selection}
                          onSelectionChange={selection => selectionChangeHandler(selection)}
                          smooth={smooth}
                          visible={visible?.[orderedSeriesNames[i][j]]}
                        />
                      )
                    })}
                    <CrossHairs x={x} y={y} />
                    <Baseline axis={`y${i}`} value={0} />
                  </Charts>
                </ChartRow>
              )
            })}
          </ChartContainer>
        </Resizable>
        <div style={{ minHeight: 30, position: 'absolute', top: 10, right: 10, fontSize: 13, fontWeight: 500 }}>
          {tracker ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AccessAlarmsRoundedIcon style={{ marginRight: 10 }}></AccessAlarmsRoundedIcon>
                {date}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.noData}>
      <Typography variant="subtitle">No hay registros</Typography>
    </div>
  )
}
export default CargaMtrCt

function CrossHairs({ x, y, height }) {
  const style = { pointerEvents: 'none', stroke: '#ccc' }

  return x && y ? (
    <g>
      <line style={style} x1={x} y1={0} x2={x} y2={height} />
    </g>
  ) : (
    <g />
  )
}
