import Immutable from 'seamless-immutable';
import { actions } from './actions';

export const defaultState = {
  healthCheck: {},
  healthCheckLoading: false
};

export function reducer(state = Immutable(defaultState), action) {
    switch (action.type) {
      case actions.INIT: {
        return Immutable.merge(state, { healthCheckLoading: true })
      }
      case actions.HEALTH_CHECK_SUCCESS: {
        return Immutable.merge(state, { 
          healthCheck: action.payload,
          healthCheckLoading: false
        })
      }
      case actions.HEALTH_CHECK_FAILURE: {
        return Immutable.merge(state, { 
            healthCheck: { healthy: false, message: action.payload },
            healthCheckLoading: false})
      }
      default: return state;    
    }
}

