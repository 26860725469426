import { Button, Card, Checkbox, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, Tooltip, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import { CloseRounded } from '@material-ui/icons'
import { useEffect, useState } from 'react'
import { CirclePicker } from 'react-color'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import { actionCreators as iotActions } from 'redux/iotHandlers';

const CreateGroupModal = ({ open, handleClose, handleCreate }) => {
  const useStyles = makeStyles((theme) => ({
    close: {
      position: 'absolute',
      right: 15,
      top: 8,
      zIndex: 99,
    },
		root: {
			color: '#2E2E2E',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
     color: {
       width: '52px',
       height: '25px',
       borderRadius: '3px',
       background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
       cursor:'pointer',
       //borderWidth: '2px',
       borderColor: '#303030',
       marginTop:'8px',
       //borderStyle:'solid'
     },
     swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      background: '#303030',
      position: 'absolute',
      padding: 10,
      zIndex: '2',
      borderRadius:6,
      marginTop:10,
      boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }));

  const [t] = useTranslation('devices')

	const dispatch = useDispatch()

	const types = useSelector((state) => state.iot.iotGroupEntityTypes);

	const [publicCheckboxDisable, setPublicCheckboxDisable] = useState(false)
  const [formValues, setFormValues] = useState({
    name: '',
    group_type_id: '',
		color: '#ff9800',
		description: '',
		private_group: false,
		let_users_update: false,
  })

  const [creating, setCreating] = useState(false)

  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState({
    r: '255',
    g: '152',
    b: '0',
    a: '1',
	})

	useEffect(() => {
		dispatch(iotActions.getGroupEntityTypes())
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

  const handleClickColorPicker = () => {
   setDisplayColorPicker(!displayColorPicker)
  };

  const handleCloseColorPicker = () => {
   setDisplayColorPicker(false)
  };

  const handleChangeColor = (color) => {
		setColor(color.rgb)
		setFormValues({
			...formValues,
			color: color.hex,
		})
  };


  const classes = useStyles()

  const handleChange = e => {
		if (e.target.name === 'private_group') {
			const publicCheckbox = document.getElementById('public')
			if (e.target.checked) {
				publicCheckbox.checked && publicCheckbox.click()
				setPublicCheckboxDisable(e.target.checked)
			} else {
				setPublicCheckboxDisable(e.target.checked)
			}
		}
		let updatedFormValues
		if (e.target.type === 'checkbox') {
			const publicCheckbox = document.getElementById('public')
			const privateCheckbox = document.getElementById('private')
			updatedFormValues = {
				...formValues,
				[publicCheckbox.name]: publicCheckbox.checked,
				[privateCheckbox.name]: privateCheckbox.checked,
			}
			setFormValues(updatedFormValues)
		} else {
			updatedFormValues = {
				...formValues,
				[e.target.name]: e.target.value,
			}
			setFormValues(updatedFormValues)
		}
	}

  const createGroup = () => {
    handleCreate(formValues);
		setFormValues({
      name: '',
    	group_type_id: '',
			color: '',
			description: '',
			private_group: false,
		let_users_update: false,
    })
		handleClose();
  }

  return (
    <Dialog
      className={styles.modal}
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Grid container justifyContent="flex-start" alignItems="center">
          <span>{t('devicesGroupsCreateGroupModalTitle')}</span>
        </Grid>
        <IconButton
          className={classes.close}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>

      {creating === false && (
        <>
          <DialogContent className={styles.dialogContent} dividers={true}>
            <Card className={styles.card}>
              <FormGroup>
                <div style={{ display: 'flex' }}>
                  <div>
                    <FormLabel className={styles.formLabel}>
                      {t('devicesGroupsCreateGroupModalNameLabel')}
                    </FormLabel>
                    <Card className={styles.cardInput} elevation={0}>
                      <Input
                        placeholder={t(
                          'devicesGroupsCreateGroupModalNamePlaceholder'
                        )}
                        className={styles.input}
                        type="text"
                        name="name"
                        onChange={handleChange}
                        disableUnderline
                      />
                    </Card>
                  </div>
                  <div>
                    <div className={styles.selectContainer}>
                      <InputLabel>
                        {t('devicesGroupsCreateGroupModalTypeLabel')}
                      </InputLabel>
                      <Select
                        className={styles.select}
                        name="group_type_id"
                        value={formValues.type}
                        onChange={handleChange}
                      >
                        {types?.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className={styles.selectContainer}>
                    <InputLabel>
                      {t('devicesGroupsCreateGroupModalColorLabel')}
                    </InputLabel>
                    <div>
                    <div style={styles.swatch} onClick={ handleClickColorPicker}>
                      <div className={ classes.color } />
                    </div>
                    { displayColorPicker ? <div className={ classes.popover }>
                      <div className={ classes.cover } onClick={ handleCloseColorPicker }/>
                      <CirclePicker color={ color } onChange={ handleChangeColor } />
                    </div> : null }

                  </div>
                  </div>
                </div>
                <div
                  style={{ display: 'flex', marginTop: 30, marginBottom: 30 }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormLabel className={styles.formLabel}>
                      {t('devicesGroupsCreateGroupModalDescriptionLabel')}
                    </FormLabel>
                    <Card className={styles.cardInputTextArea} elevation={0}>
                      <TextField
                        className={styles.input}
                        style={{ paddingLeft: 20 }}
                        name="description"
                        onChange={handleChange}
                        multiline
                        minRows={5}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Card>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 8,
                      marginLeft: 20,
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title={t(
                          'devicesGroupsCreateGroupModalCheckboxPrivateTooltip'
                        )}
                        placement="top"
                      >
												<Checkbox
													id="private"
                          name="private_group"
                          className={classes.root}
                          onClick={handleChange}
                        />
                      </Tooltip>
                      <Typography variant="body2" style={{ marginLeft: 10 }}>
                        {t('devicesGroupsCreateGroupModalCheckboxPrivate')}
                      </Typography>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Tooltip
                        title={t(
                          'devicesGroupsCreateGroupModalCheckboxPublicTooltip'
                        )}
                        placement="bottom"
                      >
                        <Checkbox
                          id="public"
                          name="let_users_update"
                          className={classes.root}
                          onClick={handleChange}
                          disabled={publicCheckboxDisable}
                        />
                      </Tooltip>
                      <Typography variant="body2" style={{ marginLeft: 10 }}>
                        {t('devicesGroupsCreateGroupModalCheckboxPublic')}
                      </Typography>
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Card>
            <div className={styles.containerButton}>
              <Button
                disabled={
                  !(
                    formValues.name.length > 0 &&
                    formValues.name.length < 50 &&
                    formValues.description.length > 0 &&
										formValues.description.length < 500 &&
										formValues.color !== undefined &&
										formValues.group_type_id !== undefined
                  )
                }
                variant="outlined"
                onClick={createGroup}
              >
                {t('devicesGroupsCreateGroupModalCreateButton')}
              </Button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export default CreateGroupModal
