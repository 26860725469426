import React from 'react'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useTranslation } from 'react-i18next'
import Skeleton from '@material-ui/lab/Skeleton'
import Devices from './components/devicesPanel'
import LogicUnits from './components/logicalUnitsPanel'

const StatisticsOperationalPanel = ({ data, currentFilter, onFilterChange }) => {
  const [value, setValue] = React.useState(0)
  const [t] = useTranslation('statisticalOperationalPanel')
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const renderSkeleton = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton animation='wave' height={60} width={100} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div style={{ width: '30%' }}>
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
          </div>
          <div style={{ width: '60%' }}>
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Paper>
      <Tabs value={value} onChange={handleChange} indicatorColor='primary' textColor='primary' centered variant='fullWidth'>
        <Tab label={t('logic-units')} />
        <Tab label={t('devices')} />
      </Tabs>
      {value === 0 &&
        (!data ? (
          renderSkeleton()
        ) : (
          <LogicUnits data={data.logicalunits} value={value} index={0} currentFilter={currentFilter} onFilterChange={onFilterChange}></LogicUnits>
        ))}
      {value === 1 && (!data ? renderSkeleton() : <Devices data={data.devices} value={value} index={1}></Devices>)}
    </Paper>
  )
}
export default StatisticsOperationalPanel
