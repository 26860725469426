/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import moment from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { getIn } from 'seamless-immutable';

import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
  Button
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import BlockRoundedIcon from '@material-ui/icons/BlockRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded'
import { Skeleton } from '@material-ui/lab';

import { actionCreators as iotActions } from 'redux/iotHandlers';
import reportIoT from 'services/ioTReportService'
import AlertActions from 'redux/alert/actions'
import { formatInterval } from 'utils/dateUtils';

import { DEFAULT_PAGE_NUMBER, pageSizeOptions } from './constants';
import styles from './styles.module.scss';

const renderSkeleton = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 275,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation="wave" height={60} width={180} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 15,
        }}
      >
        <div style={{ width: '100%' }}>
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
          <Skeleton animation="wave" height={30} />
        </div>
      </div>
    </div>
  );
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 450,
    overflowY: 'scroll',
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'monospace',
  },
}))(Tooltip);

const NetworkServerAlarmsContainer = ({
  networkServerSelected,
  alarmsList,
  dispatch,
  loading,
  totalAlarms,
}) => {
  const [t] = useTranslation('devices');
  const [anchorActionMenu, setAnchorActionMenu] = useState(null);
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [alarmIndexSelected, setAlarmIndexSelected] = useState(null);

  const handleActionMenuClick = (event, index) => {
    setAlarmIndexSelected(index);
    setAnchorActionMenu(event.currentTarget);
  };
  const handleActionMenuOption = (alarm, status) => {
    setAnchorActionMenu(null);
  };

  const handleActionMenuClose = () => {
    setAnchorActionMenu(null);
  };

  const getAlarms = useCallback(() => {
    dispatch(iotActions.getIotNetworkServerAlarms(currentPage, pageSize));
  });

  useEffect(() => {
  }, [alarmsList]);

  useEffect(() => {
    if (!alarmsList || (alarmsList && !alarmsList[`${currentPage}`]))
      getAlarms();
    else setTableData(alarmsList[`${currentPage}`]);
  }, [currentPage]);

  useEffect(() => {
    if (alarmsList) {
      if (isFirstRun && alarmsList[`1`]) {
        setIsFirstRun(false);
      }
      setTableData(alarmsList[`${currentPage}`]);
    }
  }, [alarmsList]);

  useEffect(() => {
    return () => {
      dispatch(iotActions.cleanNetworkServerAlarms());
    };
  }, []);

  const handleChangePage = (_currentPage, _pageSize) => {
    setCurrentPage(_currentPage);
    if (_pageSize) setPageSize(_pageSize);
  };

  const handleDownloadReport = () => {
    reportIoT(networkServerSelected.id, [], 'server-alarms')
      .then(resp => {
        if (resp && resp.ok) {
          let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.setAttribute('download', 'alarms_' + networkServerSelected.id + '.xlsx')
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a) // Eliminar el elemento después de descargar
          window.URL.revokeObjectURL(url) // Suelta el objeto blob
        } else dispatch(AlertActions.error('Report not found'))
      })
      .catch(err => dispatch(AlertActions.error('Report not found')))
  }

  const columns = [
    {
      name: 'id',
      label: t('deviceAlarmsTableIDTitleColumn'),
      options: {
        filter: true,
        filterType: 'textField',
        display: 'true',
        customBodyRender: (data) => {
          return <span>{data}</span>;
        },
      },
    },
    {
      name: 'executedAt',
      label: t('deviceAlarmsTableExecutedAtTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => {
          return <span>{moment(data).format('DD-MM-YYYY HH:mm:ss')}</span>;
        },
      },
    },
    {
      name: 'description',
      label: t('deviceAlarmsTabledescriptionColumn'),
      options: {
        filter: true,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => {
          return <span>{data}</span>;
        },
      },
    },
    {
      name: 'occurrences',
      label: t('deviceAlarmsTableOccurrencesTitleColumn'),
      options: {
        filter: true,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{data}</span>
        },
      },
    },
    {
      name: 'last_occurrence',
      label: t('deviceAlarmsTableLastOccurrenceTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{moment(data).format('DD-MM-YYYY HH:mm:ss')}</span>
        },
      },
    },
    {
      name: 'duration',
      label: t('deviceAlarmsTableAgingTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{formatInterval(data)}</span>
        },
      },
    },
    {
      name: 'status',
      label: t('deviceAlarmsTableStatusColumn'),
      options: {
        filter: true,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => {
          return (
            <span>
              {data === 'pending'
                ? t('deviceAlarmStatus_pending')
                : data === 'ignored'
                ? t('deviceAlarmStatus_ignored')
                : data === 'on process'
                ? t('deviceAlarmStatus_onprocess')
                : data === 'resolved'
                ? t('deviceAlarmStatus_resolved')
                : 'N/A'}
            </span>
          );
        },
      },
    },
    {
      name: '',
      options: {
        filter: true,
        display: 'true',
        customBodyRenderLite: (data) => {
          if (
            alarmsList[`${currentPage}`] &&
            alarmsList[`${currentPage}`][data] &&
            alarmsList[`${currentPage}`][data]?.iot_alarm_type
              ?.let_user_change_status &&
            alarmsList[`${currentPage}`][data]?.status_transitions
              ?.transitions instanceof Array &&
            alarmsList[`${currentPage}`][data]?.status_transitions?.transitions
              ?.length > 0
          )
            return (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div
                  aria-label="more"
                  aria-controls="long-menu"
                  onClick={(event) => handleActionMenuClick(event, data)}
                  className={styles.actionButton}
                >
                  <MoreVertIcon />
                </div>
                <Menu
                  elevation={1}
                  id="long-menu"
                  anchorEl={anchorActionMenu}
                  open={Boolean(anchorActionMenu)}
                  className={styles.actionMenu}
                  onClose={handleActionMenuClose}
                >
                  {alarmsList[`${currentPage}`][
                    alarmIndexSelected
                  ]?.status_transitions.transitions.map((el, i) => (
                    <MenuItem
                      onClick={(event) =>
                        handleActionMenuOption(
                          alarmsList[`${currentPage}`][alarmIndexSelected],
                          el.status_to_apply
                        )
                      }
                    >
                      <ListItemIcon>
                        {el.code === 'OP' && (
                          <BuildRoundedIcon fontSize="small"></BuildRoundedIcon>
                        )}
                        {el.code === 'I' && (
                          <BlockRoundedIcon fontSize="small"></BlockRoundedIcon>
                        )}
                        {el.code === 'R' && (
                          <DoneRoundedIcon fontSize="small"></DoneRoundedIcon>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={el.label} />
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            );
          else return null; /*NO MOSTRAR MENU */
        },
      },
    },
    {
      name: 'finishedAt',
      label: t('deviceAlarmsTableFinishedAtTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: data => {
          return <span>{data ? moment(data).format('DD-MM-YYYY HH:mm:ss') : ''}</span>
        },
      },
    },
    {
      name: 'data',
      label: t('networkServerEventsTableMetaDataTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => (
          <HtmlTooltip
            placement="left-start"
            title={JSON.stringify(data || { }, null, 4)}
          >
            <IconButton size="small">
              <CodeRoundedIcon fontSize="small" />
            </IconButton>
          </HtmlTooltip>
        ),
      },
    },
  ];

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyHeight: 'calc(100% - 125px)',
    download: false,
    viewColumns: false,
    serverSide: true,
    count: totalAlarms,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page + 1, null);
          break;
        case 'sort':
          break;
        default:
      }
    },
    setRowProps: (row, index) => {
      let classN;
      if (alarmsList[currentPage])
        classN =
          alarmsList[currentPage][index]?.status === 'pending'
            ? styles.backRowAlert
            : null;
      return {
        className: classN,
      };
    },
    setTableProps: () => ({
      size: 'small',
    }),
    customToolbar: () => {
      if (tableData?.length > 0) {
        return (
          <Tooltip title='Descargar archivo CSV'>
            <Button variant='secondary' startIcon={<DescriptionRoundedIcon />} onClick={handleDownloadReport}>
              Exportar datos
            </Button>
          </Tooltip>
        )
      }
    },
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar Excel', //'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  };

  return (
    <>
      {loading && isFirstRun ? (
        renderSkeleton()
      ) : (
        <MUIDataTable
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">
                Alarmas
                {loading && (
                  <CircularProgress
                    size={18}
                    style={{ marginLeft: 15, position: 'relative', top: 4 }}
                  />
                )}
              </Typography>
            </div>
          }
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  networkServerSelected: getIn(store, ['iot', 'iotNetworkServerDetail']),
  loading: getIn(store, ['iot', 'iotNetworkServerAlarmsLoading']),
  alarmsList:
    store.iot.iotNetworkServerAlarms && store.iot.iotNetworkServerAlarms.rows,
  totalAlarms:
    store.iot.iotNetworkServerAlarms &&
    store.iot.iotNetworkServerAlarms.totalNetworkServerAlarms,
  totalPages:
    store.iot.iotNetworkServerAlarms &&
    store.iot.iotNetworkServerAlarms.totalNetworkServerPages,
});

export default connect(mapStateToProps)(memo(NetworkServerAlarmsContainer));
