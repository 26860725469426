import { string, arrayOf, shape } from 'prop-types'
import React, { Fragment } from 'react'
import Divider from '@material-ui/core/Divider'

import MeasureItem from './components/MeasureItem'
import styles from './styles.module.scss'

const MeasureInfo = ({ info }) => {
  const renderItem = (item, index) => (
    <Fragment>
      <MeasureItem data={{ ...item, scondaryData: info.secondaryData[index] }} validationData={info.validationData} />
      <Divider />
    </Fragment>
  )
  return <div className={styles.infoBlock}>{info.primaryData.map(renderItem)}</div>
}

MeasureInfo.propTypes = {
  info: arrayOf(
    shape({
      detail: string.isRequired,
      value: string.isRequired,
    }),
  ),
}

export default MeasureInfo
