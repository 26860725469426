import { WebMercatorViewport } from 'react-map-gl'

import {UTILITY_DEFAULT_MAP_LOCATION} from 'constants/utility';

const applyToArray = (func, array) => func.apply(Math, array)

export const getBoundsForPoints = (points, padding) => {
  const pointsLong = points.map(point => point.coordinates._long)
  const pointsLat = points.map(point => point.coordinates._lat)
  const cornersLongLat = [
    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
  ]
  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const viewport = new WebMercatorViewport({ width: 800, height: 600 }).fitBounds(cornersLongLat, { padding }) // Can also use option: offset: [0, -100]
  const { longitude, latitude, zoom } = viewport
  return { longitude, latitude, zoom }
}

/**
 * 
 * @param {*} coordinates 
 * @returns 
 */
export const sanitizeCoordinates = coordinates => {
  if (!coordinates || coordinates.length !== 2) return [0,0];

	// extract coordinates
	let longitude = coordinates[0] || 0;
	let latitude = coordinates[1] || 0;
  
	// evaluate longitude are number and convert incase of string
	if (typeof longitude !== 'number' || isNaN(longitude))
		if (typeof longitude === 'string' || longitude instanceof String)
			//it's a string the convert to number
			longitude = parseFloat(longitude);
		else
    longitude = 0;
  
	// evaluate latitude are number and convert incase of string
	if (typeof latitude !== 'number' || isNaN(latitude))
		if (typeof latitude === 'string' || latitude instanceof String)
			//it's a string the convert to number
			latitude = parseFloat(latitude);
		else
    latitude = 0;

	return [longitude, latitude];
}


/**
 * 
 * @param {*} geoPosition 
 */
export const extractGeoInformation = geoPosition => {
	const {country = '', province = '', city = '', locality = '', address = ''} = geoPosition?.geografic_info || {};
	const [latitude = UTILITY_DEFAULT_MAP_LOCATION[1], longitude = UTILITY_DEFAULT_MAP_LOCATION[0]] = geoPosition?.map_info?.geometry?.coordinates || [];
	
	return {
		country,
		province,
		city,
		locality,
		address,
		latitude,
		longitude
	};


}
