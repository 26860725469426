import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { MapLogicalUnit } from './iconLayer';

const MapContainer = ({ logicalUnitRelation }) => {
	const [logicalUnit, setLogicalUnit] = useState();

	// Initialize map when component mounts
  	useEffect(() => {
		setLogicalValue();	
  	}, []); // eslint-disable-line react-hooks/exhaustive-deps

  	useEffect(() => {
		setLogicalValue();
  	}, [logicalUnitRelation]); // eslint-disable-line react-hooks/exhaustive-deps

	const setLogicalValue = () => {
		// format geoPosition Data
		if (logicalUnitRelation) {
			const { iot_logical_unit: iotLogicalUnit } = logicalUnitRelation;
			setLogicalUnit(iotLogicalUnit);
		}	
	}


  	return (
		<>
			{logicalUnit && (
				<MapLogicalUnit logicalUnitParam={logicalUnit} zoomParam={15}></MapLogicalUnit>
			)}	
		</>
	);
};

const mapStateToProps = store => ({})
export default connect(mapStateToProps)(MapContainer)
