import React, { useState } from 'react'
import BuildDetail from './BuildDetail'
import MtrDetail from './MtrDetail'
import CtDetail from './CtDetail'
import { OPERATION_COMPONENT } from 'constants/iot'
import { Card, IconButton } from '@material-ui/core'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { CloseRounded } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { useDispatch } from 'react-redux'
import DeviceDetailInfo from 'app/screens/IoT/DeviceType/screens/DeviceDetailInfo'
import WTModal from 'app/components/WTModal'

const useStyles = makeStyles(theme => ({
  close: {
    position: 'absolute',
    right: 25,
    top: 25,
    zIndex: 99,
  },
  card: {
    display: 'flex',
    borderRadius: 0,
    flexDirection: 'column',
    background: '#424242e6',
    height: '100vh',
    width: '460px',
  },
}))

const DetailPanel = ({ logicalUnit, viewDetail, idChildren }) => {
  const dispatch = useDispatch()
  const logicalUnitType = logicalUnit && logicalUnit.logical_unit.iot_logical_unit_type.internal_name
  const [openDetailModal, setOpenDetailModal] = useState(false)
  const [fullscreen, setFullscreen] = React.useState(false)
  const classes = useStyles()
  const handleCloseDeviceDetail = () => setOpenDetailModal(false)
  const handleGetDeviceDetail = id => {
    dispatch(iotActions.getIotDeviceDetail(id))
    setOpenDetailModal(true)
  }

  const handleToggleFullScreen = () => {
    setFullscreen(!fullscreen)
  }

  const handleCloseLogicalUnitDetail = () => {
    viewDetail(false)
  }

  return (
    <>
      <Card className={classes.card} elevation={3}>
        <IconButton className={classes.close} aria-label='close' onClick={() => handleCloseLogicalUnitDetail()}>
          <CloseRounded />
        </IconButton>
        {logicalUnitType === OPERATION_COMPONENT.BUILD && (
          <BuildDetail logicalUnit={logicalUnit} viewDetail={viewDetail} onDeviceSelect={handleGetDeviceDetail} idChildren={idChildren} />
        )}
        {logicalUnitType === OPERATION_COMPONENT.MTR && (
          <MtrDetail logicalUnit={logicalUnit} viewDetail={viewDetail} onDeviceSelect={handleGetDeviceDetail} />
        )}
        {logicalUnitType === OPERATION_COMPONENT.CT && (
          <CtDetail logicalUnit={logicalUnit} viewDetail={viewDetail} onDeviceSelect={handleGetDeviceDetail} />
        )}
      </Card>
      <WTModal open={openDetailModal} onClose={handleCloseDeviceDetail} onFullscreen={handleToggleFullScreen} fullscreen={fullscreen}>
        <DeviceDetailInfo fullscreen={fullscreen}></DeviceDetailInfo>
      </WTModal>
    </>
  )
}

export default DetailPanel
