import { FormControlLabel, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import styles from './style.module.scss'
import Tooltip from '@material-ui/core/Tooltip'

function EditableField({ fieldValue, onUpdate, index, unit, fieldType, letEmptyValue, changesInProgress, hasRoleEditParam }) {
  const [editOn, setEditOn] = useState(false)
  const [value, setValue] = useState('')
  const [origValue, setOrigValue] = useState('')
  const [showEditLabel, setShowEditLabel] = useState(false)
  const [type, setType] = useState('')
  const [allowEmptyValue, setAllowEmptyValue] = useState(false)
  
  useEffect(() => {
    setValue(fieldValue)
    setOrigValue(fieldValue)
    setType(fieldType)
    setAllowEmptyValue(!!letEmptyValue)
    setShowEditLabel(false)
  }, [fieldValue, fieldType, letEmptyValue])

  const handleEdit = () => {
    setEditOn(true)
  }

  const handleSave = () => {
    if ((value && !origValue) || value !== origValue) {
      if (((type === 'number' && isPositiveInteger(value)) || type !== 'number') && isNotEmptyValue(value)) {
        setEditOn(false)
        setOrigValue(value)
        onUpdate(index, value)
        setShowEditLabel(true)
      }
    } else {
      setEditOn(false)
      setOrigValue(value)
    }
  }

  const handleCancel = () => {
    setValue(origValue)
    setEditOn(false)
  }

  const keyPress = e => {
    if (e.keyCode === 13) {
      handleSave()
    }
    if (e.keyCode === 27) {
      e.preventDefault()
      handleCancel()
    }
  }

  const isNotEmptyValue = e => {
    return allowEmptyValue || e.trim().length > 0
  }

  const isPositiveInteger = val => {
    let str = String(val)
    str = str.trim()
    if (!str) {
      return false
    }
    str = str.replace(/^0+/, '') || '0'
    const n = Math.floor(Number(str))
    return n !== Infinity && String(n) === str && n >= 0
  }

  return (
    <div>
      {!editOn ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
          <div style={{ minWidth: 145 }}>
            <Typography color={showEditLabel ? 'primary' : ''} variant='body2'>
              {value} {unit}
            </Typography>
          </div>
          {hasRoleEditParam && !changesInProgress && (
            <Tooltip title='Editar valor' aria-label='edit' placement='right'>
              <EditRoundedIcon
                className={styles.actionButton}
                onClick={() => handleEdit()}
                style={{ marginLeft: 10 }}
                color='primary'
                fontSize='small'
              ></EditRoundedIcon>
            </Tooltip>
          )}
          {showEditLabel && (
            <Typography variant='caption' color='primary'>
              {changesInProgress ? 'EN PROCESO' : 'PENDIENTE'}
            </Typography>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 200 }}>
          <FormControlLabel
            className={styles.editField}
            value={value}
            control={<TextField onKeyDown={e => keyPress(e)} autoFocus value={value} />}
            onChange={event => setValue(event.target.value)}
          />
          <DoneRoundedIcon fontSize='small' className={styles.actionButton} onClick={() => handleSave()}></DoneRoundedIcon>
          <ClearRoundedIcon fontSize='small' className={styles.actionButton} onClick={() => handleCancel()}></ClearRoundedIcon>
        </div>
      )}
    </div>
  )
}

export default EditableField
