import moment from 'moment-timezone'

export const getTableData = (devices, t) => {
  const data = devices?.map(device => {
    return {
      uid: device.uid,
      installed: device.device_install_situation,
      connectionStatus: getConnectionStatus(device, t),
      deviceLastSignal: getDeviceLastSignal(device, t),
      deviceLastPayload: getDeviceLastPayload(device, t),
      available: getAvailable(device, t),
      hierarchy: getHierarchy(device, t),
      statusOperation: getStatusOperation(device, t),
      deviceObject: device,
    }
  })

  return data
}

const getInstalled = (device, t) => {
  return !(device.connected_network && device.available)
    ? t('stock')
    : device.connected_network && device.available
    ? t('installed')
    : t('notAvailable')
}

const getConnectionStatus = (device, t) => {
  let status = device.status_service ? t('on') : t('off')
  let date = device.last_status_service ? moment(device.last_status_service).format('DD/MM/YYYY HH:mm') : ''

  return new ConnectionStatus(status, date)
}

const getDeviceLastSignal = (device, t) => {
  return device.iot_device_brief?.last_signal ? moment(device.iot_device_brief.last_signal).format('DD/MM/YYYY HH:mm') : ''
}

const getDeviceLastPayload = (device, t) => {
  return device.iot_device_brief?.iot_payload_ul?.timestamp
    ? moment(device.iot_device_brief?.iot_payload_ul?.timestamp).format('DD/MM/YYYY HH:mm')
    : 'No disponible'
}

const getAvailable = (device, t) => {
  return device.available ? 'Si' : 'No'
}

const getHierarchy = (device, t) => {
  return device.iot_hierarchy ? device.iot_hierarchy.description : ''
}

const getStatusOperation = (device, t) => {
  return device.status_operation
}

class ConnectionStatus {
  constructor(status, date) {
    this.status = status
    this.date = date
  }
  toString() {
    return `${this.status} - ${this.date}`
  }
}
