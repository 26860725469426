import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Chip, Dialog, DialogContent, DialogTitle, FormGroup, FormLabel, Grid, IconButton, Input, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CloseRounded } from '@material-ui/icons'
import AddAlarmRoundedIcon from '@material-ui/icons/AddAlarmRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { isEmail, removeSpecialCharactersFromStringButKeepDotCommaDashUnderscore } from 'utils/stringUtils'
import { cronFormatterFromBackToFront, cronFormatterFromFrontToBack } from 'utils/iot'
import { ENTER } from 'constants/iot'

import CronSchedulerModal from '../CronSchedulerModal'
import { isEmptyObject } from 'utils/objects'

const SaveCronModal = ({ open, handleClose, handleEdit, deviceTypeId, cronId, editing }) => {
		const useStyles = makeStyles((theme) => ({
			close: {
				position: 'absolute',
				right: 15,
				top: 8,
				zIndex: 99,
			},
			root: {
				color: '#2E2E2E',
				'&:hover': {
					backgroundColor: 'transparent',
				},
			},
			color: {
				width: '52px',
				height: '25px',
				borderRadius: '3px',
				cursor:'pointer',
				//borderWidth: '2px',
				borderColor: '#303030',
				marginTop:'8px',
				//borderStyle:'solid'
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				background: '#303030',
				position: 'absolute',
				padding: 10,
				zIndex: '2',
				borderRadius:6,
				marginTop:10,
				boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
			},
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px',
			},
		}));

	const classes = useStyles()

	const [t] = useTranslation('devices')

	const dispatch = useDispatch()

	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [params, setParams] = useState([])
	const [cron, setCron] = useState('')
	const [cronLiteral, setCronLiteral] = useState('')
	const [email, setEmail] = useState('')
	const [emails, setEmails] = useState([])
	const [openCronSchedulerModal, setOpenCronSchedulerModal] = useState(false)
	const cronDetail = useSelector((state) => state.iot.iotTypeReportCronDetail);
	const loadingCronDetail = useSelector((state) => state.iot.iotTypeReportCronDetailLoading);
	const cronReportSaved = useSelector((state) => state.iot.iotCronReportSaved);

	const reportId = Number(useSelector(
    (state) => state.iot.iotReportDetail[0]?.code
	));

	const handleDelete = (index) => {
    setEmails(emails.filter((email, i) => i !== index))
  };

	const handleAddEmail = () => {
		emails?.length > 0 ? setEmails([...emails, email]) : setEmails([email])
		setEmail('')
	}

	const handleInputEmail = (e) => {
		const email = e.target.value
		setEmail(email)
	}

	const handleEnter = (e) => {
		if (e.key === ENTER) {
			if(!isEmail(email)) return
			handleAddEmail(e.target.value)
			setEmail('')
		}
	}

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
	}

	const handleDescriptionChange = (e) => {
		if(e.target.value.length > 255) return
		const input = removeSpecialCharactersFromStringButKeepDotCommaDashUnderscore(e.target.value)
		setDescription(input)
	}

	const handleCloseCronSchedulerModal = () => {
		setOpenCronSchedulerModal(false)
	}

  const saveCronReport = async() => {
		const payload = {
			title,
			description,
			cron,
		}
		if (emails.length > 0) payload.iot_report_cron_contacts = emails
		editing ? await dispatch(iotActions.putCronReport(cronId, payload)) : await dispatch(iotActions.postCreateCronReport(reportId, deviceTypeId, payload))
		if (isEmptyObject(cronReportSaved)) return
		await dispatch(iotActions.getIotReportCrons(reportId));
		handleClose();
	}

	useEffect(() => {
		if (cronId && !loadingCronDetail) {
			if (cronDetail) {
				setTitle(cronDetail.title)
				setDescription(cronDetail.description)
				setCron(cronDetail.cron)
				setCronLiteral(cronDetail.cron_literal)
				setEmails(cronDetail?.iot_report_cron_contacts?.map((contact) => contact.email))
			}
		}
		return () => {
			dispatch(iotActions.cleanIotReportCronDetail())
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cronId, loadingCronDetail])


  return (
    <Dialog
      className={styles.modal}
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="lg"
      disableEscapeKeyDown
    >
      <DialogTitle>
        <Grid container justifyContent="flex-start" alignItems="center">
          <span>{t('deviceCronsListSaveCronModalHeader')}</span>
        </Grid>
        <IconButton
          className={classes.close}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>

        <>
          <DialogContent className={styles.dialogContent} dividers={true}>
            <Card className={styles.card}>
              <FormGroup row={true}>
                <div style={{ display: 'flex', flexDirection: 'column'}}>

									<div>
										<FormLabel
											className={styles.formLabel}
											required
										>{t('deviceCronsListSaveCronModalLabelTitle')}</FormLabel>
                    <Card className={styles.cardInput} elevation={0}>
                      <Input
                        placeholder={t('deviceCronsListSaveCronModalInputTitlePlaceholder')}
												className={styles.input}
                        value={title}
												type="text"
                        name="name"
                        onChange={handleTitleChange}
                        disableUnderline
                      />
                    </Card>
									</div>

									<div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormLabel className={styles.formLabel}>
                      {t('deviceCronsListSaveCronModalLabelDescription')}
                    </FormLabel>
                    <Card className={styles.cardInputTextArea} elevation={0}>
                      <TextField
                        className={styles.input}
                        style={{ paddingLeft: 20 }}
                        name="description"
												value={description}
                        onChange={handleDescriptionChange}
                        multiline
                        minRows={5}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Card>
									</div>
                </div>

								<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 30}}>

									<div style={{ marginBottom: 25, display: 'none' }}>
										<FormLabel
											className={styles.formLabel}
											required
										>{t('deviceCronsListSaveCronModalLabelParams')}</FormLabel>
										<Button
											disabled
											variant='outlined'
											style={{ textTransform: 'none', marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
											endIcon={<ArrowForwardIosRoundedIcon />}
										>
											<div style={{overflow: "hidden", textOverflow: "ellipsis", width: 520, display: 'flex', justifyContent: 'space-between'}}>
												<Typography noWrap variant="body2" style={{ marginLeft: 10 }}>
														{params.join(', ')}
												</Typography>
											</div>
										</Button>
									</div>

									<div style={{ marginBottom: 25 }}>
										<FormLabel
											className={styles.formLabel}
											required
										>{t('deviceCronsListSaveCronModalLabelCron')}</FormLabel>
									<Button
										onClick={() => {
											setOpenCronSchedulerModal(true)
										}}
											variant='outlined'
											style={{ textTransform: 'none', marginBottom: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
											endIcon={<ArrowForwardIosRoundedIcon />}
										>
											<div style={{overflow: "hidden", textOverflow: "ellipsis", width: 520, display: 'flex', justifyContent: 'space-between'}}>
												<Typography noWrap variant="body2" style={{ marginLeft: 10 }}>
														{cronLiteral?.length ? cronLiteral : t('deviceCronsListSaveCronModalButtonCronText')}
												</Typography>
											</div>
										</Button>
									</div>

									<div style={{ display: 'flex' }} >
										<div style={{display: 'flex', flexDirection: 'column'}}>
											<FormLabel className={styles.formLabel} required>
												{t('deviceCronsListSaveCronModalLabelContacts')}
											</FormLabel>
											<div style={{display: 'flex', alignItems: 'center'}}>
												<Card  elevation={0}>
												<Input
													placeholder={t('deviceCronsListSaveCronModalInputContactsPlaceholder')}
													className={styles.input}
													value={email}
													style={{width: 500}}
													type="text"
													onChange={handleInputEmail}
													onKeyDown={handleEnter}
													disableUnderline
												/>
												</Card>
												<Tooltip style={{marginLeft: 20}} title={'Agregar email'}>
													<IconButton disabled={!isEmail(email)} onClick={handleAddEmail}>
														<AddCircleOutlineRoundedIcon
															fontSize='large'
														/>
													</IconButton>
												</Tooltip>
											</div>

										</div>
									</div>


									<div className={styles.cardChipContainer}>
                    <div className={styles.cardChips} elevation={0}>
											{emails?.map((email, index) => (
													<Chip
														className={styles.chip}
														label={email}
														variant="outlined"
														size='small'
														onDelete={() => handleDelete(index)}
													/>
											))}
                    </div>
									</div>
								</div>

              </FormGroup>
            </Card>
            <div className={styles.containerButton}>
							<Button
								startIcon={<AddAlarmRoundedIcon />}
                disabled={
                  !(
                    title?.length > 0 &&
                    params?.length < 50 &&
										cron?.length > 0 &&
										description?.length > 0 &&
										emails?.length > 0
                  )
                }
                variant="outlined"
                onClick={saveCronReport}
              >
                {t('deviceCronsListSaveCronModalButtonSave')}
              </Button>
            </div>
          </DialogContent>
			</>
			<CronSchedulerModal open={openCronSchedulerModal} handleClose={handleCloseCronSchedulerModal}  initialValue={cron?.length > 0  ? cronFormatterFromBackToFront(cron) : ''}
            handleCronChange={(a,b)=>{
							const aFormatted = cronFormatterFromFrontToBack(a);
							setCron(aFormatted);
							setCronLiteral(b);
            }} />
    </Dialog>
  );
}

export default SaveCronModal
