import { stringArrayToObject } from '../utils';
import  ioTLogicalUnitService from 'services/ioTLogicalUnitService';

/* ------------- Logic Units actions ------------- */
export const actions = stringArrayToObject(
  [
    'INIT_LOGICALUNITS_TYPE',
    'INIT_LOGICALUNITS_TYPE_SUCCESS',
    'INIT_LOGICALUNITS_TYPE_FAILURE',
    'INIT_LOGICALUNITS',
    'INIT_LOGICALUNITS_SUCCESS',
    'INIT_LOGICALUNITS_FAILURE',
    'SET_LOGICALUNIT_SELECTED',
    'CLEAR_LOGICALUNIT_SELECTED',
    'INIT_LOGICALUNIT_DETAIL',
    'END_LOGICALUNIT_DETAIL',
    'SET_DEVICE_LOGICALUNIT_CHILDREN',
    'INIT_LOGICALUNIT_CHILDREN',
    'END_LOGICALUNIT_CHILDREN'
  ],
  '@@LOGICAL_UNITS'
);

const privateActionCreators = {
  initUnitTypeSuccess: payload => ({ type: actions.INIT_LOGICALUNITS_TYPE_SUCCESS, payload }),
  initUnitTypeFailure: () => ({ type: actions.INIT_LOGICALUNITS_TYPE_FAILURE }),   
  logicalUnitsSuccess: payload => ({ type: actions.INIT_LOGICALUNITS_SUCCESS, payload }),
  logicalUnitsFailure: () => ({ type: actions.INIT_LOGICALUNITS_FAILURE }),
  setLogicalUnitSelected: payload => ({ type: actions.SET_LOGICALUNIT_SELECTED, payload }),
  setDevicesLogicalUnitChildren: payload => ({ type: actions.SET_DEVICE_LOGICALUNIT_CHILDREN, payload })
}

export const actionCreators = {
  /**
   * **Action**
   * Initialize the logical units array of store. Information about id, name, 
   * internal name, group logical unit and so on
   * @async
   * @action
   * @param {none}  
   * @returns update state iotlogicalunitType (array)
   */
  initLogicalUnitsType: () => async (dispatch, getState) => { 
    const { healthCheck } = getState().utility;    
    if (healthCheck?.healthy) {
      const { token, companyId } = getState().auth.authInformation
      dispatch({ type: actions.INIT_LOGICALUNITS_TYPE})    
      await ioTLogicalUnitService.getLogicalUnitsType(token, companyId)
      .then(async (response) => {
        if (response?.logical_unit_types) dispatch(privateActionCreators.initUnitTypeSuccess(response.logical_unit_types))
        else dispatch(privateActionCreators.initUnitTypeFailure)    
      })
    }
  },
  /**
   * **Action**
   * Set the logical units from a Hierarchy. 
   * Information of each logical unit about description, status and geoposition 
   * @async
   * @action
   * @param {number} hierarchyid 
   * @returns - update state iotlogicalunits (MAP(id_hierarchy, []))
   */
  getLogicalUnits: (h_id) => async (dispatch, getState) => { 

    const { iotlogicalunits } = getState().iotLogicalUnit;

    if (iotlogicalunits[h_id]) return; //en state ya estan cargadas las Unidades de la jerarquia activa
      //TODO: incorporar campo last_request para verificar el tiempo transcurrido desde el ultimo request API
    
    //no hay en state información para la jerarquía seleccionada => buscar por API 
    const { healthCheck } = getState().utility;    
    if (healthCheck?.healthy) {
      const { token, companyId } = getState().auth.authInformation
      dispatch({ type: actions.INIT_LOGICALUNITS})    
      await ioTLogicalUnitService.getLogicalUnitsMap(h_id, token, companyId)
      .then(async (response) => {
        if (response?.logical_units) dispatch(privateActionCreators.logicalUnitsSuccess({_id:h_id, logical_units:response.logical_units}))
        else dispatch(privateActionCreators.logicalUnitsFailure)    
      })
    }
  },
  /**
   * **Action**
   * Clear state iotlogicalunitSelected
   * @async
   * @action
   * @param none 
   * @returns - update state iotlogicalunitSelected 
   */
  clearLogicalUnitSelected: () => (dispatch, getState) => {
    dispatch({ type: actions.CLEAR_LOGICALUNIT_SELECTED })
  },
  /**
   * **Action**
   * Get detail of logical unit at view Map layer 3.
   * Update state iotlogicalunitSelected with two properties:
   * - logical_unit: is an object
   * - device: is a Map() with key=id_unit and value=array of device data
   * @async
   * @action
   * @param {number} idunit ID logical unit
   * @returns - update state iotlogicalunitSelected 
   */
   getLogicalUnitDetail: (idunit) => async (dispatch, getState) => {
  
     dispatch({ type: actions.INIT_LOGICALUNIT_DETAIL })
     
     const { healthCheck } = getState().utility
     if (healthCheck?.healthy) {
      //invocar API para traer la unidad y devices asociados (si no es edificio)
      const { token, companyId } = getState().auth.authInformation
      await ioTLogicalUnitService.getLogicalUnitDetailById(idunit, token, companyId).then(async response => {
        if (response) {
          if (response.logical_unit.children.length > 0) {
            //se trata de una unidad edificio => no buscar devices      
            dispatch(privateActionCreators.setLogicalUnitSelected({logical_unit: response.logical_unit, devices: new Map()})) 
            dispatch({ type: actions.END_LOGICALUNIT_DETAIL })
          } else {  
            //*Nota*
            //redux state solo acepta types planos (si se setea Map.set(id,devices) en el reducer NO se registra)
            //Solucion: setear devices con un Map() vacio, luego invocar el action children 
            //para add el valor con el device correspondiente
            //***/
            response["devices"]=new Map() 
            dispatch(privateActionCreators.setLogicalUnitSelected(response)) 
            //search devices 
            await ioTLogicalUnitService.getLogicalUnitDetailById_devices(idunit, token, companyId)
              .then(responseDev => {
                if (responseDev) {
                  dispatch(privateActionCreators
                    .setDevicesLogicalUnitChildren({_id: idunit, devices:responseDev.device_relations}))
                }
                dispatch({ type: actions.END_LOGICALUNIT_DETAIL })
              })
          }
        } else dispatch({ type: actions.END_LOGICALUNIT_DETAIL })        
      })
    } else dispatch({ type: actions.END_LOGICALUNIT_DETAIL })    
  },
  /**
   * **Action**
   * Get detail of logical unit children (parent build)
   * Update property *device* of state iotlogicalunitSelected 
   * - device: is a Map() with key=id_unit_children and value=array of device data
   * @async
   * @action
   * @param {number} idunitchildren ID logical unit children
   * @returns - update property *device* of state *iotlogicalunitSelected* 
   */
   getLogicalUnitDetailChildren: (idunitchildren) => async (dispatch, getState) => {

    dispatch({ type: actions.INIT_LOGICALUNIT_CHILDREN })

    const { healthCheck } = getState().utility   
    if (healthCheck?.healthy) {
      const { token, companyId } = getState().auth.authInformation      
      //search devices 
      await ioTLogicalUnitService.getLogicalUnitDetailById_devices(idunitchildren, token, companyId)
        .then(response => {
          if (response) 
            dispatch(privateActionCreators
              .setDevicesLogicalUnitChildren({_id: idunitchildren, devices:response.device_relations}))  
          dispatch({ type: actions.END_LOGICALUNIT_CHILDREN })
        })
    } else dispatch({ type: actions.END_LOGICALUNIT_CHILDREN }) 
  },
};

export default actionCreators;