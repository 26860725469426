//import axios from 'axios';

import apiIOT from 'config/api'
import { backofficeConf } from 'config/keycloakConfig'
import { AUTH_HEADER, UTILITY_HEADER } from 'constants/apiHeaders'

const kc = backofficeConf()

const init = {
  initialize: async shouldEnableIframeCheck =>
    kc.init({ onLoad: 'login-required', checkLoginIframe: false }).then(authenticated => {
      if (authenticated) {
        //Se setean los headers a las apis con el token de CK
        let companyId = process.env.REACT_APP_COMPANY_ID;
        apiIOT.setHeaders({
          [AUTH_HEADER]: `Bearer ${authenticated.token}`,
          [UTILITY_HEADER]: companyId, // se setea el company Id que no esta retornando dentro de idTokenParsed
        })
        return kc
      }
      return undefined
    }),
}

export default init

// need to wrap the KC "promise object" into a real Promise object
/*  const refreshToken = (minValidity = 5) =>
   new Promise((resolve, reject) => {
     kc.updateToken(minValidity)
       .success(() => resolve())
      .error(error => reject(error));
   });

axios.interceptors.request.use(config =>
   refreshToken()
     .then(() => {
       config.headers.Authorization = `Bearer ${kc.token}`;
       return Promise.resolve(config);
     })
     .catch(() => {
       kc.login();
    })
 ); */
