import { apiIOTReports } from 'config/api'
import store from 'redux/store'
import { IOTDEVICE, IOTSERVER } from 'constants/apiResources'
import { AUTH_HEADER, SEARCH_PARAMS } from 'constants/apiHeaders'

/**
 * *async* **Report Service of devices**
 * @param {*} id id device
 * @param {*} type report's type. [alarms|isalive|measurements|none].
 * @param {*} params - array [{"field": "", "operator":"", "value":""}]
 *
 * operator:
 * - eq: equals
 * - lt: less than
 * - gt: greather than
 * @returns {*} report excel
 */
export const reportIoT = async (id, params, type) => {
  const state = store.getState()
  let auhtInformation = state.auth['authInformation']

  let endpoint
  switch (type) {
    case 'device':
      endpoint = IOTDEVICE.DEVICE_REPORT
      break
    case 'alarms':
      endpoint = IOTDEVICE.DEVICE_REPORT_ALARMS
      break
    case 'alarms-cancel':
      endpoint = IOTDEVICE.DEVICE_REPORT_ALARMS_CANCEL
      break
    case 'isalive':
      endpoint = IOTDEVICE.DEVICE_REPORT_ISALIVE
      break
    case 'measurements':
      endpoint = IOTDEVICE.DEVICE_REPORT_MEASUREMENTS
      break
    case 'events':
      endpoint = IOTDEVICE.DEVICE_REPORT_EVENTS
      break
    case 'server-alarms':
      endpoint = IOTSERVER.SERVER_REPORT_ALARMS
      break
    default:
      endpoint = IOTDEVICE.REPORTS
      break
  }
  
  endpoint = endpoint.replace('{{iotDeviceId}}', id)
  endpoint = endpoint.replace('{{iotReportId}}', id)
  endpoint = endpoint.replace('{{iotServerId}}', id)

  apiIOTReports.setHeaders({
    ...apiIOTReports.headers,
    [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`,
    [SEARCH_PARAMS]: JSON.stringify(params),
  })
  const response = await apiIOTReports.post(endpoint, null, { responseType: 'blob' }).catch(thrown => {
    if (apiIOTReports.isCancel(thrown)) {
      console.log('Request reportIoT - canceled', thrown.message)
    } else {
      // handle error
      console.log('Request reportIoT - canceled err', thrown.message)
    }
  })
  return response
}

/**
 * 
 * @param {*} reportId 
 * @param {*} queryParams 
 * @param {*} headerParams 
 * @param {*} bodyParams 
 * @returns 
 */
export const reportDeviceInformation = async (reportId, queryParams, headerParams, bodyParams) => {
  const state = store.getState()
  let auhtInformation = state.auth['authInformation']
  
  let endpoint = IOTDEVICE.REPORTS;
  endpoint = endpoint.replace('{{iotReportId}}', reportId);

  if (queryParams && Object.keys(queryParams).length > 0) {
    const queryParamsKeys = Object.keys(queryParams);
    const queryParamsValues = Object.values(queryParams);
    let queryParamsFormat = '';

    for (let i=0, len=queryParamsKeys.length; i < len; i += 1)
      if (queryParamsValues[i])
        queryParamsFormat += `${(i === 0) ? '?' : '&'}${queryParamsKeys[i]}=${queryParamsValues[i]}`;

    endpoint += queryParamsFormat
  }

  apiIOTReports.setHeaders({
    ...apiIOTReports.headers,
    [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`,
    ...headerParams
  })

  const response = await apiIOTReports.post(endpoint, bodyParams, { responseType: 'blob' }).catch(thrown => {
    if (apiIOTReports.isCancel(thrown)) {
      console.log('Request reportIoT - canceled', thrown.message)
    } else {
      // handle error
      console.log('Request reportIoT - canceled err', thrown.message)
    }
  })
  return response
}


/**
 * *async* **Measurements Report Service of devices**
 * @param {*} type report's type
 * @param {*} id device id
 * @param {*} params
 * @returns {*} report excel
 */
export const exportMeasurements = async (type, id, params) => {
  const state = store.getState()
  let auhtInformation = state.auth['authInformation']

  const body = { channelsId: params.channelsId }

  let endpoint = IOTDEVICE.DEVICE_MEASUREMENTS
  endpoint = endpoint.replace('{{PATH-IOT_GRAPHIC_ID}}', type)
  endpoint = endpoint.replace('{{PATH-DEVICE_ID}}', id)

  if (params.startDate && params.endDate) {
    let queryParams = `?startDate={{PARAMS-START_DATE}}&endDate={{PARAMS-END_DATE}}`
    queryParams = queryParams.replace('{{PARAMS-START_DATE}}', params.startDate)
    queryParams = queryParams.replace('{{PARAMS-END_DATE}}', params.endDate)
    endpoint = endpoint + queryParams
  }

  apiIOTReports.setHeaders({
    ...apiIOTReports.headers,
    [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`,
  })
  const response = await apiIOTReports.post(endpoint, body, { responseType: 'blob' }).catch(thrown => {
    if (apiIOTReports.isCancel(thrown)) {
      console.log('Request reportIoT - canceled', thrown.message)
    } else {
      // handle error
      console.log('Request reportIoT - canceled err', thrown.message)
    }
  })
  return response
}

export default reportIoT
