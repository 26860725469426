import { useEffect, useState } from 'react';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import DeviceTypesView from './view';

import { ROUTES } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

const LEVEL_REPORTS = 'DEVICE_BULK';

function DeviceTypes() {
  const dispatch = useDispatch();

  const authInformation = useSelector((state) => state.auth.authInformation);
  const userRoles = useSelector((state) => state.auth.userRoles);

  const iotDevicesTypesLoading = useSelector(
    (state) => state.iot.iotDevicesTypesLoading
  );
  const iotDevicesTypes = useSelector((state) => state.iot.iotDevicesTypes);

  const iotDevicesTypesReportsLoading = useSelector(
    (state) => state.iot.iotDevicesTypesReportsLoading
  );
	const iotDevicesTypesReports = useSelector((state) => state.iot.iotDevicesTypesReports);

	const iotGroupsOfDevicesLoading = useSelector((state) => state.iot.iotGroupsOfDevicesLoading);


  const iotNetworkServers = useSelector((state) => state.iot.iotNetworkServers);
  const iotNetworkServersLoading = useSelector(
    (state) => state.iot.iotNetworkServersLoading
  );


  const [navigate, setNavigate] = useState(false);
  const [navigateTo, setNavigateTo] = useState(false);
  const [iotDeviceTypeSelected, setIotDeviceTypeSelected] = useState(null);
  const [iotDeviceTypeReportSelected, setIotDeviceTypeReportSelected] = useState(null);

  useEffect(() => {
    if (!iotDevicesTypes) dispatch(iotActions.cleanDevicesTypes());
    if (!iotDevicesTypesReports) dispatch(iotActions.cleanIotDevicesTypesReports());

    if (authInformation) {
      dispatch(iotActions.getIotDevicesTypes());
      dispatch(iotActions.getIotNetworkServers());

      const reportsLevelFilter = [
        { field: 'report_level', operator: 'eq', value: LEVEL_REPORTS },
        { field: 'available', operator: 'eq', value: true }
      ];
      dispatch(iotActions.getIotDevicesTypesReports(reportsLevelFilter))
    }

    return () => {
      dispatch(iotActions.cleanIotDevices());
    };
  }, []);

  const handleSelectDevicesTypes = (type) => {
    setNavigate(true);
    setNavigateTo(ROUTES.IOT_DEVICE_TYPES);
    setIotDeviceTypeSelected(type.name);
  };

  const handleSelectDevicesTypesReports = (type) => {
    setNavigate(true);
    setNavigateTo(ROUTES.IOT_DEVICE_TYPES_REPORTS);
    setIotDeviceTypeReportSelected(type.name);
  };

  return (
    <>
      {
        // redirect to devices List by Type device
        navigate && navigateTo === ROUTES.IOT_DEVICE_TYPES ? (
          <Redirect to={`${ROUTES.IOT_DEVICE_TYPES}/${iotDeviceTypeSelected}`} push />
        ) :

        // redirect to reports List by Type device
        navigate && navigateTo === ROUTES.IOT_DEVICE_TYPES_REPORTS ? (
          <Redirect to={`${ROUTES.IOT_DEVICE_TYPES_REPORTS}/${iotDeviceTypeReportSelected}`} push />
        ) :

        <DeviceTypesView
          iotDevicesTypesLoading = {iotDevicesTypesLoading}
					iotGroupsOfDevicesLoading={iotGroupsOfDevicesLoading}
          iotDevicesTypes = {iotDevicesTypes}
          handleSelectDevicesTypes = {handleSelectDevicesTypes}
          //--
          iotDevicesTypesReportsLoading = {iotDevicesTypesReportsLoading}
          iotDevicesTypesReports = {iotDevicesTypesReports}
          handleSelectDevicesTypesReports = {handleSelectDevicesTypesReports}
          //--
          iotNetworkServers = {iotNetworkServers}
          iotNetworkServersLoading = {iotNetworkServersLoading}
          //--
          userRoles={userRoles}
        ></DeviceTypesView>
      }
    </>
  );
}

export default DeviceTypes;
