import { Typography } from '@material-ui/core'
import { CHART_NAMES } from 'constants/iot'
import { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { graphicSeries } from 'services/ioTGraphicService'
import CargaMtrCt from './Carga_MTR_CT'
import CargaTelecPs from './Carga_TELEC_PS'
import InstrumentMtrCt from './Instrument_MTR_CT'
import styles from './styles.module.scss'

const Charts = ({ id, typeId, chartName, startDate, endDate }) => {
  const [series1, setSeries1] = useState()
  const [series2, setSeries2] = useState()
  const [series3, setSeries3] = useState()
  const [loading, setLoading] = useState()
  const { width, height, ref } = useResizeDetector()

  useEffect(() => {
    const fetchSeries = async (startDate, endDate) => {
      setSeries1(null)
      setSeries2(null)
      setSeries3(null)
      const result = await graphicSeries(typeId, id, { startDate, endDate })

      switch (chartName) {
        case CHART_NAMES.AIO_PERFIL_CARGA_EPE:
          setSeries1(result.data)
          break

        case CHART_NAMES.AIO_PERFIL_INSTRUMENTACION_EPE:
          setSeries2(result.data)
          break

        case CHART_NAMES.TELECPS_PERFIL_CARGA_PACTT_EPE:
        case CHART_NAMES.TELECPS_PERFIL_CARGA_PACTTABS_EPE:
        case CHART_NAMES.TELECPSSL_PERFIL_CARGA_PACTT_EPE:
        case CHART_NAMES.TELECPSSL_PERFIL_CARGA_PACTTABS_EPE:
          setSeries3(result.data)
          break

        default:
          break
      }

      setLoading(false)
    }

    setLoading(true)
    fetchSeries(startDate, endDate)
  }, [typeId, id, startDate, endDate, chartName])

  useEffect(() => {
    setSeries1(null)
    setSeries2(null)
    setSeries3(null)
    setLoading(true)
  }, [chartName])

  return (
    <div className={styles.container} ref={ref}>
      {loading ? (
        <div className={styles.loading}>
          <Typography variant="h6">Cargando datos ...</Typography>
        </div>
      ) : (
        <>
          {series1 && chartName === CHART_NAMES.AIO_PERFIL_CARGA_EPE ? (
            <CargaMtrCt series={series1?.serie} filters={series1?.iot_graphic?.profiles} containerHeight={height} containerWidth={width} />
          ) : null}

          {series2 && chartName === CHART_NAMES.AIO_PERFIL_INSTRUMENTACION_EPE ? (
            <InstrumentMtrCt series={series2?.serie} filters={series2?.iot_graphic?.profiles} containerHeight={height} containerWidth={width} />
          ) : null}

          {series3 && chartName === CHART_NAMES.TELECPS_PERFIL_CARGA_PACTT_EPE ? (
            <CargaTelecPs series={series3?.serie} filters={series3?.iot_graphic?.profiles} containerHeight={height} containerWidth={width} />
          ) : null}

          {series3 && chartName === CHART_NAMES.TELECPS_PERFIL_CARGA_PACTTABS_EPE ? (
            <CargaTelecPs series={series3?.serie} filters={series3?.iot_graphic?.profiles} containerHeight={height} containerWidth={width} />
          ) : null}

          {series3 && chartName === CHART_NAMES.TELECPSSL_PERFIL_CARGA_PACTT_EPE ? (
            <CargaTelecPs series={series3?.serie} filters={series3?.iot_graphic?.profiles} containerHeight={height} containerWidth={width} />
          ) : null}

          {series3 && chartName === CHART_NAMES.TELECPSSL_PERFIL_CARGA_PACTTABS_EPE ? (
            <CargaTelecPs series={series3?.serie} filters={series3?.iot_graphic?.profiles} containerHeight={height} containerWidth={width} />
          ) : null}
        </>
      )}
    </div>
  )
}

export default Charts
