/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { getIn } from 'seamless-immutable'
import { useTranslation } from 'react-i18next'
import MemoryRoundedIcon from '@material-ui/icons/MemoryRounded'
import { COMPONENT_NAME, COMPONENT_DESCRIPTION, COMPONENT_BADGE_NUMBER, COMPONENT_VERSION, COMPONENT_ISMAIN, COMPONENT_FIRMWARE } from './constants'
import { Skeleton } from '@material-ui/lab'

const DeviceComponentDetailContainer = ({ selectedComponent, initialValues, loading }) => {
  const [t] = useTranslation('devices')

  return (
    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
      <MemoryRoundedIcon style={{ height: 60, width: 60, marginRight: 30, opacity: 0.7 }}></MemoryRoundedIcon>
      <div style={{ minWidth: 300, marginRight: 200 }}>
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
          {loading ? (
            <Skeleton animation='wave' style={{ width: '90%', height: 21 }} />
          ) : (
            <>
              <Typography style={{ minWidth: 140, opacity: 0.7 }}>{t('serialBadge')}:</Typography>{' '}
              <Typography>{initialValues[COMPONENT_BADGE_NUMBER]}</Typography>
            </>
          )}
        </Grid>
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
          {loading ? (
            <Skeleton animation='wave' style={{ width: '100%', height: 21 }} />
          ) : (
            <>
              <Typography style={{ minWidth: 140, opacity: 0.7 }}>{t('component_description')}:</Typography>{' '}
              <Typography>{initialValues[COMPONENT_DESCRIPTION]}</Typography>
            </>
          )}
        </Grid>
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
          {loading ? (
            <Skeleton animation='wave' style={{ width: '95%', height: 21 }} />
          ) : (
            <>
              <Typography style={{ minWidth: 140, opacity: 0.7 }}>{t('version')}:</Typography>{' '}
              <Typography>{initialValues[COMPONENT_VERSION]}</Typography>
            </>
          )}
        </Grid>
      </div>
      <div style={{ minWidth: 300 }}>
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
          {loading ? (
            <Skeleton animation='wave' style={{ width: '75%', height: 21 }} />
          ) : (
            <>
              <Typography style={{ minWidth: 140, opacity: 0.7 }}>{t('componentIsMain')}:</Typography>
              <Typography>{initialValues[COMPONENT_ISMAIN]}</Typography>
            </>
          )}
        </Grid>
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
          {loading ? (
            <Skeleton animation='wave' style={{ width: '100%', height: 21 }} />
          ) : (
            <>
              <Typography style={{ minWidth: 140, opacity: 0.7 }}>{t('firmware')}:</Typography>
              <Typography>{initialValues[COMPONENT_FIRMWARE]}</Typography>
            </>
          )}
        </Grid>
        <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start'>
          {loading ? (
            <Skeleton animation='wave' style={{ width: '85%', height: 21 }} />
          ) : (
            <>
              <Typography style={{ minWidth: 140, opacity: 0.7 }}>{t('component')}:</Typography>{' '}
              <Typography>{initialValues[COMPONENT_NAME]}</Typography>
            </>
          )}
        </Grid>
      </div>
    </Grid>
  )
}

const initialValuesSelector = createSelector(
  store => getIn(store, ['iot', 'iotDeviceComponentDetail']),
  deviceComponentInfo => {
    const headerValues = {
      [COMPONENT_BADGE_NUMBER]: deviceComponentInfo?.badge_number,
      [COMPONENT_DESCRIPTION]: deviceComponentInfo?.description,
      [COMPONENT_VERSION]: deviceComponentInfo?.version,
      [COMPONENT_ISMAIN]: deviceComponentInfo?.isMain,
      [COMPONENT_FIRMWARE]: deviceComponentInfo?.iot_firmware ? deviceComponentInfo?.iot_firmware.version : null,
      [COMPONENT_NAME]: deviceComponentInfo?.iot_component ? deviceComponentInfo?.iot_component.name : null,
    }

    return {
      ...headerValues,
    }
  },
)

DeviceComponentDetailContainer.propTypes = {}

const mapStateToProps = store => ({
  userRoles: store.auth.userRoles,
  initialValues: initialValuesSelector(store),
})

export default connect(mapStateToProps)(DeviceComponentDetailContainer)
