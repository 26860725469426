import AlertActions from 'redux/alert/actions'
import  { getIotDeviceParams, putIotDeviceParamValue, putIotDeviceParamValueOnline } from 'services/iotDeviceParamsService';
import { deviceParams } from './selectors';
import { stringArrayToObject, getErrorMessage } from '../utils';

/* ------------- Logic Units actions ------------- */
export const actions = stringArrayToObject(
  [
	'GET_IOT_DEVICE_PARAMS',
	'GET_IOT_DEVICE_PARAMS_SUCCESS',
	'GET_IOT_DEVICE_PARAMS_FAILURE',

  'PUT_IOT_DEVICE_PARAM_VALUE',
	'PUT_IOT_DEVICE_PARAM_VALUE_SUCCESS',
	'PUT_IOT_DEVICE_PARAM_VALUE_FAILURE',

  'PUT_IOT_DEVICE_PARAM_VALUE_ONLINE',
	'PUT_IOT_DEVICE_PARAM_VALUE_ONLINE_SUCCESS',
	'PUT_IOT_DEVICE_PARAM_VALUE_ONLINE_FAILURE',

  'CLEAN_IOT_DEVICE_PARAMS'
  ],
  '@@DEVICE_PARAMS'
);

const privateActionCreators = {
	getIotDeviceParamsSuccess: payload => ({ type: actions.GET_IOT_DEVICE_PARAMS_SUCCESS, payload }),
	getIotDeviceParamsFailure: () => ({ type: actions.GET_IOT_DEVICE_PARAMS_FAILURE }),
  
  putIotDeviceParamValueSuccess: payload => ({ type: actions.PUT_IOT_DEVICE_PARAM_VALUE_SUCCESS, payload }),
  putIotDeviceParamValueFailure: () => ({ type: actions.PUT_IOT_DEVICE_PARAM_VALUE_FAILURE }),

  putIotDeviceParamValueOnlineSuccess: payload => ({ type: actions.PUT_IOT_DEVICE_PARAM_VALUE_ONLINE_SUCCESS, payload }),
  putIotDeviceParamValueOnlineFailure: () => ({ type: actions.PUT_IOT_DEVICE_PARAM_VALUE_ONLINE_FAILURE }),
}

export const actionCreators = {
  /**
   * **Action**
   * GET device params
   * @async
   * @action
   * @param {number}  
   * @returns list of param's device (array)
   */
  getIotDeviceParams: (deviceId) => async dispatch => {
    dispatch({ type: actions.GET_IOT_DEVICE_PARAMS })
    const response = await getIotDeviceParams(deviceId)
    if (response.ok) {
      const responsePayload = deviceParams(response.data);
      dispatch(privateActionCreators.getIotDeviceParamsSuccess(responsePayload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(privateActionCreators.getIotDeviceParamsFailure(errorMessage))
    }
  },

  /**
   * 
   * @param {*} deviceParamId 
   * @param {*} value 
   * @returns 
   */
  putIotDeviceParamValue: (deviceParamId, value) => async dispatch => {
    dispatch({ type: actions.PUT_IOT_DEVICE_PARAM_VALUE })
    const payload = { data: { value }}

    const response = await putIotDeviceParamValue(deviceParamId, payload)
    if (response.ok) {
      const responsePayload = deviceParams(response.data);
      dispatch(privateActionCreators.putIotDeviceParamValueSuccess(responsePayload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(privateActionCreators.putIotDeviceParamValueFailure(errorMessage))
    }
  },

  /**
   * 
   * @param {*} deviceParamId 
   * @param {*} value 
   * @returns 
   */
  putIotDeviceParamValueOnline: (deviceParamId, value) => async dispatch => {
    dispatch({ type: actions.PUT_IOT_DEVICE_PARAM_VALUE_ONLINE })
    const payload = { data_online: { value }}

    const response = await putIotDeviceParamValueOnline(deviceParamId, payload)
    if (response.ok) {
      const responsePayload = deviceParams(response.data);
      dispatch(privateActionCreators.putIotDeviceParamValueOnlineSuccess(responsePayload))
    } else {
      const errorMessage = getErrorMessage(response.data, 'msg error');
      dispatch(AlertActions.error(errorMessage))
      dispatch(privateActionCreators.putIotDeviceParamValueOnlineFailure(errorMessage))
    }
  },
  
/*
putIotDeviceComponentParams: (deviceComponentId, paramsToUpdate) => async dispatch => {
  dispatch({ type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS })
  const response = await iotService.putIotDeviceComponentParams(deviceComponentId, paramsToUpdate)
  if (response.ok) {
    dispatch(AlertActions.success(response.data.message))
    dispatch(actionCreators.putIotDeviceComponentParamsSuccess(response.data))
  } else {
    const errorMessage = response.data.errors[0].message 
    dispatch(AlertActions.error(errorMessage))
    dispatch(actionCreators.putIotDeviceComponentParamsFailure(errorMessage))
  }
},
putIotDeviceComponentParamsSuccess: updateParamsData => ({
  type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_SUCCESS,
  payload: updateParamsData,
}),
putIotDeviceComponentParamsFailure: () => ({
  type: actions.PUT_IOT_DEVICE_COMPONENT_PARAMS_FAILURE,
}),
*/

  /**
   * **Action**
   * Clear state iotDeviceParams
   * @async
   * @action
   * @param none 
   * @returns - update state iotDeviceParams 
   */
   cleanDeviceParams: () => (dispatch, getState) => {
    dispatch({ type: actions.CLEAN_IOT_DEVICE_PARAMS })
  },
};

export default actionCreators;