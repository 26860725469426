import moment from 'moment-timezone'

const NS_STATUS_PENDING = 'pending';
const NS_STATUS_CONNECTED = 'connected';
const NS_STATUS_DISCONNECTED = 'disconnected';

export const getTableData = (networkServers, t) => {
  const data = networkServers?.map(networkServer => {
    return {
      networkServer: networkServer.network_server,
			platform: networkServer.platform,
			applicationId: networkServer.application_id,
      connectionStatus: getConnectionStatus(networkServer, t),
    }
  })
  return data
}

const getConnectionStatus = (server, t) => {
  let status = NS_STATUS_DISCONNECTED;
  let lastConnectedDate = server.last_connected ? moment(server.last_connected).format('DD/MM/YYYY HH:mm') : ''
  let sinceDisconnectedDate = server.since_disconnected ? moment(server.since_disconnected).format('DD/MM/YYYY HH:mm') : ''
  let date;

  switch (server.status) {
    case NS_STATUS_PENDING: 
      status = t('pending');
      date = '';
      break;
    
    case NS_STATUS_CONNECTED:
      status = t('on');
      date = lastConnectedDate;
      break;

    case NS_STATUS_DISCONNECTED:
      status = t('off');
      date = sinceDisconnectedDate;
      break;
    
    default:
      status = t('pending');
      date = '';
      break;
  }
  
  return new ConnectionStatus(status, date)
}
class ConnectionStatus {
  constructor(status, date) {
    this.status = status
    this.date = date
  }
  toString() {
    return `${this.status} - ${this.date}`
  }
}
