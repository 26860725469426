import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import { COLORS } from 'app/styles/colors'

import { DIALOG_OCTAGON_OUTLINE } from 'constants/icons'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

export const WTDIALOG_TYPE = { WARNING: 'warning', CONFIRM: 'confirm', ERROR: 'error', INFO: 'info', SUCCESS: 'success' }

const WTdialog = ({ type, title, message, show, primaryActionLabel, onPrimaryAction, secondaryActionLabel, onSecondaryAction, onClose }) => {
  const [t] = useTranslation('devices')

  const getIcon = type => {
    switch (type) {
      case WTDIALOG_TYPE.WARNING:
        return <DIALOG_OCTAGON_OUTLINE color={COLORS.WARNING} className={styles.icon} />
      case WTDIALOG_TYPE.CONFIRM:
        return <DIALOG_OCTAGON_OUTLINE color={COLORS.PRIMARY} className={styles.icon} />
      case WTDIALOG_TYPE.ERROR:
        return <DIALOG_OCTAGON_OUTLINE color={COLORS.DANGER} className={styles.icon} />
      case WTDIALOG_TYPE.INFO:
        return <DIALOG_OCTAGON_OUTLINE color={COLORS.INFO} className={styles.icon} />
      case WTDIALOG_TYPE.SUCCESS:
        return <DIALOG_OCTAGON_OUTLINE color={COLORS.OK} className={styles.icon} />
      default:
        break
    }
  }

  const handleClickPrimaryAction = () => {
    if (onPrimaryAction) {
      onPrimaryAction()
    } else {
      onClose()
    }
  }
  const handleClickSecondaryAction = () => {
    if (onSecondaryAction) {
      onSecondaryAction()
    } else {
      onClose()
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog className={styles.dialog} open={show} maxWidth="xs" onClose={handleClose}>
      <div className={styles.dialogContainer}>
        <MuiDialogTitle>
          <div className={styles.title}>
            {getIcon(type)}
            <Typography variant="subtitle">{title}</Typography>
          </div>
        </MuiDialogTitle>
        <MuiDialogContent>
          <Typography>{message}</Typography>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button onClick={handleClickSecondaryAction}>{secondaryActionLabel ? secondaryActionLabel : t('wtdialog_cancel')}</Button>
          <Button variant="outlined" onClick={handleClickPrimaryAction}>
            {primaryActionLabel ? primaryActionLabel : t('wtdialog_ok')}
          </Button>
        </MuiDialogActions>
      </div>
    </Dialog>
  )
}

export default WTdialog
