import MUIDataTable from 'mui-datatables'
import { any, arrayOf, func, shape } from 'prop-types'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router';

import { Button, IconButton, Tooltip, Typography } from '@material-ui/core'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import AddAlarmRoundedIcon from '@material-ui/icons/AddAlarmRounded';

import { getDeviceIcon } from 'constants/icons'
import { getIn } from 'seamless-immutable'

import ExportReportInfo from 'app/screens/IoT/DeviceTypeReports/screens/DataExportModal'
import styles from './styles.module.scss'
import { getTableData } from './tableProvider'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { ROUTES } from 'constants/routes'
import { roles, validateAccess } from 'constants/userRoles';

const Reports = ({ listReports, onReportSelect, iotTypeName, iotTypeDescription, onGoBack }) => {
	const [t] = useTranslation('devices')
	const dispatch = useDispatch();
  const [tableData, setTableData] = useState([])
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [accessReportCron, setAccessReportCron] = useState(false)
  const [accessReport, setAccessReport] = useState(false) 
  const userRoles = useSelector(state => state.auth.userRoles)

  const handleExportModalOpen = () => {
    setExportModalOpen(true)
  }

  const handleExportModalClose = () => {
    setExportModalOpen(false)
  }

	const handleClickConfigCrons = (id) => {
		dispatch(iotActions.getIotReportDetail(id))
		dispatch(push(`${ROUTES.IOT_DEVICE_TYPES_REPORTS}/${iotTypeName}/crons/${id}`));
	}

  const setComponentAccess = () => {
    // button access report
    setAccessReport(validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_ACCESS));
    // button access report cron
    setAccessReportCron(validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_CRON_ACCESS));
  }

  useEffect(() => {
    const reports = listReports;
    const data = getTableData(reports, t)
    setTableData(data)
    setComponentAccess()
  }, [])

  const columns = [
    {
      name: 'id',
      label: t('reportsTableIdTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'title',
      label: t('reportsTableTitleTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'available',
      label: t('reportsTableAvailableTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'method',
      label: t('reportsTableMethodTitleColumn'),
      options: {
        sort: true,
      },
		},
    {
      name: '',
      label: '',
      options: {
        sort: false,
        customBodyRenderLite: data => {
          return (
            <div style={{ height: '100%', display: 'flex', justifyContent: 'end' }}>
							<div style={{ marginRight: 26}}>
              { accessReportCron && tableData[data].let_cron ? (
								<Tooltip title={t('reportsTableCronsButtonTooltipTitle')} placement="top">
									<IconButton
										size="small"
										variant="outlined"
										onClick={() => handleClickConfigCrons(tableData[data].id)}
									>
										<AddAlarmRoundedIcon />
									</IconButton>
								</Tooltip>
                ) : 
                <div></div>
              }
							</div>
							{ accessReport ? (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    onReportSelect(tableData[data].reportObject)
                    handleExportModalOpen()
                  }}
                >
                  {t('reportsTableExportButtonText')}
                </Button>
              ) : <div></div>
              }
            </div>
          )
        },
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    download: false,
    filterType: 'textField',
    selectableRows: 'none',
    rowsPerPage: 100,
    responsive: 'vertical',
    tableBodyHeight: 'calc(100% - 120px)',
    confirmFilters: true,
    viewColumns: false,
    setTableProps: () => ({
      size: 'small',
    }),
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button variant="contained" onClick={applyNewFilters}>
            {t('reportsFilterButtonText')}
          </Button>
        </div>
      )
		},
    textLabels: {
      body: {
        noMatch: 'No hay registros que coincidan con la búsqueda',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      <MUIDataTable
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={onGoBack}>
              <ArrowBackRoundedIcon></ArrowBackRoundedIcon>
            </IconButton>
            {getDeviceIcon(iotTypeName, styles.deviceIcon)}
            <Typography variant="h5" style={{ marginLeft: 10 }}>
              {iotTypeDescription ? iotTypeDescription : iotTypeName}
            </Typography>
							<Typography variant="h5" style={{ marginRight: 10, marginLeft:10 }}>
								- Reportes</Typography>
          </div>
        }
        data={tableData}
        columns={columns}
        options={options}
      />
      <ExportReportInfo open={exportModalOpen} handleClose={handleExportModalClose} iotTypeName={iotTypeName}></ExportReportInfo>
    </>
  );
}

const mapStateToProps = store => ({
  id: getIn(store, ['iot', 'iotDeviceDetail', 'id']),
})

Reports.propTypes = {
  listReports: arrayOf(shape(any)),
  onReportSelect: func
}

export default memo(connect(mapStateToProps)(Reports))
