import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import MuiTheme from './styles/MuiTheme'
import { useSelector, useDispatch } from 'react-redux'
import styles from './styles.module.scss'
import UTAlert from 'app/components/UTAlert'
import AuthActions from 'redux/auth/actions'
import MainLoading from './components/MainLoading'
import Routes from './navigation/routerConfig'
import UtilityActions from 'redux/utility/actions'
import { actionCreators as iotActions } from 'redux/iotHandlers';

function App() {
  const authInformation = useSelector(state => state.auth.authInformation)
  const authInformationLoading = useSelector(state => state.auth.authInformationLoading)
  const dispatch = useDispatch()
  const [initDone, setInitDone] = React.useState(false)
  const [thirdPartyCookiesSupported] = React.useState(true)
	const LEVEL_REPORTS = 'DEVICE_BULK';

  React.useEffect(() => {
    /**Se deshabilita el menu contextual en produccion */
    if (process.env.REACT_APP_ENV === 'prod') document.addEventListener('contextmenu', event => event.preventDefault())

    if (!initDone)
      (async () => {
        const healthCheck = async () => {
          await dispatch(UtilityActions.init())
        }
        await healthCheck()

        const authenticated = async () => {
          await dispatch(AuthActions.authInit(thirdPartyCookiesSupported))
        }
        await authenticated()

        const initStateNomenclature = async () => {
          await dispatch(UtilityActions.init_States_Nomenclature())
        }
				await initStateNomenclature()

				const fetchIotDeviceTypeReports = async() => {
					const reportsLevelFilter = [
						{ field: 'report_level', operator: 'eq', value: LEVEL_REPORTS },
						{ field: 'available', operator: 'eq', value: true }
					];
					await dispatch(iotActions.getIotDevicesTypesReports(reportsLevelFilter))
				}
				await fetchIotDeviceTypeReports()

        setInitDone(true)
      })()
  }, [initDone, dispatch, thirdPartyCookiesSupported])

  return (
    <ThemeProvider theme={MuiTheme}>
      <CssBaseline>
        <div className={styles.root}>
          <UTAlert />
          {!authInformationLoading || !authInformation ? <Routes /> : <MainLoading />}
        </div>
      </CssBaseline>
    </ThemeProvider>
  )
}

export default App
