import { Button, CircularProgress, IconButton, Tooltip, Typography } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import PortableWifiOffRounded from '@material-ui/icons/PortableWifiOffRounded';
import WifiTetheringRounded from '@material-ui/icons/WifiTetheringRounded';
import { Skeleton } from '@material-ui/lab';
import OperationBadge from 'app/components/OperationBadge';
import WTModal from 'app/components/WTModal';
import WTdialog from 'app/components/WTDialog';
import DeviceDetailInfo from 'app/screens/IoT/DeviceType/screens/DeviceDetailInfo';
import { getDeviceIcon } from 'constants/icons';
import { OPERATION_STATUS } from 'constants/iot';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { roles, validateAccess } from 'constants/userRoles';
import AddDeviceModal from './components/AddDeviceModal/AddDeviceModal';
import styles from './styles.module.scss';
import { DEFAULT_PAGE_NUMBER, PAGE_SIZE_OPTIONS, SORT } from './constants';

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
					<Skeleton animation='wave' height={30} />
					<Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}

const DeviceGroupDevicesList = ({userRoles, group, devices, totalDevices, totalPages}) => {
	const [t] = useTranslation('devices');
	const [addDevice, setAddDevice] = useState(false)
  const [initAddAdvice, setInitAddDevice] = useState(false)
	const [action, setAction] = useState('')
	const [detailModalOpen, setDetailModalOpen] = useState(false)
	const [fullscreen, setFullscreen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [deviceToDelete, setDeviceToDelete] = useState()
	const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0])
	const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER)
	const [tableData, setTableData] = useState([])
	const [isFirstRun, setIsFirstRun] = useState(true)
	const [isEditable, setIsEditable] = useState(null)
	const [sort, setSort] = useState([])
	const [isOwner, setIsOwner] = useState(null)
	const [hasRoleAddDevice, setHasRoleAddDevice] = useState(false)
	const [hasRoleRemoveDevice, setHasRoleRemoveDevice] = useState(false)


	const dispatch = useDispatch();

	const loading = useSelector((state) => state.iot.iotGroupDevicesLoading);
	const deleting = useSelector((state) => state.iot.iotDeleteDeviceFromGroupEntity);
	const adding = useSelector((state) => state.iot.iotCreateDevicesGroupLoading);
	const owner = useSelector((state) => state.iot.iotGroupOfDeviceDetail?.owner);
	const user = useSelector((state) => state.auth.authInformation?.preferredUsername);
  const isPrivate = useSelector((state) => state.iot.iotGroupOfDeviceDetail.is_private);
	const letUsersUpdate = useSelector((state) => state.iot.iotGroupOfDeviceDetail.let_users_update);

	const setComponentAccess = () => {
		// option button to Add devices to Group
		setHasRoleAddDevice(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_ADD_DEVICE));
		// option button to remove device form group
		setHasRoleRemoveDevice(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_GROUP_REMOVE_DEVICE));
	}
	
	const handleCloseAddDevice = () => {
		setAddDevice(false);
    setInitAddDevice(false);
	};

	const handleAddDevice = () => {
		setAddDevice(false);
    setInitAddDevice(false);
	};

	const handleCloseDetailModal = () => {
    setDetailModalOpen(false);
	};

	const handleOpenDetailModal = () => {
    setDetailModalOpen(true);
  };

	const handleToggleFullScreen = () => {
    setFullscreen(!fullscreen);
  };

	const handleSort = (page, sortName, sortDirection) => {
		let sortArray = [...SORT[sortName]]
		sortDirection === 'asc' ? sortArray.push('asc') : sortArray.push('desc')
		let newSort = [sortArray]
		setSort(newSort)
	}

	const getDevices = useCallback(() => {
		if (group?.id) {
			dispatch(iotActions.getIotGroupDevices(group.id, currentPage, pageSize, sort));
		}
	})


	useEffect(() => {
    setComponentAccess();
    return () => {
      dispatch(iotActions.cleanIotGroupDevices())
    }
  }, [])

	useEffect(() => {
		setIsOwner(owner === user)
	}, [owner, user])

	useEffect(() => {
    if (!devices || (devices && !devices[`${currentPage}`])) getDevices()
    else setTableData(devices[`${currentPage}`])
  }, [currentPage])

	useEffect(() => {
    // user is the owner or the group its no private and its is editable by others users
    const isEditableGroup = isOwner || (!isOwner && !isPrivate && letUsersUpdate)

		setIsEditable(isEditableGroup)
	}, [isOwner, isPrivate, letUsersUpdate])

	useEffect(() => {
		if (!isFirstRun) {
			getDevices()
			setTableData(devices[`${currentPage}`])
		}
  }, [pageSize])


	  useEffect(() => {
    if (devices) {
      if (isFirstRun && devices[`1`]) {
        setIsFirstRun(false)
      }
      setTableData(devices[`${currentPage}`])
    }
  }, [devices])

	useEffect(() => {
		if (!isFirstRun) {
			getDevices()
			setTableData(devices[`${currentPage}`])
		}
	}, [sort])

	useEffect(() => {
		if (!isFirstRun) {
			getDevices()
			setTableData(devices[`${currentPage}`])
		}
	}, [deleting, adding])


	const handleClickDevice = useCallback((value) => {
		dispatch(iotActions.getIotDeviceDetail(value.id));
		handleOpenDetailModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenDeleteModal = (device) => {
		setDeleteModalOpen(true)
		setDeviceToDelete(device)
	}

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false)
	}

	const handleDeleteDevice = async () => {
		await dispatch(iotActions.deleteDeviceFromGroup('device', group.id, deviceToDelete?.id))
		setDeleteModalOpen(false)
		dispatch(iotActions.getIotGroupDevices(group.id, currentPage, pageSize, sort));
	}

	const handleChangePage = (_currentPage, _pageSize) => {
    setCurrentPage(_currentPage)
    if (_pageSize) setPageSize(_pageSize)
  }

	const columns = [
    {
      name: 'iot_type',
      label: t('deviceGroupDevicesListTypeLabel'),
      options: {
				sort: true,
				sortCompare: (order) => (obj1, obj2) => {
					return order === 'asc'
            ? obj1.data.name.localeCompare(obj2.data.name)
            : -obj1.data.name.localeCompare(obj2.data.name);
        },
        customBodyRenderLite: (data) => {
					return (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {getDeviceIcon(tableData[data]?.iot_type?.name, styles.typeIcon)}
            </div>
          );
        },
      },
    },
    {
      name: 'devuid',
      label: t('deviceGroupDevicesListSerialNumberLabel'),
      options: {
				sort: true,
				customBodyRender: (data) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {data}
            </div>
          );
        },
      },
    },
    {
      name: 'device_install_situation',
      label: t('deviceGroupDevicesListConditionLabel'),
      options: {
				sort: true,
				customBodyRender: (data) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {data}
            </div>
          );
        },
      },
    },
    {
      name: 'status_service',
      label: t('deviceGroupDevicesListConnectionStatusLabel'),
      options: {
        sort: true,
        sortCompare: (order) => (obj1, obj2) => {
					return order === 'asc'
            ? obj1.data.localeCompare(obj2.data)
            : -obj1.data.localeCompare(obj2.data);
        },
        customBodyRenderLite: (data) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {tableData[data].status_service ? (
                  <WifiTetheringRounded style={{ fontSize: 20 }}></WifiTetheringRounded>
                ) : (
                  <PortableWifiOffRounded style={{ fontSize: 20 }}></PortableWifiOffRounded>
                )}
                {tableData[data].status_service ? (
                  <div style={{ marginLeft: 10 }}>{t('on')}</div>
                  ) : (
                  <div style={{ marginLeft: 10 }}>{t('off')}</div>
                )}
              </div>
              <Typography className={styles.statusDate}>
                  {tableData[data]?.last_status_service ? moment(tableData[data].last_status_service).format('DD-MM-YYYY HH:MM') : ''}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'last_signal',
      label: t('deviceGroupDevicesListLastSignalLabel'),
      options: {
        sort: true,
        sortCompare: (order) => (obj1, obj2) => {
					const date1 = (obj1.data.last_signal ? moment(obj1.data.last_signal) : moment('01-01-1900', 'DD-MM-YYYY')).valueOf();
					const date2 = (obj2.data.last_signal ? moment(obj2.data.last_signal) : moment('01-01-1900', 'DD-MM-YYYY')).valueOf();
					let res = date1 - date2;
          return order === 'asc' ? res : -res;
				},
				customBodyRenderLite: (data) => {
          const date = tableData[data]?.iot_device_brief?.last_signal === null ? '' : moment(tableData[data]?.iot_device_brief?.last_signal).format('DD-MM-YYYY HH:MM');
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
              {date}
            </div>
          );
        },
      },
    },
    {
      name: 'last_payload_ul',
      label: t('deviceGroupDevicesListLastMeasureLabel'),
      options: {
        sort: true,
        sortCompare: (order) => (obj1, obj2) => {
					const date1 = (obj1.data.last_payload_ul ? moment(obj1.data.last_payload_ul) : moment('01-01-1900', 'DD-MM-YYYY')).valueOf();
					const date2 = (obj2.data.last_payload_ul ? moment(obj2.data.last_payload_ul) : moment('01-01-1900', 'DD-MM-YYYY')).valueOf();
					let res = date1 - date2;
          return order === 'asc' ? res : -res;
				},
				customBodyRenderLite: (data) => {
          const date = tableData[data]?.iot_device_brief?.last_payload_ul === null ? '' : moment(tableData[data]?.iot_device_brief?.last_payload_ul).format('DD-MM-YYYY HH:MM');
					return (
						<div style={{ display: 'flex', alignItems: 'center' }}>
              {date}
            </div>
          );
        },
      },
    },
    {
      name: 'status_operation_badge',
      label: t('deviceGroupDevicesListOperationStatusLabel'),
      options: {
        sort: true,
        customBodyRenderLite: (data) => {
					const operationStatus = tableData[data].status_operation
					return (
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {operationStatus !== OPERATION_STATUS.OK && (
                <OperationBadge
                  type={operationStatus}
                  label={
                    operationStatus === OPERATION_STATUS.ALERT ? t('alert') : t('warning')
                  }
                ></OperationBadge>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'iot_hierarchy',
      label: t('deviceGroupDevicesListHierarchyLabel'),
      options: {
				sort: true,
				sortCompare: (order) => (obj1, obj2) => {
					return order === 'asc'
            ? obj1.data.description.localeCompare(obj2.data.description)
            : -obj1.data.description.localeCompare(obj2.data.description);
        },
				customBodyRenderLite: (data) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center'}}>
              {tableData[data]?.iot_hierarchy?.description}
            </div>
          );
        },
      },
    },
    {
      name: '',
      label: '',
			options: {
        sort: false,
        customBodyRenderLite: (data) => {
          return (
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleClickDevice(tableData[data])}
            >
              {t('deviceGroupDevicesListLookDevice')}
            </Button>
          );
        },
      },
    },
    {
      name: '',
			label: '',
			options: {
				display: hasRoleRemoveDevice && isEditable ? true : 'excluded',
        sort: false,
        customBodyRenderLite: (data) => {
          return (
            <Tooltip title={t('deviceGroupDevicesListDeleteDeviceTooltip')}>
              <IconButton
                size="small"
                variant="outlined"
                onClick={() => handleOpenDeleteModal(tableData[data])}
              >
                <DeleteForeverRoundedIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
  ];

  const options = {
    elevation: 1,
    filter: false,
		print: false,
		sort: true,
		search: false,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyHeight: 300,
    download: false,
		viewColumns: false,
		serverSide: true,
		count: totalDevices,
		rowsPerPage: pageSize,
		rowsPerPageOptions: PAGE_SIZE_OPTIONS,
		customToolbar: () => {
			return (
        <div style={{ display: 'inline-flex' }}>
          { hasRoleAddDevice && devices && devices[1].length > 0 && isEditable ? 
            <div>
              <Tooltip title={t('deviceGroupDevicesListAddDeviceTooltip')}>
                <IconButton onClick={() => { setAddDevice(true); setInitAddDevice(true) } }>
                  <AddRoundedIcon />
                </IconButton>
              </Tooltip>
            </div>
          : null
          }
        </div>
      );
		},
		onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page + 1, null)
          break;
				case 'sort':
					const page = tableState.page
					const sortName = tableState.sortOrder.name;
					const sortDirection = tableState.sortOrder.direction;
          handleSort(page, sortName, sortDirection)
          break;
        default:
          break;
      }
		},
		setRowProps: (row, index) => {
      const operationStatus = tableData[index].status_operation
			return {
        className:
         operationStatus === OPERATION_STATUS.ALERT ? styles.backRowAlert : operationStatus === OPERATION_STATUS.WARNING ? styles.backRowWarning : null,
      }
		},
    setTableProps: () => ({
      size: 'small',
		}),
		onChangeRowsPerPage: (numberOfRows) => {
			handleChangePage(1, numberOfRows)
		},
		textLabels: {
      body: {
        noMatch: 'No hay dispositivos',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  };

	return (
		<>
			{loading && isFirstRun ? (
				renderSkeleton()) : (
				<div
      style={{
        flexGrow:1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 20,
      }}
    >
      <MUIDataTable
				title={
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h6'>
                Dispositivos asociados
                {loading && <CircularProgress size={18} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
              </Typography>
            </div>
          }
        data={tableData}
        columns={columns}
        options={options}
      />
      {hasRoleAddDevice && devices && currentPage && devices[currentPage]?.length === 0 && (isEditable || isOwner) && (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}
        >
          <Button
            variant="outlined"
            startIcon={<AddRoundedIcon />}
            onClick={() => { setAddDevice(true); setInitAddDevice(true); }}
            style={{ position: 'fixed', top: 550, zIndex: 100 }}
          >
            {t('deviceGroupDevicesListAddDevices')}
          </Button>
        </div>
      )}
				</div>
			)}


    <AddDeviceModal
        open={addDevice}
        handleClose={handleCloseAddDevice}
				handleAdd={handleAddDevice}
				group={group}
        init={initAddAdvice}
        handleInit={setInitAddDevice}
      />
      <WTModal
        open={detailModalOpen}
        onClose={handleCloseDetailModal}
        onFullscreen={handleToggleFullScreen}
        fullscreen={fullscreen}
      >
        <DeviceDetailInfo fullscreen={fullscreen}></DeviceDetailInfo>
			</WTModal>
			<WTdialog
				type={'warning'}
				title={'Aviso de confirmación'}
				message={'Estás por desvincular al dispositivo del grupo, en caso de querer agregarlo nuevamente deberás hacerlo desde la opción “Agregar Dispositivos” realizando la búsqueda correspondiente.'}
				show={deleteModalOpen}
				primaryActionLabel={'Eliminar del grupo'}
				onPrimaryAction={handleDeleteDevice}
				secondaryActionLabel={'Cancelar'}
				onSecondaryAction={handleCloseDeleteModal}
				onClose={handleCloseDeleteModal}
      >
      </WTdialog>
      </>
  );
}

const mapStateToProps = store => ({
  devices: store.iot.iotGroupDevices && store.iot.iotGroupDevices.devices,
  totalDevices: store.iot.iotGroupDevices && store.iot.iotGroupDevices.totalDevices,
  totalPages: store.iot.iotGroupDevices && store.iot.iotGroupDevices.totalPages,
})

export default connect(mapStateToProps)(memo(DeviceGroupDevicesList))
