import { Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import styles from './styles.module.scss'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { DEVICE } from 'constants/icons'
import { OPERATION_STATUS } from 'constants/iot'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from 'utils/stringUtils'

const OperationStatus = ({ alertType, alarmDate, description, status }) => {
  const [t] = useTranslation('operationStatus')
  description = description?.length > 0 ? description : t('no-description')

  return (
    <div
      className={[
        styles.cardContent,
        alertType === OPERATION_STATUS.ALERT && styles.alert,
        alertType === OPERATION_STATUS.WARNING && styles.warning,
      ].join(' ')}
    >
      <div className={styles.header}>
        {alertType === OPERATION_STATUS.ALERT && <ErrorRoundedIcon className={styles.mainIcon} />}
        {alertType === OPERATION_STATUS.WARNING && <WarningRoundedIcon className={styles.mainIcon} />}
        <Tooltip title={description}>
          <Typography className={styles.title}>{description}</Typography>
        </Tooltip>
      </div>
      <div className={styles.content}>
        <div className={styles.column}>
          <DEVICE className={styles.iconInfo} />
          <Typography className={styles.label}>{t('type-device')}</Typography>
        </div>
        <div className={styles.dateBlock}>
          <div className={styles.column}>
            <WatchLaterRoundedIcon className={styles.iconInfo} />
            <Typography className={styles.label}>{moment(alarmDate).format('D MMM YYYY - HH:mm')}</Typography>
          </div>
          <Typography className={styles.labelCaption}>{t('last-control')}</Typography>
        </div>
        {status && (
          <Typography className={styles.status}>{`${capitalizeFirstLetter(t('status'))}: ${capitalizeFirstLetter(
            status === 'pending' ? t('pending') : t('on-process'),
          )}`}</Typography>
        )}
      </div>
    </div>
  )
}

export default OperationStatus
