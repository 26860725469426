import moment from 'moment'
import React, { useEffect } from 'react'
import {Baseline, EventChart, ChartContainer, ChartRow, Charts } from 'react-timeseries-charts'
import Resizable from 'react-timeseries-charts/lib/components/Resizable'
import { TimeRange, TimeSeries, TimeRangeEvent } from 'pondjs'

import Typography from '@material-ui/core/Typography'
import AccessAlarmsRoundedIcon from '@material-ui/icons/AccessAlarmsRounded'

import ChartFilter from '../ChartFilter'
import styles from './styles.module.scss'


import { CHART_DEVICE_DETAIL_ALARMS_COLORS } from 'app/styles/colors'

const getOrderedSeriesNames = filters => {
  const orderedSeriesNames = []
  for (let i = 0; i < filters.length; i++) {
    const seriesNames = []
    for (let j = 0; j < filters[i].profile_conf.length; j++) {
      seriesNames.push(filters[i].profile_conf[j].aggregate_var)
    }
    orderedSeriesNames.push(seriesNames)
  }
  let orderedSeriesNamesIntegrated = [];
  
  for (let j = 0; j < orderedSeriesNames.length; j += 1) {
    orderedSeriesNamesIntegrated = [...orderedSeriesNamesIntegrated, ...orderedSeriesNames[j]];
  }

  return orderedSeriesNamesIntegrated
}

const getColors = filters => {
  const map = new Map()
  for (let i = 0; i < filters.length; i++) {
    const colors = [...CHART_DEVICE_DETAIL_ALARMS_COLORS[i]]

    for (let j = 0; j < filters[i].profile_conf.length; j++) {
      const aggregate_var = filters[i].profile_conf[j].aggregate_var
      const color = colors.shift()

      map.set(aggregate_var, color)
    }
  }
  return map
}

const createInputStyler = (filters) => {
  const colors = getColors(filters)
  const inputStyler = []
  for (let i = 0; i < filters.length; i++) {
    for (let j = 0; j < filters[i].profile_conf.length; j++) {
      const aggregate_var = filters[i].profile_conf[j].aggregate_var
      const color = colors.get(aggregate_var)
      inputStyler.push({
        normal: {
          fill: color
        },
        hover: {
          fill: color,
          opacity: 0.9,
          stroke: 'none'
        },
        selected: {
          fill: color
        }
      })
    }
  }
  return inputStyler
}

function checkAllEmpty(seriesObject) {
  for (var key in seriesObject) {
    if (seriesObject[key] !== null && seriesObject[key].length > 0)
      return false;
  }
  return true;
}

const createAllTimeSeries = (allSeries, allSeriesNames) => {
  let allTimeSeriesArray = []
  allSeriesNames.forEach(seriesName => {
    const timeSeries = createTimeSeries(allSeries[seriesName], seriesName)
    allTimeSeriesArray.push(timeSeries)
  })
  
  return allTimeSeriesArray
}

/**
 *
 * @param {array} series Array of objects with the following structure: {time, type, value}
 * @param {string} seriesName The name of the series
 * @param {number} timeIntervalInSeconds The time interval in miliseconds
 * @returns {TimeSeries}
 */
const createTimeSeries = (series, seriesName) => {
  const events = series.map(
    ({ startTime, endTime, ...data }) => { 
      return new TimeRangeEvent(new TimeRange(new Date(startTime), new Date(endTime)), data);
      });

  const timeSeries = new TimeSeries({ name: seriesName, utc: false, events });
  return timeSeries;
}

const getBeginDate = timeSeries => {
  const timeSeriesFlat = timeSeries.flat()
  let beginDate = timeSeriesFlat[0].size() > 0 ? timeSeriesFlat[0].begin() : null;
  for (let i = 1; i < timeSeriesFlat.length; i++) {
    if (timeSeriesFlat[i].size() > 0) {
      if (!beginDate || timeSeriesFlat[i].begin() < beginDate) {
        beginDate = timeSeriesFlat[i].begin()
      }
    }
  }

  // evaluate beginDate
  if (!beginDate) {
    const standarBeginDate = new Date();
    beginDate = standarBeginDate.setDate(standarBeginDate.getDate() - 1); // actual date less 1 days
  }
  
  return beginDate;
}

const getEndDate = timeSeries => {
  const timeSeriesFlat = timeSeries.flat();
  let endDate = timeSeriesFlat[0].size() > 0 ? timeSeriesFlat[0].end() : null;
  for (let i = 1; i < timeSeriesFlat.length; i++) {
    if (timeSeriesFlat[i].size() > 0) {
      if (!endDate || timeSeriesFlat[i].end() > endDate) {
        endDate = timeSeriesFlat[i].end()
      }
    }
  }

  return endDate || new Date()
}

const getTimeRange = timeSeries => {
  const beginDate = getBeginDate(timeSeries)
  const endDate = getEndDate(timeSeries)
  return new TimeRange(beginDate, endDate)
}

const getMinValue = (timeSeries, orderedSeriesNames, timerange) => {
  if (timerange) {
    timeSeries = timeSeries.map(timeSerie => timeSerie.crop(timerange))
  }
  let minValue = timeSeries[0].size() ? timeSeries[0].min(orderedSeriesNames) : null;
  for (let i = 1; i < timeSeries.length; i++) {
    if (timeSeries[i].size() > 0) {
      if (!minValue || timeSeries[i].min(orderedSeriesNames) < minValue) {
        minValue = timeSeries[i].min(orderedSeriesNames)
      }
    }
  }
  return minValue || 0;
}

const getMaxValue = (timeSeries, orderedSeriesNames) => {
  let maxValue = timeSeries[0].size() ? timeSeries[0].max(orderedSeriesNames) : null;
  for (let i = 1; i < timeSeries.length; i++) {
    if (timeSeries[i].size() > 0) {
      if (!maxValue || timeSeries[i].max(orderedSeriesNames) > maxValue) {
        maxValue = timeSeries[i].max(orderedSeriesNames)
      }
    }
  }
  return maxValue || 0;
}

const getMinTime = timeSeries => {
  const timeSeriesFlat = timeSeries.flat()
  let minTime = timeSeriesFlat[0].size() ? timeSeriesFlat[0].range().begin() : null;
  for (let i = 1; i < timeSeriesFlat.length; i++) {    
    if (timeSeriesFlat[i].size() > 0) {
      if (!minTime || timeSeriesFlat[i].range().begin() < minTime) {
        minTime = timeSeriesFlat[i].range().begin()
      }
    }
  }

  // evaluate minTime
  if (!minTime) {
    const standarMinTime = new Date();
    minTime = standarMinTime.setDate(standarMinTime.getDate() - 1); // actual date less 1 days
  }
  return minTime
}

const getMaxTime = timeSeries => {
  const timeSeriesFlat = timeSeries.flat()
  let maxTime = timeSeriesFlat[0].size() ? timeSeriesFlat[0].range().end() : null;
  for (let i = 1; i < timeSeriesFlat.length; i++) {
    if (timeSeriesFlat[i].size() > 0) {
      if (!maxTime || timeSeriesFlat[i].range().end() > maxTime) {
        maxTime = timeSeriesFlat[i].range().end()
      }
    }
  }
  return maxTime
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const OutageNetworkContainer = ({ series, filters, containerHeight }) => {
  const [tracker, setTracker] = React.useState(null)

  
  const [timeSeries, setTimeSeries] = React.useState()
  
  const [x, setX] = React.useState(null)
  const [y, setY] = React.useState(null)
  const [timerange, setTimerange] = React.useState(null)
  const [visible, setVisible] = React.useState(null)
  const [date, setDate] = React.useState(null)
  const [orderedSeriesNames, setOrderedSeriesNames] = React.useState(null)
  const [noData, setNoData] = React.useState(false)
  const [chartStyles, setChartStyles] = React.useState([])

  useEffect(() => {
    if (filters && series && !checkAllEmpty(series)) {
      setNoData(false)

      const allSeriesNames = getOrderedSeriesNames(filters);
      setOrderedSeriesNames(allSeriesNames);
      
      const allTimeSeries = createAllTimeSeries(series, allSeriesNames)
      setTimeSeries(allTimeSeries)
      setTimerange(getTimeRange(allTimeSeries))
      
      setChartStyles(createInputStyler(filters))
      const orderedNames = Object.keys(series)
      
      const auxObject = {}
      for (let i = 0; i < orderedNames.length; i++) {
        auxObject[orderedNames[i]] = true
      }
      setVisible(auxObject)
    } else {
      setNoData(true)
    }
  }, [filters, series])

  const handleChartVariablesSelectionChange = (groupId, variableStates) => {
    const profileConf = filters.find(filter => filter.id === groupId).profile_conf
    let auxObject = { ...visible }
    for (let i = 0; i < variableStates.length; i++) {
      auxObject = { ...auxObject, [profileConf[i].aggregate_var]: variableStates[i] }
    }
    setVisible(auxObject)
  }

  const handleTrackerChanged = tracker => {
    if (!tracker) {
      setTracker(tracker)
      setX(null)
      setY(null)
    } else {
      setTracker(tracker)
      setDate(moment(tracker).format('D MMM YYYY - HH:mm').toUpperCase())
    }
  }

  const handleTimeRangeChange = timerange => {
    const minArray = []
    const maxArray = []
    for (let i = 0; i < orderedSeriesNames.length; i++) {
      minArray.push(getMinValue(timeSeries[i], orderedSeriesNames[i]), timerange)
      maxArray.push(getMaxValue(timeSeries[i], orderedSeriesNames[i]), timerange)
    }
  }

  const handleMouseMove = (x, y) => {
    setX(x)
    setY(y)
  }

  return timeSeries && !noData ? (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', marginBottom: 15, height: 80 }}>
        {filters?.map((profile, i) => (
          <ChartFilter
            key={profile.id}
            groupIndex={i}
            groupId={profile.id}
            groupTitle={profile.title}
            variables={profile.profile_conf.map(conf => ({ id: conf.aggregate_var, name: conf.title }))}
            onSelectionChange={handleChartVariablesSelectionChange}
            colors={getColors(filters)}
          />
        ))}
      </div>
      <div style={{ flex: 1 }}>
        {timeSeries.map((profile, i) => {
          return (
          <Resizable>
            <ChartContainer
              timeRange={timerange}
              enablePanZoom={true}
              paddingRight={30}
              maxTime={getMaxTime(timeSeries)}
              minTime={getMinTime(timeSeries)}
              onTrackerChanged={handleTrackerChanged}
              onTimeRangeChanged={handleTimeRangeChange}
              onMouseMove={(x, y) => handleMouseMove(x, y)}
              format={'%d-%b %H:%M'}
            >
              <ChartRow height={80} axisMargin={0}>
                <Charts>
                  <EventChart
                    series={timeSeries[i]}
                    size={90}
                    style={(event, state) => chartStyles[i][state]}
                    label={e => e.get("title")}
                  />
                </Charts>
                <CrossHairs x={x} y={y} />
                <Baseline axis={`y${i}`} value={0} />
              </ChartRow>
            </ChartContainer>
          </Resizable>
          )})}
        <div style={{ minHeight: 30, position: 'absolute', top: 10, right: 10, fontSize: 13, fontWeight: 500 }}>
          {tracker ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AccessAlarmsRoundedIcon style={{ marginRight: 10 }}></AccessAlarmsRoundedIcon>
                {date}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  ): (
    <div className={styles.noData}>
      <Typography variant="subtitle">No hay registros</Typography>
    </div>
  )
}
export default OutageNetworkContainer

function CrossHairs({ x, y, height }) {
  const style = { pointerEvents: 'none', stroke: '#ccc' }

  return x && y ? (
    <g>
      <line style={style} x1={x} y1={0} x2={x} y2={height} />
    </g>
  ) : (
    <g />
  )
}