import { React, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { actionCreators as iotActions } from 'redux/iotHandlers';
import { ROUTES } from 'constants/routes';

import DeviceTypeCronsDetail from './components/DeviceTypeCronsDetail';

const DeviceTypeCrons = ({match}) => {
	const dispatch = useDispatch();
	const {type, report: reportId } = match.params;

	const loading = useSelector((state) => state.iot.iotReportCronsLoading);

	useEffect(() => {
		if (reportId) {
			dispatch(iotActions.getIotReportCrons(reportId));
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			dispatch(iotActions.cleanIotReportCrons());
		};
	}, [dispatch]);

	const handleGoBack = (type) => {
		dispatch(push(`${ROUTES.IOT_DEVICE_TYPES_REPORTS}/${type}`));
  };

	return (
				<Grid container style={{ padding: 40 }} direction="column" >
					<DeviceTypeCronsDetail onGoBack={handleGoBack} deviceType={type} reportId={reportId} loading={loading} />
				</Grid>
  );
};

export default DeviceTypeCrons
