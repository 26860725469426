import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import { Skeleton } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './styles.module.scss'

const Summary = ({ loading, networkServerSelected }) => {
  const [t] = useTranslation('devices')

	const [networkServer, setNetworkServer] = useState('')

  useEffect(() => {
    setNetworkServer(networkServerSelected?.network_server)
  }, [networkServerSelected])

  return (
    <>
   {loading ? (
        <div className={styles.container}>
          <Skeleton animation="wave" style={{ width: 150, height: 50 }} />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.row} style={{ opacity: 0.5 }}>
          <DnsRoundedIcon className={styles.icon}></DnsRoundedIcon>
          <div>{t('networkServerSummaryTitle')}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.serial}>{networkServer}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default Summary
