/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { shape, any } from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import OperationBadge from 'app/components/OperationBadge'
import { formatDateTime } from 'utils/dateUtils';


const DeviceSensorContainer = ({ iotDeviceSensors, userRoles,  }) => {
  const [t] = useTranslation('deviceSensor')
  const [sensorConfig, setSensorConfig] = useState([])

  useEffect(() => {
    setValuesSensors()      
  }, [])

  useEffect(() => {
    setValuesSensors()
  }, [iotDeviceSensors])

  const setValuesSensors = () => {
    let listSensors = JSON.parse(JSON.stringify(iotDeviceSensors || []));
    
    if (listSensors && !Array.isArray(listSensors))
      listSensors = [];

    if (listSensors.length >= 2)
      // sort the array by property name
      listSensors.sort((a,b) =>  (a.sensor_type||'')-(b.sensor_type||'') );

    setSensorConfig(listSensors)
  }

  const columns = [
    {
      name: 'sensor_type',
      label: t('deviceSensorTableTypeColumn'),
      options: {
        filter: false,
        display: 'true',
      },
    },
    {
      name: 'executedAt',
      label: t('deviceSensorTableExecutedAtColumn'),
      options: {
        filter: false,
        display: 'true',
        customBodyRender: value => {
          return (<span>{formatDateTime(value)}</span>);
        }
      },
    },
    {
      name: 'value',
      label: t('deviceSensorTableValueColumn'),
      options: {
        filter: false,
        display: 'true',
        customBodyRender: value => {
          let badgeType = 'G';
          if (value === 'Activado') badgeType = 'R';          
          return (<div><OperationBadge type={badgeType} label={value} /></div>)
        },
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyHeight: '50vh',
    download: false,
    viewColumns: false,
    customToolbar: null,
    pagination: false,

    setTableProps: () => ({
      size: 'small',
    }),
    setRowProps: (row, index) => {
      return;
    },
    textLabels: {
      body: {
        noMatch: 'No hay sensores',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Parámetros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {sensorConfig && 
          <MUIDataTable columns={columns} options={options} data={sensorConfig} />
        }
      </div>
    </>
  )
}

DeviceSensorContainer.propTypes = {
  rowData: shape(any),
  iotDeviceSensors: shape(any)
}

const mapStateToProps = store => ({
  iotDeviceSensors : store.iot && store.iot.iotDeviceDetail && store.iot.iotDeviceDetail.device_addon_signal,
})

export default connect(mapStateToProps)(DeviceSensorContainer)
