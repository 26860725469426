import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux';

import { ROUTES } from 'constants/routes'
import { history } from 'redux/store'
import NotFoundPage from 'app/screens/404'
import Dashboard from 'app/screens/Dashboard'
import DeviceType from 'app/screens/IoT/DeviceType'
import DeviceTypes from 'app/screens/IoT/DeviceTypes'
import DeviceTypeReports from 'app/screens/IoT/DeviceTypeReports'
import DeviceGroup from 'app/screens/IoT/DeviceGroups/components/DeviceGroup'
import DeviceTypeCrons from 'app/screens/IoT/DeviceTypeCrons'
import AdminExternalAdminBro from 'app/screens/IoT/AdminBro'
import AdminExternalSwagger from 'app/screens/IoT/Swagger'
import SidebarMenu from './components/SideBarNav'
import { roles, validateAccess } from 'constants/userRoles';

const AppRoutes = () => {
  const userRoles = useSelector(state => state.auth.userRoles);

  return (
  <ConnectedRouter history={history}>
    <div
      style={{
        height: '100vh',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <SidebarMenu></SidebarMenu>
      <Switch>
        <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
        { validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_CRON_ACCESS) && (
				<Route path={ROUTES.IOT_DEVICE_TYPE_REPORT_CRONS} component={DeviceTypeCrons} />
        )}
        <Route exact path={ROUTES.IOT_DEVICE_TYPES} component={DeviceTypes} />
        { validateAccess(userRoles, roles.IOT_BACKOFFICE_REPORT_CRON_ACCESS) && (
        <Route
          path={ROUTES.IOT_DEVICE_TYPE_REPORTS}
          component={DeviceTypeReports}
				/>
        )}
        <Route path={ROUTES.IOT_DEVICE_GROUP} component={DeviceGroup} />
        <Route path={ROUTES.IOT_DEVICE_TYPE} component={DeviceType} />
        { validateAccess(userRoles, roles.IOT_BACKOFFICE_ADMIN_ACCESS) && (
          <Route path={ROUTES.IOT_ADMIN} component={AdminExternalAdminBro} />
        )}
        { validateAccess(userRoles, roles.IOT_BACKOFFICE_DOC_TECH_ACCESS) && (
          <Route path={ROUTES.IOT_DOC_TECH} component={AdminExternalSwagger} />
        )}
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </ConnectedRouter>
  );
};

export default AppRoutes
