/* eslint-disable camelcase */
/* eslint-disable no-console */
import { memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { bool, string } from 'prop-types'
import { getIn } from 'seamless-immutable'

import { actionCreators as iotActions } from 'redux/iotHandlers'

import ReportsTable from './components/reportsTable'

const DeviceReportsContainer = ({ dispatch, deviceReportList, iotTypeName, iotTypeDescription, onGoBack }) => {
  const [t] = useTranslation('devices');

  const handleSelectDeviceReport = useCallback(value => {
    dispatch(iotActions.cleanIotReportDetail())
    dispatch(iotActions.getIotReportDetail(value.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      { deviceReportList && deviceReportList.length > 0 ? (
          <ReportsTable
            iotTypeName={iotTypeName}
            iotTypeDescription={iotTypeDescription}
            listReports={deviceReportList}
            onReportSelect={handleSelectDeviceReport}
            onGoBack={onGoBack}
          />
      ) : (
        deviceReportList.length === 0 && t('noDeviceReportsFound')
      )}
    </div>
  )
}

const mapStateToProps = store => ({
  iotReportSelected: getIn(store, ['iot', 'iorReportDetail']),
})

DeviceReportsContainer.propTypes = {
  deviceReportList: [],
  loading: bool,
  iotTypeName: string,
}

export default connect(mapStateToProps)(memo(DeviceReportsContainer))
