import moment from 'moment'

export const returnTimeDiff = (start, end) => {
  if (!start) return
  const endDate = end ? moment(end) : moment()
  const startDate = moment(start)
  const secondsDiff = endDate.diff(startDate, 'seconds')
  const minutesDiff = endDate.diff(startDate, 'minutes')
  const hoursDiff = endDate.diff(startDate, 'hours')
  const daysDiff = endDate.diff(startDate, 'days')
  return secondsDiff < 60 ? `${secondsDiff}s` : minutesDiff < 60 ? `${minutesDiff}m` : hoursDiff < 24 ? `${hoursDiff}h` : `${daysDiff}d`
}

export const returnTimeDiffInSeconds = (start, end) => {
  if (!start) return
  const endDate = end ? moment(end) : moment()
  const startDate = moment(start)
  return endDate.diff(startDate, 'second')
}

export const validateDateTimeToGreaterThanDateTimeFrom = (dateTimeFrom, dateTimeTo) => {
  const startDate = new Date(dateTimeTo)
  const endDate = new Date(dateTimeFrom)
  return endDate - startDate > 0
}

export const formatInterval = intervalValue => {
  if (!intervalValue || typeof intervalValue !== 'object') return 'na'; 

  // extact possibles periods
  const { years, months, weeks, days, hours, minutes, seconds } = intervalValue

  // format to legible string the value to return
  let legibleIntervalString = '';
  legibleIntervalString += years ? `${years} A ` : '';
  legibleIntervalString += months ? `${months} M ` : '';
  legibleIntervalString += weeks ? ` ${weeks} S ` : '';
  legibleIntervalString += days ? ` ${days} D ` : '';
  legibleIntervalString += hours ? ` ${hours} h ` : '';
  legibleIntervalString += minutes ? `${minutes} m ` : '';
  legibleIntervalString += seconds ? `${seconds} s ` : '';

  return legibleIntervalString;
}

export const formatDateTime = (dateTime) => {
  if (!dateTime)  return null;
  return moment(dateTime).format('DD-MM-YYYY HH:mm');
}
