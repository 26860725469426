import DateRangePicker from 'dz-daterangepicker-material'
import './dz-date-picker.css'
import 'moment/locale/es'
import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Grid, Popper, Fade, Card, CardActions, Button, IconButton, Typography } from '@material-ui/core'
import { ArrowRightAlt, EventRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme =>
  createStyles({
    datepickerActions: {
      marginTop: '-15px',
      paddingBottom: '8px',
      paddingLeft: '20px',
      paddingTop: 0,
    },
    selected: {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    headerItem: {
      cursor: 'pointer',
    },
    popper: {
      zIndex: theme.zIndex.tooltip + 1,
    },
  }),
)

function WTDateRangePicker(props) {
  const classes = useStyles()
  const [datePickerMounted, setDatePickerMounted] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDatepicker, setOpenDatepicker] = useState(false)
  const dateOptions = { type: 'medium', year: 'numeric', month: 'short', day: 'numeric' }

  const today = new Date()
  var oneWeek = 24 * 60 * 60 * 1000 * 7 //7 days

  useEffect(() => {
    setOpenDatepicker(props.open)
  }, [props.open])

  useEffect(() => {
    setAnchorEl(props.anchorEl)
  }, [props.anchorEl])

  const [dates, setDates] = React.useState(
    props.dates || {
      endDate: today,
      startDate: new Date(today.getTime() - oneWeek),
    },
  )
  const [dateRange, setDateRange] = React.useState({})

  const onChangeDates = (start, end) => {
    end.setHours(23)
    end.setMinutes(59)
    setDateRange({
      endDate: end,
      startDate: start,
    })
  }

  useEffect(() => {
    setDatePickerMounted(false)
    setDates(props.dates)
    setDatePickerMounted(true)
  }, [props.dates])

  const onSubmitDatepicker = () => {
    setDates(dateRange)
    if (props.onChange) props.onChange(dateRange)
    setOpenDatepicker(false)
    props.onClose()
  }

  const onOpenDatePicker = () => setOpenDatepicker(true)
  const closeDatePicker = () => {
    setOpenDatepicker(false)
    props.onClose()
  }
  const toggleDatePicker = () => setOpenDatepicker(prev => !prev)
  const formatDate = d => d.toLocaleDateString('es-ES', dateOptions).replace('.', '').replace('sept', 'sep')

  const id = openDatepicker ? 'simple-popper' : undefined

  return (
    <>
      <Popper id={id} open={openDatepicker} anchorEl={anchorEl} placement={props.placement} className={classes.popper} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={150}>
            <Card elevation={4}>
              <Grid direction='column' container>
                {datePickerMounted && (
                  <DateRangePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onChange={onChangeDates}
                    locale={'es'}
                    startWeek={'monday'} //monday|saturday|sunday
                    /* minDate={...} */
                    maxDate={new Date()} //maxDate
                  />
                )}
                <CardActions className={classes.datepickerActions} direction='row'>
                  <Button color='secondary' className={classes.button} disabled={!dateRange.endDate} onClick={onSubmitDatepicker}>
                    Aplicar
                  </Button>
                  <Button color='default' className={classes.button} onClick={closeDatePicker}>
                    Cancelar
                  </Button>
                </CardActions>
              </Grid>
            </Card>
          </Fade>
        )}
      </Popper>
      {!props.customAnchor && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <IconButton
            aria-label='settings'
            aria-describedby={id}
            onClick={e => {
              setAnchorEl(e.currentTarget)
              toggleDatePicker()
            }}
            className={openDatepicker ? classes.selected : ''}
          >
            <EventRounded></EventRounded>
          </IconButton>

          {dates.startDate ? (
            <Grid container direction='row' justifyContent='flex-start' alignItems='center' style={{ marginLeft: 10 }}>
              <Typography variant='subtitle1' onClick={onOpenDatePicker}>
                {dates && dates.startDate ? formatDate(dates.startDate) : ''}
              </Typography>
              <ArrowRightAlt style={{ paddingTop: 5, marginLeft: 10, marginRight: 10 }} color='action' />
              <Typography variant='subtitle1' onClick={onOpenDatePicker}>
                {dates && dates.endDate ? formatDate(dates.endDate) : ''}
              </Typography>
            </Grid>
          ) : (
            <Typography variant='body1' style={{ opacity: 0.5 }}>
              Filtrar datos por fecha
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

export default WTDateRangePicker
