import { Card, Grid, Typography } from '@material-ui/core';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { getTableData } from './tableProvider';

const DetailInformation = ({ networkServerDetail }) => {
  const [t] = useTranslation('devices');

  const [tableData, setTableData] = useState([]);

  React.useEffect(() => {
    let data = [
      { attr: 'URL', valor: networkServerDetail.server },
      { attr: 'Nombre de Usuario', valor: networkServerDetail.user },
      { attr: 'Opciones', valor: JSON.stringify(networkServerDetail.options) },
    ];
    setTableData(getTableData(data, t));
  }, [networkServerDetail]);

  const columns = [
    {
      name: 'attr',
      label: 'Parámetro',
    },
    {
      name: 'valor',
      label: 'Valor',
    },
  ];

  const options = {
    elevation: 1,
    pagination: false,
    filter: false,
    print: false,
    search: false,
    selectableRows: 'none',
    responsive: 'vertical',
    download: false,
    viewColumns: false,
    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
    },
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset' }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <div style={{ minWidth: 200, marginRight: 200 }}>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 130, opacity: 0.7 }}>
                    Plataforma:
                  </Typography>
                  <Typography>{networkServerDetail.platform}</Typography>
                </>
              </Grid>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 130, opacity: 0.7 }}>
                    Application ID:
                  </Typography>{' '}
                  <Typography>{`${networkServerDetail.application_id}`}</Typography>
                </>
              </Grid>
            </div>
            <div style={{ minWidth: 300 }}>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 130, opacity: 0.7 }}>
                    Estado conexión:
                  </Typography>{' '}
                  <Typography style={{ minWidth: 130 }}>
                    {networkServerDetail.status === 'connected'
                      ? 'Conectado'
                      : 'Desconectado'}
                  </Typography>
                </>
              </Grid>
              <Grid
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <>
                  <Typography style={{ minWidth: 130, opacity: 0.7 }}>
                    Última conexión:
                  </Typography>{' '}
                  <Typography>
                    {moment(networkServerDetail.last_connected).format(
                      'DD-MM-YYYY HH:mm'
                    )}
                  </Typography>
                </>
              </Grid>
            </div>
          </Grid>
        </Card>
        {tableData.length > 0 && (
          <MUIDataTable
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SettingsRoundedIcon
                  fontSize="medium"
                  style={{ marginRight: 10 }}
                ></SettingsRoundedIcon>
                <Typography variant="h6">Datos de Conexión</Typography>
              </div>
            }
            data={tableData}
            columns={columns}
            options={options}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  networkServerDetail: store.iot.iotNetworkServerDetail,
});

export default connect(mapStateToProps)(DetailInformation);
