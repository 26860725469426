/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { string, number, shape, any, func, arrayOf } from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MUIDataTable from 'mui-datatables'
import EditableField from './components/editableField'
import { Button, LinearProgress, Typography } from '@material-ui/core'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import { roles, validateAccess } from 'constants/userRoles';

const DeviceComponentParamsApplicationContainer = ({ userRoles, iotDeviceComponentDetail, onRefresh, onSaveParams, processing }) => {
  const [t] = useTranslation('devices')
  const [paramsConfig3, setParamsConfig3] = useState(null)
  const [modifiedRows, setModifiedRows] = useState({})
  const [editedParamCodes, setEditedParamCodes] = useState([])
  const [asyncProcess, setAsyncProcess] = useState(false)
  const [progress, setProgress] = useState(0)
  const [showProcessingBlock, setShowProcessingBlock] = useState(false)
  const [processingMessage, setprocessingMessage] = useState('')
  const [hasRoleLocalSetConfiguration, setHasRoleLocalSetConfiguration] = useState(false)


  const setComponentAccess = () => {
		// option action Edit report Cron
		setHasRoleLocalSetConfiguration(validateAccess(userRoles, roles.IOT_BACKOFFICE_DEVICE_COMPONENTS_APPLICATION_SET_LOCAL));
	}

  useEffect(() => {
    setComponentAccess()
  }, [])

  useEffect(() => {
    setValuesParams()
  }, [iotDeviceComponentDetail])

  useEffect(() => {
    if (showProcessingBlock) {
      const timer = setInterval(() => {
        setProgress(oldProgress => {
          if (oldProgress > 120) {
            clearInterval(timer)
            setShowProcessingBlock(false)
            handleRefreshChanges()
          }
          return Math.min(oldProgress + 20, 121)
        })
      }, 150)
      return () => {
        clearInterval(timer)
      }
    }
  }, [showProcessingBlock])

  useEffect(() => {
    if (processing) {
      setShowProcessingBlock(true)
    }
  }, [processing])

  const setValuesParams = () => {
    if (iotDeviceComponentDetail.config_local) {
      const { config_local: deviceComponentConfigLocal } = iotDeviceComponentDetail
      const componentParamsConfigLocal = Object.values(deviceComponentConfigLocal)
      let componentParams = []

    // sort the array by property order in configuration
    componentParamsConfigLocal.sort((a,b) =>  (a.order||0)-(b.order||0) );

      for (let i = 0, lenI = componentParamsConfigLocal.length; i < lenI; i += 1) {
        const componentParamConfig = JSON.parse(JSON.stringify(componentParamsConfigLocal[i]))
        componentParams.push(componentParamConfig)
      }
      setParamsConfig3(componentParams)
    }
  }

  const handleValueUpdate = (code, value) => {
    const { config_local } = iotDeviceComponentDetail
    const configKeys = config_local ? Object.keys(config_local) : []
    const configValues = config_local ? Object.values(config_local) : []
    let codeSet = null
    let valueSet = null

    // extract the itemConfig
    for (let k = 0, len = configKeys.length; k < len; k += 1) {
      const configKey = configKeys[k]
      const configValue = configValues[k]

      if (configValue.code === code) {
        codeSet = configKey
        valueSet = value
      }
    }
    // prepare entry of rowEdited
    const rowsEdited = modifiedRows ? JSON.parse(JSON.stringify(modifiedRows)) : {}
    rowsEdited[codeSet] = valueSet
    // persist values
    setEditedParamCodes([...editedParamCodes, code])
    setModifiedRows(rowsEdited)
  }

  const handleCancelConfirmChanges = () => {
    setModifiedRows({}) // reset changes 0
    setEditedParamCodes([])
    setParamsConfig3([])
    setTimeout(() => {
      setValuesParams()
    }, 1)
  }

  const handleClickAcceptChanges = () => {
    startAsyncProcessIndicator('Procesando cambios en componente...')
    onSaveParams(modifiedRows)
    setModifiedRows({}) // reset changes 0
    setEditedParamCodes([])
  }

  const handleRefreshChanges = () => {
    onRefresh()
  }

  const startAsyncProcessIndicator = message => {
    setprocessingMessage(message)
    setProgress(0)
    setShowProcessingBlock(true)
    setAsyncProcess(true)
  }

  const columns = [
    {
      name: 'code',
      label: t('deviceComponentTableCodeTitleColumn'),
      options: {
        filter: false,
        display: 'true',
      },
    },
    {
      name: 'title',
      label: t('deviceComponentTableParamTitleColumn'),
      options: {
        filter: false,
        display: 'true',
      },
    },
    {
      name: 'fabric_value',
      label: t('deviceComponentTableValueTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>
              {value} {tableMeta.tableData[tableMeta.rowIndex].unit}
            </span>
          )
        },
      },
    },
    {
      name: 'value',
      label: t('deviceComponentTableValueOnlineTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        customBodyRender: (value, tableMeta, updateValue) => {
          if (tableMeta.tableData[tableMeta.rowIndex].isReadonly) {
            return (
              <span>
                {value} {tableMeta.tableData[tableMeta.rowIndex].unit}
              </span>
            )
          } else {
            return (
              <EditableField
                index={tableMeta.tableData[tableMeta.rowIndex].code}
                unit={tableMeta.tableData[tableMeta.rowIndex].unit}
                fieldValue={value}
                fieldType={tableMeta.tableData[tableMeta.rowIndex].type}
                letEmptyValue={tableMeta.tableData[tableMeta.rowIndex].letEmptyValue} 
                onUpdate={handleValueUpdate}
                changesInProgress={asyncProcess}
                hasRoleEditParam={hasRoleLocalSetConfiguration}
              ></EditableField>
            )
          }
        },
      },
    },
  ]

  const options = {
    elevation: 1,
    filter: false,
    print: false,
    sort: false,
    search: false,
    selectableRows: 'none',
    responsive: 'simple',
    tableBodyHeight: '325px',
    download: false,
    viewColumns: false,
    customToolbar: null,
    pagination: false,

    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {paramsConfig3 && <MUIDataTable columns={columns} options={options} data={paramsConfig3} />}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: 65 }}>
        {hasRoleLocalSetConfiguration && Object.keys(modifiedRows).length > 0 && !showProcessingBlock ? (
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10 }}>
            <InfoRoundedIcon style={{ marginRight: 10, color: '#FFF' }} />
            {Object.keys(modifiedRows).length === 1 && (
              <Typography varian='body2'>Existe {Object.keys(modifiedRows).length} cambio pendiente por confirmar</Typography>
            )}
            {Object.keys(modifiedRows).length > 1 && (
              <Typography varian='body2'>Existen {Object.keys(modifiedRows).length} cambios pendientes por confirmar</Typography>
            )}
            <div style={{ marginLeft: 20 }}>
              <Button
                color='primary'
                style={{ marginRight: 10 }}
                onClick={() => handleClickAcceptChanges()}
                startIcon={<DoneRoundedIcon></DoneRoundedIcon>}
              >
                Aplicar cambios
              </Button>
              <Button startIcon={<ClearRoundedIcon></ClearRoundedIcon>} onClick={handleCancelConfirmChanges}>
                Cancelar
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            {!showProcessingBlock ? (
              <Button style={{ marginRight: 10 }} onClick={handleRefreshChanges} startIcon={<RefreshRoundedIcon></RefreshRoundedIcon>}>
                Refrescar datos
              </Button>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingTop: 5 }}>
                <Typography color='primary'>{processingMessage}</Typography>
                <LinearProgress variant='determinate' value={progress} style={{ marginLeft: 20, marginTop: 4, height: 5, width: 200 }} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

DeviceComponentParamsApplicationContainer.propTypes = {
  userRoles: arrayOf(string),
  rowData: shape(any),
  iotDeviceComponentDetail: shape(any),
  value: string,
  field: string,
  rowIndex: number,
  onRefresh: func,
  onSaveParams: func,
}

const mapStateToProps = store => ({
  iotDeviceComponentDetail: store.iot && store.iot.iotDeviceComponentDetail,
  iotDeviceComponentDetailLoading: store.iot && store.iot.iotDeviceComponentDetailLoading,
})

export default connect(mapStateToProps)(DeviceComponentParamsApplicationContainer)
