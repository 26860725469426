const tempStorage = {}

const getLocalValue = key => {
  const encodedKey = window.btoa(key)
  let encodedValue
  try {
    encodedValue = window.localStorage.getItem(encodedKey)
  } catch (e) {
    encodedValue = tempStorage[encodedKey]
  }
  const stringValue = encodedValue && window.atob(encodedValue)
  return stringValue && JSON.parse(stringValue)
}

const setLocalValue = (key, value) => {
  const encodedKey = window.btoa(key)
  const stringValue = JSON.stringify(value)
  const encodedValue = window.btoa(stringValue)
  try {
    window.localStorage.setItem(encodedKey, encodedValue)
  } catch (e) {
    tempStorage[encodedKey] = encodedValue
  }
}

const removeLocalValue = key => {
  const encodedKey = window.btoa(key)
  try {
    window.localStorage.removeItem(encodedKey)
  } catch (e) {
    tempStorage[encodedKey] = undefined
  }
}

const defineLocalProperty = (prop, defaultKey = '', tag = '') => {
  const capitalizedKey = `${prop[0].toUpperCase()}${prop.substring(1)}`
  module.exports[`setLocal${capitalizedKey}`] = (val, key = defaultKey) => setLocalValue(`@@UTILITY:${prop}${tag}${key}`, val)
  module.exports[`getLocal${capitalizedKey}`] = (key = defaultKey) => getLocalValue(`@@UTILITY:${prop}${tag}${key}`)
  module.exports[`removeLocal${capitalizedKey}`] = (key = defaultKey) => removeLocalValue(`@@UTILITY:${prop}${tag}${key}`)
}

const getSessionValue = key => {
  const encodedKey = window.btoa(key)
  let encodedValue
  try {
    encodedValue = window.sessionStorage.getItem(encodedKey)
  } catch (e) {
    encodedValue = tempStorage[encodedKey]
  }
  const stringValue = encodedValue && window.atob(encodedValue)
  return stringValue && JSON.parse(stringValue)
}

const setSessionValue = (key, value) => {
  const encodedKey = window.btoa(key)
  const stringValue = JSON.stringify(value)
  const encodedValue = window.btoa(stringValue)
  try {
    window.sessionStorage.setItem(encodedKey, encodedValue)
  } catch (e) {
    tempStorage[encodedKey] = encodedValue
  }
}

const removeSessionValue = key => {
  const encodedKey = window.btoa(key)
  try {
    window.sessionStorage.removeItem(encodedKey)
  } catch (e) {
    tempStorage[encodedKey] = undefined
  }
}

const defineSessionProperty = (prop, defaultKey = '', tag = '') => {
  const capitalizedKey = `${prop[0].toUpperCase()}${prop.substring(1)}`
  module.exports[`setSession${capitalizedKey}`] = (val, key = defaultKey) => setSessionValue(`@@UTILITY:${prop}${tag}${key}`, val)
  module.exports[`getSession${capitalizedKey}`] = (key = defaultKey) => getSessionValue(`@@UTILITY:${prop}${tag}${key}`)
  module.exports[`removeSession${capitalizedKey}`] = (key = defaultKey) => removeSessionValue(`@@UTILITY:${prop}${tag}${key}`)
}

// ------------------------------ LOCAL STORAGE PROPERTIES ------------------------------
const localStorageValues = ['logicalUnitsRecentSearches', 'devicesRecentSearches']
localStorageValues.forEach(item => defineLocalProperty(item))

// ------------------------------ SESSION STORAGE PROPERTIES ------------------------------
const sessionStorageValues = ['statisticsOperationalPanelFilters']
sessionStorageValues.forEach(item => defineSessionProperty(item))
