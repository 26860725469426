import { create } from 'apisauce'
import store from 'redux/store'
import AuthActions from 'redux/auth/actions'
import { CHANNEL_HEADER } from 'constants/apiHeaders'

const UNAUTHORIZED_CODE = 401

export const apiIOT = create({
  baseURL: process.env.REACT_APP_IOT_API_BASE_URL,
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    [CHANNEL_HEADER]: 'backoffice-admin',
  },
  timeout: process.env.REACT_APP_IOT_API_TIMEOUT_STANDARD, // 30 seconds
})

export const apiIOTReports = create({
  baseURL: process.env.REACT_APP_IOT_API_BASE_URL,
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=utf-8',
    [CHANNEL_HEADER]: 'backoffice-admin',
  },
  timeout: process.env.REACT_APP_IOT_API_TIMEOUT_REPORTS,  // 300 seconds === 5 minutes
})

const authorizationMonitor = async response => {
  if (response.status === UNAUTHORIZED_CODE) store.dispatch(AuthActions.signOut())
}

apiIOT.addMonitor(authorizationMonitor)

export default apiIOT
