import { stringArrayToObject } from '../utils'
import ioTHierarchyService from 'services/ioTHierarchyService'

import unitLogicUnitsActions from 'redux/ioTLogicalUnit/actions'

/* ------------- Hierarchy actions ------------- */
export const actions = stringArrayToObject(
  [
    'INIT_HIERARCHY',
    'INIT_HIERARCHY_LIST_SUCCESS',
    'INIT_HIERARCHY_LIST_FAILURE',
    'BRIEF_LOGICALUNITS_SUCCESS',
    'BRIEF_LOGICALUNITS_FAILURE',
    'SET_ACTIVE_HIERARCHY',
    'INIT_HIERARCHY_DETAIL',
    'UNITS_DEVICE_STATS_SUCCESS',
    'UNITS_DEVICE_STATS_FAILURE',
  ],
  '@@HIERARCHY',
)

const privateActionCreators = {
  initHierarchySuccess: payload => ({ type: actions.INIT_HIERARCHY_LIST_SUCCESS, payload }),
  initHierarchyFailure: () => ({ type: actions.INIT_HIERARCHY_LIST_FAILURE }),
  hierarchyBriefLogicalUnitsSuccess: payload => ({ type: actions.BRIEF_LOGICALUNITS_SUCCESS, payload }),
  hierarchyBriefLogicalUnitsFailure: () => ({ type: actions.BRIEF_LOGICALUNITS_FAILURE }),
  setActiveHierarchy: payload => ({ type: actions.SET_ACTIVE_HIERARCHY, payload }),
  hierarchyUnitsDeviceStatsSuccess: payload => ({ type: actions.UNITS_DEVICE_STATS_SUCCESS, payload }),
  hierarchyUnitsDeviceStatsFailure: () => ({ type: actions.UNITS_DEVICE_STATS_FAILURE }),
}

export const actionCreators = {
  /**
   * **Hierarchy Actions**
   * Initialize the hierarchy list of store and a brief of logical units
   * @async
   * @action
   * @param {none}
   * @returns update states **iothierarchies** (array) and **iothierarchiesBrief** (object)
   */
  initHierarchyList: () => async (dispatch, getState) => {
    const { healthCheck } = getState().utility
    if (healthCheck?.healthy) {
      const { token, companyId } = getState().auth.authInformation
      dispatch({ type: actions.INIT_HIERARCHY })
      await ioTHierarchyService.getHierarchyList(token, companyId).then(async response => {
        if (response?.hirerarchies) {
          await ioTHierarchyService.getBriefLogicalUnits(token, companyId).then(response2 => {
            if (response2?.hierarchy) {
              dispatch(privateActionCreators.hierarchyBriefLogicalUnitsSuccess(response2.hierarchy))
              dispatch(privateActionCreators.initHierarchySuccess(response.hirerarchies))
            } else {
              dispatch(privateActionCreators.initHierarchyFailure)
              dispatch(privateActionCreators.hierarchyBriefLogicalUnitsFailure)
            }
          })
        } else dispatch(privateActionCreators.initHierarchyFailure)
      })
    }
  },
  /**
   * **Hierarchy Actions**
   * Set the active hierarchy and a brief of its units and devices at view Map layer 2.
   * States:
   * - **iothierarchyActive** (object) and **iothierarchiesUnitsDevice** (object) at Hierarchy reducer
   * - **iotlogicalunits** (Map(key,value)) at Logical Unit reducer
   * @async
   * @action
   * @param {number} hierarchyid
   * @returns update states
   */
  setActiveHierarchy: id => async (dispatch, getState) => {
    const { iothierarchies, iothierarchiesUnitsDevice } = getState().ioTHierarchy
    const { healthCheck } = getState().utility

    dispatch({ type: actions.INIT_HIERARCHY_DETAIL, payload: true })
    let obj = null
    if (id) obj = iothierarchies.filter(x => x.id === id)[0]
    dispatch(privateActionCreators.setActiveHierarchy(obj))

    if (!id) {
      dispatch({ type: actions.INIT_HIERARCHY_DETAIL, payload: false })
      return //no inicializar stats ni units en el mapa
    }

    //inicializar state iotlogicalunits para view map layer 2
    dispatch(unitLogicUnitsActions.getLogicalUnits(id))
    /////////////////

    if (!!iothierarchiesUnitsDevice[id]) {
      dispatch({ type: actions.INIT_HIERARCHY_DETAIL, payload: false })
      return //en state ya estan cargadas el stat units y dev de la jerarquia activa
      //TODO: incorporar campo last_request para verificar el tiempo transcurrido desde el ultimo request API
    }

    //invocar la API para traer los stats units y device para la jerarquia activa
    if (healthCheck?.healthy) {
      const { token, companyId } = getState().auth.authInformation
      await ioTHierarchyService.getHierarchyUnitsDeviceStaticts(id, token, companyId).then(response => {
        if (response) dispatch(privateActionCreators.hierarchyUnitsDeviceStatsSuccess({ _id: id, unit_device: response }))
        else dispatch(privateActionCreators.hierarchyUnitsDeviceStatsFailure)
      })
    }
  },
}

export default actionCreators
