//import * as SassColors from '../../../../../../../../../styles/variables/'

const styles = {
  dialogContent: {
    minHeight: 200,
    width: 'auto',
    padding: '15px 20px'
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0 30px 0'
  },
  subtitle: {
    //borderTop: `1px dotted ${SassColors.gray2}`,
    fontSize: '.9em',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
    paddingTop: 10
  }
};

export default styles;
