import apiIOT from 'config/api'
import { AUTH_HEADER } from 'constants/apiHeaders'
import store from 'redux/store'


let headersNotInit = true;
const initHeaders = () => {
  if (headersNotInit) {
    const auhtInformation = store.getState().auth['authInformation']

    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${auhtInformation['token']}`
    });

    headersNotInit = false;
  }

  return null;
}


/**
 * GET list of device params
 * 
 * @param {*} deviceId 
 * @returns 
 */
export const getIotDeviceParams = async (deviceId) => {
    initHeaders();

    const response = await apiIOT.get(`/api/v1/iotdevices/${deviceId}/params`);
	  return response?.data;
}

/**
 * 
 * @param {*} deviceParamId 
 * @param {*} payload 
 */
export const putIotDeviceParamValue = async (deviceParamId, payload) => {
  initHeaders();

  const response = await apiIOT.put(`/api/v1/iotdeviceparams/${deviceParamId}/value`, {...payload});
  return response?.data;
}

/**
 * 
 * @param {*} deviceParamId 
 * @param {*} payload 
 */
export const putIotDeviceParamValueOnline = async (deviceParamId, payload) => {
  initHeaders();

  const response = await apiIOT.put(`/api/v1/iotdeviceparams/${deviceParamId}/value_online`, {...payload});
  return response?.data;  
}
