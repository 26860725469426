/* eslint-disable camelcase */
/* eslint-disable no-console */
import i18n from 'i18next'
import { arrayOf, bool, string } from 'prop-types'
import { memo, useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { getIn } from 'seamless-immutable'

import { actionCreators as iotActions } from 'redux/iotHandlers'

import Skeleton from '@material-ui/lab/Skeleton'
import Devices from './components/devicesTable'
import { deviceType } from './constants'

const DevicesContainer = ({ devicesList, dispatch, loading, iotTypeName, iotTypeDescription, onGoBack }) => {
  const [, setTotalDevices] = useState(0)
  const [, setInstallDevices] = useState(0)
  const [, setStockDevices] = useState(0)
  const [, setConnectedDevices] = useState(0)
  const [, setDisconnectedDevices] = useState(0)
  const [, setAvailableDevices] = useState(0)
  const [, setOnMaintainanceDevices] = useState(0)
  const iotDevicesLoading = useSelector(state => state.iot.iotDevicesLoading)
  const authInformation = useSelector(state => state.auth.authInformation)

  useEffect(() => {
    if (authInformation) dispatch(iotActions.getIotTypeId(iotTypeName))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // total devices like length of array
    setTotalDevices(devicesList.length)

    // variables to sumarize condition of devices
    let devicesInstalled = 0
    let devicesStock = 0
    let devicesConnected = 0
    let devicesDisconnected = 0
    let devicesAvailable = 0
    let devicesOnMaintainance = 0

    for (let i = 0, len = devicesList.length; i < len; i += 1) {
      const device = devicesList[i]
      const { connected_network, status_service, available } = device

      if (connected_network) {
        // add 1 to installed
        devicesInstalled += 1

        if (status_service) {
          // add 1 to connected
          devicesConnected += 1
        } else {
          // add 1 to disconnected
          devicesDisconnected += 1
        }
      } else {
        // add 1 to inStock
        devicesStock += 1

        if (available) {
          // add 1 to available to use
          devicesAvailable += 1
        } else {
          // add 1 to non available to use
          devicesOnMaintainance += 1
        }
      }
    }

    // set on references state
    setInstallDevices(devicesInstalled)
    setStockDevices(devicesStock)
    setConnectedDevices(devicesConnected)
    setDisconnectedDevices(devicesDisconnected)
    setAvailableDevices(devicesAvailable)
    setOnMaintainanceDevices(devicesOnMaintainance)
  }, [devicesList])

  const handleSelectDevice = useCallback(value => {
    dispatch(iotActions.getIotDeviceDetail(value.id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderSkeleton = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton animation="wave" height={60} width={180} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div style={{ width: '100%' }}>
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
            <Skeleton animation="wave" height={30} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      {iotDevicesLoading ? (
        renderSkeleton()
      ) : devicesList && devicesList.length > 0 ? (
        <Devices
          iotTypeName={iotTypeName}
          iotTypeDescription={iotTypeDescription}
          listDevices={devicesList}
          onDeviceSelect={handleSelectDevice}
          onGoBack={onGoBack}
        />
      ) : (
        iotDevicesLoading && devicesList.length === 0 && i18n.t('noDevicesFound')
      )}
    </div>
  )
}

const mapStateToProps = store => ({
  loading: store.iot && store.iot.iotDevicesLoading,
  devicesList: getIn(store, ['iot', 'iotDevices']),
  iotDeviceSelected: getIn(store, ['iot', 'iotDeviceDetail']),
})

DevicesContainer.propTypes = {
  devicesList: arrayOf(deviceType),
  loading: bool,
  iotTypeName: string,
}

export default connect(mapStateToProps)(memo(DevicesContainer))
