import { useEffect, useState } from 'react'
import { Card, Grid, Typography, Tooltip } from '@material-ui/core'
import AdjustRoundedIcon from '@material-ui/icons/AdjustRounded'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Skeleton } from '@material-ui/lab'
import { formatDateTime } from 'utils/dateUtils'
import styles from '../styles.module.scss'

const Set = ({ logicalUnit, isActualLogicalUnit, loading }) => {
  
  const [typeDescription, setTypeDescription] = useState();
  const [idDescription, setIdDescription] = useState();
  const [addressDescription, setAddressDescription] = useState();
  const [cityDescription, setCityDescription] = useState();
  const [coordinatesDescription, setCoordinatesDescription] = useState();
  const [status, setSattus] = useState();

  useEffect(() => {
    if (logicalUnit) {
      setLabelDescription(logicalUnit);
    }
  }, []);

  useEffect(() => {
    if (logicalUnit) {
      setLabelDescription(logicalUnit);
    }
  }, [logicalUnit]);


  const getAssetTitle = () => {
    return isActualLogicalUnit ? `Activo (Unidad lógica Actual)` : `Activo (Unidad lógica Histórica)`
  }

  const setLabelDescription = (logicalUnit) => {
    const typeDescription = getLogicalUnitType(logicalUnit);
    const idDescription = getLogicalUnitId(logicalUnit);
    const addressDescription = getAddress(logicalUnit);
    const cityDescription = getCity(logicalUnit);
    const coordinates = getCoordinates(logicalUnit);
    const statusActual = getStatus(logicalUnit);

    setTypeDescription(typeDescription);
    setIdDescription(idDescription);
    setAddressDescription(addressDescription);
    setCityDescription(cityDescription);
    setCoordinatesDescription(coordinates);
    setSattus(statusActual);
  }

  const getLogicalUnitType = (logicalUnit) => {
    const { iot_logical_unit: iotLogicalUnit } = logicalUnit;
    const { iot_logical_unit_type: iotLogicalUnitType } = iotLogicalUnit;
    const { name: nameTyoe, description: descriptionType } = iotLogicalUnitType;

    return `${descriptionType} (${nameTyoe})`;
  }

  const getLogicalUnitId = (logicalUnit) => {
    const { iot_logical_unit: iotLogicalUnit } = logicalUnit;
    const { external_id: externlaId, external_name: externalName } = iotLogicalUnit;

    return `${externalName} (${externlaId})`;    
  }

  const getAddress = (logicalUnit) => {
    const { iot_logical_unit: iotLogicalUnit } = logicalUnit;
    const { geo_position: geoPosition } = iotLogicalUnit;
    const { geografic_info: geoInfo } = geoPosition;
    const { address } = geoInfo;

    return `${address}`; 
  }

  const getCity = (logicalUnit) => {
    const { iot_logical_unit: iotLogicalUnit } = logicalUnit;
    const { geo_position: geoPosition } = iotLogicalUnit;
    const { geografic_info: geoInfo } = geoPosition;
    const { city, locality, province, country } = geoInfo;
    
    return `${locality}, ${city}, ${province}, ${country}`; 
  }

  const getCoordinates = (logicalUnit) => {
    const { iot_logical_unit: iotLogicalUnit } = logicalUnit;
    const { geo_position: geoPosition } = iotLogicalUnit;
    const { map_info: mapInfo } = geoPosition;
    const { geometry } = mapInfo;
    const { coordinates } = geometry;
    const [lat, lng] = coordinates;

    return `${lat} ${lng}`; 
  }

  const getStatus = (logicalUnit) => {
    return logicalUnit.active ? 'Instalado' : 'Removido';
  }

  return (
    <>
      {loading ? (
        <>
          <Skeleton animation="wave" style={{ height: 45, opacity: 0.7 }} />
          <Skeleton animation="wave" variant="rect" style={{ height: 200, opacity: 0.7 }} />
        </>
      ) : (
        <Card style={{ padding: 20, marginBottom: 20, overflow: 'unset', height: '99%' }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <div style={{ minWidth: 300 }}>
              <Grid
                style={{ marginBottom: 25 }}
                className={styles.label}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {loading ? (
                  <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                ) : (
                  <>
                    <AdjustRoundedIcon style={{ fontSize: 27, marginRight: 10 }}></AdjustRoundedIcon>
                    <Typography variant="h6">{getAssetTitle()}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                {loading ? (
                  <Skeleton animation="wave" style={{ width: '70%', height: 21 }} />
                ) : (
                    <>
                      <Typography style={{ opacity: 0.7 }}>Tipo:</Typography>
                      <Tooltip title={typeDescription}>
                        <Typography style={{ textTransform: 'uppercase', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          {typeDescription}
                        </Typography>
                      </Tooltip>
                    </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                {loading ? (
                  <Skeleton animation="wave" style={{ width: '70%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ opacity: 0.7 }}>ID:</Typography>
                    <Tooltip title={idDescription}>
                      <Typography style={{ textTransform: 'uppercase', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {idDescription}
                      </Typography>
                    </Tooltip>                    
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                {loading ? (
                  <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 250, opacity: 0.7 }}>Ubicación:</Typography>
                      <Tooltip title={addressDescription}>
                        <Typography style={{ textTransform: 'uppercase', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          {addressDescription}
                        </Typography>
                      </Tooltip>
                    <Tooltip title={cityDescription}>
                      <Typography style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        {cityDescription}
                      </Typography>
                    </Tooltip>
                    <br></br>    
                     <div className={styles.address}>
                      <LocationOnIcon className={styles.locationIcon} />                
                      <Tooltip title={coordinatesDescription}>
                        <Typography style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          {coordinatesDescription}
                        </Typography>
                      </Tooltip>  
                    </div>                    
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                {loading ? (
                  <Skeleton animation="wave" style={{ width: '70%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 250, opacity: 0.7 }}>Fecha Instalación:</Typography>{' '}
                    <Typography>{formatDateTime(logicalUnit.start_date)}</Typography>
                  </>
                )}
              </Grid>
              <Grid className={styles.label} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                {loading ? (
                  <Skeleton animation="wave" style={{ width: '70%', height: 21 }} />
                ) : (
                  <>
                    <Typography style={{ minWidth: 250, opacity: 0.7 }}>Fecha Remoción:</Typography>
                    <Typography>{formatDateTime(logicalUnit.end_date) || '-'}</Typography>
                  </>
                )}
              </Grid>
            </div>
          </Grid>
        </Card>
      )}
    </>
  )
}

export default Set;