import AnnouncementRoundedIcon from '@material-ui/icons/AnnouncementRounded'
import styles from './styles.module.scss'

const WTFormHeader = ({ line1, line2}) => {
  
  return (
        <div className={styles.container}>
          <AnnouncementRoundedIcon className={styles.infoIcon} style={{ fontSize: 45 }} />
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
            <div className={styles.text}>{line1}</div>
            <div className={styles.text}>{line2}</div>
          </div>
        </div> 
  )}

export default WTFormHeader
