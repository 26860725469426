import apiIOT from 'config/api'
import { IOTLogicalUnit } from 'constants/apiResources'
import store from 'redux/store'

import { AUTH_HEADER, UTILITY_HEADER } from 'constants/apiHeaders'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getLogicalUnitsType: async (token, companyId) => {
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${token}`,
      [UTILITY_HEADER]: companyId,
    })
    const response = await apiIOT.get(IOTLogicalUnit.LOGICALUNIT_TYPES)
    return response?.data
  },
  getLogicalUnitsMap: async (id, token, companyId) => {
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${token}`,
      [UTILITY_HEADER]: companyId,
    })
    const response = await apiIOT.get(IOTLogicalUnit.LOGICALUNITS_MAP, { hierarchy_id: id })
    return response?.data
  },
  getLogicalUnitDetailById: async (id, token, companyId) => {
    const state = store.getState()
    const authInformation = state.auth['authInformation']
    if (!token) token = authInformation['token']
    if (!companyId) companyId = authInformation['companyId']
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${token}`,
      [UTILITY_HEADER]: companyId,
    })
    const response = await apiIOT.get(`${IOTLogicalUnit.LOGICALUNIT_DETAIL_BY_ID}${id}`)
    return response?.data
  },
  getLogicalUnitDetailById_devices: async (id, token, companyId) => {
    IOTLogicalUnit.logicalUnitID = id
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${token}`,
      [UTILITY_HEADER]: companyId,
    })
    const response = await apiIOT.get(`${IOTLogicalUnit.LOGICALUNIT_DEVICES_BY_ID.replace('{{PATH-LOGICAL_UNIT_ID}}', id)}`)
    return response?.data
  },
  getLogicalUnitByDeviceId: async id => {
    const state = store.getState()
    const authInformation = state.auth['authInformation']
    apiIOT.setHeaders({
      ...apiIOT.headers,
      [AUTH_HEADER]: `Bearer ${authInformation['token']}`,
      [UTILITY_HEADER]: authInformation['companyId'],
    })
    const response = await apiIOT.get(IOTLogicalUnit.LOGICALUNIT_BY_DEVICE_ID, { devuid: id }).catch(function (thrown) {
      if (apiIOT.isCancel(thrown)) {
        // console.log('Request canceled', thrown.message)
      } else {
        // handle error
        //  console.log('Request canceled err', thrown.message)
      }
    })
    if (response?.data?.logical_unit_relations?.length > 0) return response?.data
    else return null
  },
}
