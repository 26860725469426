import React, { Component } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import styles from './styles.module.scss'

class Loading extends Component {
  render() {
    return (
      <div className={styles.body}>
        {
          <div>
            <CircularProgress size={50} thickness={5} />
          </div>
        }
      </div>
    )
  }
}

export default Loading
