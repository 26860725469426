import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import styles from './styles.module.scss'
import Tooltip from '@material-ui/core/Tooltip'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded'
import { OPERATION_STATUS } from 'constants/iot'
import OperationBadge from 'app/components/OperationBadge'
import { useTranslation } from 'react-i18next'
import { LOGICAL_UNIT } from 'constants/icons'
import { kFormatter } from 'utils/stringUtils'
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded'
import PercentageBar from './PercentageBar/view'
import { getLogicalUnitIcon } from 'constants/icons'

const LogicUnits = props => {
  const { data, value, index, currentFilter, onFilterChange, ...other } = props

  const [t] = useTranslation('logicUnits')

  let total = 0
  data &&
    data.forEach(element => {
      total += element.total_green
      total += element.total_yellow
      total += element.total_red
    })

  const handleOnFilterClick = event => {
    onFilterChange(event)
  }

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && total === 0 && (
        <div className={[styles.panelContainer, styles.devices].join(' ')}>
          <div className={styles.noResults}>
            <NotInterestedRoundedIcon className={styles.noResultsIcon}></NotInterestedRoundedIcon>
            <Typography className={styles.noResultsText}>{t('no-data-available')}</Typography>
          </div>
        </div>
      )}
      {value === index && total !== 0 && (
        <div className={styles.panelContainer}>
          <div className={styles.dataRow} style={{ marginBottom: 25 }}>
            <LOGICAL_UNIT className={styles.mainIcon}></LOGICAL_UNIT>
            <div className={styles.fontTitleNumber}>{kFormatter(total, 2)}</div>
            <div className={styles.fontTitle}>{t('total-logic-units')}</div>
          </div>
          <div className={styles.dataRow}>
            <div className={styles.fontSubTitle} style={{ minWidth: 230 }}>
              {t('type-totals')}
            </div>
            <div className={styles.fontSubTitle}>{t('operation-status')}</div>
          </div>
          <div className={styles.detail}>
            {data &&
              data.map(el => (
                <div
                  key={el.internal_name}
                  className={clsx(styles.detailRow, { [styles.currentFilter]: currentFilter.byComponent[el.internal_name] })}
                >
                  <div className={styles.detailRowFirst}>
                    {currentFilter.byComponent[el.internal_name] ? (
                      <VisibilityOffRoundedIcon
                        className={[styles.viewIconTop, styles.button].join(' ')}
                        onClick={() => handleOnFilterClick(el.internal_name)}
                      />
                    ) : (
                      <VisibilityIcon
                        className={[styles.viewIconTop, styles.button].join(' ')}
                        onClick={() => handleOnFilterClick(el.internal_name)}
                      />
                    )}
                    {getLogicalUnitIcon(el.internal_name, styles.typeIcon)}
                    <Tooltip className={styles.tooltip} title={el.logical_unit_type_description} placement='top-start'>
                      <div className={styles.typeText}>{el.name.replace('_', ' ')}</div>
                    </Tooltip>
                    <div className={styles.typeNumber}>
                      <span className={styles.typeValue}>{kFormatter(el.total_green + el.total_yellow + el.total_red, 2)}</span>{' '}
                      <span className={styles.typeUnit}> unid.</span>
                    </div>
                  </div>
                  <PercentageBar
                    alert={el.total_red}
                    warning={el.total_yellow}
                    correct={el.total_green}
                    filter={Object.keys(currentFilter.byStatus).filter(item => currentFilter.byStatus[item] === true)}
                  />
                </div>
              ))}
          </div>
          <Divider light />
          <div className={styles.alertTypes}>
            <div className={clsx(styles.alertTypesRow, { [styles.currentFilter]: currentFilter.byStatus[OPERATION_STATUS.ALERT] })}>
              <OperationBadge type={OPERATION_STATUS.ALERT} />
              <Typography variant='body2' className={styles.operationStatusTypo}>
                {t('alert')}
              </Typography>
              {currentFilter.byStatus[OPERATION_STATUS.ALERT] ? (
                <VisibilityOffRoundedIcon
                  className={[styles.viewIconTop, styles.button].join(' ')}
                  onClick={() => handleOnFilterClick(OPERATION_STATUS.ALERT)}
                />
              ) : (
                <VisibilityIcon
                  className={[styles.viewIconTop, styles.button].join(' ')}
                  onClick={() => handleOnFilterClick(OPERATION_STATUS.ALERT)}
                />
              )}
            </div>
            <div className={clsx(styles.alertTypesRow, { [styles.currentFilter]: currentFilter.byStatus[OPERATION_STATUS.WARNING] })}>
              <OperationBadge type={OPERATION_STATUS.WARNING} />
              <Typography variant='body2' className={styles.operationStatusTypo}>
                {t('warning')}
              </Typography>
              {currentFilter.byStatus[OPERATION_STATUS.WARNING] ? (
                <VisibilityOffRoundedIcon
                  className={[styles.viewIconTop, styles.button].join(' ')}
                  onClick={() => handleOnFilterClick(OPERATION_STATUS.WARNING)}
                />
              ) : (
                <VisibilityIcon
                  className={[styles.viewIconTop, styles.button].join(' ')}
                  onClick={() => handleOnFilterClick(OPERATION_STATUS.WARNING)}
                />
              )}
            </div>
            <div className={clsx(styles.alertTypesRow, { [styles.currentFilter]: currentFilter.byStatus[OPERATION_STATUS.OK] })}>
              <OperationBadge type={OPERATION_STATUS.OK} />
              <Typography variant='body2' className={styles.operationStatusTypo}>
                {t('ok')}
              </Typography>
              {currentFilter.byStatus[OPERATION_STATUS.OK] ? (
                <VisibilityOffRoundedIcon
                  className={[styles.viewIconTop, styles.button].join(' ')}
                  onClick={() => handleOnFilterClick(OPERATION_STATUS.OK)}
                />
              ) : (
                <VisibilityIcon className={[styles.viewIconTop, styles.button].join(' ')} onClick={() => handleOnFilterClick(OPERATION_STATUS.OK)} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

LogicUnits.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default LogicUnits
