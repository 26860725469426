import { Button, Grid, IconButton, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CloseRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react';
import Cron, { HEADER } from 'react-cron-generator';
import './cron_styles.scss';


function CronSchedulerModal({ open, handleClose, handleCronChange, initialValue }) {
	const useStyles = makeStyles((theme) => ({
    close: {
      position: 'absolute',
      right: 15,
      top: 8,
      zIndex: 99,
    },

    dialogContent: {
      minHeight: 'auto',
      width: 'auto',
      borderBottom: 'none',
      minWidth:'800px',
    },

    containerButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '10px',
      gap:10
    },
  }));

	const [t] = useTranslation('devices')

  const classes = useStyles();
  const options = {
    headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.HOURLY, HEADER.DAILY]
  };
  const translationsObj = require('./cron_translation.json');
  const [cron, setCron] = useState(null);
  const [cronText, setCronText] = useState(null);

	useEffect(() => {
		if (initialValue) {
			setCron(initialValue);
		}
		return () => {
			setCron(null);
			setCronText(null);
		}
	}, [initialValue, open]);

  return (
      <Dialog open={open} scroll="paper" fullWidth={false} disableEscapeKeyDown maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        <Grid container justifyContent="flex-start" alignItems="center">
          <span>{t('deviceCronSchedulerTitle')}</span>
        </Grid>
        <IconButton
          className={classes.close}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseRounded />
        </IconButton>
      </DialogTitle>

      <DialogContent className={[classes.dialogContent, 'wiot_cron_builder'].join(' ')} dividers={true}>
				<Cron
          onChange={(e, c) => {
            setCron(e);
            setCronText(c);
          }}
          value={cron}
          locale = {'es'}
          showResultText={true}
          showResultCron={false}
          options={options}
          translateFn={(t)=>{
            return translationsObj[t];
          }}
        />
        <div className={classes.containerButton}>
        <Button
            disabled={false}
            variant="outlined"
            onClick={() => {
              handleClose()
            }}
          >
            {t('deviceCronSchedulerCancelButton')}
          </Button>
          <Button
            disabled={false}
            variant="outlined"
            onClick={() => {
                handleCronChange(cron,cronText);
                handleClose();
            }}
          >
            {t('deviceCronSchedulerApplyButton')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CronSchedulerModal;
