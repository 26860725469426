import { sanitizeCoordinates } from './geoUtils';


/**
 * 
 * @param {*} logicalUnit 
 * @returns 
 */
const getCoordinates = (hierarchy) => {
    if (!hierarchy) return [0,0];

	// extract coordinates
	let longitude = hierarchy?.geo_position?.map_info?.geometry?.coordinates[1] || 0;
	let latitude = hierarchy?.geo_position?.map_info?.geometry?.coordinates[0] || 0;
  
	return sanitizeCoordinates([longitude, latitude]);
}

export { getCoordinates };