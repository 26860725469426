/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'
import { push } from 'connected-react-router'

import { ROUTES } from 'constants/routes'

import DeviceReports from './screens/DeviceReports'
import { Skeleton } from '@material-ui/lab'

const renderSkeleton = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton animation='wave' height={60} width={300} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div style={{ width: '100%' }}>
            <Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
						<Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
            <Skeleton animation='wave' height={40} />
          </div>
        </div>
      </div>
    )
  }

const DeviceTypeReports = ({ match }) => {
  const dispatch = useDispatch()

  const [iotTypeDescription, setIotTypeDescription] = useState('')
	const [iotDeviceTypeReports, setIotDeviceTypeReports] = useState([])
	const [loading, setLoading] = useState(true)

	const iotDevicesTypesReports = useSelector(state => state.iot.iotDevicesTypesReports)

	useEffect(() => {
		if (iotDevicesTypesReports.length > 0) {
			let devTypeReport = iotDevicesTypesReports.find(type => type.name === match.params.type)
			if (!devTypeReport) {
				dispatch(push(ROUTES.IOT_DEVICE_TYPES))
			} else {
				setIotTypeDescription(devTypeReport?.description)
				setIotDeviceTypeReports(devTypeReport?.iot_utility_type_reports);
				setLoading(false)
			}
		}
	}, [iotDevicesTypesReports])

  const handleGoBack = () => {
    dispatch(push(ROUTES.IOT_DEVICE_TYPES))
  }

  return (
    <Grid container style={{ padding: 40 }} direction="column">
      {!loading ? <DeviceReports
        deviceReportList={iotDeviceTypeReports}
        iotTypeName={match.params.type}
        iotTypeDescription={iotTypeDescription}
        onGoBack={handleGoBack}>
      </DeviceReports> : renderSkeleton()}
    </Grid>
  )
}

export default DeviceTypeReports
