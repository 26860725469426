import { Skeleton } from '@material-ui/lab'
import { getDeviceIcon } from 'constants/icons'
import { useEffect, useState } from 'react'
import styles from './styles.module.scss'

const Summary = ({ deviceSelected, loading }) => {
  const [iotType, setIotType] = useState(deviceSelected?.iot_type.name.replaceAll('_', ' '))
  const [devUid, setDevUid] = useState(deviceSelected?.devuid)
  const [deviceIcon, setDeviceIcon] = useState(getDeviceIcon(deviceSelected?.iot_type.name, styles.icon))

  useEffect(() => {
    setIotType(deviceSelected?.iot_type.name.replaceAll('_', ' '))
    setDevUid(deviceSelected?.devuid)
    setDeviceIcon(getDeviceIcon(deviceSelected?.iot_type.name, styles.icon))
  }, [deviceSelected])

  return (
    <>
      {loading ? (
        <div className={styles.container}>
          <Skeleton animation="wave" style={{ width: 150, height: 50 }} />
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.row} style={{ opacity: 0.5 }}>
            {deviceIcon}
            <div>{iotType}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.serial}>Serie: </div>
            <div className={styles.serial}>{devUid}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default Summary
