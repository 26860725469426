import { sanitizeCoordinates } from './geoUtils';

/**
 * 
 * @param {*} logicalUnit 
 * @returns 
 */
const getCoordinates = (logicalUnit) => {
    if (!logicalUnit) return [0,0];

	// extract coordinates
	let longitude = logicalUnit?.geo_position?.map_info?.geometry?.coordinates[1] || 0;
	let latitude = logicalUnit?.geo_position?.map_info?.geometry?.coordinates[0] || 0;
  
	return sanitizeCoordinates([longitude, latitude]);
}

export { getCoordinates };