/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { Card, Grid, Typography, Button, MobileStepper } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { INSTALLED, NOT_AVAILABLE, STOCK } from 'constants/icons'
import { OPERATION_COMPONENT } from 'constants/iot'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { getIn } from 'seamless-immutable'
import Set from './components/Set'
import styles from './styles.module.scss'


import { makeStyles, useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Map from './components/Map';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Installation = ({ loading = true, logicalUnits, deviceDetail, userRoles, dispatch }) => {
  const [t] = useTranslation('devices')
  const [stock, setStock] = useState(false)
  const [installed, setInstalled] = useState(false)
  const [notAvailable, setNotAvailable] = useState(false)
  const [activeStep, setActiveStep] = useState(0);
  const [countRelations, setCountRelations] = useState(0);
  const [relationToShow, setRelationToShow] = useState();
  const [isFirstRun, setIsFirstRun] = useState()

  const classes = useStyles();
  const theme = useTheme();


  useEffect( () => {
    dispatch(iotActions.getIotDeviceRelations())
    dispatch(iotActions.getIotLogicalUnitRelatedToDevice(deviceDetail.devuid))

    const connected = deviceDetail.connected_network
    const available = deviceDetail.available
    !connected && available ? setStock(true) : connected && available ? setInstalled(true) : setNotAvailable(true)
    // setCountRelations(logicalUnits.length)
    // setRelationToShow(logicalUnits[activeStep])
    
    setIsFirstRun(false)    
  }, [])

  useEffect(() => {
    //se verifica el tipo de dispoistivo porque la estructua del logicalUnit retornado por el selector depende de eso
    if (deviceDetail.iot_type.name === OPERATION_COMPONENT.TELEC_PS_CON) {
      // setType(logicalUnit?.logical_unit?.iot_logical_unit_type?.internal_name)
    } else {
      if (logicalUnits && logicalUnits.length > 0) {
        setCountRelations(logicalUnits.length)
        setRelationToShow(logicalUnits[activeStep])
      }
    }
  }, [logicalUnits])

  useEffect(() => {
    if (logicalUnits && logicalUnits.length > 0) {
      setRelationToShow(logicalUnits[activeStep])
    }
  }, [activeStep])

  const renderSkeleton = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 275, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Skeleton animation='wave' height={60} width={180} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
          <div style={{ width: '100%' }}>
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
            <Skeleton animation='wave' height={30} />
          </div>
        </div>
      </div>
    )
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isActualLogicalUnit = () => {
    return activeStep===0 && relationToShow.active; // supose list of logicalUnits are oreder by must recent date_start
  }

  return (
    <>
      {loading ? (
        renderSkeleton()
      ) : (
        <div style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }}>
          <Card style={{ paddingTop: 20, paddingLeft: 20, marginBottom: 20, overflow: 'unset' }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <div style={{ minWidth: 300 }}>
                <Grid className={styles.firstGrid} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                  {loading || !deviceDetail ? (
                    <Skeleton animation="wave" style={{ width: '85%', height: 21 }} />
                  ) : (
                    <>
                      <Typography style={{ opacity: 0.7 }}>Situación actual del dispositivo:</Typography>
                      <br></br>
                      {installed && (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <INSTALLED fontSize="medium" style={{ marginRight: 10, marginTop: 3, marginBottom: 25 }}></INSTALLED>
                          <Typography variant="h6">{t('installed')}</Typography>
                        </div>
                      )}
                      {stock && (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <STOCK fontSize="medium" style={{ marginRight: 10, marginTop: 3, marginBottom: 25 }}></STOCK>
                          <Typography variant="h6">{t('stock')}</Typography>
                        </div>
                      )}
                      {notAvailable && (
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <NOT_AVAILABLE fontSize="medium" style={{ marginRight: 10, marginTop: 3, marginBottom: 25 }}></NOT_AVAILABLE>
                          <Typography variant="h6">{t('notAvailable')}</Typography>
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              </div>
            </Grid>
          </Card>
          {relationToShow && (
            <>
              <div style={{ display: 'flex', width: '100%', height: '69%'}}>
                <div style={{ paddingRight: '10px' }}>
                  <Set logicalUnit={relationToShow} isActualLogicalUnit={isActualLogicalUnit()} loading={loading} />
                </div>
                <div style={{ width: '-webkit-fill-available', height: '99%', position: 'relative'}}>
                  <Map logicalUnitRelation={relationToShow}/>
                </div>
              </div>

              <MobileStepper
                variant="dots"
                steps={countRelations}
                position="static"
                activeStep={activeStep}
                className={classes.root}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={activeStep === (countRelations - 1)}>
                    { activeStep === (countRelations - 1) ? 'Primera' : 'Instalación Anterior' }
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    { activeStep === 0 ? 'Actual' : 'Instalación Posterior' }
                  </Button>
                }
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

const mapStateToProps = store => ({
  userRoles: getIn(store, ['auth', 'userRoles']),
  deviceDetail: getIn(store, ['iot', 'iotDeviceDetail']),
  logicalUnits: getIn(store, ['iot', 'iotLogicalUnitsRelatedToDevice']),
  loading: getIn(store, ['iot', 'iotLogicalUnitsRelatedToDeviceLoading']),
})
export default connect(mapStateToProps)(Installation)
