import { Card, Tab, Tabs } from '@material-ui/core';
import styles from './styles.module.scss';

import ListRoundedIcon from '@material-ui/icons/ListRounded';
import React from 'react';
import DetailInformation from './components/DetailInformation';
import { Skeleton } from '@material-ui/lab';

const renderSkeleton = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 200, paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Skeleton animation='wave' height={60} width={180} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
        <div style={{ width: '100%' }}>
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
          <Skeleton animation='wave' height={30} />
        </div>
      </div>
    </div>
  )
}

const DeviceGroupDetail = ({loading, group}) => {
	const [activeTab, setActiveTab] = React.useState(0);

	const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
	};

	return (
		<>
			{loading ? renderSkeleton() : (
			<Card style={{ minHeight: 210,  display: 'flex', flexDirection: 'column', paddingLeft:20, paddingRight:20 }}>
	  			<Tabs
					value={activeTab}
					className={styles.tabs}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
				>
					<Tab
						style={{ minWidth: '140px' }}
						className={styles.tab}
						label={
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<ListRoundedIcon style={{ fontSize: 25, marginRight: 10 }}></ListRoundedIcon>
							<span>Detalle</span>
							</div>
						}
					/>
				</Tabs>
				<div className={styles.tabContent}>
					{activeTab === 0 && <DetailInformation loading={loading} group={group} />}
				</div>
				</Card>
			)}

		</>

  );
}

export default DeviceGroupDetail