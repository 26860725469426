/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getIn } from 'seamless-immutable';

import MUIDataTable from 'mui-datatables';

import { Typography, Tooltip, withStyles, IconButton } from '@material-ui/core';
import PortableWifiOffRoundedIcon from '@material-ui/icons/PortableWifiOffRounded';
import WifiTetheringRoundedIcon from '@material-ui/icons/WifiTetheringRounded';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';

import { getTableData } from './tableProvider';
import styles from './styles.module.scss';


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 450,
    overflowY: 'scroll',
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'monospace',
  },
}))(Tooltip);

const ServerGatewaysContainer = ({ networkServerSelected }) => {
  const [t] = useTranslation('devices');
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const gateways = networkServerSelected.iot_server_gateways;
    if (gateways.length > 0) {
      let data = getTableData(gateways, t);
      setTableData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkServerSelected]);

  const columns = [
    {
      name: 'id',
      label: t('networkServerGatewayTableIdTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'eui',
      label: t('networkServerGatewayTableEuiTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'name',
      label: t('networkServerGatewayTableNameTitleColumn'),
      options: {
        sort: true,
      },
    },
    {
      name: 'connectionStatus',
      label: t('networkServerGatewayTableConnectionStatusTitleColumn'),
      options: {
        sort: true,
        sortCompare: (order) => (obj1, obj2) => {
          return order === 'asc'
            ? obj1.data.status.localeCompare(obj2.data.status)
            : -obj1.data.status.localeCompare(obj2.data.status);
        },
        setTableProps: () => ({
          size: 'small',
        }),
        customBodyRender: (data) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {data.status === t('on') ? (
                  <WifiTetheringRoundedIcon
                    style={{ fontSize: 20 }}
                  ></WifiTetheringRoundedIcon>
                ) : (
                  <PortableWifiOffRoundedIcon
                    style={{ fontSize: 20 }}
                  ></PortableWifiOffRoundedIcon>
                )}
                <div style={{ marginLeft: 10 }}>{data.status}</div>
              </div>
              <Typography className={styles.statusDate}>{data.date}</Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'last_review',
      label: t('networkServerEventsTableLastReviewDateTitle'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => {
          return <span>{moment(data).format('DD-MM-YYYY HH:mm:ss')}</span>;
        },
      },
    },
    {
      name: 'data',
      label: t('networkServerEventsTableMetaDataTitleColumn'),
      options: {
        filter: false,
        display: 'true',
        searchable: false,
        customBodyRender: (data) => (
          <HtmlTooltip
            placement="left-start"
            title={JSON.stringify(data || { }, null, 4)}
          >
            <IconButton size="small">
              <CodeRoundedIcon fontSize="small" />
            </IconButton>
          </HtmlTooltip>
        ),
      },
    },
  ];

  const options = {
    elevation: 1,
    pagination: false,
    filter: false,
    print: false,
    search: false,
    selectableRows: 'none',
    responsive: 'vertical',
    tableBodyHeight: 'calc(100% - 125px)',
    download: false,
    viewColumns: false,
    setTableProps: () => ({
      size: 'small',
    }),
    textLabels: {
      body: {
        noMatch: 'No hay registros',
        toolTip: 'Ordernar',
      },
    },
    // setRowProps: (row, index) => {
    //   return {
    //     className:
    //       tableData[index].connectionStatus.status === t('off')
    //         ? styles.backRowAlert
    //         : null,
    //   };
    // },
  };

  return (
    <>
      <MUIDataTable
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Gateways relacionados</Typography>
          </div>
        }
        data={tableData}
        columns={columns}
        options={options}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  networkServerSelected: getIn(store, ['iot', 'iotNetworkServerDetail']),
});

export default connect(mapStateToProps)(memo(ServerGatewaysContainer));
