import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import Immutable from 'seamless-immutable';

import { actions as authActions } from './auth/actions';
import { reducer as alert } from './alert/reducer';
import { reducer as auth } from './auth/reducer';
import { reducer as utility } from './utility/reducer';
import { reducer as ioTHierarchy } from './ioTHierarchy/reducer';
import { reducer as iotLogicalUnit } from './ioTLogicalUnit/reducer'
import { reducer as iotDeviceParams } from './iotDeviceParams/reducer'
import { reducer as iot } from './iotHandlers'; 

export const history = createBrowserHistory()

const reducers = combineReducers({
  alert,
  auth,
  utility,
  ioTHierarchy,
  iotLogicalUnit,
  iotDeviceParams,
  iot,
  router: connectRouter(history)
})

const middlewares = [routerMiddleware(history)]
const enhancers = []

/* ------------- Thunk Middleware ------------- */
middlewares.push(thunk)

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middlewares))

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const getGlobalState = () =>
  Immutable({
    auth: { initialLoading: false },
  })

const rootReducer = (state, action) => {
  if (action.type === authActions.SIGN_OUT) {
    return reducers(getGlobalState(state), action)
  }
  return reducers(state, action)
}

const store = createStore(rootReducer, composeEnhancers(...enhancers))

export default store