/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import moment from 'moment-timezone'
import MUIDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { actionCreators as iotActions } from 'redux/iotHandlers'
import { OPERATION_STATUS } from 'constants/iot'
import { DEFAULT_PAGE_NUMBER, pageSizeOptions } from './constants';

import OperationBadge from 'app/components/OperationBadge'
import { CloseRounded } from '@material-ui/icons'

const CronExecutionsModal = ({reportCronId, open, handleClose }) => {
	const useStyles = makeStyles((theme) => ({
    close: {
      position: 'absolute',
      right: 15,
      top: 8,
      zIndex: 99,
    },
		root: {
			color: '#2E2E2E',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
		color: {
			width: '52px',
			height: '25px',
			borderRadius: '3px',
			cursor:'pointer',
       //borderWidth: '2px',
			borderColor: '#303030',
			marginTop:'8px',
       //borderStyle:'solid'
		},
		swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      background: '#303030',
      position: 'absolute',
      padding: 10,
      zIndex: '2',
      borderRadius:6,
      marginTop:10,
      boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }));

	const classes = useStyles()

	const [t] = useTranslation('devices')

	const [tableData, setTableData] = useState([])
	const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER)
	const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [isFirstRun, setIsFirstRun] = useState(true)

  const dispatch = useDispatch()

	const workerExecutions = useSelector((state) => state.iot.iotWorkerExecutions).rows;
	const totalWorkerExecutions = useSelector((state) => state.iot.iotWorkerExecutions).totalWorkerExecutions;
	const loading = useSelector((state) => state.iot.iotWorkerExecutionsLoading);
	const cronDetail = useSelector((state) => state.iot.iotTypeReportCronDetail);
	const loadingCronDetail = useSelector((state) => state.iot.iotTypeReportCronDetailLoading);

	const getWorkerExecutions = useCallback(() => {
		dispatch(iotActions.getWorkerExecutions(reportCronId, currentPage, pageSize))
	})

	const handleChangePage = (_currentPage, _pageSize) => {
    setCurrentPage(_currentPage)
    if (_pageSize) setPageSize(_pageSize)
  }

	useEffect(() => {
    if (workerExecutions) {
      if (isFirstRun && workerExecutions[`1`]) {
        setIsFirstRun(false)
      }
      setTableData(workerExecutions[`${currentPage}`])
    }
  }, [workerExecutions])

  useEffect(() => {
		if (!workerExecutions) {
			getWorkerExecutions()
			setTableData(workerExecutions)
		}
    return () => {
      dispatch(iotActions.cleanWorkerExecutions())
    }
  }, [])

	useEffect(() => {
    if (!workerExecutions || (workerExecutions && !workerExecutions[`${currentPage}`])) getWorkerExecutions()
    else setTableData(workerExecutions[`${currentPage}`])
  }, [currentPage])

  const columns = [
    {
      name: 'executedAt',
      label: t('deviceCronLogsTableLabelExecutedAt'),
			options: {
				customBodyRenderLite: (dataIndex) => {
					const dateTime = tableData[dataIndex].executedAt
					return dateTime ? moment(dateTime, 'YYYY/MM/DD HH:mm').format('DD/MM/YYYY HH:mm') : ''
				},
        sort: true,
        sortCompare: order => (obj1, obj2) => {
					let val1 = obj1.data === '' ? Infinity : moment(obj1.data).valueOf()
          let val2 = obj2.data === '' ? Infinity : moment(obj2.data).valueOf()
          let res = val1 - val2
          return order === 'asc' ? res : -res
        },
      },
		},
		{
      name: 'result',
      label: t('deviceCronLogsTableLabelResult'),
      options: {
				sort: true,
				customBodyRender: outcome => {
					return outcome ? (
						<Typography variant="body2" className={styles.success}>OK</Typography>
					) : (
							<OperationBadge type={OPERATION_STATUS.ALERT} label="Error" />
					)
				}
      },
		},
    {
      name: 'file_name',
      label: t('deviceCronLogsTableLabelFileName'),
      options: {
        sort: true,
      },
		},
  ]

  const options = {
		elevation: 1,
    filter: false,
		print: false,
		sort: false,
		search: false,
    selectableRows: 'none',
    responsive: 'simple',
		tableBodyHeight: 'auto',
		download: false,
		viewColumns: false,
		serverSide: true,
		count: totalWorkerExecutions,
		rowsPerPage: pageSize,
		rowsPerPageOptions: [],
    setTableProps: () => ({
      size: 'small',
		}),
		onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page + 1, null)
          break
        case 'sort':
          //this.sort(tableState.page, tableState.sortOrder);
          break
        default:
        //console.log('action not handled.');
      }
    },
    textLabels: {
      body: {
        noMatch: 'Sin registros',
        toolTip: 'Ordernar',
      },
      pagination: {
        next: 'Página siguiente',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
        jumpToPage: 'Ir a la página:',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver columnas',
        filterTable: 'Filtrar tabla',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'Limpiar',
      },
      viewColumns: {
        title: 'Mostrar columnas',
        titleAria: 'Mostrar/Ocultar columnas',
      },
    },
  }

  return (
    <>
			{
				loading && isFirstRun
					? <CircularProgress />
					: <Dialog
							className={styles.modal}
							open={open}
							scroll="paper"
							fullWidth={true}
							maxWidth="lg"
							disableEscapeKeyDown
						>
							<DialogTitle>
								<Grid container justifyContent="flex-start" alignItems="center">
									<span>{'Ejecuciones'}</span>
								</Grid>
								<IconButton
									className={classes.close}
									aria-label="close"
									onClick={handleClose}
								>
									<CloseRounded />
								</IconButton>
							</DialogTitle>
							<DialogContent className={styles.dialogContent} dividers={true}>
							<Typography variant="body2" className={styles.title}>
								{loadingCronDetail ? <CircularProgress /> : cronDetail.title}
							</Typography>
							<Typography variant="body2" className={styles.description}>
								{loadingCronDetail ? <CircularProgress /> : cronDetail.description}
							</Typography>
								<MUIDataTable
									data={tableData}
									columns={columns}
									options={options}
								/>
							</DialogContent>
						</Dialog>
			}
    </>
  );
}

export default CronExecutionsModal