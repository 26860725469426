import { Button, Card, Divider, Fade, Grid, Paper, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import OperationStatus from 'app/components/OperationStatus'
import { getDeviceIcon, LOGICAL_UNIT_BUILD } from 'constants/icons'
import { useEffect, useState } from 'react'

import Collapse from '@material-ui/core/Collapse'
import InputBase from '@material-ui/core/InputBase'
import ClearIcon from '@material-ui/icons/Clear'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import ioTLogicalUnitActions from 'redux/ioTLogicalUnit/actions'

import moment from 'moment'

import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded'
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded'
import RoomRoundedIcon from '@material-ui/icons/RoomRounded'
import SettingsInputAntennaRoundedIcon from '@material-ui/icons/SettingsInputAntennaRounded'
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded'
import Skeleton from '@material-ui/lab/Skeleton'
import OperationBadge from 'app/components/OperationBadge'
import { OPERATION_STATUS } from 'constants/iot'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles.module.scss'

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 5,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

const BuildDetailView = ({ logicalUnit, viewDetail, onDeviceSelect, idChildren }) => {
  const [t] = useTranslation('logicUnitDetail')
  const dispatch = useDispatch()
  const classes = useStyles()
  //const iotLogicalUnitDetailLoading = useSelector(state => state.iotLogicalUnit.iotlogicalunitDetailLoading)
  const iotLogicalUnitChildrenLoading = useSelector(state => state.iotLogicalUnit.iotlogicalunitchildrenLoading)
  const devices = useSelector(state => state.iotLogicalUnit.iotlogicalunitSelected.devices)
  const [inputValue, setInputValue] = useState('')
  let children = [...logicalUnit?.logical_unit?.children].sort(function (a, b) {
    if (a.status_operation === 'R') return -1
    if (a.status_operation === 'Y' && b.status_operation === 'R') return 1
    if (a.status_operation === 'Y' && b.status_operation === 'G') return -1
    if ((a.status_operation === 'G' && b.status_operation === 'R') || b.status_operation === 'Y') return 1
    return 0
  })
  const [expanded, setExpanded] = useState({})
  const [filteredChildren, setFilteredChildren] = useState(children)

  const getLogicalUnitDetailChildren = id => {
    dispatch(ioTLogicalUnitActions.getLogicalUnitDetailChildren(id))
  }

  const handleExpandClick = async id => {
    !expanded[id] && (await getLogicalUnitDetailChildren(Number(id)))
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    })
  }

  const handleOnChange = e => {
    setInputValue(e.target.value)
    setFilteredChildren(
      children.filter(
        child =>
          child.external_id.replaceAll('_', ' ').toLowerCase().includes(e.target.value.toLowerCase()) ||
          child.external_name.toLowerCase().includes(e.target.value.toLowerCase()),
      ),
    )
  }

  const deleteAll = () => {
    setInputValue('')
    setFilteredChildren(children)
  }

  useEffect(() => {
    idChildren && handleExpandClick(idChildren)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!logicalUnit ? (
        <Skeleton animation="wave" variant="rect" width={460} height={800}></Skeleton>
      ) : (
        <>
          <div className={styles.detailWrapper}>
            <div className={styles.detailHeader}>
              <div className={styles.titleBlock}>
                <LOGICAL_UNIT_BUILD className={styles.iconType} />
                <Typography className={styles.title}>{logicalUnit.logical_unit.iot_logical_unit_type.description}</Typography>
              </div>

              <div>
                <Typography className={styles.subtitleId}>{logicalUnit.logical_unit.external_id}</Typography>
                <Typography className={styles.subtitleName}>{logicalUnit.logical_unit.external_name}</Typography>

                <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ marginBottom: 20, opacity: 0.8 }}>
                  <RoomRoundedIcon style={{ marginRight: 4, height: 18, width: 18 }}></RoomRoundedIcon>
                  <Typography className={styles.subtitleAddress}>
                    {logicalUnit.logical_unit.geo_position?.geografic_info.address
                      ? `${logicalUnit.logical_unit.geo_position?.geografic_info.address}, `
                      : ''}
                    {logicalUnit.logical_unit.geo_position?.geografic_info.city
                      ? `${logicalUnit.logical_unit.geo_position?.geografic_info.city}, `
                      : ''}
                    {logicalUnit.logical_unit.geo_position?.geografic_info.locality}
                  </Typography>
                </Grid>
              </div>
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              {children && <Typography variant="h6">{`${t('related-elements')} (${children.length} unid.)`} </Typography>}
            </Grid>
            <Divider variant="middle" style={{ marginTop: 10, marginLeft: 0, marginRight: 0, marginBottom: 10 }} />

            <Paper elevation={0} className={styles.filter} style={{ marginBottom: 10 }}>
              <SearchIcon className={styles.searchIcon} />
              <InputBase
                value={inputValue}
                className={styles.input}
                placeholder={t('search')}
                inputProps={{ 'aria-label': 'Buscar' }}
                autoComplete={'off'}
                onChange={e => {
                  handleOnChange(e)
                }}
              />

              <Fade direction="up" in={inputValue.length > 0}>
                <ClearIcon className={styles.closeIcon} onClick={() => deleteAll()} />
              </Fade>
            </Paper>

            <Card elevation={3} className={styles.interiorCard}>
              {filteredChildren &&
                filteredChildren.map(child => (
                  <div>
                    <div className={styles.deviceRow} onClick={() => handleExpandClick(child.id)}>
                      <Typography
                        noWrap
                        variant="subtitle2"
                        className={clsx(styles.deviceTabLabel, {
                          [styles.selected]: expanded[child.id],
                        })}
                        color="textPrimary"
                      >{`${child.external_id.replaceAll('_', ' ')} - ${child.external_name}`}</Typography>
                      <div style={{ display: 'flex', marginRight: 15 }}>
                        {child.status_operation !== OPERATION_STATUS.OK && <OperationBadge type={child.status_operation} />}

                        <ExpandMoreIcon
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded[child.id],
                          })}
                          aria-expanded={expanded}
                          aria-label="show more"
                        />
                      </div>
                    </div>
                    <Divider variant="middle" />
                    <Collapse in={expanded[child.id]} timeout="auto">
                      <Fade in={expanded[child.id]}>
                        {!iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                          <div className={styles.noDataPanel}>
                            <Typography className={styles.noData}>{t('no-related-device')}</Typography>
                          </div>
                        ) : !iotLogicalUnitChildrenLoading && devices[child.id] && !devices[child.id][0].iot_device?.iot_device_brief ? (
                          <div className={styles.dataPanel}>
                            <div className={styles.row}>
                              {getDeviceIcon(devices[child.id][0]?.iot_device?.iot_type.name, styles.devicesIcon)}
                              <Tooltip title={devices[child.id] && devices[child.id][0]?.iot_device?.iot_type.description} placement="top-start">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography className={styles.deviceCod}>
                                    {`${devices[child.id] && devices[child.id][0]?.iot_device?.devuid}`}
                                  </Typography>
                                  <Typography className={styles.deviceCodLabel}>(Nro. de serie)</Typography>
                                </div>
                              </Tooltip>
                            </div>
                            <div className={styles.noDataPanel}>
                              <Typography className={styles.noData}>{t('no-data')}</Typography>
                            </div>
                          </div>
                        ) : (
                          <div className={styles.dataPanel}>
                            <div className={styles.row}>
                              {iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                                <Skeleton animation="wave" variant="circle" width={23} height={23} style={{ marginRight: '10px' }}></Skeleton>
                              ) : (
                                getDeviceIcon(devices[child.id][0]?.iot_device?.iot_type.name, styles.devicesIcon)
                              )}
                              {iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                                <Skeleton animation="wave" width={60} height={23}></Skeleton>
                              ) : (
                                devices[child.id] && (
                                  <Tooltip title={devices[child.id] && devices[child.id][0]?.iot_device?.iot_type.description} placement="top-start">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Typography className={styles.deviceCod}>
                                        {`${devices[child.id] && devices[child.id][0]?.iot_device?.devuid}`}
                                      </Typography>
                                      <Typography className={styles.deviceCodLabel}>(Nro. de serie)</Typography>
                                    </div>
                                  </Tooltip>
                                )
                              )}
                            </div>
                            <div className={styles.row}>
                              {iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                                <Skeleton animation="wave" width={300} height={23}></Skeleton>
                              ) : (
                                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: 5 }}>
                                  <div style={{ display: 'flex', alignItems: 'flex-end', opacity: 0.6 }}>
                                    <Typography className={styles.title}>Estado de operación:</Typography>
                                  </div>
                                  <div style={{ marginLeft: 110 }}>
                                    {devices[child.id][0]?.iot_device?.status_operation === OPERATION_STATUS.OK && (
                                      <OperationBadge type={devices[child.id][0]?.iot_device?.status_operation} label={t('ok')} />
                                    )}
                                    {devices[child.id][0]?.iot_device?.status_operation === OPERATION_STATUS.WARNING && (
                                      <OperationBadge type={devices[child.id][0]?.iot_device?.status_operation} label={t('warning')} />
                                    )}
                                    {devices[child.id][0]?.iot_device?.status_operation === OPERATION_STATUS.ALERT && (
                                      <OperationBadge type={devices[child.id][0]?.iot_device?.status_operation} label={t('alert')} />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            {iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                              <Skeleton animation="wave" width={300} height={23}></Skeleton>
                            ) : (
                              <div style={{ display: 'flex', alignItems: 'flex-end', opacity: 0.6 }}>
                                <SettingsInputAntennaRoundedIcon
                                  style={{ fontSize: 20, marginRight: 5, marginBottom: 1 }}
                                ></SettingsInputAntennaRoundedIcon>
                                <Typography className={styles.title}>{t('last-conn-control')}</Typography>
                              </div>
                            )}

                            <div className={styles.row}>
                              {iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                                <Skeleton animation="wave" width={300} height={23}></Skeleton>
                              ) : (
                                <Typography className={styles.dateLabel}>
                                  {moment(devices[child.id] && devices[child.id][0]?.iot_device?.iot_device_brief?.last_signal).format(
                                    'D MMM YYYY - HH:mm',
                                  )}
                                </Typography>
                              )}
                            </div>

                            {devices[child.id] &&
                            (devices[child.id][0]?.iot_device?.iot_device_brief?.iot_alarm?.status === 'pending' ||
                              devices[child.id][0]?.iot_device?.iot_device_brief?.iot_alarm?.status === 'inProgress') ? (
                              iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                                <Skeleton animation="wave" width={'100%'} height={130}></Skeleton>
                              ) : (
                                <div className={styles.operationStatusWrapper}>
                                  <OperationStatus
                                    alertType={devices[child.id] && devices[child.id][0]?.iot_device?.status_operation}
                                    alarmDate={devices[child.id] && devices[child.id][0]?.iot_device?.iot_device_brief?.iot_alarm?.executedAt}
                                    description={devices[child.id] && devices[child.id][0]?.iot_device?.iot_device_brief?.iot_alarm?.description}
                                    status={devices[child.id] && devices[child.id][0]?.iot_device?.iot_device_brief?.iot_alarm?.status}
                                  />
                                </div>
                              )
                            ) : null}
                            {iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                              <Skeleton animation="wave" width={100} height={27}></Skeleton>
                            ) : (
                              devices[child.id] && (
                                <div style={{ display: 'flex', alignItems: 'flex-end', opacity: 0.6 }}>
                                  <SpeedRoundedIcon style={{ fontSize: 20, marginRight: 5, marginBottom: 1 }}></SpeedRoundedIcon>
                                  <Typography className={styles.title}>{t('last-status')}</Typography>
                                </div>
                              )
                            )}
                            <div className={styles.row}>
                              {iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                                <Skeleton animation="wave" width={300} height={23}></Skeleton>
                              ) : (
                                devices[child.id] && (
                                  <Typography className={styles.dateLabel}>
                                    {devices[child.id] && devices[child.id][0]?.iot_device?.iot_device_brief?.iot_payload_ul?.timestamp
                                      ? moment(devices[child.id][0]?.iot_device?.iot_device_brief?.iot_payload_ul?.timestamp).format(
                                          'D MMM YYYY - HH:mm',
                                        )
                                      : 'No disponible'}
                                  </Typography>
                                )
                              )}
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginBottom: 20,
                                marginTop: 5,
                                maxHeight: 250,
                                overflowY: 'scroll',
                                paddingRight: 8,
                              }}
                            >
                              {iotLogicalUnitChildrenLoading && !devices[child.id]
                                ? null
                                : devices[child.id][0]?.iot_device?.iot_device_brief?.iot_payload_ul
                                ? devices[child.id] &&
                                  devices[child.id][0]?.iot_device?.iot_device_brief?.iot_payload_ul?.measure_values?.map(
                                    measure =>
                                      measure.value !== 'NA' && (
                                        <div className={styles.measureRow}>
                                          <Typography className={styles.label}>{measure.title}</Typography>
                                          <div className={styles.separator}></div>
                                          <Typography className={styles.value} color="secondary">{`${Number(measure.value).toLocaleString('es-AR', {
                                            minimumFractionDigits: measure.decimalView,
                                          })} ${measure.unit}`}</Typography>
                                        </div>
                                      ),
                                  )
                                : null}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                              {devices[child.id] && devices[child.id][0]?.iot_device?.iot_device_brief ? (
                                iotLogicalUnitChildrenLoading && !devices[child.id] ? (
                                  <Skeleton animation="wave" width={144} height={36}></Skeleton>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    onClick={() => onDeviceSelect(devices[child.id] && devices[child.id][0]?.iot_device?.id)}
                                    startIcon={<AccountTreeRoundedIcon size="small"></AccountTreeRoundedIcon>}
                                  >
                                    Ficha de dispositivo
                                  </Button>
                                )
                              ) : null}
                            </div>
                          </div>
                        )}
                      </Fade>
                    </Collapse>
                  </div>
                ))}

              {filteredChildren.length === 0 && inputValue.length > 0 && (
                <Fade in={true} style={{ display: 'flex', flexGrow: 1 }}>
                  <div className={styles.noResults}>
                    <NotInterestedRoundedIcon className={styles.noResultsIcon}></NotInterestedRoundedIcon>
                    <Typography className={styles.noResultsText}>{t('no-search-results')}</Typography>
                  </div>
                </Fade>
              )}
            </Card>
          </div>
        </>
      )}
    </>
  )
}

export default BuildDetailView
