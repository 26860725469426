import { stringArrayToObject, snackbarDispatcher } from '../utils';

/* ------------- Alert actions ------------- */
export const actions = stringArrayToObject(
  [
    'OPEN_ALERT_DIALOG',
    'CLOSE_ALERT_DIALOG',
    'SUCCESS',
    'ERROR',
    'INFO',
    'CLOSE_ALERT_SNACKBAR',
    'SHOW_NOTIFICATION'
  ],
  '@@ALERT'
);

export const actionCreators = {
  openAlertDialog(options) {
    return {
      type: actions.OPEN_ALERT_DIALOG,
      payload: {
        ...options,
        onDismiss: { func: params => (options.onDismiss ? options.onDismiss(params) : () => null) }
      }
    };
  },
  closeAlertDialog: () => dispatch => {
    dispatch({ type: actions.CLOSE_ALERT_DIALOG });
  },
  success: (message, topRight, timeDuration = 10000) => async dispatch => {
    snackbarDispatcher(dispatch, actions.SUCCESS, {
      message,
      type: 'success',
      open: true,
      topRight,
      timeDuration
    });
  },
  error: (message, topRight, timeDuration = 10000) => async dispatch => {
    snackbarDispatcher(dispatch, actions.ERROR, {
      message,
      type: 'error',
      open: true,
      topRight,
      timeDuration
    });
  },
  info: (message, topRight, timeDuration = 10000) => async dispatch => {
    snackbarDispatcher(dispatch, actions.INFO, {
      message,
      type: 'info',
      open: true,
      topRight,
      timeDuration
    });
  },
  closeAlertSnackbar() {
    return {
      type: actions.CLOSE_ALERT_SNACKBAR
    };
  },
  showNotification: ({ title, tag, body, icon }) => ({
    type: actions.SHOW_NOTIFICATION,
    payload: { title, tag, body, icon, date: new Date() }
  })
};

export default actionCreators;
