export const parseJson = json => {
  try {
    return JSON.parse(json)
  } catch (e) {
    return json
  }
}

export const kFormatter = (num, numberOfDecimals) => {
  return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(numberOfDecimals) + 'k' : Math.sign(num) * Math.abs(num)
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const removeDiacritics = string => {
  return string.normalize('NFD').replace(/\p{Diacritic}/gu, '')
}

export const normalizeString = string => {
  return removeDiacritics(string.toLowerCase().replace(/\s/g, '_'))
}

export const isEmail = string => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(string)
}

export const removeSpecialCharactersFromStringButKeepDotCommaDashUnderscore = string => {
	return string.replace(/[^a-zA-Z0-9.,-_ ]/g, '')
}
