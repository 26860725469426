import UtilityService from 'services/UtilityService';
//import { ROUTES } from 'constants/routes';
import { stringArrayToObject } from '../utils';

import ioTHierarchyActions from 'redux/ioTHierarchy/actions'
import iotLogicalUnitsActions from 'redux/ioTLogicalUnit/actions'
import { actionCreators as iotDevicesActions } from 'redux/iotHandlers'; //'redux/ioTDevice/actions'

export const actions = stringArrayToObject(
    ['HEALTH_CHECK', 'HEALTH_CHECK_SUCCESS', 'HEALTH_CHECK_FAILURE', 'INIT', 'INIT_SUCCESS'], '@@CONFIG');

const privateActionCreators = {
  healthCheckSuccess: payload => ({
    type: actions.HEALTH_CHECK_SUCCESS,
    payload
  }),
  healthCheckFailure: payload => ({
    type: actions.HEALTH_CHECK_FAILURE,
    payload
  })
};

export const actionCreators = {
  init: () => async dispatch => {
    dispatch({ type: actions.INIT, target: 'init' });
    await dispatch(actionCreators.healthCheck());    
  },
  healthCheck: () => async dispatch => {
    dispatch({ type: actions.HEALTH_CHECK, target: 'healthCheck' });
    await UtilityService.healthCheck()
    .then((response) => {
      if ( response && !response.disable_access_backoffice) {
        // TODO: Change this when this service response is updated
        response = { data: { healthy: true, message: 'success', display_message: null, checkedAt: response.checkedAt } };
        dispatch(privateActionCreators.healthCheckSuccess(response.data));
        //if (!response.data?.healthy) dispatch(push(ROUTES.MAINTENANCE));
      } else {
        dispatch(privateActionCreators.healthCheckFailure(response?.problem));
        //dispatch(push(ROUTES.MAINTENANCE));
      }
    })
  },
  /**
   * **Utility Actions**
   * Dispatch actions to initialize the states 
   * - ***iothierarchies*** and ***iothierarchiesBrief*** at Hierarchy reducer 
   * - ***iotlogicalunitType*** at Logical Unit reducer
   * - ***iotDevicesType*** at Device reducer
   * @async
   * @action
   * @param {none}  
   * @returns update states 
   */
   init_States_Nomenclature: () => async dispatch => {
      const getHierarchies = async () => {
        await dispatch(ioTHierarchyActions.initHierarchyList()) //get Hierarchies
      }
      await getHierarchies()

      const getLogicalUnitsType = async () => {
        await dispatch(iotLogicalUnitsActions.initLogicalUnitsType()) //get Logical Units Type
      }
      await getLogicalUnitsType()

      const getDevicesType = async () => {
        await dispatch(iotDevicesActions.initDevicesType()) //get Devices Type
      }
      await getDevicesType()
  }
};

export default actionCreators;